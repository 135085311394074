export function paginationArray(
  maxPages: number,
  totalPages: number,
  currentPage: number
) {
  if (totalPages <= maxPages) {
    const pageArray = [];
    for (let i = 1; i <= totalPages; i++) {
      pageArray.push(i);
    }
    return pageArray;
  }

  const pageSet = new Set<number>();
  let offset = 1;
  pageSet.add(1); // always include first page
  pageSet.add(totalPages); // always include last page
  pageSet.add(currentPage); // always include current page
  while (pageSet.size < maxPages - 2) {
    if (currentPage + offset < totalPages) {
      pageSet.add(currentPage + offset);
    }
    if (currentPage - offset > 0) {
      pageSet.add(currentPage - offset);
    }
    offset++;
  }

  const pages = Array.from(pageSet).sort((a, b) => a - b);
  const pageArray = [pages[0]];
  for (let i = 0; i < pages.length - 1; i++) {
    if (pages[i] + 1 < pages[i + 1]) {
      pageArray.push(-1);
    }
    pageArray.push(pages[i + 1]);
  }

  return pageArray;
}
