import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { merge } from 'rxjs';
import {
  DesktopLicenseInvoiceDetails,
  InvoiceDetails,
  InvoicesService,
} from 'src/app/services/invoices.service';
import {
  DesktopLicenseWithProduct,
  LicensesService,
} from 'src/app/services/licenses.service';
import { LoggerService } from 'src/app/services/logger.service';
import { ProductVariant } from 'src/app/services/products.service';

type LicenseOption = {
  details: DesktopLicenseInvoiceDetails;
  action?: LicenseAction;
};

type NotRenewingOption = {
  license: DesktopLicenseWithProduct;
  action?: LicenseAction;
};

type LicenseAction = RemoveLicense | ReAddLicense;

type RemoveLicense = {
  action: 'remove';
};

type ReAddLicense = {
  action: 'add';
};

@Component({
  selector: 'app-edit-invoice-page',
  templateUrl: './edit-invoice-page.component.html',
  styleUrls: ['./edit-invoice-page.component.scss'],
})
export class EditInvoicePageComponent {
  @ViewChild('editLicenseModal', { static: false }) editLicenseModal:
    | ElementRef<HTMLDialogElement>
    | undefined;

  loadingInvoice = false;
  invoice?: InvoiceDetails;
  errorFetchingInvoice = '';
  licenseList: LicenseOption[] = [];
  notRenewingList: NotRenewingOption[] = [];

  editingLicense: LicenseOption | null = null;
  loadingDurationOptions = false;
  durationOptions: ProductVariant[] = [];
  selectedDurationOption: ProductVariant | null = null;

  savingChanges = false;
  errorSavingChanges = '';
  changesSaved = false;

  constructor(
    private invoicesService: InvoicesService,
    private licensesService: LicensesService,
    private loggerService: LoggerService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    const id = this.route.snapshot.paramMap.get('id');
    if (!id) {
      this.loggerService.error(new Error('No invoice id'));
      this.errorFetchingInvoice =
        'Error fetching invoice. No invoice id parameter provided.';
      return;
    }

    this.getInvoiceDetails(id);
  }

  getInvoiceDetails(id: string) {
    this.loadingInvoice = true;
    this.invoicesService.getInvoiceDetails(id).subscribe({
      next: (data) => {
        this.loadingInvoice = false;
        this.invoice = data.invoice_details;
        this.initializeLicenseLists(this.invoice);
      },
      error: (err) => {
        this.loadingInvoice = false;
        this.errorFetchingInvoice = err.message;
      },
    });
  }

  initializeLicenseLists(invoice: InvoiceDetails) {
    this.licenseList = [];
    for (const details of invoice.license_details) {
      this.licenseList.push({ details: details });
    }

    this.notRenewingList = [];
    for (const license of invoice.not_renewing_licenses) {
      this.notRenewingList.push({ license: license });
    }
  }

  get hasChanges() {
    return (
      this.licenseList.some((license) => {
        return license.action;
      }) ||
      this.notRenewingList.some((license) => {
        return license.action;
      })
    );
  }

  getDurationString(months: number) {
    if (months % 12 === 0) {
      return months === 12 ? '1 year' : `${months / 12} years`;
    } else {
      return months === 1 ? '1 month' : `${months} months`;
    }
  }

  removeLicense(license: LicenseOption) {
    this.changesSaved = false;
    license.action = { action: 'remove' };
  }

  undoRemoveLicense(license: LicenseOption) {
    this.changesSaved = false;
    license.action = undefined;
  }

  reAddLicense(license: NotRenewingOption) {
    this.changesSaved = false;
    license.action = { action: 'add' };
  }

  undoAddLicense(license: NotRenewingOption) {
    this.changesSaved = false;
    license.action = undefined;
  }

  returnToInvoice(invoice: InvoiceDetails) {
    // check if there are changes
    if (this.hasChanges) {
      if (!confirm('Are you sure you want to leave without saving changes?')) {
        // do nothing
        return;
      }
    }

    // navigate back to invoice page
    this.router.navigate(['/invoice', invoice.desktop_invoice.id]);
  }

  saveChanges(invoice: InvoiceDetails) {
    this.changesSaved = false;
    this.savingChanges = true;
    this.errorSavingChanges = '';

    const observables = [];
    for (const license of this.licenseList) {
      if (license.action?.action === 'remove') {
        // set will renew to false
        license.details.license_with_product.will_renew = false;
        observables.push(
          this.licensesService.updateLicenseWillRenew(
            license.details.license_with_product
          )
        );
      }
    }

    for (const license of this.notRenewingList) {
      if (license.action?.action === 'add') {
        // set will renew to true
        license.license.will_renew = true;
        observables.push(
          this.licensesService.updateLicenseWillRenew(license.license)
        );
      }
    }

    merge(...observables).subscribe({
      next: (response) => {
        this.loggerService.log('Response:', response);
      },
      error: (err) => {
        this.loggerService.error(err);
        this.errorSavingChanges =
          'Unexpected error occurred. Please try again.';
      },
      complete: () => {
        this.loggerService.log('All changes saved');
        // reload initial invoice details
        this.invoicesService
          .getInvoiceDetails(invoice.desktop_invoice.id)
          .subscribe({
            next: (response) => {
              this.savingChanges = false;
              this.changesSaved = true;
              this.invoice = response.invoice_details;
              this.initializeLicenseLists(this.invoice);
            },
            error: (err) => {
              this.loggerService.error(err);
            },
          });
      },
    });
  }
}

