<form method="dialog">
  <button
    (click)="clearSelectedValues()"
    class="btn btn-circle btn-ghost btn-sm absolute right-2 top-2"
  >
    ✕
  </button>
</form>

@if (selectingProduct) {
  <div class="pt-4">
    @if (loadingProducts) {
      <span class="loading loading-spinner loading-md"></span>
    } @else {
      <div class="card-title">Add License</div>
      <div class="mb-2 flex flex-row">
        <div class="w-full">
          <p class="px-1 py-2 text-sm">Product</p>
          <div class="dropdown w-full">
            <div
              tabindex="0"
              role="button"
              class="btn w-full justify-between border-base-content/20 bg-base-100"
              (click)="focusProductSearchInput()"
            >
              @if (currentProduct) {
                {{ currentProduct.code }} - {{ currentProduct.title }} ({{
                  currentProduct.variance_code
                }})
              } @else {
                <span class="text-base-content/60">Select Product</span>
              }
              <app-chevron-down-icon class="h-5 w-5"></app-chevron-down-icon>
            </div>
            <ul
              tabindex="0"
              class="menu dropdown-content z-[1] max-h-96 w-full flex-nowrap overflow-y-auto rounded-box border border-base-content/20 bg-base-100 p-2 shadow"
            >
              <li>
                <input
                  #productSearchInput
                  type="text"
                  class="input input-bordered w-full"
                  placeholder="Search Products..."
                  [(ngModel)]="productFilters.searchText"
                />
              </li>
              @for (product of displayProducts; track product; let i = $index) {
                <li
                  [ngClass]="{
                    'border-t border-base-content/20': i > 0,
                  }"
                  (click)="handleProductChange(product)"
                >
                  <p class="flex">
                    <span>{{ product.code }}</span>
                    <span>-</span
                    ><span>
                      {{ product.title }} ({{ product.variance_code }})
                    </span>
                  </p>
                </li>
              }
            </ul>
          </div>
        </div>
      </div>
      <div class="flex flex-row space-x-4">
        <label class="form-control">
          <div class="label">
            <span class="label-text">Duration (Months)</span>
          </div>
          <select
            class="select select-bordered w-32"
            [(ngModel)]="productFilters.duration"
          >
            <option selected value=""></option>
            @for (duration of variantDurations; track duration) {
              <option [value]="duration">
                {{ duration }}
              </option>
            }
          </select>
        </label>
        <label class="form-control">
          <div class="label">
            <span class="label-text">Price Lock Level</span>
          </div>
          <select
            class="select select-bordered w-32"
            [(ngModel)]="productFilters.priceLockLevel"
          >
            <option selected value=""></option>
            @for (priceLockLevel of priceLockLevels; track priceLockLevel) {
              <option [value]="priceLockLevel">
                {{ priceLockLevel }}
              </option>
            }
          </select>
        </label>
        <label class="form-control">
          <div class="label">
            <span class="label-text">Delivery Method</span>
          </div>
          <div class="dropdown w-full">
            <select
              class="select select-bordered w-32"
              [(ngModel)]="productFilters.deliveryMethod"
            >
              <option selected value=""></option>
              <option value="XXX">Physical</option>
              <option value="DNL">Download</option>
            </select>
          </div>
        </label>
      </div>

      @if (displayVariants.length > 0) {
        <table class="table table-zebra mt-4">
          <thead class="text-sm font-medium">
            <td>Duration</td>
            <td>Delivery<br />Method</td>
            <td>Lock<br />Level</td>
            <td>Price</td>
            <td>Site<br />Price</td>
            <td></td>
          </thead>
          <tbody>
            @for (variant of displayVariants; track variant) {
              <tr>
                <td>{{ variant.duration_months }}</td>
                <td>
                  {{ displayDeliveryMethod(variant.delivery_method) }}
                </td>
                <td>{{ variant.price_lock_level }}</td>
                <td>{{ variant.price }}</td>
                <td>{{ variant.site_price }}</td>
                <td>
                  <button
                    class="btn btn-primary btn-sm"
                    (click)="handleAddLicenseClick(variant)"
                  >
                    Add
                  </button>
                </td>
              </tr>
            }
          </tbody>
        </table>
      }
    }
  </div>
} @else {
  <h3 class="pb-4 pt-4 text-2xl font-medium">
    {{ currentProduct?.title }}
  </h3>
  <div class="pb-4">
    <p class="text-lg font-medium">Product Description</p>
    <p class="text-sm">{{ currentProduct?.description }}</p>
  </div>
  <div>
    <p class="text-lg font-medium">Variant Description</p>
    <p class="text-sm">{{ currentVariant?.description }}</p>
  </div>

  <hr class="my-4 border-base-content" />

  @if (currentProduct?.requires_parent) {
    <div class="py-2">
      <p class="text-sm italic">
        *This product is an add on, and must be attached to a main license.
        Select the main license below.
      </p>
      <div class="form-control w-full max-w-xs">
        <label class="label">
          <span class="label-text">Main License</span>
        </label>
        <select
          [(ngModel)]="selectedParentLicenseId"
          class="select select-bordered w-full max-w-xs"
          (change)="handleParentLicenseChange()"
        >
          <option disabled selected value="">Select License</option>
          @for (license of possibleParentLicenses; track license) {
            <option [value]="license.id">
              {{ license.product.code }} - {{ license.product.title }} ({{
                license.product.variance_code
              }})
            </option>
          } @empty {
            <option disabled selected value="">
              No licenses available for this product
            </option>
          }
        </select>
      </div>
    </div>
  }

  @if (foundParentLicense) {
    <div class="py-2">
      <p class="mb-2 text-sm italic">
        *An existing product of this type and duration was found. This license
        will be added as a site license. Existing license info shown below.
      </p>

      <p>
        {{ foundParentLicense.product.code }} -
        {{ foundParentLicense.product.title }} ({{
          foundParentLicense.product.variance_code
        }})
      </p>
      <div>
        <span class="text-sm"
          >Start Date:
          {{ foundParentLicense.created_at | date: 'shortDate' }}</span
        >
        <span class="mx-4">|</span>
        <span class="text-sm"
          >Exp Date:
          {{ foundParentLicense.expiration | date: 'shortDate' : 'UTC' }}</span
        >
      </div>
    </div>

    <hr class="my-4 border-base-content" />
  }

  <label class="form-control w-full max-w-xs py-2">
    <div class="label">
      <span class="label-text">Start Date</span>
    </div>
    <input
      [(ngModel)]="startDate"
      type="date"
      class="input input-bordered w-full max-w-xs"
      (input)="handleStartDateChange()"
    />
  </label>

  <label class="form-control w-full max-w-xs py-2">
    <div class="label">
      <span class="label-text">Expiration Date</span>
    </div>
    @if (errorGettingExpirationDate) {
      <p class="ml-4 text-sm text-error">
        {{ errorGettingExpirationDate }}
      </p>
    } @else if (currentProduct?.requires_parent && !selectedParentLicenseId) {
      <p class="ml-4 text-sm italic opacity-60">
        Select a parent license first
      </p>
    } @else if (calculatingExpirationDate) {
      <span class="loading loading-spinner loading-md"></span>
    } @else {
      <input
        [(ngModel)]="expirationDate"
        type="date"
        class="input input-bordered w-full max-w-xs"
      />
    }
  </label>

  <!-- <label class="form-control w-full max-w-xs py-2">
    <div class="label">
      <span class="label-text">New License?</span>
    </div>
    <input [(ngModel)]="isNewLicense" type="checkbox" class="checkbox" />
  </label> -->

  <div class="modal-action">
    <button
      (click)="addLicense()"
      class="btn btn-primary"
      [disabled]="addingLicense"
    >
      Add License
    </button>
    <button (click)="clearSelectedValues()" class="btn pl-4">Back</button>
  </div>
  @if (errorAddingLicense) {
    <p class="text-error">
      {{ errorAddingLicense }}
    </p>
  }
}
