import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { LoggerService } from 'src/app/services/logger.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { Role, RolesService } from 'src/app/services/roles.service';
import {
  UserWithPermissions,
  UsersService,
} from 'src/app/services/users.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrl: './edit-user.component.scss',
})
export class EditUserComponent implements OnInit {
  @ViewChild('addRoleModal')
  addRoleModal!: ElementRef<HTMLDialogElement>;
  @ViewChild('removeRoleModal')
  removeRoleModal!: ElementRef<HTMLDialogElement>;

  originalUser?: UserWithPermissions;
  user?: UserWithPermissions;
  fetchingUser = false;
  error: string = '';

  rolesWithAccess?: Role[];
  errorFetchingRoles = '';
  selectedRole?: Role;
  addingRole = false;
  errorAddingRole = '';

  roleToRemove?: Role;
  removingRole = false;
  errorRemovingRole = '';

  savingChanges = false;
  errorSavingChanges = '';

  toastMessage?: string;

  constructor(
    private location: Location,
    private loggerService: LoggerService,
    private permissionsService: PermissionsService,
    private rolesService: RolesService,
    private route: ActivatedRoute,
    private usersService: UsersService
  ) {}

  ngOnInit() {
    this.loggerService.log('EditUserComponent ngOnInit');

    const userId = this.route.snapshot.paramMap.get('id');
    if (!userId) {
      this.loggerService.error(new Error('User ID not provided in route'));
      this.error = 'User ID not provided in route';
      return;
    }

    this.fetchingUser = true;
    this.usersService.getUser(userId).subscribe({
      next: (res) => {
        this.fetchingUser = false;
        this.loggerService.log('EditUserComponent ngOnInit -> res: ', res);
        if (res.user) {
          this.user = res.user;
          // create a copy of the user so bound values are not updated
          this.originalUser = Object.assign({}, res.user);
        } else {
          this.loggerService.error(new Error('No user returned from server'));
          this.error = 'No user returned from server';
        }
      },
      error: (err) => {
        this.fetchingUser = false;
        this.loggerService.error(err);
        this.error = err;
      },
    });

    this.fetchRoles();
  }

  get hasChanges() {
    if (!this.user || !this.originalUser) return false;
    return this.user.active !== this.originalUser.active;
  }

  fetchRoles() {
    this.rolesService.getRolesWithAccess().subscribe({
      next: (res) => {
        this.loggerService.log('res: ', res);
        this.rolesWithAccess = res.roles;
      },
      error: (err) => {
        this.loggerService.error(err);
        this.errorFetchingRoles = err;
      },
    });
  }

  showRemoveRoleModal(role: Role) {
    this.roleToRemove = role;
    this.removeRoleModal.nativeElement.showModal();
  }

  removeRole(role: Role) {
    if (!this.user) {
      this.loggerService.error(new Error('No user set'));
      return;
    }
    this.removingRole = true;
    this.errorRemovingRole = '';
    this.permissionsService
      .removePermission({
        user_id: this.user.id,
        role_id: role.id,
      })
      .subscribe({
        next: (res) => {
          this.removingRole = false;
          this.loggerService.log('res: ', res);
          if (res.user) {
            this.user = res.user;
            this.originalUser = Object.assign({}, res.user);
          }
          this.removeRoleModal.nativeElement.close();
          this.toastMessage = 'Role successfully removed';
        },
        error: (err) => {
          this.loggerService.error(err);
          this.errorRemovingRole = 'Unexpected error occurred';
          this.removingRole = false;
        },
      });
  }

  hideRemoveRoleModal() {
    this.removeRoleModal.nativeElement.close();
    this.roleToRemove = undefined;
  }

  showAddRoleModal() {
    this.addRoleModal.nativeElement.showModal();
  }

  setSelectedRole(role: Role) {
    this.selectedRole = role;
    if (document.activeElement) {
      (document.activeElement as HTMLElement).blur();
    }
  }

  addRole() {
    if (!this.selectedRole) {
      this.loggerService.error(new Error('No role selected'));
      return;
    }
    if (!this.user) {
      this.loggerService.error(new Error('No user set'));
      return;
    }
    this.addingRole = true;
    this.errorAddingRole = '';
    this.permissionsService
      .addPermission({
        user_id: this.user?.id,
        role_id: this.selectedRole.id,
      })
      .subscribe({
        next: (res) => {
          this.addingRole = false;
          this.loggerService.log('res: ', res);
          if (res.user) {
            this.user = res.user;
            this.originalUser = Object.assign({}, res.user);
          }
          this.addRoleModal.nativeElement.close();
          this.toastMessage = 'Role successfully added';
        },
        error: (err) => {
          this.addingRole = false;
          this.loggerService.error(err);
          this.errorAddingRole = 'Unexpected error occurred';
        },
      });
  }

  back() {
    this.location.back();
  }

  saveChanges() {
    if (!this.user) {
      this.loggerService.error(new Error('No user set'));
      return;
    }
    this.savingChanges = true;
    this.errorSavingChanges = '';

    this.usersService.setActive(this.user.id, this.user.active).subscribe({
      next: (res) => {
        this.savingChanges = false;
        this.loggerService.log('res: ', res);
        if (res.user) {
          this.user = res.user;
          this.originalUser = Object.assign({}, res.user);
        }
        this.toastMessage = 'User successfully saved';
      },
      error: (err) => {
        this.savingChanges = false;
        this.loggerService.error(err);
        this.errorSavingChanges = 'Unexpected error occurred';
      },
    });
  }
}
