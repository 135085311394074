<div>
  <div class="prose">
    <h1>Invoices</h1>
  </div>

  @if (errorFetchingInvoices) {
    <div class="mt-4">
      <p class="text-error">{{ errorFetchingInvoices }}</p>
    </div>
  } @else if (loadingInvoices) {
    <div class="mt-4 flex justify-center">
      <div class="loading loading-spinner loading-lg"></div>
    </div>
  } @else if (!allInvoices) {
    <div class="mt-4 text-center text-error">
      <p>Error fetching invoices</p>
    </div>
  } @else {
    <div class="mt-8">
      <div class="prose">
        <h2 class="mb-4 ml-6">Active</h2>
      </div>
      <div class="overflow-x-auto rounded-lg border border-solid border-black">
        <table
          class="table table-zebra table-pin-rows table-pin-cols table-lg static"
        >
          <thead class="text-base font-medium">
            <tr>
              <td>Invoice ID</td>
              <td>Paid?</td>
              <td>Total Amount</td>
              <td>Created Date</td>
              <td>Due Date</td>
            </tr>
          </thead>
          <tbody>
            @for (invoice of invoices?.pending; track invoice) {
              <tr
                class="cursor-pointer text-base"
                (click)="navigateToInvoiceDetails(invoice)"
              >
                <td>{{ invoice.desktop_invoice.id.substring(0, 8) }}</td>
                <td
                  [ngClass]="{
                    'text-error': !invoice.desktop_invoice.completed,
                  }"
                >
                  {{ invoice.desktop_invoice.completed ? 'Yes' : 'No' }}
                </td>
                <td>{{ invoice.desktop_invoice.total_amount | currency }}</td>
                <td>
                  {{ invoice.desktop_invoice.created_at | date: 'MM/dd/YYYY, hh:mmaa' }}
                </td>
                <td>
                  {{ invoice.desktop_invoice.expiration | date: 'MM/dd/YYYY' : 'UTC' }}
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    </div>

    @if (invoices?.complete && invoices!.complete!.length > 0) {
      <div class="mt-8">
        <div class="prose">
          <h2 class="mb-4 ml-6">Paid</h2>
        </div>
        <div
          class="overflow-x-auto rounded-lg border border-solid border-black"
        >
          <table
            class="table table-zebra table-pin-rows table-pin-cols table-lg static"
          >
            <thead class="text-base font-medium">
              <tr>
                <td>Invoice ID</td>
                <td>Paid?</td>
                <td>Total Amount</td>
                <td>Created Date</td>
                <td>Expiration Date</td>
              </tr>
            </thead>
            <tbody>
              @for (invoice of invoices!.complete; track invoice) {
                <tr
                  class="cursor-pointer text-base"
                  (click)="navigateToInvoiceDetails(invoice)"
                >
                  <td>{{ invoice.desktop_invoice.id.substring(0, 8) }}</td>
                  <td
                    [ngClass]="{
                      'text-error': !invoice.desktop_invoice.completed,
                    }"
                  >
                    {{ invoice.desktop_invoice.completed ? 'Yes' : 'No' }}
                  </td>
                  <td>{{ invoice.desktop_invoice.total_amount | currency }}</td>
                  <td>
                    {{ invoice.desktop_invoice.created_at | date: 'MM/dd/YYYY, hh:mmaa' }}
                  </td>
                  <td>
                    {{ invoice.desktop_invoice.expiration | date: 'MM/dd/YYYY' : 'UTC' }}
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    }

    <!-- <div class="mt-5 flex justify-center">
      <select
        class="select select-bordered mr-2 w-min"
        [(ngModel)]="spacing"
        (change)="saveSettings()"
      >
        <option disabled>Table Spacing</option>
        @for (spacingOption of spacingOptions; track spacingOption) {
          <option [value]="spacingOption">
            {{ spacingOption }}
          </option>
        }
      </select>
      <div class="join">
        <button
          class="btn btn-primary join-item"
          [disabled]="currentPage === 1"
          (click)="changePage(1)"
        >
          First
        </button>
        <button
          class="btn btn-primary join-item"
          [disabled]="currentPage === 1"
          (click)="prevPage()"
        >
          Prev
        </button>
      </div>
      <div class="join mx-4">
        @for (i of pageArray; track i) {
          <button
            class="btn join-item btn-md"
            [class]="{ 'btn-active': currentPage === i }"
            (click)="changePage(i)"
          >
            {{ i }}
          </button>
        }
      </div>
      <div class="join">
        <button
          class="btn btn-primary join-item"
          [disabled]="currentPage === totalPages"
          (click)="nextPage()"
        >
          Next
        </button>
        <button
          class="btn btn-primary join-item"
          [disabled]="currentPage === totalPages"
          (click)="changePage(totalPages)"
        >
          Last
        </button>
      </div>
      <select
        class="select select-bordered ml-2 w-min"
        [(ngModel)]="pageSize"
        (change)="saveSettings()"
      >
        <option disabled>Page Size</option>
        @for (pageSizeOption of pageSizeOptions; track pageSizeOption) {
          <option [value]="pageSizeOption">
            {{ pageSizeOption }}
          </option>
        }
      </select>
    </div> -->
  }
</div>
