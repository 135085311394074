<div class="m-2 w-fit rounded-lg border border-gray-300 p-4">
  <div class="flex justify-between">
    <div class="mr-8">
      <div class="mb-2 flex items-center">
        <h6 class="text-lg">{{ paymentMethod.card_type }}</h6>
        <p class="ml-4 text-sm">•••• {{ paymentMethod.last_4_digits }}</p>
      </div>
      <p class="text-sm">Exp: {{ paymentMethod.card_expiration }}</p>
      <p class="mt-2 text-sm">Billing Address:</p>
      <p class="text-sm" [innerHTML]="formattedAddress(paymentMethod)"></p>
    </div>
    <div class="flex flex-col justify-between">
      @if (paymentMethod.is_default) {
        <p class="text-right text-base italic">
          {{ paymentMethod.is_default ? 'Default' : '' }}
        </p>
      }
      @if (!paymentMethod.is_default && canManagePaymentMethods) {
        <button
          class="btn btn-primary"
          (click)="setDefault.emit(paymentMethod)"
        >
          Set Default
        </button>
      }
      @if (!paymentMethod.is_default && canManagePaymentMethods) {
        <button
          class="btn btn-error"
          (click)="deleteMethod.emit(paymentMethod)"
        >
          Remove
        </button>
      }
    </div>
  </div>
</div>
