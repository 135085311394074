import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FirebaseService } from './firebase.service';
import { mergeMap } from 'rxjs';
import { UserWithPermissions } from './users.service';

type NewPermissionRequest = {
  user_id: string;
  role_id: string;
};

type RemovePermissionRequest = {
  user_id: string;
  role_id: string;
};

type AddPermissionResponse = {
  user: UserWithPermissions;
};

type RemovePermissionResponse = {
  user: UserWithPermissions;
};

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private addPermissionUrl = environment.serverUrl + '/permissions/add';
  private removePermissionUrl = environment.serverUrl + '/permissions/remove';

  constructor(
    private http: HttpClient,
    private firebaseService: FirebaseService
  ) {}

  addPermission(request: NewPermissionRequest) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.post<AddPermissionResponse>(
          this.addPermissionUrl,
          request,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }

  removePermission(request: RemovePermissionRequest) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.post<RemovePermissionResponse>(
          this.removePermissionUrl,
          request,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }
}
