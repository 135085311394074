import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-manage-auth',
  templateUrl: './manage-auth.component.html',
  styleUrl: './manage-auth.component.scss',
})
export class ManageAuthComponent {
  pageState: 'verifyEmail' | 'resetPassword' = 'verifyEmail';

  oobCode: string | null = null;

  verificationError?: string = undefined;

  verifyingEmail = false;
  emailVerified = false;
  resendingVerificationEmail = false;
  emailResent = false;
  resendError?: string = undefined;

  verifyingPasswordResetCode = false;
  passwordResetCodeVerified = false;
  passwordResetCodeError?: string = undefined;
  passwordResetEmail?: string = undefined;
  newPassword = '';
  confirmPassword = '';
  resettingPassword = false;
  passwordReset = false;
  passwordResetError?: string = undefined;

  error = '';

  constructor(
    private authService: AuthService,
    private firebaseService: FirebaseService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    const mode = this.route.snapshot.queryParamMap.get('mode');
    this.oobCode = this.route.snapshot.queryParamMap.get('oobCode');
    console.log(
      'route paramMap: ',
      this.route.snapshot.queryParamMap
    );
    console.log('mode: ', mode);
    console.log('oobCode: ', this.oobCode);

    if (mode === 'resetPassword') {
      this.pageState = 'resetPassword';

      if (this.oobCode) {
        console.log('verifying password reset code');
        this.verifyPasswordResetCode(this.oobCode);
      } else {
        this.verificationError = 'Verification code missing';
      }
    } else if (mode === 'verifyEmail') {
      this.pageState = 'verifyEmail';

      // check if user email is already verified
      if (this.firebaseService.currentUser?.emailVerified) {
        console.log('email already verified');
        this.emailVerified = true;
      } else if (this.oobCode) {
        console.log('verifying email');
        this.verifyCode(this.oobCode);
      } else {
        this.verificationError = 'Verification code missing';
      }
    }
  }

  async verifyPasswordResetCode(code: string) {
    this.verifyingPasswordResetCode = true;

    this.firebaseService.checkPasswordResetCode(code).subscribe({
      next: (email: string) => {
        console.log('password reset code verified');
        this.passwordResetCodeVerified = true;
        this.passwordResetEmail = email;
      },
      error: (err) => {
        console.error(err);
        if (err.code === 'auth/expired-action-code') {
          this.verificationError = 'This password reset code has expired';
        } else {
          this.verificationError = 'Invalid verification code';
        }
        this.verifyingPasswordResetCode = false;
      },
      complete: () => {
        this.verifyingPasswordResetCode = false;
      },
    });
  }

  async resetPassword() {
    if (!this.oobCode) {
      this.verificationError = 'Verification code missing';
      return;
    }

    if (this.newPassword.length < 8) {
      this.passwordResetError = 'Password must be at least 8 characters';
      return;
    }

    if (this.newPassword !== this.confirmPassword) {
      this.passwordResetError = 'Passwords do not match';
      return;
    }

    this.firebaseService
      .resetPassword(this.newPassword, this.oobCode)
      .subscribe({
        next: () => {
          console.log('password reset');
          this.passwordReset = true;
        },
        error: (err) => {
          console.error(err);
          this.passwordResetError = 'Error resetting password';
        },
      });
  }

  validatePasswords(password: string, confirmPassword: string) {
    return password !== confirmPassword;
  }

  async verifyCode(code: string) {
    this.verifyingEmail = true;

    this.firebaseService.checkEmailVerificationCode(code).subscribe({
      next: () => {
        console.log('email verified');
        this.emailVerified = true;
      },
      error: (err) => {
        console.error(err);
        this.verificationError = 'Invalid verification code';
        this.verifyingEmail = false;
      },
      complete: () => {
        this.verifyingEmail = false;
      },
    });
  }

  async resendVerificationEmail() {
    this.resendingVerificationEmail = true;
    this.resendError = undefined;

    if (!this.firebaseService.currentUser) {
      console.error(
        new Error('Unable to send verification email. Please sign in.')
      );
      this.resendError = 'Unable to send verification email. Please sign in.';
      return;
    }

    if (!this.firebaseService.currentUser.email) {
      console.error(
        new Error('Unable to send verification email. No email found.')
      );
      this.resendError = 'Unable to send verification email. No email found.';
      return;
    }

    this.authService
      .sendEmailVerificationEmail(this.firebaseService.currentUser.email)
      .subscribe({
        next: () => {
          console.log('verification email resent');
          this.emailResent = true;
        },
        error: (err) => {
          console.error(err);
          this.resendError = 'Error resending verification email';
        },
        complete: () => {
          this.resendingVerificationEmail = false;
        },
      });
  }

  signOutAndNavigateToLogin() {
    // sign out user
    this.authService.signOut().subscribe({
      next: (res) => {
        console.log('Response from server: ', res);
      },
      error: (err) => {
        console.error(err);
        this.error = JSON.stringify(err, null, 2);
      },
    });
    // navigate to login page and set query param to confirm email is verified
    this.router.navigate(['/login'], {
      queryParams: {
        emailVerified: 'true',
      },
    });
  }
}
