import { Component, OnInit } from '@angular/core';
import Shepherd from 'shepherd.js';

@Component({
  selector: 'app-shepherd',
  templateUrl: './shepherd.component.html',
  styleUrls: ['./shepherd.component.scss'],
})
export class ShepherdComponent implements OnInit {
  ngOnInit() {
    const tour = new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        arrow: true,
        classes: 'shepherd-theme-custom',
        scrollTo: true,
        cancelIcon: {
          enabled: true,
        },
      },
    });

    tour.addStep({
      title: 'Example Steps 1/4',
      id: 'example-step',
      text: 'This step should be in the center',
      buttons: [
        {
          classes: '.btn btn-square',
          text: 'Next',
          action: tour.next,
        },
      ],
    });

    tour.addStep({
      title: 'Example Steps 2/4',
      id: 'example-step-2',
      text: 'This is the second step and is attached to the top navigation on the right',
      attachTo: {
        element: 'app-top-nav',
        on: 'bottom-end',
      },
      buttons: [
        {
          classes: '.btn btn-square',
          text: 'Back',
          action: tour.back,
        },
        {
          classes: '.btn btn-square',
          text: 'Next',
          action: tour.next,
        },
      ],
    });

    tour.addStep({
      title: 'Example Steps 3/4',
      id: 'example-step-2',
      text: 'This is the third step and is attached to the side navigation',
      attachTo: {
        element: 'app-side-nav',
        on: 'right',
      },
      buttons: [
        {
          classes: '.btn btn-square',
          text: 'Back',
          action: tour.back,
        },
        {
          classes: '.btn btn-square',
          text: 'Next',
          action: tour.next,
        },
      ],
    });

    tour.addStep({
      title: 'Example Steps 4/4',
      id: 'example-step-3',
      text: 'This is the final example step and is attached to the bottom of the top navigation',
      attachTo: {
        element: 'app-top-nav',
        on: 'bottom',
      },
      buttons: [
        {
          classes: '.btn btn-square',
          text: 'Back',
          action: tour.back,
        },
        {
          classes: '.btn btn-square',
          text: 'Finish',
          action: tour.complete,
        },
      ],
    });

    tour.start();
  }
}

