import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor() {}

  handleObservableError<T>() {
    return (error: any): Observable<T> => {
      let errorMessage = 'An error occurred';

      if (error instanceof HttpErrorResponse) {
        // Server or connection error
        if (error.error instanceof ErrorEvent) {
          errorMessage = `${error.error.message}`;
        } else {
          // Backend error
          errorMessage = `${error.status}: ${error.error?.message || error.message}`;
        }
      } else if (error instanceof Error) {
        errorMessage = error.message;
      }

      return throwError(() => errorMessage);
    };
  }
}
