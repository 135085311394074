@if (!show2FAOptions) {
  <div>
    <h1 class="m-auto max-w-lg text-5xl font-semibold leading-normal">
      Verify Login
    </h1>
    <p>Please login again to verify your account</p>
    <div class="my-8">
      <input
        class="input input-bordered w-full"
        type="text"
        [(ngModel)]="username"
        placeholder="Email"
      />
    </div>
    <div class="my-8">
      <input
        class="input input-bordered w-full"
        type="password"
        [(ngModel)]="password"
        (keydown.enter)="signIn()"
        placeholder="Password"
      />
    </div>
    <div class="mb-4 mt-8">
      <button class="btn btn-primary w-full" (click)="signIn()">Login</button>
    </div>

    <div class="my-4 flex">
      <hr class="my-auto w-full" />
      <p class="px-4 text-center text-sm">Or</p>
      <hr class="my-auto w-full" />
    </div>

    <div class="my-4">
      <button
        class="btn w-full border border-[#8E918F] bg-[#131314] text-[#E3E3E3] hover:border hover:border-[#8E918F] hover:bg-[#131314]"
        (click)="signInWithGoogle()"
      >
        <div class="h-5 w-5">
          <app-google-icon></app-google-icon>
        </div>
        Login with Google
      </button>
    </div>

    <div class="mt-8">
      <a class="link" routerLink="/">Back to Dashboard</a>
    </div>
  </div>
} @else {
  <app-two-factor-login
    [mfaResolver]="mfaResolver"
    (success)="loginSuccess()"
    (back)="show2FAOptions = false"
  ></app-two-factor-login>
}
