<div class="mx-auto flex max-w-lg flex-col">
  <div>
    <h1 class="mb-4 text-2xl font-bold">Edit Product</h1>
  </div>

  @if (errorFetchingProduct) {
    <div class="mt-4 flex justify-center">
      <p class="text-error">{{ errorFetchingProduct }}</p>
    </div>
  } @else if (loadingProduct) {
    <div class="mt-4 flex justify-center">
      <div class="loading loading-spinner loading-lg" role="status"></div>
    </div>
  } @else if (originalProduct) {
    <div>
      <div class="form-control mb-4">
        <label class="label">
          <span class="label-text">Title</span>
        </label>
        <textarea
          class="textarea textarea-bordered"
          [(ngModel)]="title"
        ></textarea>
      </div>
      <div class="form-control mb-4">
        <label class="label">
          <span class="label-text">Description</span>
        </label>
        <textarea
          class="textarea textarea-bordered"
          [(ngModel)]="description"
        ></textarea>
      </div>
      <div class="form-control mb-4">
        <label class="label">
          <span class="label-text">Variance Code</span>
        </label>
        <input
          class="input input-bordered"
          type="text"
          [(ngModel)]="varianceCode"
        />
      </div>
      <div class="form-control mb-4 w-fit flex-row">
        <label class="label mr-4"><span class="label-text">Type</span></label>
        <select class="select select-bordered" [(ngModel)]="type">
          @for (t of productTypes; track t) {
            <option [value]="t">{{ t }}</option>
          }
        </select>
      </div>
      <div class="form-control mb-2 w-fit">
        <label class="label cursor-pointer">
          <input type="checkbox" class="checkbox" [(ngModel)]="active" />
          <span class="label-text ml-4">Active</span>
        </label>
      </div>
      <div class="form-control mb-2 w-fit">
        <label class="label cursor-pointer">
          <input type="checkbox" class="checkbox" [(ngModel)]="isService" />
          <span class="label-text ml-4">Is Service</span>
        </label>
      </div>
      <div class="form-control w-fit">
        <label class="label cursor-pointer">
          <input type="checkbox" class="checkbox" [(ngModel)]="standalone" />
          <span class="label-text ml-4">Standalone</span>
        </label>
      </div>
      <div class="mt-8 flex justify-center space-x-4">
        <button
          class="btn btn-primary"
          [disabled]="!hasChanges || savingChanges"
          (click)="changeDialog.showModal()"
        >
          Save
        </button>
        <button class="btn btn-error" (click)="navigateToProduct()">
          Cancel
        </button>
      </div>
      @if (savingChanges) {
        <div class="mt-4 flex justify-center">
          <span class="loading loading-spinner loading-md"></span>
        </div>
      }
      @if (productUpdated) {
        <div class="mt-4 flex justify-center">
          <p class="text-success">Product changes saved successfully</p>
        </div>
      }
      @if (errorUpdatingProduct) {
        <div class="mt-4 flex justify-center">
          <p class="text-error">
            {{ errorUpdatingProduct }}
          </p>
        </div>
      }
    </div>
  }
</div>

<dialog #changeDialog class="modal">
  <div class="modal-box max-w-3xl p-8">
    <h2 class="text-2xl font-bold text-error">WARNING</h2>
    <h4 class="text-lg text-error">
      You are changing a live product that may affect users who have this
      product currently licensed.
    </h4>
    <table class="table my-4">
      <thead class="text-base">
        <tr>
          <th class="font-medium">Field</th>
          <th class="font-medium">Original Value</th>
          <th class="font-medium">New Value</th>
        </tr>
      </thead>
      <tbody>
        <tr
          [ngClass]="{
            'text-warning': originalProduct?.title !== title,
          }"
        >
          <td>Title</td>
          <td>{{ originalProduct?.title }}</td>
          <td>{{ title }}</td>
        </tr>
        <tr
          [ngClass]="{
            'text-warning': originalProduct?.description !== description,
          }"
        >
          <td>Description</td>
          <td>{{ originalProduct?.description }}</td>
          <td>{{ description }}</td>
        </tr>
        <tr
          [ngClass]="{
            'text-warning': originalProduct?.type !== type,
          }"
        >
          <td>Type</td>
          <td>{{ originalProduct?.type }}</td>
          <td>{{ type }}</td>
        </tr>
        <tr
          [ngClass]="{
            'text-warning': originalProduct?.active !== active,
          }"
        >
          <td>Active</td>
          <td>{{ originalProduct?.active }}</td>
          <td>{{ active }}</td>
        </tr>
        <tr
          [ngClass]="{
            'text-warning': originalProduct?.is_service !== isService,
          }"
        >
          <td>Is Service</td>
          <td>{{ originalProduct?.is_service }}</td>
          <td>{{ isService }}</td>
        </tr>
        <tr
          [ngClass]="{
            'text-warning': originalProduct?.standalone !== standalone,
          }"
        >
          <td>Standalone</td>
          <td>{{ originalProduct?.standalone }}</td>
          <td>{{ standalone }}</td>
        </tr>
      </tbody>
    </table>
    <h4 class="text-lg text-error">
      Are you sure you want to make these changes?
    </h4>
    <div class="mt-4 flex justify-center space-x-4">
      <button class="btn btn-primary" (click)="saveChanges()">Yes</button>
      <button class="btn btn-error" (click)="closeChangeDialog()">No</button>
    </div>
  </div>
</dialog>
