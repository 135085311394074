<div class="container p-12">
  @if (tenantFetchError || !tenant) {
    <div class="mt-4 text-center text-error">
      <p>{{ tenantFetchError ? tenantFetchError : 'Tenant not found' }}</p>
    </div>
  } @else if (loadingTenant) {
    <div class="mt-4">
      <div class="loading loading-spinner loading-lg"></div>
    </div>
  } @else if (!tenant) {
    <div class="mt-4 text-center text-error">
      <p>Tenant not found</p>
    </div>
  } @else {
    <app-tenant-info-form [tenant]="tenant"></app-tenant-info-form>
  }
</div>
