import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-tenant-invoice',
  templateUrl: './tenant-invoice.component.html',
  styleUrl: './tenant-invoice.component.scss',
})
export class TenantInvoiceComponent {
  invoiceId?: string;
  tenantId?: string;

  constructor(private route: ActivatedRoute) {
    const params = this.route.snapshot.params;
    this.tenantId = params['tenantId'];
    this.invoiceId = params['invoiceId'];
  }
}
