import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoggerService } from 'src/app/services/logger.service';
import { Product, ProductsService } from 'src/app/services/products.service';
import { paginationArray } from 'src/app/utils/pagination';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent {
  allProducts: Product[] = [];
  displayProducts: Product[] = [];
  loading = false;

  searchText = '';

  pageSize = 15;
  currentPage = 1;

  pageSizeOptions = [10, 15, 20, 50, 100];

  spacingOptions = ['compact', 'normal', 'comfortable'];
  spacing = 'normal';

  constructor(
    private loggerService: LoggerService,
    private productsService: ProductsService,
    private router: Router
  ) {
    this.loggerService.log('ProductListComponent constructor');
    this.loadSettings();
    this.getProducts();
  }

  get pageArray() {
    return paginationArray(this.pageSize, this.totalPages, this.currentPage);
    // const pageArray = [];
    // for (let i = 1; i <= this.totalPages; i++) {
    //   pageArray.push(i);
    // }
    // return pageArray;
  }

  get totalPages() {
    return Math.ceil(this.displayProducts.length / this.pageSize);
  }

  loadSettings() {
    try {
      const settings = localStorage.getItem('productTableSettings');
      if (settings) {
        const parsed = JSON.parse(settings);
        this.pageSize = parsed.pageSize;
        this.spacing = parsed.spacing;
      }
    } catch (err) {
      this.loggerService.error(err as Error);
    }
  }

  saveSettings() {
    const settings = {
      pageSize: this.pageSize,
      spacing: this.spacing,
    };
    localStorage.setItem('productTableSettings', JSON.stringify(settings));
  }

  getProducts() {
    if (this.loading) return;
    this.loading = true;

    this.productsService.getAllProducts().subscribe({
      next: (res) => {
        this.loggerService.log('res: ', res);
        if (res) {
          this.allProducts = res.products;
          this.allProducts.sort((a, b) => {
            if (a.code < b.code) return -1;
            if (a.code > b.code) return 1;
            return 0;
          });
          this.updateDisplayProducts();
        } else {
          this.loggerService.error(
            new Error('No products returned from server')
          );
          this.loggerService.log('res: ', res);
          this.allProducts = [];
        }
      },
      error: (err) => {
        this.loggerService.error(
          new Error('Error getting products from server')
        );
        this.loggerService.log('err: ', err);
        this.allProducts = [];
      },
      complete: () => {
        this.loggerService.log('ProductListComponent getProducts complete');
        this.loading = false;
      },
    });
  }

  updateDisplayProducts() {
    this.loggerService.log('this.allProducts: ', this.allProducts);

    if (this.searchText.trim() !== '') {
      this.displayProducts = this.allProducts.filter((product) => {
        const s = this.searchText.toLowerCase();
        return (
          product.title.toLowerCase().includes(s) ||
          product.description.toLowerCase().includes(s) ||
          product.code.toLowerCase().includes(s) ||
          product.type.toLowerCase().includes(s)
        );
      });

      if (this.currentPage - 1 > this.displayProducts.length / this.pageSize) {
        this.currentPage = 1;
      }
    } else {
      this.displayProducts = this.allProducts;
    }
  }

  get currentPageProducts() {
    const offset = (this.currentPage - 1) * this.pageSize;
    return this.displayProducts.slice(offset, offset + this.pageSize);
  }

  navigateToProductDetails(product: Product) {
    this.loggerService.log('navigateToProductDetails');
    this.loggerService.log('product: ', product);
    this.router.navigate([`admin/product/${product.id}`]);
  }

  changePage(page: number) {
    this.currentPage = page;
    this.updateDisplayProducts();
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updateDisplayProducts();
    }
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.updateDisplayProducts();
    }
  }
}
