import { Component } from '@angular/core';

@Component({
  selector: 'app-arrow-right-to-bracket-icon',
  templateUrl: './arrow-right-to-bracket-icon.component.html',
  styleUrl: './arrow-right-to-bracket-icon.component.scss'
})
export class ArrowRightToBracketIconComponent {

}
