<div class="flex">
  <app-graphic-side-panel
    class="hidden h-screen w-[50vw] md:flex"
  ></app-graphic-side-panel>

  <div class="flex h-screen w-screen items-center justify-center md:w-[50vw]">
    @if (pageState === 'verifyEmail') {
      @if (verifyingEmail) {
        <div class="flex flex-col">
          <div>
            <p class="mb-4">Please wait while we verify your email address.</p>
          </div>
          <span class="loading loading-spinner loading-sm self-center"></span>
        </div>
      } @else {
        @if (verificationError) {
          <div class="mx-6 w-full max-w-lg">
            <p class="text-xl text-error">{{ verificationError }}</p>
            <div class="mt-4">
              <p>
                The provided verification link is no longer valid. Use the link
                below to resend a new verification email.
              </p>
              <button
                class="btn btn-primary mt-4"
                (click)="resendVerificationEmail()"
                [disabled]="resendingVerificationEmail"
              >
                Resend Verification Email
              </button>
              @if (resendingVerificationEmail) {
                <div>
                  <span class="loading loading-spinner loading-lg"></span>
                </div>
              }
              @if (!resendingVerificationEmail && resendError) {
                <div>
                  <p class="text-error">{{ resendError }}</p>
                </div>
              }
              @if (emailResent) {
                <div class="mt-4">
                  <p class="text-success">Email resent</p>
                </div>
              }
            </div>
          </div>
        } @else if (emailVerified) {
          <div class="mx-6 w-full max-w-lg">
            <h1
              class="z-10 m-auto max-w-lg text-3xl font-semibold leading-normal text-success md:block md:text-5xl"
            >
              Email Verified
            </h1>
            <div class="md:mt-4">
              <p class="mb-4">
                Your email has been verified successfully. Please proceed to the
                login page to login and set up two factor authentication.
              </p>
              <button
                class="btn btn-primary w-full"
                (click)="signOutAndNavigateToLogin()"
              >
                Proceed to Login
              </button>
            </div>
          </div>
        }
      }

    } @else if (pageState === 'resetPassword') {
      @if (verificationError) {
        <div class="mx-6 w-full max-w-lg">
          <p class="text-xl text-error">{{ verificationError }}</p>
          <div class="mt-4">
            <p>The provided verification link is no longer valid.</p>
          </div>
        </div>
      } @else if (verifyingPasswordResetCode) {
        <div class="flex flex-col">
          <div>
            <p class="mb-4">Loading...</p>
          </div>
          <span class="loading loading-spinner loading-sm self-center"></span>
        </div>
      } @else if (passwordResetCodeVerified) {
        <div class="mx-6 w-full max-w-lg">
          <div class="flex flex-col">
            <h1
              class="mb-4 text-3xl font-semibold leading-normal md:block md:text-5xl"
            >
              Reset Password
            </h1>
            @if (!passwordReset) {
              <p class="mb-8 text-xl">
                Enter a new password for your {{ passwordResetEmail }} account.
              </p>
              <input
                class="input input-bordered mb-4 w-full"
                type="password"
                [(ngModel)]="newPassword"
                placeholder="Password"
              />
              <input
                class="input input-bordered w-full"
                type="password"
                [(ngModel)]="confirmPassword"
                placeholder="Confirm Password"
              />
              <button
                class="btn btn-primary mt-4 w-full"
                (click)="resetPassword()"
                [disabled]="resettingPassword"
              >
                Reset
              </button>
              @if (resettingPassword) {
                <div class="mt-4 text-center">
                  <span class="loading loading-spinner loading-lg"></span>
                </div>
              }
            } @else {
              <p class="mb-4 text-xl text-success">
                Password successfully reset!
              </p>

              <button
                class="btn btn-primary mt-4 w-full"
                (click)="signOutAndNavigateToLogin()"
              >
                Proceed to Login
              </button>
            }
            @if (passwordResetError) {
              <div class="mt-4 text-error">
                <p>{{ passwordResetError }}</p>
              </div>
            }
          </div>
        </div>
      } @else {
        <div class="mx-6 w-full max-w-lg">
          <h1
            class="mb-4 text-3xl font-semibold leading-normal md:block md:text-5xl"
          >
            Reset Password
          </h1>
          <p class="text-error">
            There was an unexpected error. Please try again.
          </p>
        </div>
      }

    } @else {
      <div class="mx-6 w-full max-w-lg">
        <h1
          class="mb-4 text-3xl font-semibold leading-normal md:block md:text-5xl"
        >
          Manage Authentication
        </h1>
        <p class="text-error">
          There was an unexpected error. Please try again.
        </p>
      </div>
    }
  </div>
</div>
