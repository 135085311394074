import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrentInvitationsResolverResponse } from 'src/app/router/resolvers/current-tenant-invitations';
import { CurrentUsersResolverResponse } from 'src/app/router/resolvers/current-tenant-users';
import { RolesWithAccessResolverResponse } from 'src/app/router/resolvers/roles-with-access';
import {
  InvitationsService,
  UserInvitation,
} from 'src/app/services/invitations.service';
import { Role } from 'src/app/services/roles.service';
import {
  UserWithPermissions,
  UsersService,
} from 'src/app/services/users.service';
import { LoggerService } from 'src/app/services/logger.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-tenant-users',
  templateUrl: './tenant-users.component.html',
  styleUrls: ['./tenant-users.component.scss'],
})
export class TenantUsersComponent {
  @ViewChild('inviteUserModal') inviteUserModal!: ElementRef<HTMLDialogElement>;

  users?: UserWithPermissions[];
  pendingInvitations?: UserInvitation[];
  roles?: Role[];

  errorFetchingUsers = '';
  errorFetchingInvitations = '';
  errorFetchingRoles = '';

  expandedRowId = -1;
  showWarning = false;
  sending = false;
  sendSuccess = false;
  sendError = '';
  invalidInvite = '';

  inviteEmail = '';
  selectedRoleId = '';

  constructor(
    private authService: AuthService,
    private invitationsService: InvitationsService,
    private loggerService: LoggerService,
    private route: ActivatedRoute,
    private router: Router,
    private usersService: UsersService
  ) {
    const data = this.route.snapshot.data;
    this.loggerService.log('route data: ', data);

    const userData = data['users'] as CurrentUsersResolverResponse;
    const invitationData = data[
      'invitations'
    ] as CurrentInvitationsResolverResponse;
    const rolesData = data['roles'] as RolesWithAccessResolverResponse;

    if (userData?.error) {
      this.errorFetchingUsers = userData.error;
    } else if (!userData?.users) {
      this.errorFetchingUsers = 'Error fetching users';
    } else {
      this.users = userData.users;
    }

    if (invitationData?.error) {
      this.errorFetchingInvitations = invitationData.error;
    } else if (!invitationData?.user_invitations) {
      this.errorFetchingInvitations = 'Error fetching invitations';
    } else {
      this.pendingInvitations = invitationData.user_invitations.pending;
    }

    if (rolesData?.error) {
      this.errorFetchingRoles = rolesData.error;
    } else if (!rolesData?.roles) {
      this.errorFetchingRoles = 'Error fetching roles';
    } else {
      this.roles = rolesData.roles;
    }

    this.loggerService.log('users: ', this.users);
    this.loggerService.log('invitations: ', this.pendingInvitations);
    this.loggerService.log('roles: ', this.roles);
  }

  canEditUser(userToEdit: UserWithPermissions) {
    if (!this.authService.currentUser) return false;

    return this.usersService.canEditUser(
      this.authService.currentUser,
      userToEdit
    );
  }

  hasPermissions(user: UserWithPermissions, roles: string[]) {
    for (const p of user.permissions) {
      for (const role of roles) {
        if (p.role.name === role) return true;
      }
    }
    return false;
  }

  browseToEditUser(user: UserWithPermissions) {
    this.router.navigate(['/edit-user', user.id]);
  }

  get selectedRoleDisplayName() {
    if (!this.selectedRoleId || !this.roles) {
      return '';
    }
    for (const role of this.roles) {
      if (role.id === this.selectedRoleId) {
        return role.display_name;
      }
    }
    return '';
  }

  roleList(user: UserWithPermissions) {
    return user.permissions.map((p) => p.role.display_name).join(', ');
  }

  handleRoleChange(role: Role) {
    this.loggerService.log('handleRoleChange: ', role);
    this.selectedRoleId = role.id;
    // unfocus dropdown so it closes
    if (document.activeElement) {
      (document.activeElement as HTMLElement).blur();
    }
  }

  closeInviteUserModal() {
    this.inviteUserModal.nativeElement.close();
  }

  sendInvitation() {
    this.invalidInvite = '';

    if (!this.pendingInvitations) {
      this.sendError = 'Error sending invitation';
      return;
    }

    if (!this.inviteEmail.trim()) {
      this.invalidInvite = 'Email is required';
      return;
    }

    if (!this.selectedRoleId) {
      this.invalidInvite = 'No role selected';
      return;
    }

    this.sendSuccess = false;
    this.sendError = '';
    this.sending = true;
    this.invitationsService
      .createInvitation(this.inviteEmail, this.selectedRoleId)
      .subscribe({
        next: (result) => {
          this.closeInviteUserModal();
          this.sending = false;
          if (!this.pendingInvitations) {
            this.sendError = 'Error sending invitation';
            return;
          }
          this.sendSuccess = true;
          this.inviteEmail = '';
          this.selectedRoleId = '';
          this.loggerService.log('invitation created: ', result);
          this.pendingInvitations.push(result.user_invitation);
        },
        error: (err) => {
          this.closeInviteUserModal();
          this.sending = false;
          if (err.error?.error === 'email already in use') {
            this.sendError = 'Email already in use';
          } else {
            this.sendError = 'Error creating invitation';
          }
          this.loggerService.error(err);
        },
      });
  }
}
