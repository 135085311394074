<div class="p-5">
  <div class="grid grid-cols-2">
    <div>
      <label class="form-control mb-4 max-w-xs">
        <label class="label">
          <span class="label-text">Email</span>
        </label>
        <input
          class="input input-bordered"
          type="text"
          [(ngModel)]="email"
          placeholder="Email"
          name="email"
          #emailField="ngModel"
          required
          email
        />
        @if (emailField.invalid && (emailField.dirty || emailField.touched)) {
          <div class="text-error">
            <p>Please enter a valid email address</p>
          </div>
        }
      </label>

      <label class="form-control mb-4 max-w-xs">
        <label class="label">
          <span class="label-text">Company Name</span>
        </label>
        <input
          class="input input-bordered"
          type="text"
          [(ngModel)]="companyName"
          placeholder="Company Name"
          name="companyName"
          #companyNameField="ngModel"
          required
        />
        @if (
          companyNameField.invalid &&
          (companyNameField.dirty || companyNameField.touched)
        ) {
          <div class="text-error">
            <p>Please enter company name</p>
          </div>
        }
      </label>

      @if (!loadingDistributors) {
        <label class="form-control mb-4 max-w-xs">
          <label class="label">
            <span class="label-text">Distributor</span>
          </label>
          <select
            class="select select-bordered"
            [(ngModel)]="selectedDistributorId"
          >
            <option value="" disabled selected>Select Distributor</option>
            @for (distributor of distributors; track distributor) {
              <option [value]="distributor.id">
                {{ distributor.name }}
              </option>
            }
          </select>
        </label>
      }

      @if (!loadingFranchises) {
        <label class="form-control max-w-xs">
          <label class="label">
            <span class="label-text">Franchise (optional)</span>
          </label>
          <select
            class="select select-bordered"
            [(ngModel)]="selectedFranchiseId"
          >
            <option value="" selected>(None)</option>
            @for (franchise of franchises; track franchise) {
              <option [value]="franchise.id">
                {{ franchise.name }}
              </option>
            }
          </select>
        </label>
      }
    </div>

    <div>
      <button
        class="btn btn-accent mt-5"
        (click)="createTenant()"
        [disabled]="!canCreateTenant || creatingTenant"
      >
        Create New Tenant
      </button>
    </div>
  </div>

  @if (creatingTenant) {
    <div class="mt-4">
      <div class="loading-spinner"></div>
    </div>
  }

  @if (createResponse) {
    <div class="mt-4">
      <p class="text-success">Tenant created successfully!</p>
      <p>Account Number: {{ createResponse.tenant.account_number }}</p>
    </div>
  }

  @if (createError) {
    <div class="mt-4 text-error">
      <p>Error:</p>
      <pre>{{ createError }}</pre>
    </div>
  }
</div>
