<div class="overflow-x-auto rounded-lg border border-solid border-black">
  <table class="table table-zebra static">
    <thead class="text-base font-medium">
      <tr>
        <td class="cursor-pointer" (click)="sortChanged.emit('email')">
          <span>Email</span>
          @if (currentSort?.field == 'email') {
            @if (currentSort?.direction === 'ASC') {
              <app-chevron-up-icon
                class="absolute inline-block h-6 w-6"
              ></app-chevron-up-icon>
            } @else {
              <app-chevron-down-icon
                class="absolute inline-block h-6 w-6"
              ></app-chevron-down-icon>
            }
          }
        </td>
        <td>Username</td>
        <td>Roles</td>
        <td class="cursor-pointer" (click)="sortChanged.emit('active')">
          <span>Active</span>
          @if (currentSort?.field == 'active') {
            @if (currentSort?.direction === 'ASC') {
              <app-chevron-up-icon
                class="absolute inline-block h-6 w-6"
              ></app-chevron-up-icon>
            } @else {
              <app-chevron-down-icon
                class="absolute inline-block h-6 w-6"
              ></app-chevron-down-icon>
            }
          }
        </td>
        <td class="cursor-pointer" (click)="sortChanged.emit('created_at')">
          <span>Created</span>
          @if (currentSort?.field == 'created_at') {
            @if (currentSort?.direction === 'ASC') {
              <app-chevron-up-icon
                class="absolute inline-block h-6 w-6"
              ></app-chevron-up-icon>
            } @else {
              <app-chevron-down-icon
                class="absolute inline-block h-6 w-6"
              ></app-chevron-down-icon>
            }
          }
        </td>
        <td></td>
      </tr>
    </thead>
    <tbody>
      @for (user of users; track user; let i = $index) {
        <tr>
          <td>{{ user.email }}</td>
          <td>{{ user.username }}</td>
          <td>{{ roleList(user) }}</td>
          <td>{{ user.active }}</td>
          <td>{{ user.created_at | date: 'MM/dd/YYYY, hh:mmaa' }}</td>
          <td>
            @if (canEditUser(user)) {
              <app-edit-icon
                class="block h-6 w-6 cursor-pointer"
                [routerLink]="['/edit-user', user.id]"
              ></app-edit-icon>
            }
          </td>
        </tr>
      } @empty {
        <tr>
          <td colspan="4">No Users</td>
        </tr>
      }
    </tbody>
  </table>
</div>
