import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthorizeNetSavedPaymentMethod } from 'src/app/services/payments.service';

@Component({
  selector: 'app-saved-payment-card',
  templateUrl: './saved-payment-card.component.html',
  styleUrl: './saved-payment-card.component.scss',
})
export class SavedPaymentCardComponent {
  @Input() paymentMethod!: AuthorizeNetSavedPaymentMethod;
  @Input() canManagePaymentMethods!: boolean;
  @Output() setDefault = new EventEmitter<AuthorizeNetSavedPaymentMethod>();
  @Output() deleteMethod = new EventEmitter<AuthorizeNetSavedPaymentMethod>();

  formattedAddress(method: AuthorizeNetSavedPaymentMethod) {
    let str = '';
    if (method.company) {
      str += `${method.company}<br>`;
    } else {
      str += `${method.first_name} ${method.last_name}<br>`;
    }
    str += `${method.address}<br>${method.city}, ${method.state} ${method.zip_code}`;
    return str;
  }
}
