<div class="mx-auto flex max-w-lg flex-col">
  <div>
    <div class="mb-4 h-8 w-8">
      <app-left-arrow-icon
        class="cursor-pointer"
        (click)="location.back()"
      ></app-left-arrow-icon>
    </div>
    <h1 class="mb-4 text-2xl font-bold">Edit License</h1>
  </div>

  @if (errorFetchingLicense) {
    <div class="mt-4">
      <p class="text-error">{{ errorFetchingLicense }}</p>
    </div>
  } @else if (loadingLicense) {
    <div class="mt-4 text-center">
      <div class="loading loading-spinner"></div>
    </div>
  } @else if (!originalLicense || !tenant) {
    <div class="mt-4">
      <p class="text-error">No license or tenant data</p>
    </div>
  } @else {
    <div class="mb-4">
      <div class="mb-2">
        <h4 class="text-md font-bold">Account Number</h4>
        <p class="text-sm">{{ tenant.account_number }}</p>
      </div>
      <div class="mb-2">
        <h4 class="text-md font-bold">Product</h4>
        <p class="text-sm">{{ originalLicense.product.title }}</p>
      </div>
      <div class="mb-2">
        <h4 class="text-md font-bold">Product Variant</h4>
        <p class="text-sm">{{ originalLicense.product_variant.description }}</p>
      </div>
      <hr class="my-4" />
      <div class="form-control mb-2 w-fit">
        <label class="label">
          <span class="label-text">Start Date</span>
        </label>
        <input
          type="date"
          class="input input-bordered"
          [(ngModel)]="inputStartDate"
        />
      </div>
      <div class="form-control mb-2 w-fit">
        <label class="label">
          <span class="label-text">Expiration Date</span>
        </label>
        <input
          type="date"
          class="input input-bordered"
          [(ngModel)]="inputExpirationDate"
        />
      </div>
      <div class="form-control mb-2 w-fit">
        <label class="label cursor-pointer">
          <input
            type="checkbox"
            class="checkbox"
            [(ngModel)]="updates.active"
          />
          <span class="label-text ml-4">Active</span>
        </label>
      </div>
      <div class="form-control mb-2 w-fit">
        <label class="label cursor-pointer">
          <input
            type="checkbox"
            class="checkbox"
            [(ngModel)]="updates.will_renew"
          />
          <span class="label-text ml-4">Will Renew</span>
        </label>
      </div>
      <div class="form-control mb-2 w-fit">
        <label class="label cursor-pointer">
          <input
            type="checkbox"
            class="checkbox"
            [(ngModel)]="updates.new_license"
          />
          <span class="label-text ml-4">New License</span>
        </label>
      </div>
      <div class="form-control mb-2 w-fit">
        <label class="label">
          <span class="label-text">Transfers Left</span>
        </label>
        <input
          type="number"
          class="input input-bordered"
          [(ngModel)]="updates.transfers_left"
        />
      </div>
      <div class="mt-4 flex w-fit">
        <button
          class="btn btn-primary"
          [disabled]="!updateChanges || updating"
          (click)="updateLicense()"
        >
          Save Changes
        </button>

        @if (updating) {
          <div class="ml-4 flex content-center">
            <div class="loading loading-spinner"></div>
          </div>
        }
      </div>
    </div>
  }

  @if (errorUpdating) {
    <div class="mt-4">
      <p class="text-error">{{ errorUpdating }}</p>
    </div>
  }

  @if (updateSuccess) {
    <div class="mt-4">
      <p class="text-success">License updated successfully</p>
    </div>
  }
</div>
