import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FirebaseService } from './firebase.service';
import { mergeMap } from 'rxjs';
import { Tenant } from './tenants.service';

export type Franchise = {
  id: string;
  name: string;
  created_at: Date;
};

export type FranchiseResponse = {
  franchise: Franchise;
};

export type CreateFranchiseRequest = {
  name: string;
};

export type FranchiseDetails = {
  franchise: Franchise;
  tenants: Tenant[];
};

type FranchisesResponse = {
  franchises: Franchise[];
};

type FranchiseDetailsResponse = {
  franchise_details: FranchiseDetails;
};

@Injectable({
  providedIn: 'root',
})
export class FranchisesService {
  getFranchiseUrl = environment.serverUrl + '/franchises/get';
  getAllFranchisesUrl = environment.serverUrl + '/franchises/get-all';
  createFranchiseUrl = environment.serverUrl + '/franchises/create';
  getFranchiseDetailsUrl = environment.serverUrl + '/franchises/get-details';

  constructor(
    private http: HttpClient,
    private firebaseService: FirebaseService
  ) {}

  getFranchiseById(id: string) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.get<FranchiseResponse>(
          this.getFranchiseUrl + '/' + id,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }

  getAllFranchises() {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.get<FranchisesResponse>(this.getAllFranchisesUrl, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        });
      })
    );
  }

  createFranchise(req: CreateFranchiseRequest) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.post<FranchiseResponse>(this.createFranchiseUrl, req, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        });
      })
    );
  }

  getFranchiseDetails(id: string) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.get<FranchiseDetailsResponse>(
          this.getFranchiseDetailsUrl + '/' + id,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }
}
