import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MultiFactorResolver } from 'firebase/auth';
import { AuthService } from 'src/app/services/auth.service';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-reauthenticate',
  templateUrl: './reauthenticate.component.html',
  styleUrl: './reauthenticate.component.scss',
})
export class ReauthenticateComponent {
  @Output() success = new EventEmitter<void>();
  username = '';
  password = '';

  error = '';

  show2FAOptions = false;
  mfaResolver?: MultiFactorResolver;

  constructor(
    private authService: AuthService,
    private firebaseService: FirebaseService,
    private router: Router
  ) {}

  get enrollments() {
    if (!this.firebaseService.mfaUser) {
      return [];
    }
    return this.firebaseService.mfaUser.enrolledFactors;
  }

  signIn() {
    this.authService.signInWithEmail(this.username, this.password).subscribe({
      next: this.handleSignInResponse.bind(this),
      error: this.handleSignInError.bind(this),
    });
  }

  signInWithGoogle() {
    this.authService.signInWithGoogle().subscribe({
      next: this.handleSignInResponse.bind(this),
      error: this.handleSignInError.bind(this),
    });
  }

  handleSignInResponse(res: boolean) {
    console.log(
      `Response from server: ${JSON.stringify(res, null, 2)}`
    );
    // This should not succeed if the user has 2FA enabled
    // redirect to login page if this occurs to avoid non-2fa users
    this.router.navigate(['/login']);
  }

  handleSignInError(err: any) {
    console.error(err);
    if (err.code === 'auth/multi-factor-auth-required') {
      // THIS IS EXPECTED
      // this means the user has 2FA enabled

      const mfaResolver = this.firebaseService.mfaResolver(err);
      this.mfaResolver = mfaResolver;
      console.log('mfaResolver: ', mfaResolver);

      if (
        mfaResolver.hints.some((h) => h.factorId === 'phone') ||
        mfaResolver.hints.some((h) => h.factorId === 'totp')
      ) {
        this.show2FAOptions = true;
        // this.pageState = '2fa-login';
        console.log('this.enrollments: ', this.enrollments);
        console.log(
          'firebaseService.currentUser: ',
          this.firebaseService.currentUser
        );
      } else {
        console.error(new Error('Unsupported 2FA method'));
        this.error = 'Unsupported 2FA method';
      }
    } else {
      this.error = JSON.stringify(err, null, 2);
    }
  }

  loginSuccess() {
    this.success.emit();
    setTimeout(() => {
      this.username = '';
      this.password = '';
      this.show2FAOptions = false;
    }, 1000);
  }
}
