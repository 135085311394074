<!-- Top Nav -->
<div class="no-print navbar flex w-screen justify-between bg-base-200">
  <div>
    <a class="px-2 text-3xl font-medium normal-case" [routerLink]="['/']"
      ><span class="text-primary">Accu</span
      ><span class="text-secondary">ZIP</span></a
    >
  </div>
  <div>
    <details
      #mobileNavMenu
      class="dropdown dropdown-end dropdown-bottom [position:unset]"
      (click)="toggleMobileNav($event)"
    >
      <summary class="btn btn-ghost no-animation">
        <div class="flex items-center">
          <div class="w-10 rounded-full">
            @if (userImageURL) {
              <img src="{{ userImageURL }}" />
            } @else {
              <app-user-icon class="text-current"></app-user-icon>
            }
          </div>
          <app-bars-icon
            class="ml-2 inline-block h-6 w-6 text-current"
          ></app-bars-icon>
        </div>
      </summary>
      <div
        tabindex="0"
        class="card dropdown-content card-compact absolute left-0 z-[1] w-screen rounded-none bg-primary p-2 text-primary-content shadow"
      >
        <div class="card-body">
          <ul class="menu h-full w-full p-0 text-primary-content">
            @if (currentTenant) {
              <li class="mt-auto">
                <a class="text-xl normal-case" (click)="copyAccountNumber()">
                  <app-file-copy-icon class="h-5 w-5"></app-file-copy-icon>
                  Account #: {{ currentTenant.account_number }}
                </a>
              </li>
            }
            <li class="mt-auto">
              <a class="text-xl normal-case" [routerLink]="['/admin']">
                <app-cog-icon
                  class="inline-block h-5 w-5 text-current"
                ></app-cog-icon>
                Settings
              </a>
            </li>
            <li class="mt-auto">
              <a class="text-xl normal-case" (click)="signOut()">
                <app-arrow-right-to-bracket-icon
                  class="inline-block h-5 w-5 text-current"
                ></app-arrow-right-to-bracket-icon>
                Logout
              </a>
            </li>
            <li class="mt-auto">
              <a class="text-xl normal-case" [routerLink]="['/test']">
                <app-lightbulb-icon
                  class="inline-block h-5 w-5 text-current"
                ></app-lightbulb-icon>
                Test Page
              </a>
            </li>
          </ul>
        </div>
      </div>
    </details>
  </div>
</div>

<!-- Bottom Nav -->
<div class="btm-nav w-screen">
  @if (showAdmin) {
    <button
      [routerLink]="['/admin']"
      class="text-primary"
      [ngClass]="{
        'active text-secondary': currentMobileBottomNavTab === '/admin',
      }"
    >
      <app-user-setting-icon class="h-7 w-7"></app-user-setting-icon>
    </button>
  } @else {
    <button [routerLink]="['']" class="text-primary">
      <app-home-icon class="h-7 w-7"></app-home-icon>
    </button>
  }
  <button
    [routerLink]="['/licenses']"
    class="text-primary"
    [ngClass]="{
      'active text-secondary': currentMobileBottomNavTab === '/licenses',
    }"
  >
    <app-clipboard-icon class="h-7 w-7"></app-clipboard-icon>
  </button>

  <button
    [routerLink]="['/invoices']"
    class="text-primary"
    [ngClass]="{
      'active text-secondary': currentMobileBottomNavTab === '/invoices',
    }"
  >
    <app-file-invoice-icon class="h-7 w-7"></app-file-invoice-icon>
  </button>
  <button
    [routerLink]="['/tenant/users']"
    class="text-primary"
    [ngClass]="{
      'active text-secondary': currentMobileBottomNavTab === '/tenant/users',
    }"
  >
    <svg
      class="h-7 w-7 text-current"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-width="2"
        d="M16 19h4a1 1 0 0 0 1-1v-1a3 3 0 0 0-3-3h-2m-2.236-4a3 3 0 1 0 0-4M3 18v-1a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1Zm8-10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      />
    </svg>
  </button>
  <button
    [routerLink]="['/saved-payment-methods']"
    class="text-primary"
    [ngClass]="{
      'active text-secondary':
        currentMobileBottomNavTab === '/saved-payment-methods',
    }"
  >
    <app-credit-card-icon class="h-7 w-7"></app-credit-card-icon>
  </button>
</div>

@if (accountNumberCopied) {
  <app-toast-timer
    status="info"
    message="Account number copied to clipboard"
    (clear)="accountNumberCopied = false"
  ></app-toast-timer>
}
