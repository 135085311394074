import { Component } from '@angular/core';

@Component({
  selector: 'app-info-circle-icon',
  templateUrl: './info-circle-icon.component.html',
  styleUrl: './info-circle-icon.component.scss'
})
export class InfoCircleIconComponent {

}
