import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { FirebaseService } from 'src/app/services/firebase.service';
import { UsersService } from 'src/app/services/users.service';
import { UserCredential } from 'firebase/auth';
import { AuthService } from 'src/app/services/auth.service';
import { InvitationsService } from 'src/app/services/invitations.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent {
  verifyingInvitation = false;
  invitationId: string;
  invitationValid?: boolean;

  email: string = '';
  password: string = '';
  confirmPassword: string = '';

  agreedToTerms = false;

  creatingUser: boolean = false;

  userCreated: boolean = false;
  verificationEmail = '';

  error: string = '';

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private firebaseService: FirebaseService,
    private invitationsService: InvitationsService,
    private usersService: UsersService
  ) {
    if (this.authService.signedIn) {
      console.log('Signing out from sign up page');
      this.authService.signOut(false).subscribe({
        next: () => {
          console.log('Signed out from sign up page');
        },
        error: (err) => {
          console.error(err);
        },
      });
    }

    if (!this.route.snapshot.paramMap.has('invitationId')) {
      console.error(new Error('No invitationId found in route'));
      this.invitationValid = false;
      this.invitationId = '';
      return;
    }

    this.invitationId = this.route.snapshot.paramMap.get(
      'invitationId'
    ) as string;

    this.verifyInvitation(this.invitationId);
  }

  get signedIn() {
    return this.authService.signedIn;
  }

  verifyInvitation(id: string) {
    this.verifyingInvitation = true;
    this.invitationsService.validateInvitation(id).subscribe({
      next: (res) => {
        this.verifyingInvitation = false;
        this.invitationValid = res.valid;
      },
      error: (err) => {
        this.verifyingInvitation = false;
        this.invitationValid = false;
        console.error(err);
      },
    });
  }

  createUserWithEmail() {
    if (this.creatingUser) {
      return;
    }

    if (this.email === '') {
      console.error(new Error('Email is required'));
      this.error = 'Email is required';
      return;
    }

    if (this.password !== this.confirmPassword) {
      console.error(new Error('Passwords do not match'));
      this.error = 'Passwords do not match';
      return;
    }

    this.creatingUser = true;
    this.error = '';

    console.log('Creating user with email: ', this.email);

    this.handleUserCreation(
      this.firebaseService.createUserWithEmail(this.email, this.password)
    );
  }

  createUserWithGoogle() {
    if (this.creatingUser) {
      return;
    }

    this.creatingUser = true;
    this.error = '';

    console.log('Creating user with Google');

    this.handleUserCreation(this.firebaseService.createUserWithGoogle());
  }

  handleUserCreation(observable: Observable<UserCredential>) {
    observable.subscribe({
      next: (result) => {
        console.log('Firebase user created: ', result);
        this.createServerUser(result);
      },
      error: (err) => {
        console.error(err);
        this.creatingUser = false;
        if (err.code === 'auth/email-already-in-use') {
          this.error = 'Email is already in use';
        } else if (err.code === 'auth/multi-factor-auth-required') {
          this.error = 'User already exists with this Google account';
        } else {
          this.error = 'Error creating user';
        }
      },
    });
  }

  createServerUser(creds: UserCredential) {
    const newUser = {
      user: {
        firebase_id: creds.user.uid,
        username: creds.user.email || '',
        email: creds.user.email || '',
      },
      invitation_id: this.invitationId,
    };
    console.log('sign-up.component -> creating user: ', newUser);
    this.usersService.createUser(newUser).subscribe({
      next: (user) => {
        console.log('User created on server');
        this.sendVerificationEmail(user.user.email);
      },
      error: (err) => {
        console.error(err);
        this.creatingUser = false;
        this.error = 'Unexpected server error creating user';
      },
    });
  }

  sendVerificationEmail(email: string) {
    this.verificationEmail = email;
    this.authService.sendEmailVerificationEmail(email).subscribe({
      next: () => {
        console.log('Email verification sent');
        this.creatingUser = false;
        this.userCreated = true;
      },
      error: (err) => {
        console.error(err);
        this.creatingUser = false;
        this.error = 'User created, but error sending verification email';
      },
    });
  }
}
