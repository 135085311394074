import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RecaptchaVerifier } from 'firebase/auth';
import { FirebaseService } from 'src/app/services/firebase.service';
import { LoggerService } from 'src/app/services/logger.service';

@Component({
  selector: 'app-sms-enrollment',
  templateUrl: './sms-enrollment.component.html',
  styleUrl: './sms-enrollment.component.scss',
})
export class SmsEnrollmentComponent {
  // @Input() verifyCode!: (code: string) => void;
  // @Input() goBack!: () => void;
  // @Input() sendSMS!: (
  //   phoneNumber: string,
  //   recaptchaVerifier: RecaptchaVerifier
  // ) => void;
  @Input() smsSent!: boolean;

  @Output() sendSMS = new EventEmitter<{
    phoneNumber: string;
    recaptchaVerifier: RecaptchaVerifier;
  }>();
  @Output() verifyCode = new EventEmitter<string>();
  @Output() goBack = new EventEmitter<void>();

  recaptchaVerifier!: RecaptchaVerifier;
  phoneNumber = '';
  smsCode = '';
  invalidPhoneNumber = false;

  constructor(
    private firebaseService: FirebaseService,
    private loggerService: LoggerService
  ) {}

  sendSMSForEnrollment() {
    this.loggerService.debug(
      'sms-enrollment.component -> sendSMSForEnrollment()'
    );
    if (!this.verifyPhoneNumber(this.phoneNumber)) {
      this.loggerService.error(
        new Error(`Invalid phone number "${this.phoneNumber}"`)
      );
      this.invalidPhoneNumber = true;
      return;
    } else {
      this.invalidPhoneNumber = false;
    }

    // recaptcha for phone number verification
    this.recaptchaVerifier =
      this.firebaseService.recaptchaVerifier('sms-2fa-button');

    // remove non-digit characters and add country code
    const phoneNumber = '+1' + this.phoneNumber.replace(/\D/g, '');
    this.loggerService.debug(
      'sms-enrollment.component -> sendSMSForEnrollment',
      {
        phoneNumber,
      }
    );
    this.sendSMS.emit({
      phoneNumber: phoneNumber,
      recaptchaVerifier: this.recaptchaVerifier,
    });
  }

  verifyPhoneNumber(phoneNumber: string) {
    const number = phoneNumber.replace(/\D/g, '');
    if (number.length !== 10) {
      return false;
    }
    return true;
  }

  verifySMSCodeForEnrollment(code: string) {
    this.loggerService.debug(
      'sms-enrollment.component -> verifySMSCodeForEnrollment()'
    );

    this.verifyCode.emit(code);
  }
}
