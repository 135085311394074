import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DesktopLicenseWithProduct } from 'src/app/services/licenses.service';

type Sort = {
  field: string;
  direction: 'ASC' | 'DESC';
};

@Component({
  selector: 'app-licenses-table',
  templateUrl: './licenses-table.component.html',
  styleUrl: './licenses-table.component.scss',
})
export class LicensesTableComponent {
  @Input('licenses') licenses?: DesktopLicenseWithProduct[];
  @Input('current-sort') currentSort?: Sort;
  @Output('sort-change') sortChange = new EventEmitter<string>();
}
