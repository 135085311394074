import { Component } from '@angular/core';

@Component({
  selector: 'app-mastercard-icon',
  templateUrl: './mastercard-icon.component.html',
  styleUrl: './mastercard-icon.component.scss'
})
export class MastercardIconComponent {

}
