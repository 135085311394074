import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { ThemeOption, ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss'],
})
export class TopNavComponent {
  accountNumberCopied = false;

  constructor(
    private authService: AuthService,
    private firebaseService: FirebaseService,
    private themeService: ThemeService
  ) {}

  signOut() {
    this.authService.signOut().subscribe();
  }

  get currentTenant() {
    return this.authService.currentTenant;
  }

  get darkModeEnabled() {
    return this.themeService.theme === 'accuzipDarkTheme';
  }

  get userImageURL() {
    if (!this.firebaseService.currentUser) {
      return;
    }

    return this.firebaseService.currentUser.photoURL;
  }

  copyAccountNumber() {
    this.accountNumberCopied = false;
    if (this.currentTenant) {
      navigator.clipboard.writeText(this.currentTenant.account_number);
      this.accountNumberCopied = true;
    }
  }

  updateTheme(ev: Event) {
    const theme = (ev.target as HTMLSelectElement).value as ThemeOption;
    this.themeService.setTheme(theme, true);
  }

  handleThemeChange(ev: Event) {
    if (ev.target) {
      if ((ev.target as HTMLInputElement).checked) {
        this.themeService.setTheme('accuzipDarkTheme', true);
      } else {
        this.themeService.setTheme('accuzipLightTheme', true);
      }
    }
  }
}
