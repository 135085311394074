import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { paginationArray } from 'src/app/utils/pagination';

export type TableSpacing = 'compact' | 'normal' | 'comfortable';
export type TableSettings = {
  spacing: TableSpacing;
  pageSize: number;
};

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.scss',
})
export class PaginationComponent implements OnInit {
  @Input('spacing') spacing: TableSpacing = 'normal';
  @Input('pageSize') pageSize: number = 20;
  @Input('currentPage') currentPage: number = 1;
  @Input('totalPages') totalPages: number = 1;

  @Output('pageChanged') pageChanged = new EventEmitter<number>();
  @Output('settingsChanged') settingsChanged =
    new EventEmitter<TableSettings>();

  spacingOptions = ['compact', 'normal', 'comfortable'];
  pageSizeOptions = [10, 15, 20, 50, 100];

  currentSpacing: TableSpacing = 'normal';
  currentPageSize: number = 20;

  buttonMinCharWidth = 1;

  ngOnInit() {
    this.currentSpacing = this.spacing;
    this.currentPageSize = this.pageSize;
    this.setButtonWidth();
  }

  get pageArray() {
    return paginationArray(10, this.totalPages, this.currentPage);
  }

  setButtonWidth() {
    if (this.totalPages < 10) {
      this.buttonMinCharWidth = 1;
    } else if (this.totalPages < 100) {
      this.buttonMinCharWidth = 2;
    } else if (this.totalPages < 1000) {
      this.buttonMinCharWidth = 3;
    } else if (this.totalPages < 10000) {
      this.buttonMinCharWidth = 4;
    }
  }

  changePage(page: number) {
    this.pageChanged.emit(page);
  }

  prevPage() {
    this.changePage(this.currentPage - 1);
  }

  nextPage() {
    this.changePage(this.currentPage + 1);
  }

  handleSettingsChanged() {
    console.log('handleSettingsChanged');
    this.pageSize = this.currentPageSize;
    this.spacing = this.currentSpacing;
    this.settingsChanged.emit({
      spacing: this.currentSpacing,
      pageSize: this.currentPageSize,
    });
    this.setButtonWidth();
  }
}
