import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';
import { catchError, mergeMap, throwError } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import {
  DesktopLicenseWithProduct,
  LicensesService,
} from 'src/app/services/licenses.service';
import { LoggerService } from 'src/app/services/logger.service';

export type CurrentLicensesResolverResponse = {
  desktop_licenses?: {
    active: DesktopLicenseWithProduct[];
    expired: DesktopLicenseWithProduct[];
  };
  error?: string;
};

export const currentTenantLicensesResolver: ResolveFn<
  CurrentLicensesResolverResponse
> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const licensesService = inject(LicensesService);
  const loggerService = inject(LoggerService);
  return authService.authReady.pipe(
    mergeMap((ready) => {
      if (!ready) {
        throwError(() => new Error('User not authenticated'));
      }
      return licensesService.getActiveLicenses().pipe(
        catchError((err) => {
          loggerService.error(err);
          return [{ error: 'Error getting licenses' }];
        })
      );
    })
  );
};
