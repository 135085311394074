import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InvoiceDetails } from 'src/app/services/invoices.service';

type Sort = {
  field: string;
  direction: 'ASC' | 'DESC';
};

@Component({
  selector: 'app-invoices-table',
  templateUrl: './invoices-table.component.html',
  styleUrl: './invoices-table.component.scss',
})
export class InvoicesTableComponent {
  @Input('invoices') invoices!: InvoiceDetails[];
  @Input('current-sort') currentSort?: Sort;
  @Output('sort-changed') sortChanged = new EventEmitter<string>();
  @Output('delete-invoice') deleteEmitter = new EventEmitter<InvoiceDetails>();

  ngOnInit(): void {
    console.log('invoices: ', this.invoices);
  }

  browseToInvoice(invoiceId: string, tenantId: string) {
    window.location.href =
      '/admin/tenant/' + tenantId + '/invoice/' + invoiceId;
  }

  get showDeleteButton() {
    return this.invoices.every(
      (i) =>
        !i.desktop_invoice.completed ||
        new Date(i.desktop_invoice.expiration) < new Date()
    );
  }

  deleteInvoice(ev: Event, invoice: InvoiceDetails) {
    // stop event from bubbling up to parent and browsing to invoice page
    ev.stopPropagation();
    console.log('ev: ', ev);
    console.log('invoice: ', invoice);
    this.deleteEmitter.emit(invoice);
  }
}
