import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';
import { catchError, mergeMap, throwError } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { LoggerService } from 'src/app/services/logger.service';
import {
  AuthorizeNetSavedPaymentMethod,
  PaymentsService,
} from 'src/app/services/payments.service';

export type CurrentPaymentMethodsResolverResponse = {
  payment_profiles?: AuthorizeNetSavedPaymentMethod[];
  error?: string;
};

export const currentTenantPaymentMethodsResolver: ResolveFn<
  CurrentPaymentMethodsResolverResponse
> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const paymentsService = inject(PaymentsService);
  const loggerService = inject(LoggerService);
  return authService.authReady.pipe(
    mergeMap((ready) => {
      if (!ready) {
        throwError(() => new Error('User not authenticated'));
      }
      return paymentsService.getCurrentUserSavedPaymentMethods().pipe(
        catchError((err) => {
          loggerService.error(err);
          return [{ error: 'Error getting payment methods' }];
        })
      );
    })
  );
};
