import { Component, ElementRef, ViewChild } from '@angular/core';
import { LoggerService } from 'src/app/services/logger.service';
import {
  ProductType,
  ProductsService,
} from 'src/app/services/products.service';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss'],
})
export class AddProductComponent {
  @ViewChild('confirmDialog') confirmDialog!: ElementRef<HTMLDialogElement>;
  active = true;
  type!: ProductType;
  isService = false;
  code = '';
  varianceCode = '';
  standalone = false;
  description = '';

  creatingProduct = false;
  productCreated = false;
  errorCreatingProduct = '';

  productTypes = this.productsService.productTypes;

  constructor(
    private loggerService: LoggerService,
    private productsService: ProductsService
  ) {}

  openConfirmDialog() {
    if (!this.validateInputs()) {
      return;
    }

    this.confirmDialog.nativeElement.showModal();
  }

  validateInputs() {
    this.errorCreatingProduct = '';
    if (!this.type || this.type.trim() === '') {
      this.errorCreatingProduct = 'Please select a type';
      return false;
    }
    if (!this.description || this.description.trim() === '') {
      this.errorCreatingProduct = 'Please enter a description';
      return false;
    }
    if (!this.code || this.code.trim() === '') {
      this.errorCreatingProduct = 'Please enter a code';
      return false;
    }
    return true;
  }

  createProduct() {
    this.closeConfirmDialog();
    this.creatingProduct = true;
    this.productCreated = false;
    this.errorCreatingProduct = '';

    this.productsService
      .createProduct({
        active: this.active,
        type: this.type,
        is_service: this.isService,
        code: this.code,
        variance_code: this.varianceCode,
        standalone: this.standalone,
        description: this.description,
      })
      .subscribe({
        next: (response) => {
          this.loggerService.log('response: ', response);
          this.creatingProduct = false;
          this.productCreated = true;
          this.errorCreatingProduct = '';
        },
        error: (error) => {
          this.loggerService.error(error);
          this.creatingProduct = false;
          this.productCreated = false;
          this.errorCreatingProduct = error.message;
        },
      });
  }

  closeConfirmDialog() {
    this.confirmDialog.nativeElement.close();
  }
}
