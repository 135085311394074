import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  MultiFactorInfo,
  MultiFactorUser,
  RecaptchaVerifier,
  User,
} from 'firebase/auth';
import { AuthService } from 'src/app/services/auth.service';
import { FirebaseService, TOTPInfo } from 'src/app/services/firebase.service';
import { UserWithPermissions } from 'src/app/services/users.service';
import { toCanvas } from 'qrcode';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrl: './user-settings.component.scss',
})
export class UserSettingsComponent implements OnInit {
  @ViewChild('reauthDialog', { static: true })
  reauthDialog!: ElementRef<HTMLDialogElement>;
  @ViewChild('totpDialog', { static: true })
  totpDialog!: ElementRef<HTMLDialogElement>;
  @ViewChild('smsDialog', { static: true })
  smsDialog!: ElementRef<HTMLDialogElement>;
  @ViewChild('totpQRCanvas', { static: true })
  totpQRCanvas?: ElementRef<HTMLDivElement>;
  @ViewChild('confirmTOTPDeleteDialog', { static: true })
  confirmTOTPDeleteDialog!: ElementRef<HTMLDialogElement>;
  @ViewChild('confirmSMSDeleteDialog', { static: true })
  confirmSMSDeleteDialog!: ElementRef<HTMLDialogElement>;

  user?: UserWithPermissions;
  firebaseUser?: User;
  mfaUser?: MultiFactorUser;
  totpMultiFactorInfo?: MultiFactorInfo;
  smsMultiFactorInfo?: MultiFactorInfo;
  errorFetchingUser = '';

  totpInfo?: TOTPInfo | undefined;
  totpError = '';
  totpSuccessMessage = '';

  sendingSMS = false;
  smsSent = false;
  smsVerificationId = '';
  smsError = '';
  smsSuccessMessage = '';

  displayName: string = '';
  emailAddress: string = '';

  constructor(
    private authService: AuthService,
    private firebaseService: FirebaseService    // private router: Router
  ) {}

  ngOnInit() {
    this.setUserInfo();

    // check last login time, if more than 5 minutes ago, show reauthenticate dialog
    if (this.firebaseUser?.metadata.lastSignInTime) {
      const lastLoginTime = new Date(this.firebaseUser.metadata.lastSignInTime);
      const now = new Date();
      const fiveMinutesAgo = new Date(now.getTime() - 5 * 60 * 1000);

      if (lastLoginTime < fiveMinutesAgo) {
        this.showReauthenticateDialog();
        // this.reauthDialog.nativeElement.showModal();
      }
    }

    this.firebaseService.setupAuthChangeListener((user: User | null) => {
      // if auth changes occur, redirect to login page if user is not signed in
      if (user == null) {
        this.authService.signOut(true).subscribe();
      }
    });
  }

  get hasEmailLogin(): boolean {
    if (!this.firebaseUser) {
      return false;
    }
    for (const provider of this.firebaseUser.providerData) {
      if (provider.providerId === 'password') {
        return true;
      }
    }
    return false;
  }

  get hasGoogleLogin(): boolean {
    if (!this.firebaseUser) {
      return false;
    }
    for (const provider of this.firebaseUser.providerData) {
      if (provider.providerId === 'google.com') {
        return true;
      }
    }
    return false;
  }

  handleAuthChange(user: User | null) {
    console.log('user-settings -> handleAuthChange: ', user);
  }

  showReauthenticateDialog() {
    // close any conflicting dialogs
    this.totpDialog.nativeElement.close();
    this.smsDialog.nativeElement.close();
    this.confirmSMSDeleteDialog.nativeElement.close();
    this.confirmTOTPDeleteDialog.nativeElement.close();
    // show reauthenticate dialog
    this.reauthDialog.nativeElement.showModal();
  }

  showDeleteTOTPModal() {
    this.confirmTOTPDeleteDialog.nativeElement.showModal();
  }
  closeDeleteTOTPModal() {
    this.confirmTOTPDeleteDialog.nativeElement.close();
  }

  showDeleteSMSModal() {
    this.confirmSMSDeleteDialog.nativeElement.showModal();
  }
  closeDeleteSMSModal() {
    this.confirmSMSDeleteDialog.nativeElement.close();
  }

  setUserInfo() {
    console.log('user-settings -> setUserInfo');
    if (
      !this.authService.currentUser ||
      !this.firebaseService.currentUser ||
      !this.firebaseService.mfaUser
    ) {
      this.errorFetchingUser = 'Error fetching user data';
      return;
    }

    this.user = this.authService.currentUser;
    this.firebaseUser = this.firebaseService.currentUser;
    this.mfaUser = this.firebaseService.mfaUser;

    console.log({
      user: this.user,
      firebaseUser: this.firebaseUser,
      mfaUser: this.mfaUser,
    });

    const totpFactor = this.mfaUser.enrolledFactors.find(
      (f) => f.factorId === 'totp'
    );
    const smsFactor = this.mfaUser.enrolledFactors.find(
      (f) => f.factorId === 'phone'
    );

    if (totpFactor) {
      this.totpMultiFactorInfo = totpFactor;
    }
    if (smsFactor) {
      this.smsMultiFactorInfo = smsFactor;
    }

    if (this.firebaseUser.displayName) {
      this.displayName = this.firebaseUser.displayName;
    }
    if (this.firebaseUser.email) {
      this.emailAddress = this.firebaseUser.email;
    }
  }

  checkUserEnrollment() {
    console.log('user-settings -> checkUserEnrollment');
    if (!this.authService.signedIn) {
      this.authService.signOut().subscribe();
    } else {
      this.setUserInfo();
    }
  }

  reauthSuccess() {
    // reauthentication successful, ok to allow setting changes
    this.reauthDialog.nativeElement.close();
  }

  addTOTPMultiFactor() {
    console.log('user-settings -> addTotpMultiFactor');
    this.totpDialog.nativeElement.showModal();

    this.firebaseService.generateTOTPInfo().subscribe({
      next: (res) => {
        console.log('TOTP Info: ', res);
        this.totpInfo = res;
        this.totpError = '';
      },
      error: (err) => {
        console.error(err);
        if (err.code === 'auth/requires-recent-login') {
          // user needs to reauthenticate
          this.showReauthenticateDialog();
        } else {
          this.totpError = JSON.stringify(err, null, 2);
        }
      },
    });
  }

  closeTOTPEnrollment() {
    this.totpDialog.nativeElement.close();
    this.totpError = '';
    this.totpSuccessMessage = '';
    this.totpInfo = undefined;
  }

  setupTOTP2FA() {
    console.log('user-setting -> setupTOTP2FA');
    this.firebaseService.generateTOTPInfo().subscribe({
      next: (res) => {
        console.log(
          'user-settings -> setupTOTP2FA -> TOTP Info: ',
          res
        );
        this.totpInfo = res;
        toCanvas(this.totpQRCanvas?.nativeElement, res.totpUri).then(() => {
          console.log(
            'user-settings -> setupTOTP2FA -> canvas: ',
            this.totpQRCanvas?.nativeElement
          );
        });
        this.totpError = '';
      },
      error: (err) => {
        console.error(err);
        this.totpError = JSON.stringify(err, null, 2);
        this.closeTOTPEnrollment();
      },
    });
  }

  verifyTOTPForEnrollment(code: string) {
    if (!this.totpInfo) {
      console.error(new Error('TOTP info missing'));
      this.totpError = 'TOTP info missing';
      return;
    }

    this.firebaseService
      .verifyTOTPCodeForEnrollment(this.totpInfo.secret, code)
      .subscribe({
        next: (res) => {
          console.log(
            `user-settings -> verifyTOTPForEnrollment -> TOTP code verified`
          );
          this.totpSuccessMessage = '2FA enrollment successful';
          this.totpError = '';
          this.closeTOTPEnrollment();
          this.firebaseService.reloadCurrentUser().then(() => {
            this.checkUserEnrollment();
          });
        },
        error: (err) => {
          console.error(err);
          this.totpError = JSON.stringify(err, null, 2);
          this.closeTOTPEnrollment();
        },
      });
  }

  deleteTOTPMultiFactor() {
    this.closeDeleteTOTPModal();
    console.log('user-settings -> deleteTOTPMultiFactor');
    this.firebaseService.unenrollTOTP().subscribe({
      next: () => {
        console.log(
          'user-settings -> deleteTOTPMultiFactor -> TOTP multi-factor option unenrolled'
        );
        this.totpMultiFactorInfo = undefined;
        this.totpError = '';
        this.totpSuccessMessage = 'TOTP multi-factor option unenrolled';
        this.firebaseService.reloadCurrentUser().then(() => {
          this.checkUserEnrollment();
        });
      },
      error: (err) => {
        console.error(err);
        if (err.code === 'auth/user-token-expired') {
          // requires reauthentication
          // this.reauthDialog.nativeElement.showModal();
          this.showReauthenticateDialog();
        } else {
          this.totpError = 'Error unenrolling TOTP multi-factor option';
        }
      },
    });
  }

  addSMSMultiFactor() {
    this.smsDialog.nativeElement.showModal();
  }

  closeSMSEnrollment() {
    this.smsDialog.nativeElement.close();
    this.smsSent = false;
    this.smsVerificationId = '';
    this.smsError = '';
    this.sendingSMS = false;
  }

  sendSMSForEnrollment(info: {
    phoneNumber: string;
    recaptchaVerifier: RecaptchaVerifier;
  }) {
    console.log('user-settings -> sendSMSForEnrollment');

    this.sendingSMS = true;

    this.firebaseService
      .sendSMSForEnrollment(info.phoneNumber, info.recaptchaVerifier)
      .subscribe({
        next: (verificationId) => {
          this.sendingSMS = false;
          console.log(
            'user-settings -> sendSMSForEnrollment -> SMS sent'
          );
          this.smsVerificationId = verificationId;
          this.smsSent = true;
          this.smsError = '';
        },
        error: (err) => {
          console.error(new Error(err));
          this.smsError = JSON.stringify(err, null, 2);
          this.closeSMSEnrollment();
        },
      });
  }

  verifySMSCodeForEnrollment(code: string) {
    console.log('user-settings -> verifySMSCodeForEnrollment');

    if (!this.smsVerificationId) {
      console.error(new Error('SMS verification ID missing'));
      this.smsError = 'SMS verification ID missing';
      return;
    }

    this.firebaseService
      .verifySMSCodeForEnrollment(this.smsVerificationId, code)
      .subscribe({
        next: (res) => {
          console.log(
            `user-settings -> verifySMSCodeForEnrollment -> SMS code verified`
          );
          this.smsSuccessMessage = '2FA enrollment successful';
          this.smsError = '';
          this.closeSMSEnrollment();
          this.firebaseService.reloadCurrentUser().then(() => {
            this.checkUserEnrollment();
          });
        },
        error: (err) => {
          console.error(err);
          this.smsError = JSON.stringify(err, null, 2);
          this.closeSMSEnrollment();
        },
      });
  }

  deleteSMSMultiFactor() {
    console.log('user-settings -> deleteSMSMultiFactor');
    this.closeDeleteSMSModal();
    this.firebaseService.unenrollSMS().subscribe({
      next: () => {
        console.log(
          'user-settings -> deleteSMSMultiFactor -> SMS multi-factor option unenrolled'
        );
        this.smsMultiFactorInfo = undefined;
        this.smsError = '';
        this.smsSuccessMessage = 'SMS multi-factor option unenrolled';
        this.firebaseService.reloadCurrentUser().then(() => {
          this.checkUserEnrollment();
        });
      },
      error: (err) => {
        console.error(err);
        if (err.code === 'auth/requires-recent-login') {
          // user needs to reauthenticate, show reauthenticate dialog
          this.showReauthenticateDialog();
        }
        if (err.code === 'auth/user-token-expired') {
          // requires reauthentication
          // this.reauthDialog.nativeElement.showModal();
          this.showReauthenticateDialog();
        } else {
          this.smsError = 'Error unenrolling SMS multi-factor option';
        }
      },
    });
  }
}
