import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';
import { catchError, mergeMap, throwError } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import {
  InvoiceDetails,
  InvoicesService,
} from 'src/app/services/invoices.service';
import { LoggerService } from 'src/app/services/logger.service';

export type CurrentInvoicesResolverResponse = {
  desktop_invoices?: {
    pending: InvoiceDetails[];
    complete: InvoiceDetails[];
    expired: InvoiceDetails[];
  };
  error?: string;
};

export const currentTenantInvoicesResolver: ResolveFn<
  CurrentInvoicesResolverResponse
> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const invoiceService = inject(InvoicesService);
  const loggerService = inject(LoggerService);
  return authService.authReady.pipe(
    mergeMap((ready) => {
      if (!ready) {
        throwError(() => new Error('User not authenticated'));
      }
      return invoiceService.getInvoicesForCurrentTenant().pipe(
        catchError((err) => {
          loggerService.error(err);
          return [{ error: 'Error getting invoices' }];
        })
      );
    })
  );
};
