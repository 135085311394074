import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';
import { catchError } from 'rxjs';
import { LoggerService } from 'src/app/services/logger.service';
import {
  ProductWithVariants,
  ProductsService,
} from 'src/app/services/products.service';

export type ProductDetailsResolverResponse = {
  product_with_variants?: ProductWithVariants;
  error?: string;
};

export const productDetailsResolver: ResolveFn<
  ProductDetailsResolverResponse
> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const loggerService = inject(LoggerService);
  return inject(ProductsService)
    .getProductDetails(route.paramMap.get('id')!)
    .pipe(
      catchError((err) => {
        loggerService.error(err);
        return [{ error: 'Error getting product details' }];
      })
    );
};
