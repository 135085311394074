<!-- Top Nav -->
<div class="navbar flex w-screen justify-between bg-base-200">
  <div>
    <a class="px-2 text-3xl font-medium normal-case" [routerLink]="['/']"
      ><span class="text-primary">Accu</span
      ><span class="text-secondary">ZIP</span></a
    >
  </div>
  <div>
    <details
      #mobileNavMenu
      class="dropdown dropdown-end dropdown-bottom [position:unset]"
      (click)="toggleMobileNav($event)"
    >
      <summary class="btn btn-ghost no-animation">
        <div class="flex items-center">
          <div class="w-10 rounded-full">
            @if (userImageURL) {
              <img src="{{ userImageURL }}" />
            } @else {
              <app-user-icon class="text-current"></app-user-icon>
            }
          </div>
          <svg
            class="ml-2 h-6 w-6 text-current"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-width="2"
              d="M5 7h14M5 12h14M5 17h14"
            />
          </svg>
        </div>
      </summary>
      <div
        tabindex="0"
        class="card dropdown-content card-compact absolute left-0 z-[1] w-screen rounded-none bg-primary p-2 text-primary-content shadow"
      >
        <div class="card-body">
          <ul class="menu h-full w-full p-0 text-primary-content">
            @if (currentTenant) {
              <li class="mt-auto">
                <a class="text-xl normal-case" (click)="copyAccountNumber()">
                  <app-file-copy-icon class="h-5 w-5"></app-file-copy-icon>
                  Account #: {{ currentTenant.account_number }}
                </a>
              </li>
            }
            <li class="mt-auto">
              <a class="text-xl normal-case" [routerLink]="['/admin']">
                <svg
                  class="h-5 w-5 text-current"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 13v-2a1 1 0 0 0-1-1h-.757l-.707-1.707.535-.536a1 1 0 0 0 0-1.414l-1.414-1.414a1 1 0 0 0-1.414 0l-.536.535L14 4.757V4a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v.757l-1.707.707-.536-.535a1 1 0 0 0-1.414 0L4.929 6.343a1 1 0 0 0 0 1.414l.536.536L4.757 10H4a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h.757l.707 1.707-.535.536a1 1 0 0 0 0 1.414l1.414 1.414a1 1 0 0 0 1.414 0l.536-.535 1.707.707V20a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-.757l1.707-.708.536.536a1 1 0 0 0 1.414 0l1.414-1.414a1 1 0 0 0 0-1.414l-.535-.536.707-1.707H20a1 1 0 0 0 1-1Z"
                  />
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                  />
                </svg>
                Settings
              </a>
            </li>
            <li class="mt-auto">
              <a class="text-xl normal-case" (click)="signOut()">
                <svg
                  class="h-5 w-5 text-current"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M20 12H8m12 0-4 4m4-4-4-4M9 4H7a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h2"
                  />
                </svg>
                Logout
              </a>
            </li>
            <li class="mt-auto">
              <a class="text-xl normal-case" [routerLink]="['/test']">
                <svg
                  class="h-5 w-5 text-current"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 9a3 3 0 0 1 3-3m-2 15h4m0-3c0-4.1 4-4.9 4-9A6 6 0 1 0 6 9c0 4 4 5 4 9h4Z"
                  />
                </svg>
                Test Page
              </a>
            </li>
          </ul>
        </div>
      </div>
    </details>
  </div>
</div>

<!-- Bottom Nav -->
<div class="btm-nav w-screen">
  @if (showAdmin) {
    <button
      [routerLink]="['/admin']"
      class="text-primary"
      [ngClass]="{
        'active text-secondary': currentMobileBottomNavTab === '/admin',
      }"
    >
      <app-user-setting-icon class="h-7 w-7"></app-user-setting-icon>
    </button>
  } @else {
    <button [routerLink]="['']" class="text-primary">
      <app-home-icon class="h-7 w-7"></app-home-icon>
    </button>
  }
  <button
    [routerLink]="['/licenses']"
    class="text-primary"
    [ngClass]="{
      'active text-secondary': currentMobileBottomNavTab === '/licenses',
    }"
  >
    <app-clipboard-icon class="h-7 w-7"></app-clipboard-icon>
  </button>

  <button
    [routerLink]="['/invoices']"
    class="text-primary"
    [ngClass]="{
      'active text-secondary': currentMobileBottomNavTab === '/invoices',
    }"
  >
    <app-file-invoice-icon class="h-7 w-7"></app-file-invoice-icon>
  </button>
  <button
    [routerLink]="['/tenant/users']"
    class="text-primary"
    [ngClass]="{
      'active text-secondary': currentMobileBottomNavTab === '/tenant/users',
    }"
  >
    <svg
      class="h-7 w-7 text-current"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-width="2"
        d="M16 19h4a1 1 0 0 0 1-1v-1a3 3 0 0 0-3-3h-2m-2.236-4a3 3 0 1 0 0-4M3 18v-1a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1Zm8-10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      />
    </svg>
  </button>
  <button
    [routerLink]="['/saved-payment-methods']"
    class="text-primary"
    [ngClass]="{
      'active text-secondary':
        currentMobileBottomNavTab === '/saved-payment-methods',
    }"
  >
    <app-credit-card-icon class="h-7 w-7"></app-credit-card-icon>
  </button>
</div>

@if (accountNumberCopied) {
  <app-toast-timer
    status="info"
    message="Account number copied to clipboard"
    (clear)="accountNumberCopied = false"
  ></app-toast-timer>
}
