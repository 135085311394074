<div class="flex flex-col p-4">
  <div class="breadcrumbs text-sm">
    <ul>
      <li>
        <a [routerLink]="['/admin']" class="inline-flex items-center gap-2">
          <app-user-setting-icon
            class="h-5 w-5 text-current"
          ></app-user-setting-icon>
          Admin
        </a>
      </li>
      <li>
        <a
          [routerLink]="['/admin']"
          [queryParams]="{ tab: 'product-list' }"
          class="inline-flex items-center gap-2"
        >
          <app-clipboard-icon class="h-5 w-5 text-current"></app-clipboard-icon>
          Product List
        </a>
      </li>
      <li>
        <span class="inline-flex items-center gap-2">
          <app-present-icon class="h-5 w-5 text-current"></app-present-icon>
          Product Details
        </span>
      </li>
    </ul>
  </div>
  @if (error) {
    <div class="mt-4">
      <p class="text-error">{{ error }}</p>
    </div>
  } @else {
    <div>
      <div class="card">
        <div class="card-body flex-row justify-between">
          <div>
            <h4 class="card-title">{{ product?.product?.title }}</h4>
            <p class="my-2 text-sm">
              <span class="font-bold">Description:</span>&nbsp;{{
                product?.product?.description
              }}
            </p>
            <p class="text-sm">
              <span class="mr-3"
                ><span class="font-bold">Type:</span>&nbsp;{{
                  product?.product?.type
                }}</span
              >
              <span>|</span>
              <span class="mx-3"
                ><span class="font-bold">Code:</span>&nbsp;{{
                  product?.product?.code
                }}</span
              >
              <span>|</span>
              <span class="mx-3"
                ><span class="font-bold">Variance:</span>&nbsp;{{
                  product?.product?.variance_code
                }}</span
              >
              <span>|</span>
              <span class="ml-3"
                ><span class="font-bold">Is Service:</span>&nbsp;{{
                  product?.product?.is_service
                }}</span
              >
            </p>
          </div>
          <div class="flex flex-row space-x-8">
            <button
              class="btn btn-primary"
              [routerLink]="
                '/admin/add-product-variant/' + product?.product?.id
              "
            >
              <app-plus-icon class="h-6 w-6"></app-plus-icon>
              Add Variant
            </button>
            <button
              class="btn btn-secondary"
              [routerLink]="'/admin/edit-product/' + product?.product?.id"
            >
              <app-edit-icon class="h-6 w-6"></app-edit-icon>
              Edit
            </button>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto rounded-lg border border-solid border-black">
        <table class="table table-md">
          <thead class="text-sm font-medium">
            <tr>
              <th>Default?</th>
              <th>Duration<br />(Months)</th>
              <th>Delivery<br />Method</th>
              <th>Price<br />Lock<br />Level</th>
              <th>Price</th>
              <th>Renewal<br />Price</th>
              <th>Site<br />Price</th>
              <th>Site<br />Renewal<br />Price</th>
              <th>Active</th>
              <th>Created</th>
            </tr>
          </thead>
          <tbody>
            @for (variant of displayVariants; track variant; let i = $index) {
              <tr
                (click)="setActiveVariant(i)"
                [ngClass]="{
                  'bg-base-300': i === currentVariantIndex,
                  'bg-base-200': i % 2 === 0,
                }"
                class="cursor-pointer"
              >
                <td>{{ variant.is_default }}</td>
                <td>{{ variant.duration_months }}</td>
                <td>{{ variant.delivery_method }}</td>
                <td>{{ variant.price_lock_level }}</td>
                <td>{{ variant.price | currency }}</td>
                <td>{{ variant.renewal_price | currency }}</td>
                <td>{{ variant.site_price | currency }}</td>
                <td>{{ variant.site_renewal_price | currency }}</td>
                <td>{{ variant.active }}</td>
                <td class="text-nowrap">
                  {{ variant.created_at | date: 'short' }}
                </td>
              </tr>
              @if (i === currentVariantIndex) {
                <tr
                  [ngClass]="{
                    'bg-base-200': i % 2 === 0,
                  }"
                >
                  <td colspan="10" class="pb-10">
                    <div class="mb-8 flex flex-row space-x-8">
                      <div class="flex-1">
                        <p
                          class="mb-2 font-semibold text-base-content opacity-60"
                        >
                          Price Key
                        </p>
                        <p>{{ variant.price_key }}</p>
                      </div>
                      <div class="flex-1">
                        <p class="mb-2 font-semibold text-base-content opacity-60">Renewal Price Key</p>
                        <p>{{ variant.renewal_price_key }}</p>
                      </div>
                      <div class="flex-1">
                        <p class="mb-2 font-semibold text-base-content opacity-60">Site Price Key</p>
                        <p>{{ variant.site_price_key }}</p>
                      </div>
                      <div class="flex-1">
                        <p class="mb-2 font-semibold text-base-content opacity-60">Site Renewal Price Key</p>
                        <p>{{ variant.site_renewal_price_key }}</p>
                      </div>
                    </div>

                    <div class="mb-8 flex flex-row space-x-8">
                      <div class="flex-1">
                        <p class="mb-2 font-semibold text-base-content opacity-60">Tax Code</p>
                        <p>{{ variant.tax_code }}</p>
                      </div>
                      <div class="flex-1">
                        <p class="mb-2 font-semibold text-base-content opacity-60">Renewal Tax Code</p>
                        <p>{{ variant.renewal_tax_code }}</p>
                      </div>
                      <div class="flex-1">
                        <p class="mb-2 font-semibold text-base-content opacity-60">Site Tax Code</p>
                        <p>{{ variant.site_tax_code }}</p>
                      </div>
                      <div class="flex-1">
                        <p class="mb-2 font-semibold text-base-content opacity-60">Site Renewal Tax Code</p>
                        <p>{{ variant.site_renewal_tax_code }}</p>
                      </div>
                    </div>

                    <div class="flex flex-row space-x-8">
                      <div>
                        <p class="mb-2 font-semibold text-base-content opacity-60">Variance Code</p>
                        <p>{{ variant.variance_code }}</p>
                      </div>
                      <div class="flex-1">
                        <p class="mb-2 font-semibold text-base-content opacity-60">Description</p>
                        <p>{{ variant.description }}</p>
                      </div>
                    </div>

                    <!-- <table class="table">
                      <thead>
                        <tr>
                          <th>Variance Code</th>
                          <th>Tax Code</th>
                          <th>Renewal Tax Code</th>
                          <th>Site Tax Code</th>
                          <th>Site Renewal Tax Code</th>
                          <th>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{ variant.variance_code }}</td>
                          <td>{{ variant.tax_code }}</td>
                          <td>{{ variant.renewal_tax_code }}</td>
                          <td>{{ variant.site_tax_code }}</td>
                          <td>{{ variant.site_renewal_tax_code }}</td>
                          <td>{{ variant.description }}</td>
                        </tr>
                      </tbody>
                    </table> -->
                  </td>
                </tr>
              }
              @if (i === currentVariantIndex) {
                <tr class="bg-base-200">
                  <td class="text-center" colspan="10">
                    <button
                      class="btn btn-secondary"
                      [routerLink]="'/admin/edit-product-variant/' + variant.id"
                    >
                      <app-edit-icon class="h-6 w-6"></app-edit-icon>
                      Edit
                    </button>
                  </td>
                </tr>
              }
            }
          </tbody>
        </table>
      </div>
    </div>
  }
</div>
