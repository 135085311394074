import { Component } from '@angular/core';

@Component({
  selector: 'app-chevron-left-icon',
  templateUrl: './chevron-left-icon.component.html',
  styleUrl: './chevron-left-icon.component.scss'
})
export class ChevronLeftIconComponent {

}
