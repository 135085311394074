<div class="prose">
  <h1>Dashboard</h1>
</div>

<div class="mt-6 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
  <div
    class="rounded-lg border border-solid border-black hover:cursor-pointer"
    [routerLink]="['/licenses']"
  >
    <div class="prose flex justify-between rounded-t-lg bg-base-300 p-4">
      <h2 class="m-0">Licenses</h2>
      <app-right-arrow-icon class="h-8 w-8 text-current"></app-right-arrow-icon>
    </div>
    <div>
      <p class="p-4">
        Visit the Licenses page to see all of your currently active licenses.
      </p>
    </div>
  </div>

  <div
    class="rounded-lg border border-solid border-black hover:cursor-pointer"
    [routerLink]="['/invoices']"
  >
    <div class="prose flex justify-between rounded-t-lg bg-base-300 p-4">
      <h2 class="m-0">Invoices</h2>
      <app-right-arrow-icon class="h-8 w-8 text-current"></app-right-arrow-icon>
    </div>
    <div>
      <p class="p-4">
        Visit the Invoices page to see all of your currently active invoices.
      </p>
    </div>
  </div>

  <div
    class="rounded-lg border border-solid border-black hover:cursor-pointer"
    [routerLink]="['/tenant/users']"
  >
    <div class="prose flex justify-between rounded-t-lg bg-base-300 p-4">
      <h2 class="m-0">Users</h2>
      <app-right-arrow-icon class="h-8 w-8 text-current"></app-right-arrow-icon>
    </div>
    <div>
      <p class="p-4">
        Visit the Users page to see all of your currently active users and
        pending invitations.
      </p>
    </div>
  </div>
</div>
