<svg
  width="48"
  height="32"
  viewBox="0 0 48 32"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g id="jcb-color-large">
    <rect id="card_bg" width="48" height="32" rx="4" fill="white" />
    <g id="jcb-logo">
      <path
        id="Path"
        d="M32.1 18.57H34.41H34.69C35.1671 18.4778 35.5088 18.0559 35.5 17.57C35.483 17.0934 35.1527 16.6856 34.69 16.57C34.5969 16.5593 34.503 16.5593 34.41 16.57H32.1V18.57Z"
        fill="#40A648"
      />
      <path
        id="Path_2"
        d="M34.14 4C31.9309 4 30.14 5.79086 30.14 8V12.15H35.79C35.9229 12.1344 36.0571 12.1344 36.19 12.15C37.46 12.21 38.41 12.87 38.41 14.01C38.3837 15.0096 37.5796 15.8138 36.58 15.84C37.88 15.93 38.87 16.65 38.87 17.78C38.87 18.91 37.77 19.78 36.32 19.78H30.12V28H36C38.2091 28 40 26.2091 40 24V4H34.14Z"
        fill="#40A648"
      />
      <path
        id="Path_3"
        d="M35.22 14.31C35.2562 13.8151 34.9016 13.3773 34.41 13.31H34.19H32.1V15.24H34.19C34.2629 15.2513 34.3371 15.2513 34.41 15.24C34.8801 15.1789 35.229 14.774 35.22 14.3V14.31Z"
        fill="#40A648"
      />
      <path
        id="Path_4"
        d="M12 4C9.79086 4 8 5.79086 8 8V17.87C9.06652 18.4287 10.2465 18.7365 11.45 18.77C12.0104 18.8353 12.5711 18.6509 12.9832 18.2657C13.3954 17.8805 13.6173 17.3335 13.59 16.77V12.13H17V16.77C17 18.57 15.88 20.04 12.08 20.04C10.7048 20.039 9.33477 19.8711 8 19.54V28H13.87C16.0791 28 17.87 26.2091 17.87 24V4H12Z"
        fill="#0D67B0"
      />
      <path
        id="Path_5"
        d="M23.09 4C20.8809 4 19.09 5.79086 19.09 8V13.23C20.09 12.37 21.86 11.82 24.69 11.96C25.7492 12.0232 26.8003 12.1839 27.83 12.44V14.13C26.9019 13.6317 25.8803 13.332 24.83 13.25C22.68 13.1 21.38 14.15 21.38 16C21.38 17.85 22.68 18.92 24.83 18.75C25.8777 18.6543 26.8967 18.3554 27.83 17.87V19.56C26.8008 19.8182 25.7494 19.9789 24.69 20.04C21.86 20.18 20.1 19.63 19.09 18.77V28H25C27.2092 28 29 26.2091 29 24V4H23.09Z"
        fill="#BE2034"
      />
    </g>
  </g>
</svg>
