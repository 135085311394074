import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';
import { catchError, mergeMap, throwError } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import {
  InvitationsService,
  UserInvitation,
} from 'src/app/services/invitations.service';
import { LoggerService } from 'src/app/services/logger.service';

export type CurrentInvitationsResolverResponse = {
  user_invitations?: {
    accepted: UserInvitation[];
    expired: UserInvitation[];
    pending: UserInvitation[];
  };
  error?: string;
};

export const currentTenantInvitationsResolver: ResolveFn<
  CurrentInvitationsResolverResponse
> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const invitationsService = inject(InvitationsService);
  const loggerService = inject(LoggerService);
  return authService.authReady.pipe(
    mergeMap((ready) => {
      if (!ready) {
        throwError(() => new Error('User not authenticated'));
      }
      return invitationsService.getInvitationsForCurrentTenant().pipe(
        catchError((err) => {
          loggerService.error(new Error(err));
          return [{ error: 'Error getting invitations' }];
        })
      );
    })
  );
};
