import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductResolverResponse } from 'src/app/router/resolvers/product';
import { LoggerService } from 'src/app/services/logger.service';
import {
  Product,
  ProductType,
  ProductsService,
  UpdateProductRequest,
} from 'src/app/services/products.service';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss'],
})
export class EditProductComponent {
  @ViewChild('changeDialog') changeDialog!: ElementRef<HTMLDialogElement>;

  productTypes = this.productsService.productTypes;

  originalProduct?: Product;

  errorFetchingProduct = '';

  active!: boolean;
  type!: ProductType;
  isService!: boolean;
  code!: string;
  varianceCode!: string;
  standalone!: boolean;
  title!: string;
  description!: string;

  savingChanges = false;
  productUpdated = false;
  errorUpdatingProduct = '';

  constructor(
    private loggerService: LoggerService,
    private route: ActivatedRoute,
    private router: Router,
    private productsService: ProductsService
  ) {
    const data = this.route.snapshot.data['product'] as ProductResolverResponse;
    this.loggerService.log('route data: ', data);

    if (data?.error) {
      this.loggerService.error(new Error(data.error));
      this.errorFetchingProduct = data.error;
      return;
    } else if (!data || !data.product) {
      this.loggerService.error(new Error('No product found in route'));
      this.errorFetchingProduct = 'No product found';
      return;
    }

    this.originalProduct = data.product;
    this.initializeData(this.originalProduct);
  }

  initializeData(product: Product) {
    this.active = product.active;
    this.type = product.type;
    this.isService = product.is_service;
    this.code = product.code;
    this.varianceCode = product.variance_code;
    this.standalone = product.standalone;
    this.title = product.title;
    this.description = product.description;
  }

  saveChanges() {
    if (!this.originalProduct) return;

    this.closeChangeDialog();
    this.savingChanges = true;
    this.productUpdated = false;
    this.errorUpdatingProduct = '';
    const updates = this.productUpdates();

    this.productsService
      .updateProduct(this.originalProduct.id, updates)
      .subscribe({
        next: (response) => {
          this.loggerService.log('response: ', response);
          this.productUpdated = true;
          this.originalProduct = response.product;
        },
        error: (error) => {
          this.loggerService.error(error);
          this.errorUpdatingProduct = error.message;
        },
        complete: () => {
          this.savingChanges = false;
        },
      });
  }

  get hasChanges() {
    if (!this.originalProduct) return false;
    return (
      this.active !== this.originalProduct.active ||
      this.type !== this.originalProduct.type ||
      this.isService !== this.originalProduct.is_service ||
      this.code !== this.originalProduct.code ||
      this.standalone !== this.originalProduct.standalone ||
      this.title !== this.originalProduct.title ||
      this.description !== this.originalProduct.description
    );
  }

  productUpdates() {
    if (!this.originalProduct) return {};
    const updates: UpdateProductRequest = {};
    if (this.active !== this.originalProduct.active) {
      updates.active = this.active;
    }
    if (this.type !== this.originalProduct.type) {
      updates.type = this.type;
    }
    if (this.isService !== this.originalProduct.is_service) {
      updates.is_service = this.isService;
    }
    if (this.code !== this.originalProduct.code) {
      updates.code = this.code;
    }
    if (this.standalone !== this.originalProduct.standalone) {
      updates.standalone = this.standalone;
    }
    if (this.title !== this.originalProduct.title) {
      updates.title = this.title;
    }
    if (this.description !== this.originalProduct.description) {
      updates.description = this.description;
    }
    return updates;
  }

  closeChangeDialog() {
    this.changeDialog.nativeElement.close();
  }

  navigateToProduct() {
    if (!this.originalProduct) return;
    this.router.navigate(['/admin/product', this.originalProduct.id]);
  }
}
