<div class="text-center">
  <h1 class="mb-8 text-3xl font-semibold leading-normal">
    Setup Text Message Authentication
  </h1>
  @if (!smsSent) {
    <div>
      <p class="mb-2">Enter phone number</p>
      <p class="text- text-sm">### ###-####</p>
      <div class="mt-6 flex justify-center">
        <div class="join">
          <input
            class="input join-item input-bordered w-[52px] cursor-default"
            type="text"
            [value]="'+1'"
            readonly
          />
          <input
            class="input join-item input-bordered"
            type="text"
            [(ngModel)]="phoneNumber"
            (keydown.enter)="sendSMSForEnrollment()"
            (input)="invalidPhoneNumber = false"
            placeholder="Enter Phone Number"
          />
        </div>
      </div>
      @if (invalidPhoneNumber) {
        <p class="mt-2 text-error">
          Please enter a valid 10 digit phone number
        </p>
      }
      <button
        class="btn btn-primary my-6 w-full"
        id="sms-2fa-button"
        (click)="sendSMSForEnrollment()"
      >
        Send SMS
      </button>
      <button class="btn btn-secondary w-full" (click)="goBack.emit()">
        Back
      </button>
    </div>
  }
  @if (smsSent) {
    <div>
      <p class="text-success">SMS sent</p>
      <p>Enter code from SMS:</p>
      <input
        class="input input-bordered mt-6"
        type="text"
        [(ngModel)]="smsCode"
        (keydown.enter)="verifySMSCodeForEnrollment(smsCode)"
        placeholder="Enter SMS Code"
      />
      <button
        class="btn btn-primary my-6 w-full"
        (click)="verifySMSCodeForEnrollment(smsCode)"
      >
        Verify
      </button>
      <button class="btn btn-secondary w-full" (click)="goBack.emit()">
        Back
      </button>
    </div>
  }
</div>
