import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';
import { catchError, mergeMap, throwError } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import {
  DesktopLicenseWithProduct,
  LicensesService,
} from 'src/app/services/licenses.service';
import { LoggerService } from 'src/app/services/logger.service';
import { Tenant } from 'src/app/services/tenants.service';

export type LicenseDetailsResolverResponse = {
  desktop_license?: DesktopLicenseWithProduct;
  tenant?: Tenant;
  error?: string;
};

export const desktopLicenseDetailsResolver: ResolveFn<
  LicenseDetailsResolverResponse
> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const licenseService = inject(LicensesService);
  const loggerService = inject(LoggerService);
  return authService.authReady.pipe(
    mergeMap((ready) => {
      if (!ready) {
        throwError(() => new Error('User not authenticated'));
      }
      return licenseService.getLicenseDetails(route.paramMap.get('id')!).pipe(
        catchError((err) => {
          loggerService.error(err);
          return [{ error: 'Error getting license details' }];
        })
      );
    })
  );
};
