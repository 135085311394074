import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CurrentLicensesResolverResponse } from 'src/app/router/resolvers/current-tenant-licenses';
import {
  DesktopLicenseWithProduct,
  LicensesService,
} from 'src/app/services/licenses.service';
import { LoggerService } from 'src/app/services/logger.service';

@Component({
  selector: 'app-licenses-page',
  templateUrl: './licenses-page.component.html',
  styleUrls: ['./licenses-page.component.scss'],
})
export class LicensesPageComponent {
  licenses?: {
    active: DesktopLicenseWithProduct[];
    expired: DesktopLicenseWithProduct[];
  };
  errorFetchingLicenses: string = '';
  expandedRowId: number = -1;
  showRenewWarning: boolean = false;
  // saving: boolean = false;
  saveSuccess: boolean = false;
  saveError: boolean = false;
  isHoveringToast: boolean = false;
  intervalID?: any;

  constructor(
    private route: ActivatedRoute,
    private licensesService: LicensesService,
    private loggerService: LoggerService
  ) {
    const data = this.route.snapshot.data[
      'licenses'
    ] as CurrentLicensesResolverResponse;
    this.loggerService.log('route data: ', route.snapshot.data);

    if (data?.error) {
      this.loggerService.error(new Error(data.error));
      this.errorFetchingLicenses = data.error;
      return;
    } else if (!data.desktop_licenses) {
      this.loggerService.log('No licenses data');
      this.errorFetchingLicenses = 'Error fetching licenses';
      return;
    }

    this.licenses = data.desktop_licenses;
  }

  expandRow(idx: number) {
    this.showRenewWarning = false;
    if (this.expandedRowId === idx) {
      this.expandedRowId = -1;
    } else {
      this.expandedRowId = idx;
    }
  }

  getProductDuration(license: DesktopLicenseWithProduct) {
    if (license.product_variant.duration_months % 12 === 0) {
      if (license.product_variant.duration_months / 12 === 1) {
        return `${license.product_variant.duration_months / 12} year`;
      }
      return `${license.product_variant.duration_months / 12} years`;
    } else {
      if (license.product_variant.duration_months === 1) {
        return `${license.product_variant.duration_months} month`;
      }
      return `${license.product_variant.duration_months} months`;
    }
  }

  toggleRenew(license: DesktopLicenseWithProduct, ev: Event) {
    this.loggerService.log('toggle renew - license: ', license);
    if (license.desktop_license.will_renew) {
      ev.preventDefault();
      this.showRenewWarning = true;
      // license.desktop_license.will_renew = false;
    } else if (!license.desktop_license.will_renew) {
      this.showRenewWarning = false;
      license.desktop_license.will_renew = true;
      this.saveLicense(license);
    }
  }

  disableLicenseRenewal(license: DesktopLicenseWithProduct) {
    license.desktop_license.will_renew = false;
    this.saveLicense(license);
  }

  saveLicense(license: DesktopLicenseWithProduct) {
    this.loggerService.log('saving - license: ', license);
    this.saveError = false;
    this.saveSuccess = false;
    this.showRenewWarning = false;
    // this.saving = true;
    this.licensesService
      .updateLicenseWillRenew(license.desktop_license)
      .subscribe({
        next: (data) => {
          this.saveSuccess = true;
          this.loggerService.log('data: ', data);
        },
        error: (err) => {
          this.saveError = true;
          this.loggerService.log('err: ', err);
        },
      });
  }
}
