import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  InvitationsService,
  UserInvitation,
} from 'src/app/services/invitations.service';
import { Role, RolesService } from 'src/app/services/roles.service';
import {
  UserWithPermissions,
  UsersService,
} from 'src/app/services/users.service';
import { LoggerService } from 'src/app/services/logger.service';
import { AuthService } from 'src/app/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-tenant-users',
  templateUrl: './tenant-users.component.html',
  styleUrls: ['./tenant-users.component.scss'],
})
export class TenantUsersComponent {
  @ViewChild('inviteUserModal') inviteUserModal!: ElementRef<HTMLDialogElement>;

  loadingUsers = false;
  users?: UserWithPermissions[];
  errorFetchingUsers = '';

  loadingInvitations = false;
  pendingInvitations?: UserInvitation[];
  errorFetchingInvitations = '';

  loadingRoles = false;
  roles?: Role[];
  errorFetchingRoles = '';

  expandedRowId = -1;
  showWarning = false;
  sending = false;
  sendSuccess = false;
  sendError = '';
  invalidInvite = '';

  inviteEmail = '';
  selectedRoleId = '';

  revokeSuccess = false;
  revokeError = '';

  constructor(
    private authService: AuthService,
    private invitationsService: InvitationsService,
    private loggerService: LoggerService,
    private rolesService: RolesService,
    private router: Router,
    private usersService: UsersService
  ) {
    this.getUsers();
    this.getInvitations();
    this.getRolesWithAccess();
  }

  getUsers() {
    this.loadingUsers = true;
    this.usersService.getUsersForCurrentTenant().subscribe({
      next: (res) => {
        this.loadingUsers = false;
        this.users = res.users;
      },
      error: (err) => {
        this.loadingUsers = false;
        this.loggerService.error(err);
        this.errorFetchingUsers = err;
      },
    });
  }

  getInvitations() {
    this.loadingInvitations = true;
    this.invitationsService.getInvitationsForCurrentTenant().subscribe({
      next: (res) => {
        this.loadingInvitations = false;
        this.pendingInvitations = res.user_invitations.pending;
      },
      error: (err) => {
        this.loadingInvitations = false;
        this.loggerService.error(err);
        this.errorFetchingInvitations = err;
      },
    });
  }

  getRolesWithAccess() {
    this.loadingRoles = true;
    this.rolesService.getRolesWithAccess().subscribe({
      next: (res) => {
        this.loadingRoles = false;
        this.roles = res.roles;
      },
      error: (err) => {
        this.loadingRoles = false;
        this.loggerService.error(err);
        this.errorFetchingRoles = err;
      },
    });
  }

  canEditUser(userToEdit: UserWithPermissions) {
    if (!this.authService.currentUser) return false;

    return this.usersService.canEditUser(
      this.authService.currentUser,
      userToEdit
    );
  }

  hasPermissions(user: UserWithPermissions, roles: string[]) {
    for (const p of user.permissions) {
      for (const role of roles) {
        if (p.role.name === role) return true;
      }
    }
    return false;
  }

  browseToEditUser(user: UserWithPermissions) {
    this.router.navigate(['/edit-user', user.id]);
  }

  get selectedRoleDisplayName() {
    if (!this.selectedRoleId || !this.roles) {
      return '';
    }
    for (const role of this.roles) {
      if (role.id === this.selectedRoleId) {
        return role.display_name;
      }
    }
    return '';
  }

  roleList(user: UserWithPermissions) {
    return user.permissions.map((p) => p.role.display_name).join(', ');
  }

  handleRoleChange(role: Role) {
    this.loggerService.log('handleRoleChange: ', role);
    this.selectedRoleId = role.id;
    // unfocus dropdown so it closes
    if (document.activeElement) {
      (document.activeElement as HTMLElement).blur();
    }
  }

  closeInviteUserModal() {
    this.inviteUserModal.nativeElement.close();
  }

  sendInvitation() {
    this.invalidInvite = '';

    if (!this.pendingInvitations) {
      this.sendError = 'Error sending invitation';
      return;
    }

    if (!this.inviteEmail.trim()) {
      this.invalidInvite = 'Email is required';
      return;
    }

    if (!this.selectedRoleId) {
      this.invalidInvite = 'No role selected';
      return;
    }

    this.sendSuccess = false;
    this.sendError = '';
    this.sending = true;
    this.invitationsService
      .createInvitation(this.inviteEmail, this.selectedRoleId)
      .subscribe({
        next: (result) => {
          this.closeInviteUserModal();
          this.sending = false;
          if (!this.pendingInvitations) {
            this.sendError = 'Error sending invitation';
            return;
          }
          this.sendSuccess = true;
          this.inviteEmail = '';
          this.selectedRoleId = '';
          this.loggerService.log('invitation created: ', result);
          this.pendingInvitations.push(result.user_invitation);
        },
        error: (err) => {
          this.closeInviteUserModal();
          this.sending = false;
          if (err.error?.error === 'email already in use') {
            this.sendError = 'Email already in use';
          } else {
            this.sendError = 'Error creating invitation';
          }
          this.loggerService.error(err);
        },
      });
  }

  revokeInvitation(invitation: UserInvitation) {
    this.invitationsService.revokeInvitation(invitation.id).subscribe({
      next: (res) => {
        this.loggerService.log('Invitation revoked: ', res);
        this.pendingInvitations = this.pendingInvitations?.filter(
          (i) => i.id !== invitation.id
        );
        this.revokeSuccess = true;
      },
      error: (err) => {
        this.loggerService.error(err);
        if (err instanceof HttpErrorResponse) {
          this.revokeError = err.statusText;
        }
        this.revokeError = err.message || err;
      },
    });
  }
}
