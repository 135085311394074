import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { catchError, mergeMap, of } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { LoggerService } from 'src/app/services/logger.service';

/** Guards routes to any authenticated users */
export const userGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const firebaseService = inject(FirebaseService);
  const loggerService = inject(LoggerService);
  const router = inject(Router);

  return authService.authReady.pipe(
    mergeMap((authReady) => {
      if (!authReady || !authService.signedIn) {
        loggerService.log('auth not ready, or user not signed in');
        return router.navigate(['/login']);
      }

      return of(true);
    }),
    catchError((err, caught) => {
      loggerService.error(err as Error);
      try {
        firebaseService.logAnalyticsEvent('app_error', err);
      } catch (err) {
        loggerService.error(err as Error);
      }

      return of(false);
    })
  );
};
