import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FranchiseDetails,
  FranchisesService,
} from 'src/app/services/franchises.service';
import { LoggerService } from 'src/app/services/logger.service';
import { Tenant, TenantsService } from 'src/app/services/tenants.service';

@Component({
  selector: 'app-franchise-details',
  templateUrl: './franchise-details.component.html',
  styleUrl: './franchise-details.component.scss',
})
export class FranchiseDetailsComponent {
  @ViewChild('addTenantModal', { static: true })
  addTenantModal!: ElementRef<HTMLDialogElement>;
  @ViewChild('confirmRemoveTenantModal', { static: false })
  confirmRemoveTenantModal?: ElementRef<HTMLDialogElement>;

  franchiseDetails?: FranchiseDetails;
  loading = false;
  errorFetchingFranchise = '';

  searchAccountNumber = '';
  searchCompanyName = '';
  searching = false;
  searchResults?: {
    available: Tenant[];
    inFranchise: Tenant[];
  };
  searchError = '';

  addingTenant = false;
  errorAddingTenant = '';

  tenantToRemove?: Tenant;
  removingTenant = false;
  errorRemovingTenant = '';

  constructor(
    private franchisesService: FranchisesService,
    private loggerService: LoggerService,
    private route: ActivatedRoute,
    private router: Router,
    private tenantsService: TenantsService
  ) {}

  ngOnInit(): void {
    this.loggerService.debug('franchise-details.component -> ngOnInit');
    this.loading = true;
    const params = this.route.snapshot.params;
    const id = params['id'];

    this.franchisesService.getFranchiseDetails(id).subscribe({
      next: (franchise) => {
        this.loading = false;
        this.loggerService.log(
          'franchise-details.component -> ngOnInit -> franchise: ',
          franchise
        );

        this.franchiseDetails = franchise.franchise_details;
      },
      error: (err) => {
        this.loading = false;
        this.loggerService.error(err);
        this.errorFetchingFranchise = err.message;
      },
    });
  }

  navigateToTenantDetails(tenant: Tenant) {
    this.router.navigate([`admin/tenant/${tenant.id}`]);
  }

  showAddTenantModal() {
    this.addTenantModal.nativeElement.showModal();
  }

  searchForTenants() {
    if (
      this.searchAccountNumber.trim() === '' &&
      this.searchCompanyName.trim() === ''
    ) {
      this.loggerService.error(new Error('No search criteria provided'));
      this.searchError = 'No search criteria provided';
      return;
    }

    this.searching = true;
    this.searchError = '';
    this.searchResults = undefined;

    this.tenantsService
      .searchTenants({
        account_number: this.searchAccountNumber.trim(),
        company_name: this.searchCompanyName.trim(),
      })
      .subscribe({
        next: (res) => {
          this.searching = false;
          this.loggerService.log('searchTenants results: ', res);
          if (res.tenants) {
            this.searchResults = this.formatSearchResults(res.tenants);
          } else {
            this.loggerService.error(
              new Error('No tenants returned from server')
            );
            this.searchResults = { available: [], inFranchise: [] };
          }
        },
        error: (err) => {
          this.searching = false;
          this.loggerService.error(new Error(err));
          this.searchError = err.message;
        },
      });
  }

  formatSearchResults(tenants: Tenant[]) {
    const available = [];
    const inFranchise = [];

    for (const tenant of tenants) {
      if (tenant.franchise_id) {
        inFranchise.push(tenant);
      } else {
        available.push(tenant);
      }
    }

    return { available, inFranchise };
  }

  addTenantToFranchise(tenant: Tenant) {
    if (!this.franchiseDetails) {
      this.loggerService.error(new Error('No franchise set'));
      this.errorAddingTenant = 'No franchise set';
      return;
    }

    this.addingTenant = true;

    this.tenantsService
      .addTenantToFranchise(tenant.id, this.franchiseDetails.franchise.id)
      .subscribe({
        next: (res) => {
          this.addingTenant = false;
          if (!this.franchiseDetails) {
            this.loggerService.error(new Error('No franchise set'));
            this.errorAddingTenant = 'No franchise set';
            return;
          }
          this.loggerService.log('res: ', res);
          this.franchiseDetails.tenants.push(res.tenant);
          this.addTenantModal.nativeElement.close();
        },
        error: (err) => {
          this.addingTenant = false;
          this.loggerService.error(err);
          this.errorAddingTenant = err.message;
        },
      });
  }

  promptForTenantRemove(tenant: Tenant) {
    this.loggerService.debug(
      'franchise-details -> promptForTenantRemove: ',
      tenant
    );

    if (!this.franchiseDetails) {
      this.loggerService.error(new Error('No franchise set'));
      this.errorAddingTenant = 'No franchise set';
      return;
    }

    if (!tenant.franchise_id) {
      this.loggerService.error(new Error('Tenant not in franchise'));
      this.errorAddingTenant = 'Tenant not in franchise';
      return;
    }

    this.tenantToRemove = tenant;
    this.confirmRemoveTenantModal?.nativeElement.showModal();
  }

  hideConfirmRemoveTenantModal() {
    this.tenantToRemove = undefined;
    this.removingTenant = false;
    this.confirmRemoveTenantModal?.nativeElement.close();
  }

  removeTenantFromFranchise() {
    if (!this.franchiseDetails) {
      this.loggerService.error(new Error('No franchise set'));
      this.errorRemovingTenant = 'No franchise set';
      return;
    }

    if (!this.tenantToRemove) {
      this.loggerService.error(new Error('No tenant to remove'));
      this.errorRemovingTenant = 'No tenant to remove';
      return;
    }

    this.removingTenant = true;

    this.tenantsService
      .removeTenantFromFranchise(
        this.tenantToRemove.id,
        this.franchiseDetails.franchise.id
      )
      .subscribe({
        next: (res) => {
          if (!this.franchiseDetails) {
            this.loggerService.error(new Error('No franchise set'));
            this.errorRemovingTenant = 'No franchise set';
            return;
          }
          this.removingTenant = false;
          this.loggerService.log('res: ', res);
          this.franchiseDetails.tenants = this.franchiseDetails.tenants.filter(
            (t) => t.id !== this.tenantToRemove?.id
          );
          this.hideConfirmRemoveTenantModal();
        },
        error: (err) => {
          this.removingTenant = false;
          this.loggerService.error(err);
          this.errorRemovingTenant = err.message;
        },
      });
  }
}
