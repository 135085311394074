import { Component } from '@angular/core';

@Component({
  selector: 'app-american-express-icon',
  templateUrl: './american-express-icon.component.html',
  styleUrl: './american-express-icon.component.scss'
})
export class AmericanExpressIconComponent {

}
