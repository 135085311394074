import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Tenant, TenantsService } from 'src/app/services/tenants.service';

@Component({
  selector: 'app-tenant-admin-edit',
  templateUrl: './tenant-admin-edit.component.html',
  styleUrl: './tenant-admin-edit.component.scss',
})
export class TenantAdminEditComponent {
  tenant?: Tenant;
  tenantFetchError?: string;
  fetchingTenant = true;

  avataxCustomerCode?: string;
  notes?: string;

  updating = false;
  updateSuccess = false;
  updateError = '';

  constructor(
    private tenantsService: TenantsService,
    private route: ActivatedRoute,
    private location: Location
  ) {
    this.fetchingTenant = true;
    const tenantId = this.route.snapshot.paramMap.get('tenantId');
    if (tenantId === null || tenantId === '') {
      console.error(
        new Error('tenant id not provided in route params')
      );
      this.tenantFetchError = 'Tenant ID not provided in route params';
      this.fetchingTenant = false;
      return;
    }

    this.tenantsService.getTenantById(tenantId).subscribe({
      next: (res) => {
        this.fetchingTenant = false;
        console.log('res: ', res);
        if (res.tenant) {
          this.tenant = res.tenant;
          this.avataxCustomerCode = res.tenant.avatax_customer_code;
          this.notes = res.tenant.notes;
        } else {
          console.error(new Error('No tenant returned from server'));
          this.tenantFetchError = 'No tenant returned from server';
        }
      },
      error: (err) => {
        this.fetchingTenant = false;
        console.error(err);
        this.tenantFetchError = err;
      },
    });
  }

  submitAdminSettings() {
    console.log('submitAdminSettings');
    console.log('avalaraCompanyCode: ', this.avataxCustomerCode);
    if (!this.tenant) {
      this.updateError = 'No tenant found';
      return;
    }

    this.tenantsService
      .updateTenantAdminValues(
        this.tenant!.id,
        this.avataxCustomerCode,
        this.notes
      )
      .subscribe({
        next: (res) => {
          console.log('res: ', res);
          this.updateSuccess = true;
          setTimeout(() => {
            this.updateSuccess = false;
          }, 3000);
        },
        error: (err) => {
          console.error(err);
          this.updateError = err;
        },
      });
  }

  back() {
    this.location.back();
  }
}
