<div class="p-8">
  <div class="prose">
    <h1>User Settings</h1>
  </div>

  @if (errorFetchingUser) {
    <div class="pt-8 text-center">
      <p class="text-error">{{ errorFetchingUser }}</p>
    </div>
  } @else if (user && firebaseUser && mfaUser) {
    <!-- <div class="form-control mb-2 w-fit pt-8">
      <label class="label">
        <span class="label-text">Display Name</span>
      </label>
      <input
        type="text"
        class="input input-bordered w-80"
        [(ngModel)]="displayName"
        readonly
      />
    </div> -->

    <div class="form-control mb-2 w-fit pt-8">
      <label class="label">
        <span class="label-text">Email Address</span>
      </label>
      <input
        type="text"
        class="input input-bordered w-80 cursor-default"
        [(ngModel)]="emailAddress"
        readonly
      />
    </div>

    @if (hasGoogleLogin) {
      <div class="mt-12 flex w-fit rounded-md border px-4 py-2">
        <div class="mr-4 h-8 w-8">
          <app-google-icon class="h-5 w-5"></app-google-icon>
        </div>
        <h2 class="self-center text-xl font-semibold">
          Authenticated with Google
        </h2>
      </div>
    }

    <h2 class="mb-8 mt-12 text-xl font-semibold">
      Multi-Factor Authentication Methods
    </h2>

    <div class="mb-8">
      <div class="flex w-80 flex-row">
        <app-qr-code-icon
          class="block h-12 w-12 text-current"
        ></app-qr-code-icon>
        <div class="ml-2">
          <p class="text-lg font-semibold">Authenticator App</p>
          @if (totpMultiFactorInfo) {
            <p>
              Added on
              {{ totpMultiFactorInfo.enrollmentTime | date: 'MM/dd/YYYY' }}
            </p>
          } @else {
            <p>Not enrolled</p>
          }
        </div>

        @if (totpMultiFactorInfo) {
          <div class="ml-auto flex items-center">
            <app-trash-icon
              class="block h-6 w-6 cursor-pointer text-current"
              (click)="showDeleteTOTPModal()"
            ></app-trash-icon>
          </div>
        } @else {
          <div class="ml-auto flex items-center">
            <button
              class="btn btn-outline btn-sm"
              (click)="addTOTPMultiFactor()"
            >
              + Add
            </button>
          </div>
        }
      </div>
    </div>

    <div class="mb-8">
      <div class="flex w-80 flex-row">
        <app-mobile-phone-icon
          class="block h-12 w-12 text-current"
        ></app-mobile-phone-icon>
        <div class="ml-2">
          <p class="text-lg font-semibold">Text Message</p>
          @if (smsMultiFactorInfo) {
            <p>
              Added on
              {{ smsMultiFactorInfo.enrollmentTime | date: 'MM/dd/YYYY' }}
            </p>
          } @else {
            <p>Not enrolled</p>
          }
        </div>
        @if (smsMultiFactorInfo) {
          <div class="ml-auto flex items-center">
            <app-trash-icon
              class="block h-6 w-6 cursor-pointer text-current"
              (click)="showDeleteSMSModal()"
            ></app-trash-icon>
          </div>
        } @else {
          <div class="ml-auto flex items-center">
            <button
              class="btn btn-outline btn-sm"
              (click)="addSMSMultiFactor()"
            >
              + Add
            </button>
          </div>
        }
      </div>
    </div>

    @if (smsError) {
      <div class="text-error">
        <p>{{ smsError }}</p>
      </div>
    }

    @if (totpError) {
      <div class="text-error">
        <p>{{ totpError }}</p>
      </div>
    }
  }
</div>

<dialog #reauthDialog id="reauthDialog" class="modal">
  <div class="modal-box min-w-fit overflow-hidden p-12">
    <app-reauthenticate (success)="reauthSuccess()"></app-reauthenticate>
  </div>
</dialog>

<dialog #totpDialog id="totpEnrollmentDialog" class="modal">
  <div class="modal-box min-w-fit p-12">
    <form method="dialog">
      <button class="btn btn-circle btn-ghost btn-sm absolute right-2 top-2">
        ✕
      </button>
    </form>
    <app-totp-enrollment
      [totpInfo]="totpInfo"
      (verifyCode)="verifyTOTPForEnrollment($event)"
      (goBack)="closeTOTPEnrollment()"
    ></app-totp-enrollment>
  </div>
</dialog>

<dialog #smsDialog id="smsEnrollmentDialog" class="modal">
  <div class="modal-box min-w-fit overflow-hidden p-12">
    <form method="dialog">
      <button class="btn btn-circle btn-ghost btn-sm absolute right-2 top-2">
        ✕
      </button>
    </form>
    <app-sms-enrollment
      [smsSent]="smsSent"
      (sendSMS)="sendSMSForEnrollment($event)"
      (verifyCode)="verifySMSCodeForEnrollment($event)"
      (goBack)="closeSMSEnrollment()"
    ></app-sms-enrollment>
  </div>
</dialog>

<dialog #confirmTOTPDeleteDialog class="modal">
  <div class="modal-box min-w-fit p-8">
    <form method="dialog">
      <button class="btn btn-circle btn-ghost btn-sm absolute right-2 top-2">
        ✕
      </button>
    </form>
    <h3 class="mb-4 text-lg font-bold">Confirm Removal</h3>
    <p class="mb-2">
      Are you sure you want to remove this Two Factor Authentication method?
    </p>

    <p class="mb-4 text-sm">
      You will be redirected to the login page if you remove it.
    </p>

    <div class="flex justify-center space-x-4">
      <button class="btn btn-error btn-sm" (click)="deleteTOTPMultiFactor()">
        Remove
      </button>
      <button class="btn btn-neutral btn-sm" (click)="closeDeleteTOTPModal()">
        Cancel
      </button>
    </div>
  </div>
</dialog>

<dialog #confirmSMSDeleteDialog class="modal">
  <div class="modal-box min-w-fit p-8">
    <form method="dialog">
      <button class="btn btn-circle btn-ghost btn-sm absolute right-2 top-2">
        ✕
      </button>
    </form>
    <h3 class="mb-4 text-lg font-bold">Confirm Removal</h3>
    <p class="mb-2">
      Are you sure you want to remove this Two Factor Authentication method?
    </p>

    <p class="mb-4 text-sm">
      You will be redirected to the login page if you remove it.
    </p>

    <div class="flex justify-center space-x-4">
      <button class="btn btn-error btn-sm" (click)="deleteSMSMultiFactor()">
        Remove
      </button>
      <button class="btn btn-neutral btn-sm" (click)="closeDeleteSMSModal()">
        Cancel
      </button>
    </div>
  </div>
</dialog>
