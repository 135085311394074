<div class="flex justify-center p-5">
  @if (errorFetchingInvoice) {
    <div class="text-error">
      <p>{{ errorFetchingInvoice }}</p>
    </div>
  } @else if (loadingInvoice) {
    <div class="w-full text-center">
      <div class="loading loading-spinner loading-lg"></div>
    </div>
  } @else if (invoice) {
    <div class="w-full">
      <div class="flex justify-between">
        <div>
          <p class="mb-2">
            Invoice created on:
            {{
              invoice.desktop_invoice.created_at | date: 'MM/dd/YYYY, hh:mmaa'
            }}
          </p>
          <p class="mb-4 mt-2">
            Due on: {{ invoice.due_by | date: 'MM/dd/YYYY' : 'UTC' }}
          </p>
        </div>
      </div>
      <div class="overflow-x-auto">
        <table class="table table-zebra">
          <thead class="text-sm">
            <tr>
              <th class="font-medium">Product</th>
              <th class="font-medium">Type</th>
              <th class="font-medium">Price</th>
              <th class="font-medium">New Expiration</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            @for (el of licenseList; track el) {
              <tr [ngClass]="{ 'line-through': el.remove }">
                <td>
                  <div class="h-full w-full">
                    <span
                      [class]="{
                        'pl-4':
                          el.details.license_with_product
                            .parent_desktop_license_id,
                      }"
                    >
                      {{ el.details.license_with_product.product.title }}
                    </span>
                  </div>
                </td>
                <td>
                  {{
                    el.details.license_with_product.site_license
                      ? 'Site'
                      : 'Main'
                  }}
                </td>
                <td>{{ el.details.total_amount | currency: 'USD' }}</td>
                <td>
                  {{
                    el.details.new_expiration_date | date: 'MM/dd/YYYY' : 'UTC'
                  }}
                </td>
                <td class="w-40">
                  @if (!el.remove) {
                    <button
                      class="btn btn-primary btn-sm mr-4"
                      title="Edit"
                      (click)="editLicense(el)"
                    >
                      <app-edit-icon></app-edit-icon>
                    </button>
                    <button
                      class="btn btn-error btn-sm"
                      title="Remove"
                      (click)="removeLicense(el)"
                    >
                      <app-trash-icon></app-trash-icon>
                    </button>
                  }
                  @if (el.remove) {
                    <button
                      class="btn btn-warning btn-sm"
                      (click)="addLicense(el)"
                    >
                      Undo
                    </button>
                  }
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
      <div class="mt-16 w-full space-x-8 text-center">
        <button
          class="btn btn-primary"
          (click)="promptConfirmChanges()"
          [disabled]="!hasChanges || savingChanges"
        >
          Save
        </button>
        <button class="btn btn-error" (click)="returnToInvoice(invoice)">
          Back
        </button>
      </div>
      @if (savingChanges) {
        <div class="mt-8 w-full text-center">
          <div class="loading loading-lg"></div>
        </div>
      }
      @if (changesSaved) {
        <div class="mt-8 w-full text-center text-success">
          Changes saved successfully!
        </div>
      }
      @if (errorSavingChanges) {
        <div class="mt-8 w-full text-center text-error">
          {{ errorSavingChanges }}
        </div>
      }
    </div>
  }
</div>

<dialog #removeChildrenModal class="modal">
  <div class="modal-box max-w-3xl p-8">
    <h3 class="text-lg font-bold">Confirm License Removal</h3>
    <p class="mt-4 text-sm">
      The license you are removing has the following site or add-on licenses.
      Removing this license will also remove the licenses below.
    </p>

    <div class="my-4">
      <table class="table table-zebra">
        <thead class="text-sm">
          <tr>
            <th class="font-medium">Product</th>
            <th class="font-medium">Type</th>
            <th class="font-medium">Price</th>
            <th class="font-medium">New Expiration</th>
          </tr>
        </thead>
        <tbody>
          @for (el of currentChildLicensesToRemove; track el) {
            <tr>
              <td>
                <div class="h-full w-full">
                  <span>
                    {{ el.details.license_with_product.product.title }}
                  </span>
                </div>
              </td>
              <td>
                {{
                  el.details.license_with_product.site_license ? 'Site' : 'Main'
                }}
              </td>
              <td>{{ el.details.total_amount | currency: 'USD' }}</td>
              <td>
                {{
                  el.details.new_expiration_date | date: 'MM/dd/YYYY' : 'UTC'
                }}
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>

    <p class="mt-4 text-sm text-error">
      Are you sure you want to remove this license along with all of its
      site/add-on licenses?
    </p>

    <div class="modal-action">
      <button class="btn btn-error" (click)="removeCurrentChildLicenses()">
        Remove Licenses
      </button>
      <button class="btn btn-neutral" (click)="cancelRemoveChildLicenses()">
        Cancel
      </button>
    </div>
  </div>
</dialog>

<dialog #addParentsModal class="modal">
  <div class="modal-box max-w-3xl p-8">
    <h3 class="text-lg font-bold">Confirm Undo Removal</h3>
    <p class="mt-4 text-sm">
      The license you are adding has the following parent licenses which are not
      active. You will need to re-add these parent licenses if you want to
      continue adding this license.
    </p>

    <div class="my-4">
      <table class="table table-zebra">
        <thead class="text-sm">
          <tr>
            <th class="font-medium">Product</th>
            <th class="font-medium">Type</th>
            <th class="font-medium">Price</th>
            <th class="font-medium">New Expiration</th>
          </tr>
        </thead>
        <tbody>
          @for (el of currentParentLicensesToAdd; track el) {
            <tr>
              <td>
                <div class="h-full w-full">
                  <span>
                    {{ el.details.license_with_product.product.title }}
                  </span>
                </div>
              </td>
              <td>
                {{
                  el.details.license_with_product.site_license ? 'Site' : 'Main'
                }}
              </td>
              <td>{{ el.details.total_amount | currency: 'USD' }}</td>
              <td>
                {{
                  el.details.new_expiration_date | date: 'MM/dd/YYYY' : 'UTC'
                }}
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>

    <p class="mt-4 text-sm text-error">
      Are you sure you want to add this license along with the parent licenses
      above?
    </p>

    <div class="modal-action">
      <button class="btn btn-error" (click)="addCurrentParentLicenses()">
        Add Licenses
      </button>
      <button class="btn btn-neutral" (click)="cancelAddParentLicenses()">
        Cancel
      </button>
    </div>
  </div>
</dialog>

<dialog #confirmRemoveModal class="modal">
  <div class="modal-box max-w-3xl p-8">
    <h3 class="text-lg font-bold">Confirm Removal</h3>
    <p class="mt-4 text-sm">
      The licenses below will be removed from this invoice.
    </p>

    <div class="my-4">
      <table class="table table-zebra">
        <thead class="text-sm">
          <tr>
            <th class="font-medium">Product</th>
            <th class="font-medium">Type</th>
            <th class="font-medium">Price</th>
            <th class="font-medium">New Expiration</th>
          </tr>
        </thead>
        <tbody>
          @for (el of licensesToRemove; track el) {
            <tr>
              <td>
                <div class="h-full w-full">
                  <span>
                    {{ el.details.license_with_product.product.title }}
                  </span>
                </div>
              </td>
              <td>
                {{
                  el.details.license_with_product.site_license ? 'Site' : 'Main'
                }}
              </td>
              <td>{{ el.details.total_amount | currency: 'USD' }}</td>
              <td>
                {{
                  el.details.new_expiration_date | date: 'MM/dd/YYYY' : 'UTC'
                }}
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>

    <p class="mt-4 text-sm text-error">
      This change is permanent. Are you sure you want to remove these licenses?
    </p>

    <div class="modal-action">
      <button class="btn btn-error" (click)="saveChanges()">
        Remove Licenses
      </button>
      <button class="btn btn-neutral" (click)="closeConfirmRemoveModal()">
        Cancel
      </button>
    </div>
  </div>
</dialog>

<dialog #editLicenseModal class="modal">
  <div class="modal-box max-w-3xl p-8">
    <form method="dialog">
      <button
        class="btn btn-circle btn-ghost btn-sm absolute right-2 top-2"
        (click)="closeEditDialog()"
      >
        ✕
      </button>
    </form>

    <h3 class="text-lg font-bold">Edit License Duration</h3>
    @if (editingLicense) {
      <p class="mt-4 text-sm">
        Current License Duration:
        {{
          getDurationString(
            editingLicense.details.license_with_product.product_variant
              .duration_months
          )
        }}
      </p>
    }

    @if (errorFetchingDurationOptions) {
      <div class="mt-4 text-center text-error">
        {{ errorFetchingDurationOptions }}
      </div>
    } @else if (loadingDurationOptions) {
      <div class="mt-4 w-full text-center">
        <div class="loading loading-spinner loading-lg"></div>
      </div>
    } @else if (durationOptions) {
      <div class="form-control my-4 w-fit flex-row">
        <label class="label mr-2"
          ><span class="label-text">New Duration</span></label
        >
        <select
          class="select select-bordered select-sm"
          [(ngModel)]="selectedDurationOption"
        >
          <option value="" disabled selected>Select a new duration</option>
          @for (option of durationOptions; track option) {
            <option [ngValue]="option">
              {{ getDurationString(option.duration_months) }}
            </option>
          }
        </select>
      </div>
    }

    @if (associatedLicenses.length > 0) {
      <p class="mt-8 text-sm">
        This license has the following associated licenses. Changing the
        duration of this license will also change the duration of the associated
        licenses.
      </p>

      <div class="my-4">
        <table class="table table-zebra">
          <thead class="text-sm">
            <tr>
              <th class="font-medium">Product</th>
              <th class="font-medium">Type</th>
              <th class="font-medium">Price</th>
              <th class="font-medium">New Expiration</th>
            </tr>
          </thead>
          <tbody>
            @for (el of associatedLicenses; track el) {
              <tr>
                <td>
                  <div class="h-full w-full">
                    <span>
                      {{ el.details.license_with_product.product.title }}
                    </span>
                  </div>
                </td>
                <td>
                  {{
                    el.details.license_with_product.site_license
                      ? 'Site'
                      : 'Main'
                  }}
                </td>
                <td>{{ el.details.total_amount | currency: 'USD' }}</td>
                <td>
                  {{
                    el.details.new_expiration_date | date: 'MM/dd/YYYY' : 'UTC'
                  }}
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    }

    @if (errorUpdatingLicenseDuration) {
      <div class="mt-4 text-center text-error">
        {{ errorUpdatingLicenseDuration }}
      </div>
    } @else if (updatingLicenseDuration) {
      <div class="mt-4 w-full text-center">
        <div class="loading loading-spinner"></div>
      </div>
    }

    <div class="mt-8 flex justify-end">
      <button
        class="btn btn-primary mr-4"
        (click)="updateLicenseDuration()"
        [disabled]="!selectedDurationOption || updatingLicenseDuration"
      >
        Save
      </button>
      <button class="btn btn-neutral" (click)="closeEditDialog()">
        Cancel
      </button>
    </div>
  </div>
</dialog>

<dialog #cannotRemoveAllModal class="modal">
  <div class="modal-box max-w-xl p-8">
    <h3 class="text-lg font-bold">Cannot Remove All Licenses</h3>
    <p class="my-4 text-sm">
      Saving these changes would remove all licenses from this invoice which is
      not allowed. Please make sure that at least one license remains.
    </p>
    <p class="my-4 text-sm">
      If you don't wish to renew any licenses, you can leave this invoice
      unpaid.
    </p>

    <div class="modal-action">
      <button class="btn btn-error" (click)="closeCannotRemoveAllModal()">
        Ok
      </button>
    </div>
  </div>
</dialog>
