import { Component } from '@angular/core';
import {
  InvoiceDetails,
  InvoicesService,
} from 'src/app/services/invoices.service';

@Component({
  selector: 'app-invoices-page',
  templateUrl: './invoices-page.component.html',
  styleUrls: ['./invoices-page.component.scss'],
})
export class InvoicesPageComponent {
  loadingInvoices = false;
  invoices?: {
    pending: InvoiceDetails[];
    complete: InvoiceDetails[];
    expired: InvoiceDetails[];
  };
  allInvoices: InvoiceDetails[] = [];
  errorFetchingInvoices = '';

  searchText = '';

  currentPage = 1;

  pageSizeOptions = [10, 15, 20, 50, 100];
  pageSize = 15;

  spacingOptions = ['compact', 'normal', 'comfortable'];
  spacing = 'normal';

  constructor(
    private invoicesService: InvoicesService,
  ) {
    this.loadSettings();
    this.getInvoices();
  }

  getInvoices() {
    this.loadingInvoices = true;
    this.invoicesService.getInvoicesForCurrentTenant().subscribe({
      next: (res) => {
        this.loadingInvoices = false;
        this.invoices = res.desktop_invoices;
        this.allInvoices.push(...this.invoices.pending);
        this.allInvoices.push(...this.invoices.complete);
        this.allInvoices.push(...this.invoices.expired);
      },
      error: (err) => {
        this.loadingInvoices = false;
        console.error(err);
        this.errorFetchingInvoices = err;
      },
    });
  }

  get pageArray() {
    const pageArray = [];
    for (let i = 1; i <= this.totalPages; i++) {
      pageArray.push(i);
    }
    return pageArray;
  }

  get totalPages() {
    if (!this.allInvoices) return 1;
    return Math.ceil(this.allInvoices.length / this.pageSize);
  }

  loadSettings() {
    try {
      const settings = localStorage.getItem('invoiceTableSettings');
      if (settings) {
        const parsed = JSON.parse(settings);
        this.pageSize = parsed.pageSize;
        this.spacing = parsed.spacing;
      }
    } catch (err) {
      console.error(err as Error);
    }
  }

  saveSettings() {
    const settings = {
      pageSize: this.pageSize,
      spacing: this.spacing,
    };
    localStorage.setItem('invoiceTableSettings', JSON.stringify(settings));
  }

  get currentPageInvoices() {
    if (!this.allInvoices) return [];
    const offset = (this.currentPage - 1) * this.pageSize;
    return this.allInvoices.slice(offset, offset + this.pageSize);
    // return this.allInvoices;
  }

  navigateToInvoiceDetails(invoice: InvoiceDetails) {
    window.location.href = '/invoice/' + invoice.desktop_invoice.id;
  }

  changePage(page: number) {
    this.currentPage = page;
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }
}
