import { Component } from '@angular/core';

@Component({
  selector: 'app-discover-icon',
  templateUrl: './discover-icon.component.html',
  styleUrl: './discover-icon.component.scss'
})
export class DiscoverIconComponent {

}
