import { Component } from '@angular/core';

@Component({
  selector: 'app-cog-icon',
  templateUrl: './cog-icon.component.html',
  styleUrl: './cog-icon.component.scss'
})
export class CogIconComponent {

}
