import { Component } from '@angular/core';

@Component({
  selector: 'app-undo-icon',
  templateUrl: './undo-icon.component.html',
  styleUrl: './undo-icon.component.scss'
})
export class UndoIconComponent {

}
