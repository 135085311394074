<ul
  class="menu h-full w-52 bg-base-200 p-0 pt-4 text-base [&_li>*]:rounded-none"
>
  <li>
    <a
      routerLink="/"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      class="active text-xl normal-case"
    >
      <app-home-icon class="h-5 w-5 text-current"></app-home-icon>
      Dashboard
    </a>
  </li>
  <li>
    <a
      routerLink="/licenses"
      routerLinkActive="active"
      class="text-xl normal-case"
    >
      <app-clipboard-icon class="h-5 w-5 text-current"></app-clipboard-icon>
      Licenses
    </a>
  </li>
  <li>
    <a
      routerLink="/tenant/users"
      routerLinkActive="active"
      class="text-xl normal-case"
    >
      <app-users-icon class="h-5 w-5 text-current"></app-users-icon>
      Users
    </a>
  </li>
  <li>
    <a
      routerLink="/invoices"
      routerLinkActive="active"
      class="text-xl normal-case"
    >
      <app-file-invoice-icon
        class="h-5 w-5 text-current"
      ></app-file-invoice-icon>
      Invoices
    </a>
  </li>
  <li>
    <a
      routerLink="/saved-payment-methods"
      routerLinkActive="active"
      class="text-xl normal-case"
    >
      <app-credit-card-icon class="h-5 w-5 text-current"></app-credit-card-icon>
      Saved Payment
    </a>
  </li>
  @if (showAdmin) {
    <li>
      <a
        [routerLink]="['/admin']"
        routerLinkActive="active"
        class="text-xl normal-case"
      >
        <app-user-setting-icon
          class="h-5 w-5 text-current"
        ></app-user-setting-icon>
        Admin
      </a>
    </li>
  }
  <li class="mt-auto">
    <a class="text-xl normal-case" routerLink="/test" routerLinkActive="active">
      <svg
        class="h-5 w-5 text-current"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M9 9a3 3 0 0 1 3-3m-2 15h4m0-3c0-4.1 4-4.9 4-9A6 6 0 1 0 6 9c0 4 4 5 4 9h4Z"
        />
      </svg>
      Test Page
    </a>
  </li>
</ul>
