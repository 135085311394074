import { Component } from '@angular/core';

@Component({
  selector: 'app-bars-icon',
  templateUrl: './bars-icon.component.html',
  styleUrl: './bars-icon.component.scss'
})
export class BarsIconComponent {

}
