import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-moon-icon',
  templateUrl: './moon-icon.component.html',
  styleUrl: './moon-icon.component.scss',
})
export class MoonIconComponent {
  @Input() svgClass: string = '';
}
