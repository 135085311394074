import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  InvitationsService,
  UserInvitation,
  UserInvitationRequest,
} from 'src/app/services/invitations.service';
import {
  InvoiceDetails,
  InvoicesService,
} from 'src/app/services/invoices.service';
import {
  DesktopLicenseDetails,
  DesktopLicenseWithProduct,
  LicensesService,
} from 'src/app/services/licenses.service';
import { Role, RolesService } from 'src/app/services/roles.service';
import { Tenant, TenantsService } from 'src/app/services/tenants.service';
import {
  UserWithPermissions,
  UsersService,
} from 'src/app/services/users.service';
import {
  Franchise,
  FranchisesService,
} from 'src/app/services/franchises.service';
import { AuthService } from 'src/app/services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';

type LicenseGroups = {
  active: DesktopLicenseDetails[];
  expired: DesktopLicenseDetails[];
  pending: DesktopLicenseDetails[];
  disabled: DesktopLicenseDetails[];
};

type Sort = {
  field: string;
  direction: 'ASC' | 'DESC';
};

@Component({
  selector: 'app-tenant-details',
  templateUrl: './tenant-details.component.html',
  styleUrls: ['./tenant-details.component.scss'],
})
export class TenantDetailsComponent {
  @ViewChild('addLicenseDialog', { static: true })
  addLicenseDialog!: ElementRef<HTMLDialogElement>;
  @ViewChild('createInvoiceDialog', { static: true })
  createInvoiceDialog!: ElementRef<HTMLDialogElement>;
  @ViewChild('inviteUserModal') inviteUserModal!: ElementRef<HTMLDialogElement>;
  @ViewChild('productSearchInput')
  productSearchInput!: ElementRef<HTMLInputElement>;
  @ViewChild('deleteInvoiceDialog')
  deleteInvoiceDialog!: ElementRef<HTMLDialogElement>;

  tenant?: Tenant;
  franchise?: Franchise;
  desktopLicenses?: LicenseGroups;
  desktopInvoices?: {
    pending: InvoiceDetails[];
    complete: InvoiceDetails[];
    expired: InvoiceDetails[];
  };
  users?: UserWithPermissions[];
  invitations?: {
    pending: UserInvitation[];
    accepted: UserInvitation[];
    expired: UserInvitation[];
    revoked: UserInvitation[];
  };

  loadingUsers = false;
  loadingInvitations = false;
  loadingTenant = false;
  loadingFranchise = false;
  loadingLicenses = false;
  loadingInvoices = false;

  error = '';
  errorFetchingTenant = '';
  errorFetchingFranchise = '';
  errorFetchingLicenses = '';
  errorFetchingInvoices = '';
  errorFetchingUsers = '';
  errorFetchingInvitations = '';
  errorFetchingRoles = '';

  roles?: Role[];
  selectedRoleId = '';
  invitationEmail = '';
  successSendingInvitation = false;
  sendingInvitation = false;
  errorSendingInvitation = '';

  invoiceCreatedSuccessfully = false;
  invoiceToDelete?: InvoiceDetails;
  deletingInvoice = false;
  errorDeletingInvoice = '';
  invoiceDeletedSuccessfully = false;

  currentLicenseSort?: Sort;
  currentInvoiceSort?: Sort;
  currentUserSort?: Sort;

  errorRevokingInvitation = '';
  invitationRevokedSuccessfully = false;

  constructor(
    private authService: AuthService,
    private franchisesService: FranchisesService,
    private invitationsService: InvitationsService,
    private invoicesService: InvoicesService,
    private licensesService: LicensesService,
    private route: ActivatedRoute,
    private rolesService: RolesService,
    private tenantsService: TenantsService,
    private usersService: UsersService
  ) {}

  ngOnInit(): void {
    console.log('ProductDetailsComponent ngOnInit');
    const tenantId = this.route.snapshot.paramMap.get('id');

    if (tenantId === null || tenantId === '') {
      console.error(new Error('tenant id not provided in route params'));
      this.error = 'Tenant ID not provided in route params';
      return;
    }

    this.getTenantDetails(tenantId);
    this.getLicensesForTenant(tenantId);
    this.getInvoicesForTenant(tenantId);
    this.getUsersForTenant(tenantId);
    this.getUserInvitationsForTenant(tenantId);
    this.getRoles();
  }

  getTenantDetails(tenantId: string) {
    this.loadingTenant = true;
    this.tenantsService.getTenantById(tenantId).subscribe({
      next: (tenant) => {
        this.tenant = tenant.tenant;
        if (tenant.tenant.franchise_id) {
          this.getFranchiseDetails(tenant.tenant.franchise_id);
        } else {
          this.loadingTenant = false;
        }
      },
      error: (err) => {
        console.error(err);
        this.loadingTenant = false;
        this.errorFetchingTenant = err;
      },
    });
  }

  getFranchiseDetails(franchiseId: string) {
    this.loadingFranchise = true;
    this.franchisesService.getFranchiseById(franchiseId).subscribe({
      next: (franchise) => {
        this.loadingFranchise = false;
        this.loadingTenant = false;
        this.franchise = franchise.franchise;
      },
      error: (err) => {
        this.loadingFranchise = false;
        this.loadingTenant = false;
        console.error(err);
        this.errorFetchingFranchise = err;
      },
    });
  }

  getLicensesForTenant(tenantId: string) {
    this.loadingLicenses = true;
    this.licensesService.getLicensesForTenant(tenantId).subscribe({
      next: (licenses) => {
        this.loadingLicenses = false;
        console.log('licenses: ', licenses);
        this.desktopLicenses = this.sortLicensesByDefault(
          licenses.desktop_licenses
        );
      },
      error: (err) => {
        console.error(err);
        this.loadingLicenses = false;
        this.errorFetchingLicenses = err;
      },
    });
  }

  tenantAddressValid(tenant: Tenant) {
    return this.tenantsService.tenantAddressValid(tenant);
  }

  sortLicensesByDefault(licenseGroups: LicenseGroups) {
    this.currentLicenseSort = {
      field: 'default',
      direction: 'ASC',
    };

    for (const group in licenseGroups) {
      const licenses = licenseGroups[group as keyof LicenseGroups];

      const sortedLicenses =
        this.licensesService.sortLicensesByRelationship(licenses);
      console.log(`${group} sortedLicenses: `, sortedLicenses);

      licenseGroups[group as keyof LicenseGroups] = sortedLicenses;
    }

    return licenseGroups;
  }

  sortLicenses(field: string) {
    let direction: 'ASC' | 'DESC' = 'DESC';
    if (
      this.currentLicenseSort?.field === field &&
      this.currentLicenseSort?.direction === 'DESC'
    ) {
      direction = 'ASC';
    }
    this.currentLicenseSort = {
      field: field,
      direction: direction,
    };

    this.desktopLicenses?.active.sort((a, b) =>
      this.compFunc(a, b, field, this.currentLicenseSort!.direction)
    );
    this.desktopLicenses?.expired.sort((a, b) =>
      this.compFunc(a, b, field, this.currentLicenseSort!.direction)
    );
    this.desktopLicenses?.pending.sort((a, b) =>
      this.compFunc(a, b, field, this.currentLicenseSort!.direction)
    );
    this.desktopLicenses?.disabled.sort((a, b) =>
      this.compFunc(a, b, field, this.currentLicenseSort!.direction)
    );
  }

  sortInvoices(field: string) {
    let direction: 'ASC' | 'DESC' = 'DESC';
    if (
      this.currentInvoiceSort?.field === field &&
      this.currentInvoiceSort?.direction === 'DESC'
    ) {
      direction = 'ASC';
    }
    this.currentInvoiceSort = {
      field: field,
      direction: direction,
    };

    this.desktopInvoices?.pending.sort((a, b) =>
      this.compFunc(a, b, field, direction)
    );
    this.desktopInvoices?.complete.sort((a, b) =>
      this.compFunc(a, b, field, direction)
    );
    this.desktopInvoices?.expired.sort((a, b) =>
      this.compFunc(a, b, field, direction)
    );
  }

  sortUsers(field: string) {
    let direction: 'ASC' | 'DESC' = 'DESC';
    if (
      this.currentUserSort?.field === field &&
      this.currentUserSort?.direction === 'DESC'
    ) {
      direction = 'ASC';
    }
    this.currentUserSort = {
      field: field,
      direction: direction,
    };

    this.users?.sort((a, b) => this.compFunc(a, b, field, direction));
  }

  compFunc(a: any, b: any, field: string, order: 'ASC' | 'DESC') {
    const aVal = field.split('.').reduce((p, c) => p?.[c], a);
    const bVal = field.split('.').reduce((p, c) => p?.[c], b);
    if (aVal && bVal) {
      const diff = aVal > bVal ? 1 : -1;
      return order === 'ASC' ? diff : -diff;
    }
    if (aVal) {
      return order === 'ASC' ? 1 : -1;
    }
    if (bVal) {
      return order === 'ASC' ? -1 : 1;
    }
    return 0;
  }

  getInvoicesForTenant(tenantId: string) {
    this.loadingInvoices = true;
    this.invoicesService.getInvoicesForTenant(tenantId).subscribe({
      next: (invoices) => {
        console.log('invoices: ', invoices);
        this.loadingInvoices = false;
        this.desktopInvoices = invoices.desktop_invoices;
      },
      error: (err) => {
        console.error(err);
        this.loadingInvoices = false;
        this.errorFetchingInvoices = err;
      },
    });
  }

  getUsersForTenant(tenantId: string) {
    this.loadingUsers = true;
    this.usersService.getUsersForTenant(tenantId).subscribe({
      next: (users) => {
        this.loadingUsers = false;
        this.users = users.users;
      },
      error: (err) => {
        console.error(err);
        this.loadingUsers = false;
        this.errorFetchingUsers = err;
      },
    });
  }

  getUserInvitationsForTenant(tenantId: string) {
    this.loadingInvitations = true;
    this.invitationsService.getInvitationsForTenant(tenantId).subscribe({
      next: (res) => {
        this.loadingInvitations = false;
        this.invitations = res.user_invitations;
      },
      error: (err) => {
        console.error(err);
        this.loadingInvitations = false;
        this.errorFetchingInvitations = err;
      },
    });
  }

  getRoles() {
    this.rolesService.getRolesWithAccess().subscribe({
      next: (res) => {
        this.roles = res.roles;
      },
      error: (err) => {
        this.errorFetchingRoles = err;
      },
    });
  }

  roleList(user: UserWithPermissions) {
    return user.permissions.map((p) => p.role.display_name).join(', ');
  }

  showAddLicenseTable() {
    this.addLicenseDialog?.nativeElement.showModal();
  }

  handleLicenseAdded(license: DesktopLicenseWithProduct) {
    console.log('handleLicenseAdded: ', license);

    // trigger a license fetch to update the licenses lists
    this.getLicensesForTenant(this.tenant!.id);

    this.addLicenseDialog?.nativeElement.close();
  }

  showCreateInvoiceModal() {
    this.createInvoiceDialog.nativeElement.showModal();
  }

  handleInvoiceCreated(invoice: InvoiceDetails) {
    console.log('invoice created: ', invoice);
    this.invoiceCreatedSuccessfully = true;

    if (this.desktopInvoices) {
      this.desktopInvoices.pending.push(invoice);
      // assign new object to force change detection
      this.desktopInvoices = Object.assign({}, this.desktopInvoices);
    } else {
      this.desktopInvoices = {
        pending: [invoice],
        complete: [],
        expired: [],
      };
    }

    // trigger a license fetch to update the invoice indicators
    this.getLicensesForTenant(this.tenant!.id);

    this.createInvoiceDialog.nativeElement.close();
  }

  browseToInvoice(invoiceId: string, tenantId: string) {
    window.location.href =
      '/admin/tenant/' + tenantId + '/invoice/' + invoiceId;
  }

  promptDeleteInvoice(invoice: InvoiceDetails) {
    this.invoiceToDelete = invoice;
    this.deleteInvoiceDialog.nativeElement.showModal();
  }

  closeDeleteInvoiceDialog() {
    this.deleteInvoiceDialog.nativeElement.close();
    this.invoiceToDelete = undefined;
  }

  deleteInvoice() {
    if (!this.invoiceToDelete) {
      console.error(new Error('No invoice to delete'));
      this.errorDeletingInvoice = 'No invoice to delete';
      return;
    }

    const invoiceToDelete = this.invoiceToDelete;

    this.deletingInvoice = true;
    this.invoicesService
      .deleteInvoice(invoiceToDelete.desktop_invoice.id)
      .subscribe({
        next: (res) => {
          console.log('invoice deleted: ', res);
          this.deletingInvoice = false;
          this.errorDeletingInvoice = '';
          this.invoiceToDelete = undefined;

          // remove invoice from list it was in
          if (this.desktopInvoices?.pending) {
            this.desktopInvoices.pending = this.desktopInvoices.pending.filter(
              (i) => i.desktop_invoice.id !== invoiceToDelete.desktop_invoice.id
            );
          }
          if (this.desktopInvoices?.expired) {
            this.desktopInvoices.expired = this.desktopInvoices.expired.filter(
              (i) => i.desktop_invoice.id !== invoiceToDelete.desktop_invoice.id
            );
          }

          // trigger a license fetch to update the invoice indicators
          this.getLicensesForTenant(this.tenant!.id);

          this.deleteInvoiceDialog.nativeElement.close();
          this.invoiceDeletedSuccessfully = true;
        },
        error: (err) => {
          this.deletingInvoice = false;
          console.error(err);
          this.errorDeletingInvoice = 'Unexpected error deleting invoice';
        },
      });
  }

  sendInvitation() {
    if (!this.invitations || !this.tenant) {
      this.errorSendingInvitation = 'Error sending invitation';
      return;
    }

    const request: UserInvitationRequest = {
      email: this.invitationEmail,
      starting_role_id: this.selectedRoleId,
      tenant_id: this.tenant.id,
      first_user: false,
    };

    this.successSendingInvitation = false;
    this.errorSendingInvitation = '';
    this.sendingInvitation = true;
    this.invitationsService.createInvitationForTenant(request).subscribe({
      next: (result) => {
        this.inviteUserModal.nativeElement.close();
        this.sendingInvitation = false;
        if (!this.invitations) {
          this.errorSendingInvitation = 'Error sending invitation';
          return;
        }
        this.successSendingInvitation = true;
        this.invitationEmail = '';
        this.selectedRoleId = '';
        console.log('invitation created: ', result);
        this.invitations.pending.push(result.user_invitation);
      },
      error: (err) => {
        this.inviteUserModal.nativeElement.close();
        this.sendingInvitation = false;
        if (err.error?.error === 'email already in use') {
          this.errorSendingInvitation = 'Email already in use';
        } else {
          this.errorSendingInvitation = 'Error creating invitation';
        }
        console.error(err);
      },
    });
  }

  canEditUser(userToEdit: UserWithPermissions) {
    if (!this.authService.currentUser) return false;

    return this.usersService.canEditUser(
      this.authService.currentUser,
      userToEdit
    );
  }

  revokeInvitation(invitation: UserInvitation) {
    this.invitationsService.revokeInvitation(invitation.id).subscribe({
      next: (res) => {
        if (!this.invitations) {
          this.errorFetchingInvitations = 'Invitations not loaded properly';
          return;
        }
        // remove invitation from pending list
        this.invitations.pending = this.invitations.pending.filter(
          (i) => i.id !== invitation.id
        );
        // add to revoked invitations
        this.invitations.revoked.push(res.user_invitation);

        this.invitationRevokedSuccessfully = true;
      },
      error: (err) => {
        console.error(err);
        if (err instanceof HttpErrorResponse) {
          this.errorRevokingInvitation = err.statusText;
        }
        this.errorRevokingInvitation = err.message || err;
      },
    });
  }
}
