import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FirebaseService } from './firebase.service';
import { HttpClient } from '@angular/common/http';
import { mergeMap } from 'rxjs';
import { AuthService } from './auth.service';

type AuthorizeNetPaymentRequest = {
  opaque_data: {
    datDescriptor: string;
    dataValue: string;
  };
  invoice_id: string;
};

type SavedMethodPaymentRequest = {
  invoice_id: string;
  saved_payment_method_id: string;
};

export type AuthorizeNetSavedPaymentMethod = {
  id: string;
  tenant_id: string;
  last_4_digits: string;
  card_expiration: string;
  card_type: string;
  first_name: string;
  last_name: string;
  company: string;
  address: string;
  city: string;
  state: string;
  zip_code: string;
  is_default: boolean;
  created_at: string;
  updated_at: string;
};

type CreateSavedPaymentMethodRequest = {
  opaque_data: {
    datDescriptor: string;
    dataValue: string;
  };
  bill_to: {
    firstName?: string;
    lastName?: string;
    company?: string;
    address: string;
    city: string;
    state: string;
    zip: string;
  };
  tenant_id: string;
  is_default: boolean;
};

export type GetSavedPaymentMethodsResponse = {
  payment_profiles: AuthorizeNetSavedPaymentMethod[];
};

type CreateSavedPaymentMethodResponse = {
  payment_profile: AuthorizeNetSavedPaymentMethod;
};

type SetDefaultPaymentMethodResponse = {
  message: string;
};

export type DesktopPayment = {
  id: string;
  desktop_invoice_id: string;
  completed: boolean;
  status: DesktopPaymentStatus;
  created_at: string;
  updated_at: string;
};

export type DesktopPaymentResponse = {
  desktop_payment: DesktopPayment;
};

type DesktopPaymentStatus =
  | 'created'
  | 'processing'
  | 'failed'
  | 'declined'
  | 'held'
  | 'completed'
  | 'cancelled'
  | 'refunding'
  | 'refunded';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  private processPaymentUrl =
    environment.serverUrl + '/payments/process-authorizenet-payment';
  private processPaymentWithSavedMethodUrl =
    environment.serverUrl + '/payments/process-payment-with-saved-method';
  private savedPaymentMethodsForTenantUrl =
    environment.serverUrl + '/payments/saved-payment-methods-for-tenant';
  private createSavedPaymentMethodUrl =
    environment.serverUrl + '/payments/save-payment-method';
  private setDefaultPaymentMethodUrl =
    environment.serverUrl + '/payments/set-default-payment-method';
  private deletePaymentMethodUrl =
    environment.serverUrl + '/payments/delete-saved-payment-method';

  constructor(
    private authService: AuthService,
    private firebaseService: FirebaseService,
    private http: HttpClient
  ) {}

  processAuthorizeNetPayment(req: AuthorizeNetPaymentRequest) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.post<DesktopPaymentResponse>(
          this.processPaymentUrl,
          req,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      })
    );
  }

  processPaymentWithSavedMethod(req: SavedMethodPaymentRequest) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.post<DesktopPaymentResponse>(
          this.processPaymentWithSavedMethodUrl,
          req,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      })
    );
  }

  getCurrentUserSavedPaymentMethods() {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        const tenantId = this.authService.currentUser?.tenant_id;
        if (!tenantId) {
          throw new Error('No tenant ID found');
        }
        return this.http.get<GetSavedPaymentMethodsResponse>(
          `${this.savedPaymentMethodsForTenantUrl}/${tenantId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      })
    );
  }

  getSavedPaymentMethods(tenantId: string) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.get<GetSavedPaymentMethodsResponse>(
          `${this.savedPaymentMethodsForTenantUrl}/${tenantId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      })
    );
  }

  createNewPaymentMethod(req: CreateSavedPaymentMethodRequest) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.post<CreateSavedPaymentMethodResponse>(
          this.createSavedPaymentMethodUrl,
          req,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      })
    );
  }

  setDefaultPaymentMethod(paymentMethod: AuthorizeNetSavedPaymentMethod) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.put<SetDefaultPaymentMethodResponse>(
          `${this.setDefaultPaymentMethodUrl}/${paymentMethod.id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      })
    );
  }

  deleteSavedPaymentMethod(paymentMethod: AuthorizeNetSavedPaymentMethod) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.delete(
          `${this.deletePaymentMethodUrl}/${paymentMethod.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      })
    );
  }
}
