import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';
import { catchError, mergeMap, throwError } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { LoggerService } from 'src/app/services/logger.service';
import {
  UserWithPermissions,
  UsersService,
} from 'src/app/services/users.service';

export type CurrentUsersResolverResponse = {
  users?: UserWithPermissions[];
  error?: string;
};

export const currentTenantUsersResolver: ResolveFn<
  CurrentUsersResolverResponse
> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const loggerService = inject(LoggerService);
  const usersService = inject(UsersService);
  return authService.authReady.pipe(
    mergeMap((ready) => {
      if (!ready) {
        throwError(() => new Error('User not authenticated'));
      }
      return usersService.getUsersForCurrentTenant().pipe(
        catchError((err) => {
          loggerService.error(err);
          return [{ error: 'Error getting users' }];
        })
      );
    })
  );
};
