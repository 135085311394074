import { Injectable } from '@angular/core';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  UserCredential,
  applyActionCode,
  multiFactor,
  TotpMultiFactorGenerator,
  TotpSecret,
  getMultiFactorResolver,
  MultiFactorResolver,
  RecaptchaVerifier,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  MultiFactorInfo,
  updateProfile,
  signInWithPopup,
  GoogleAuthProvider,
  NextOrObserver,
  User,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from 'firebase/auth';
import { environment } from 'src/environments/environment';
import { Observable, ReplaySubject } from 'rxjs';
import { ThemeService } from './theme.service';

type AnalyticsEvent = 'login' | 'logout' | 'sign_up' | 'error' | 'app_error';

export type TOTPInfo = {
  secret: TotpSecret;
  totpUri: string;
};

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  private app = initializeApp(environment.firebaseConfig);
  private analytics = getAnalytics(this.app);
  private auth = getAuth(this.app);

  public authReady = new ReplaySubject<boolean>(1);

  constructor(
    private themeService: ThemeService
  ) {
    this.initializeAuth();
  }

  initializeAuth() {
    console.log('FirebaseService: Waiting for auth to load');
    const unsub = onAuthStateChanged(this.auth, (user) => {
      console.log('FirebaseService: Auth loaded');
      this.authReady.next(true);
      console.log('user: ', user);
      console.log('mfaUser: ', this.mfaUser);
      unsub();
    });
  }

  get signedIn() {
    return this.auth.currentUser !== null;
  }

  get currentUser() {
    return this.auth.currentUser;
  }

  get mfaUser() {
    if (!this.currentUser) {
      return null;
    }
    return multiFactor(this.currentUser);
  }

  setupAuthChangeListener(next: NextOrObserver<User | null>) {
    return onAuthStateChanged(this.auth, next);
  }

  idToken() {
    return new Observable<string>((observer) => {
      if (!this.auth.currentUser) {
        observer.error('No user signed in');
        observer.complete();
        return;
      }

      this.auth.currentUser
        .getIdToken()
        .then((token) => {
          observer.next(token);
        })
        .catch((err) => {
          console.error(err);
          observer.error(err);
        })
        .finally(() => {
          observer.complete();
        });
    });
  }

  reloadCurrentUser() {
    if (this.auth.currentUser === null) {
      return new Promise<void>((resolve) => resolve());
    } else {
      return this.auth.currentUser.reload();
    }
  }

  createUserWithEmail(email: string, password: string) {
    return new Observable<UserCredential>((observer) => {
      createUserWithEmailAndPassword(this.auth, email, password)
        .then((result) => {
          this.logAnalyticsEvent('sign_up', result);
          observer.next(result);
        })
        .catch((err) => {
          console.error(err);
          this.logAnalyticsEvent('error', err);
          observer.error(err);
        })
        .finally(() => {
          observer.complete();
        });
    });
  }

  createUserWithGoogle() {
    return new Observable<UserCredential>((observer) => {
      signInWithPopup(this.auth, new GoogleAuthProvider())
        .then((userCredential) => {
          this.logAnalyticsEvent('sign_up', userCredential);
          observer.next(userCredential);
        })
        .catch((err) => {
          console.error(err);
          this.logAnalyticsEvent('error', err);
          observer.error(err);
        })
        .finally(() => {
          observer.complete();
        });
    });
  }

  signInWithEmail(email: string, password: string) {
    return new Observable<UserCredential>((observer) => {
      signInWithEmailAndPassword(this.auth, email, password)
        .then((userCredential) => {
          this.logAnalyticsEvent('login', userCredential);
          observer.next(userCredential);
        })
        .catch((err) => {
          console.error(err);
          this.logAnalyticsEvent('error', err);
          observer.error(err);
        })
        .finally(() => {
          observer.complete();
        });
    });
  }

  signInWithGoogle() {
    return new Observable<UserCredential>((observer) => {
      signInWithPopup(this.auth, new GoogleAuthProvider())
        .then((userCredential) => {
          this.logAnalyticsEvent('login', userCredential);
          observer.next(userCredential);
        })
        .catch((err) => {
          console.error(err);
          this.logAnalyticsEvent('error', err);
          observer.error(err);
        })
        .finally(() => {
          observer.complete();
        });
    });
  }

  signOut() {
    return new Observable<void>((observer) => {
      this.auth
        .signOut()
        .then(() => {
          console.log('Signed out of Firebase');
          observer.next();
        })
        .catch((err) => {
          console.error(err);
          observer.error(err);
        })
        .finally(() => {
          observer.complete();
        });
    });
  }

  // sendVerificationEmail() {
  //   return new Observable<void>((observer) => {
  //     if (this.auth.currentUser) {
  //       if (this.auth.currentUser.emailVerified) {
  //         observer.next();
  //         observer.complete();
  //         return;
  //       }
  //     }

  //     sendEmailVerification(this.auth.currentUser!)
  //       .then(() => {
  //         console.log(
  //           'Email verification sent to ',
  //           this.auth.currentUser?.email
  //         );
  //         observer.next();
  //       })
  //       .catch((err) => {
  //         console.error(err);
  //         observer.error(err);
  //       })
  //       .finally(() => {
  //         observer.complete();
  //       });
  //   });
  // }

  checkEmailVerificationCode(code: string) {
    return new Observable<boolean>((observer) => {
      applyActionCode(this.auth, code)
        .then(() => {
          observer.next(true);
        })
        .catch((err) => {
          console.error(err);
          observer.error(err);
        })
        .finally(() => {
          observer.complete();
        });
    });
  }

  // sendPasswordResetEmail(email: string) {
  //   return new Observable<void>((observer) => {
  //     sendPasswordResetEmail(this.auth, email)
  //       .then(() => {
  //         observer.next();
  //       })
  //       .catch((err) => {
  //         console.error(err);
  //         observer.error(err);
  //       })
  //       .finally(() => {
  //         observer.complete();
  //       });
  //   });
  // }

  checkPasswordResetCode(code: string) {
    return new Observable<string>((observer) => {
      verifyPasswordResetCode(this.auth, code)
        .then((email) => {
          observer.next(email);
        })
        .catch((err) => {
          console.error(err);
          observer.error(err);
        })
        .finally(() => {
          observer.complete();
        });
    });
  }

  resetPassword(password: string, oobCode: string) {
    return new Observable((observer) => {
      confirmPasswordReset(this.auth, oobCode, password)
        .then(() => {
          observer.next();
        })
        .catch((err) => {
          console.error(err);
          observer.error(err);
        })
        .finally(() => {
          observer.complete();
        });
    });
  }

  updateDisplayName(displayName: string) {
    if (!this.auth.currentUser) {
      throw new Error('No user signed in');
    }
    updateProfile(this.auth.currentUser, { displayName });
  }

  generateTOTPInfo() {
    console.log('Generating TOTP secret');
    if (!this.currentUser) {
      throw new Error('No user signed in');
    }

    return new Observable<TOTPInfo>((observer) => {
      multiFactor(this.currentUser!)
        .getSession()
        .then((session) => {
          TotpMultiFactorGenerator.generateSecret(session)
            .then((secret) => {
              console.log('TOTP secret generated: ', secret);
              const appName = environment.production
                ? 'AccuZIP Customer Portal'
                : 'AccuZIP Customer Portal (Dev)';
              const totpUri = secret.generateQrCodeUrl(
                this.currentUser?.email || undefined,
                appName
              );
              // console.log('TOTP URI: ', totpUri);
              console.log({ secret, totpUri });
              observer.next({ secret, totpUri });
            })
            .catch((err) => {
              console.error(err);
              observer.error(err);
            })
            .finally(() => {
              console.log('TOTP secret generation complete');
              observer.complete();
            });
        })
        .catch((err) => {
          console.error(err);
          observer.error(err);
          observer.complete();
        });
    });
  }

  verifyTOTPCodeForEnrollment(secret: TotpSecret, code: string) {
    if (!this.currentUser) {
      throw new Error('No user signed in');
    }

    return new Observable<boolean>((observer) => {
      const multiFactorAssertion =
        TotpMultiFactorGenerator.assertionForEnrollment(secret, code);
      const appName = environment.production
        ? 'AccuZIP Customer Portal'
        : 'AccuZIP Customer Portal (Dev)';
      multiFactor(this.currentUser!)
        .enroll(multiFactorAssertion, appName)
        .then(() => {
          observer.next(true);
        })
        .catch((err) => {
          console.error(err);
          observer.error(err);
        })
        .finally(() => {
          observer.complete();
        });
    });
  }

  verifyTOTPCodeForSignIn(
    mfaResolver: MultiFactorResolver,
    uid: string,
    code: string
  ) {
    return new Observable<UserCredential>((observer) => {
      const assertion = TotpMultiFactorGenerator.assertionForSignIn(uid, code);
      mfaResolver
        .resolveSignIn(assertion)
        .then((userCredential) => {
          observer.next(userCredential);
        })
        .catch((err) => {
          console.error(err);
          observer.error(err);
        })
        .finally(() => {
          observer.complete();
        });
    });
  }

  // Unenrolls the TOTP multi-factor option. This will sign out the user
  unenrollTOTP() {
    if (!this.currentUser) {
      throw new Error('No user signed in');
    }

    // get the multi-factor totp option
    const totpMethod = this.mfaUser?.enrolledFactors.find(
      (f) => f.factorId === 'totp'
    );

    if (!totpMethod) {
      throw new Error('No TOTP multi-factor option found');
    }

    console.log(
      'Unenrolling TOTP multi-factor option: ',
      totpMethod
    );

    return new Observable<void>((observer) => {
      try {
        this.mfaUser?.unenroll(totpMethod).then(() => {
          this.auth.currentUser?.reload().then(() => {
            observer.next();
            observer.complete();
          });
        });
      } catch (err) {
        console.error(err as Error);
        observer.error(err);
        observer.complete();
      }
    });
  }

  sendSMSForEnrollment(
    phoneNumber: string,
    recaptchaVerifier: RecaptchaVerifier
  ) {
    console.log('firebase.service -> sendSMSForEnrollment', {
      phoneNumber,
    });

    return new Observable<string>((observer) => {
      if (!this.currentUser) {
        throw new Error('No user signed in');
      }
      multiFactor(this.currentUser!)
        .getSession()
        .then((session) => {
          console.log('session: ', session);
          const phoneInfoOptions = {
            phoneNumber: phoneNumber,
            session: session,
          };

          const phoneAuthProvider = new PhoneAuthProvider(this.auth);
          phoneAuthProvider
            .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
            .then((verificationId) => {
              // verificationId will be needed to complete enrollment.
              console.log('verificationId: ', verificationId);
              observer.next(verificationId);
            })
            .catch((err) => {
              console.error(err);
              observer.error(err);
            });
        })
        .catch((err) => {
          console.error(err);
          observer.error(err);
        });
    });
  }

  verifySMSCodeForEnrollment(verificationId: string, code: string) {
    console.log('firebase.service -> verifySMSCodeForEnrollment', {
      verificationId,
      code,
    });
    return new Observable<void>((observer) => {
      try {
        const phoneCredential = PhoneAuthProvider.credential(
          verificationId,
          code
        );
        const multiFactorAssertion =
          PhoneMultiFactorGenerator.assertion(phoneCredential);

        multiFactor(this.currentUser!)
          .enroll(multiFactorAssertion, 'Phone number')
          .then(() => {
            console.log(
              'firebase.service -> verifySMSCodeForEnrollment -> enrollment successful'
            );
            observer.next();
          })
          .catch((err) => {
            console.error(err as Error);
            observer.error(err);
          })
          .finally(() => {
            observer.complete();
          });
      } catch (err) {
        console.error(err as Error);
        observer.error(err);
        observer.complete();
      }
    });
  }

  sendSMSForSignIn(
    mfaResolver: MultiFactorResolver,
    recaptchaVerifier: RecaptchaVerifier
  ) {
    console.log('firebase.service -> sendSMSForSignIn');
    return new Observable<string>((observer) => {
      const phoneHint = mfaResolver.hints.find((h) => h.factorId === 'phone');
      console.log('phoneHint: ', phoneHint);
      const phoneInfoOptions = {
        multiFactorHint: phoneHint,
        session: mfaResolver.session,
      };

      const phoneAuthProvider = new PhoneAuthProvider(this.auth);
      phoneAuthProvider
        .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
        .then((verificationId) => {
          // verificationId will be needed to complete sign-in.
          console.log('verificationId: ', verificationId);
          observer.next(verificationId);
        })
        .catch((err) => {
          console.error(err);
          observer.error(err);
        });
    });
  }

  verifySMSCodeForSignIn(
    mfaResolver: MultiFactorResolver,
    verificationId: string,
    code: string
  ) {
    return new Observable<UserCredential>((observer) => {
      const cred = PhoneAuthProvider.credential(verificationId, code);
      const assertion = PhoneMultiFactorGenerator.assertion(cred);

      mfaResolver
        .resolveSignIn(assertion)
        .then((userCredential) => {
          console.log('Successfully signed in with phone number');
          observer.next(userCredential);
        })
        .catch((err) => {
          console.error(err);
          observer.error(err);
        })
        .finally(() => {
          observer.complete();
        });
    });
  }

  // Unenrolls the SMS multi-factor option. This will sign out the user
  unenrollSMS() {
    if (!this.currentUser) {
      throw new Error('No user signed in');
    }

    const smsMethod = this.mfaUser?.enrolledFactors.find(
      (f) => f.factorId === 'phone'
    );

    if (!smsMethod) {
      throw new Error('No SMS multi-factor option found');
    }

    console.log('Unenrolling SMS multi-factor option: ', smsMethod);

    return new Observable<void>((observer) => {
      if (!this.mfaUser) {
        console.error(new Error('No MFA user found'));
        observer.error(new Error('No MFA user found'));
        observer.complete();
        return;
      }

      this.mfaUser
        .unenroll(smsMethod)
        .then(() => {
          observer.next();
        })
        .catch((err) => {
          console.error(err as Error);
          observer.error(err);
        })
        .finally(() => {
          observer.complete();
        });
    });
  }

  mfaResolver(error: any) {
    return getMultiFactorResolver(this.auth, error);
  }

  recaptchaVerifier(container: string, callback?: () => void) {
    const theme =
      this.themeService.theme === 'accuzipDarkTheme' ? 'dark' : 'light';
    return new RecaptchaVerifier(this.auth, container, {
      size: 'invisible',
      callback: callback,
      theme: theme,
    });
  }

  unenrollMFA(info: MultiFactorInfo) {
    return new Observable<void>((observer) => {
      const mfaUser = multiFactor(this.currentUser!);
      if (!mfaUser.enrolledFactors.length) {
        observer.next();
        observer.complete();
        return;
      }

      mfaUser
        .unenroll(info)
        .then(() => {
          observer.next();
        })
        .catch((err) => {
          console.error(err);
          observer.error(err);
        })
        .finally(() => {
          observer.complete();
        });
    });
  }

  logAnalyticsEvent(event: AnalyticsEvent, params?: any) {
    logEvent(this.analytics, event as string, params);
  }
}
