import { Component } from '@angular/core';
import {
  Tenant,
  TenantSearchQuery,
  TenantsService,
} from '../../../../services/tenants.service';
import { Router } from '@angular/router';
import { paginationArray } from 'src/app/utils/pagination';
import {
  TableSettings,
  TableSpacing,
} from 'src/app/components/pagination/pagination.component';

@Component({
  selector: 'app-tenants-list',
  templateUrl: './tenants-list.component.html',
  styleUrls: ['./tenants-list.component.scss'],
})
export class TenantsListComponent {
  currentTenants: Tenant[] = [];
  searchResults: Tenant[] = [];
  showingSearchResults = false;
  tenantCount: number = 0;
  loading = false;

  pageSize = 20;
  currentPage = 1;
  tableSpacing: TableSpacing = 'normal';

  accountSearchQuery = '';
  companySearchQuery = '';
  searchComplete = false;
  searching = false;
  errorSearchingTenants = '';

  constructor(
    private tenantsService: TenantsService,
    private router: Router  ) {}

  ngOnInit() {
    this.loadSettings();
    this.updateTenants();
  }

  updateTenants() {
    if (this.loading) return;
    this.loading = true;

    // if showing search results, update the tenants shown on the page based
    // on the current page and offset
    if (this.showingSearchResults) {
      this.loading = false;
      this.currentTenants = this.searchResults.slice(
        (this.currentPage - 1) * this.pageSize,
        this.currentPage * this.pageSize
      );
      return;
    }

    // if not showing search results, update the tenants shown on the page
    // from the basic tenant range
    const offset = (this.currentPage - 1) * this.pageSize;
    this.tenantsService.getTenantRange(offset, this.pageSize).subscribe({
      next: (res) => {
        this.loading = false;
        console.log('res: ', res);
        if (res.tenants) {
          this.currentTenants = res.tenants;
          this.tenantCount = res.total_count;
        } else {
          console.error(
            new Error('No tenants returned from server')
          );
          console.log('res: ', res);
          this.currentTenants = [];
        }
      },
      error: (err) => {
        this.loading = false;
        console.error(
          new Error('Error getting tenants from server')
        );
        console.log('err: ', err);
        this.currentTenants = [];
      },
    });
  }

  clearSearch() {
    this.accountSearchQuery = '';
    this.companySearchQuery = '';
    this.searchResults = [];
    this.searching = false;
    this.showingSearchResults = false;
    this.currentPage = 1;
    this.updateTenants();
  }

  searchTenants() {
    this.searchResults = [];
    this.currentTenants = [];
    this.showingSearchResults = true;
    this.searching = true;

    let search: TenantSearchQuery = {};
    if (this.accountSearchQuery.trim() !== '') {
      search.account_number = this.accountSearchQuery.trim();
    }
    if (this.companySearchQuery.trim() !== '') {
      search.company_name = this.companySearchQuery.trim();
    }
    this.tenantsService.searchTenants(search).subscribe({
      next: (res) => {
        this.searching = false;
        console.log('res: ', res);
        if (res.tenants) {
          this.searchResults = res.tenants;
        } else {
          console.error(
            new Error('No tenants returned from server')
          );
          console.log('res: ', res);
          this.searchResults = [];
        }
        this.updateTenants();
      },
      error: (err) => {
        this.searching = false;
        console.error(
          new Error('Error getting tenants from server')
        );
        console.log('err: ', err);
        this.searchResults = [];
        this.errorSearchingTenants = `Error getting tenants: ${err.message || err}`;
      },
    });
  }

  navigateToTenantDetails(tenant: Tenant) {
    console.log('navigateToTenantDetails: ', tenant);
    this.router.navigate([`admin/tenant/${tenant.id}`]);
  }

  get pageArray() {
    const maxPages = 10;

    return paginationArray(maxPages, this.totalPages, this.currentPage);
  }

  get totalPages() {
    if (this.showingSearchResults) {
      return Math.ceil(this.searchResults.length / this.pageSize);
    }
    return Math.ceil(this.tenantCount / this.pageSize);
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updateTenants();
    }
  }

  nextPage() {
    if (this.currentPage < this.pageArray.length) {
      this.currentPage++;
      this.updateTenants();
    }
  }

  changePage(page: number) {
    if (this.currentPage === page) return;
    this.currentPage = page;

    this.updateTenants();
  }

  handleSettingsChanged(settings: TableSettings) {
    this.pageSize = Number(settings.pageSize);
    this.tableSpacing = settings.spacing;
    this.updateTenants();
    const newSettings = {
      pageSize: Number(settings.pageSize),
      spacing: settings.spacing,
    };
    localStorage.setItem('tenantTableSettings', JSON.stringify(newSettings));
  }

  loadSettings() {
    try {
      const settings = localStorage.getItem('tenantTableSettings');
      if (settings) {
        const parsed = JSON.parse(settings);
        this.pageSize = Number(parsed.pageSize);
        this.tableSpacing = parsed.spacing;
      }
    } catch (err) {
      console.error(err as Error);
    }
  }
}
