import { Component } from '@angular/core';

@Component({
  selector: 'app-file-copy-icon',
  templateUrl: './file-copy-icon.component.html',
  styleUrl: './file-copy-icon.component.scss'
})
export class FileCopyIconComponent {

}
