<div class="overflow-x-auto rounded-lg border border-solid border-black">
  <table class="table table-zebra">
    <thead class="text-base font-medium">
      <tr>
        <td class="cursor-pointer" (click)="sortChange.emit('product.code')">
          <span>Product</span>
          @if (currentSort?.field === 'product.code') {
            @if (currentSort?.direction === 'ASC') {
              <app-chevron-up-icon class="absolute inline-block h-6 w-6" />
            } @else {
              <app-chevron-down-icon class="absolute inline-block h-6 w-6" />
            }
          }
        </td>
        <td>Type</td>
        <td>Duration<br />(Months)</td>
        <td class="cursor-pointer" (click)="sortChange.emit('expiration')">
          <span>Expiration</span>
          @if (currentSort?.field === 'expiration') {
            @if (currentSort?.direction === 'ASC') {
              <app-chevron-up-icon class="absolute inline-block h-6 w-6" />
            } @else {
              <app-chevron-down-icon class="absolute inline-block h-6 w-6" />
            }
          }
        </td>
        <td class="cursor-pointer" (click)="sortChange.emit('created_at')">
          <span>Created</span>
          @if (currentSort?.field === 'created_at') {
            @if (currentSort?.direction === 'ASC') {
              <app-chevron-up-icon class="absolute inline-block h-6 w-6" />
            } @else {
              <app-chevron-down-icon class="absolute inline-block h-6 w-6" />
            }
          }
        </td>
        <td></td>
      </tr>
    </thead>
    <tbody>
      @for (license of licenses; track license; let i = $index) {
        <tr>
          <td>
            {{ license.product.code }} -
            {{ license.product.title }}
          </td>
          <td>
            {{ license.site_license ? 'Site' : 'Main' }}
          </td>
          <td>{{ license.product_variant.duration_months }}</td>
          <td>
            {{ license.expiration | date: 'mediumDate' : 'UTC' }}
          </td>
          <td>
            {{ license.created_at | date: 'medium' }}
          </td>
          <td>
            <a [routerLink]="'/admin/edit-license/' + license.id">Edit</a>
          </td>
        </tr>
      } @empty {
        <tr>
          <td colspan="5" class="text-center">No licenses</td>
        </tr>
      }
    </tbody>
  </table>
</div>
