<div class="overflow-x-auto rounded-lg border border-solid border-black">
  <table class="table table-zebra static">
    <thead class="text-base font-medium">
      <tr>
        @if (invoices[0].desktop_invoice.completed) {
          <td>Completed At</td>
        }
        <td>Total Amount</td>
        <td
          class="cursor-pointer"
          (click)="sortChanged.emit('desktop_invoice.created_at')"
        >
          <span>Created Date</span>
          @if (currentSort?.field === 'desktop_invoice.created_at') {
            @if (currentSort?.direction === 'ASC') {
              <app-chevron-up-icon
                class="absolute inline-block h-6 w-6"
              ></app-chevron-up-icon>
            } @else {
              <app-chevron-down-icon
                class="absolute inline-block h-6 w-6"
              ></app-chevron-down-icon>
            }
          }
        </td>
        <td
          class="cursor-pointer"
          (click)="sortChanged.emit('desktop_invoice.expiration')"
        >
          <span>Expiration Date</span>
          @if (currentSort?.field === 'desktop_invoice.expiration') {
            @if (currentSort?.direction === 'ASC') {
              <app-chevron-up-icon
                class="absolute inline-block h-6 w-6"
              ></app-chevron-up-icon>
            } @else {
              <app-chevron-down-icon
                class="absolute inline-block h-6 w-6"
              ></app-chevron-down-icon>
            }
          }
        </td>
        @if (showDeleteButton) {
          <td></td>
        }
      </tr>
    </thead>
    <tbody>
      @for (invoice of invoices; track invoice) {
        <tr
          class="cursor-pointer"
          (click)="
            browseToInvoice(
              invoice.desktop_invoice.id,
              invoice.desktop_invoice.tenant_id
            )
          "
        >
          @if (invoice.desktop_invoice.completed) {
            <td>
              {{
                invoice.desktop_invoice.completed_at
                  | date: 'MM/dd/YYYY, hh:mmaa'
              }}
            </td>
          }
          <td>
            {{ invoice.desktop_invoice.total_amount | currency }}
          </td>
          <td>
            {{
              invoice.desktop_invoice.created_at | date: 'MM/dd/YYYY, hh:mmaa'
            }}
          </td>
          <td>
            {{
              invoice.desktop_invoice.expiration | date: 'MM/dd/YYYY' : 'UTC'
            }}
          </td>
          @if (showDeleteButton) {
            <td class="w-24">
              <button
                class="btn btn-error btn-sm"
                (click)="deleteInvoice($event, invoice)"
              >
                <app-trash-icon></app-trash-icon>
              </button>
            </td>
          }
        </tr>
      }
    </tbody>
  </table>
</div>
