import { Component } from '@angular/core';

@Component({
  selector: 'app-present-icon',
  templateUrl: './present-icon.component.html',
  styleUrl: './present-icon.component.scss'
})
export class PresentIconComponent {

}
