import { Component, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { CassResponse } from 'src/app/services/cass.service';
import { LoggerService } from 'src/app/services/logger.service';
import {
  AuthorizeNetSavedPaymentMethod,
  PaymentsService,
} from 'src/app/services/payments.service';
import { RemovePaymentMethodModalComponent } from './remove-payment-method-modal/remove-payment-method-modal.component';
import { AddPaymentMethodModalComponent } from './add-payment-method-modal/add-payment-method-modal.component';
import { Tenant, TenantsService } from 'src/app/services/tenants.service';

type Input = {
  value: string;
  error: string;
};

@Component({
  selector: 'app-saved-payment-methods',
  templateUrl: './saved-payment-methods.component.html',
  styleUrls: ['./saved-payment-methods.component.scss'],
})
export class SavedPaymentMethodsComponent {
  @ViewChild('acceptPaymentButton')
  acceptPaymentButton!: ElementRef<HTMLButtonElement>;
  @ViewChild('addressCorrectionModal')
  addressCorrectionModal!: ElementRef<HTMLDialogElement>;
  @ViewChild('addPaymentMethodModal')
  addPaymentMethodModal!: AddPaymentMethodModalComponent;
  @ViewChild('removePaymentMethodModal')
  removePaymentMethodModal!: RemovePaymentMethodModalComponent;

  loadingPaymentMethods = false;
  errorFetchingPaymentMethods = '';
  savedPaymentMethods?: AuthorizeNetSavedPaymentMethod[];

  loadingTenant = false;
  errorFetchingTenant = '';
  tenant?: Tenant;

  loading = false;

  inputs: { [prop: string]: Input } = {
    first: { value: '', error: '' },
    last: { value: '', error: '' },
    company: { value: '', error: '' },
    address: { value: '', error: '' },
    city: { value: '', error: '' },
    state: { value: '', error: '' },
    zip: { value: '', error: '' },
  };
  isDefault = false;
  savedCardResponse: any;

  cassResponse?: CassResponse = undefined;

  creatingSavedMethod = false;
  addSavedMethodError = '';

  methodToRemove?: AuthorizeNetSavedPaymentMethod;
  removingSavedMethod = false;

  autopayError = '';
  showAutopayWarning = false;

  constructor(
    private authService: AuthService,
    private loggerService: LoggerService,
    // private route: ActivatedRoute,
    private paymentsService: PaymentsService,
    private tenantsService: TenantsService
  ) {}

  get canManagePaymentMethods() {
    return (
      this.authService.currentUser?.permissions.find(
        (p) =>
          p.role.name === 'super_admin' ||
          p.role.name === 'internal_admin' ||
          p.role.name === 'admin' ||
          p.role.name === 'owner'
      ) !== undefined
    );
  }

  ngOnInit() {
    this.getTenantDetails();
    this.getSavedPaymentMethods();
  }

  getSavedPaymentMethods() {
    this.loadingPaymentMethods = true;
    this.paymentsService.getCurrentUserSavedPaymentMethods().subscribe({
      next: (res) => {
        this.loadingPaymentMethods = false;
        this.savedPaymentMethods = res.payment_profiles;
      },
      error: (err) => {
        this.loggerService.error(err);
        this.loadingPaymentMethods = false;
        this.errorFetchingPaymentMethods =
          'Error fetching saved payment methods';
      },
    });
  }

  getTenantDetails() {
    this.loadingTenant = true;
    this.tenantsService.getCurrentTenantDetails().subscribe({
      next: (tenant) => {
        this.loadingTenant = false;
        this.tenant = tenant.tenant;
      },
      error: (err) => {
        this.loggerService.error(err);
        this.loadingTenant = false;
        this.errorFetchingTenant = "Error fetching tenant's details";
      },
    });
  }

  handlePaymentMethodCreation(paymentMethod: AuthorizeNetSavedPaymentMethod) {
    if (!this.savedPaymentMethods) {
      this.errorFetchingPaymentMethods =
        'Error handling payment method addition';
      return;
    }

    this.addPaymentMethodModal.hideAddMethodModal();

    this.savedPaymentMethods.push(paymentMethod);
    // if returned payment method is default, update all others
    if (paymentMethod.is_default) {
      this.savedPaymentMethods.forEach((p) => {
        p.is_default = p.id === paymentMethod.id;
      });
    }
  }

  setDefaultPaymentMethod(paymentMethod: AuthorizeNetSavedPaymentMethod) {
    if (!this.savedPaymentMethods) {
      this.loggerService.error(new Error('No saved payment methods set'));
      this.addSavedMethodError = 'No saved payment methods found';
      return;
    }

    this.loggerService.log('setting default payment method: ', paymentMethod);
    this.paymentsService.setDefaultPaymentMethod(paymentMethod).subscribe({
      next: (res) => {
        this.loggerService.log('Payment method set as default:', res);
        this.savedPaymentMethods!.forEach((p) => {
          p.is_default = p.id === paymentMethod.id;
        });
      },
      error: (err) => {
        this.loggerService.error(err);
        this.addSavedMethodError =
          'There was an error setting your saved payment method.';
      },
    });
  }

  confirmDeleteSavedMethod(paymentMethod: AuthorizeNetSavedPaymentMethod) {
    this.methodToRemove = paymentMethod;
    this.removePaymentMethodModal.showRemoveMethodModal();
    // this.removeMethodModal.nativeElement.showModal();
  }

  savedMethodDeleted(paymentMethod: AuthorizeNetSavedPaymentMethod) {
    this.loggerService.log('saved method deleted: ', paymentMethod);
    this.savedPaymentMethods = this.savedPaymentMethods!.filter(
      (p) => p.id !== paymentMethod.id
    );
  }

  toggleAutopay(ev: Event) {
    if (!this.tenant) {
      this.loggerService.error(new Error('No tenant set'));
      return;
    }

    if (this.tenant.autopay_enabled) {
      ev.preventDefault();
      this.showAutopayWarning = true;
    } else {
      this.showAutopayWarning = false;
      this.tenant.autopay_enabled = true;
      this.saveAutopaySetting(true);
    }
  }

  disableAutopay() {
    if (!this.tenant) {
      this.loggerService.error(new Error('No tenant set'));
      return;
    }
    this.tenant.autopay_enabled = false;
    this.showAutopayWarning = false;
    this.saveAutopaySetting(false);
  }

  saveAutopaySetting(autopay: boolean) {
    this.loggerService.log('saving - autopay setting: ', autopay);
    if (!this.tenant) {
      this.autopayError = 'Tenant not found';
      return;
    }

    this.tenantsService.updateTenantAutopay(this.tenant.id, autopay).subscribe({
      next: () => {
        this.autopayError = '';
      },
      error: (err) => {
        // revert to previous value since update failed
        this.tenant!.autopay_enabled = !autopay;
        if (
          err.error.message === 'no payment methods found for tenant' ||
          err.error.message === 'no default payment method found for tenant'
        ) {
          this.autopayError =
            'Must have a deafult payment method to enable autopay';
        } else {
          this.autopayError = err.error.message;
        }
      },
    });
  }
}
