import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  CreateDesktopLicenseRequest,
  DesktopLicenseWithProduct,
  LicensesService,
} from 'src/app/services/licenses.service';
import { LoggerService } from 'src/app/services/logger.service';
import {
  Product,
  ProductVariant,
  ProductsService,
} from 'src/app/services/products.service';
import { Tenant } from 'src/app/services/tenants.service';

@Component({
  selector: 'app-add-license',
  templateUrl: './add-license.component.html',
  styleUrl: './add-license.component.scss',
})
export class AddLicenseComponent {
  @ViewChild('productSearchInput')
  productSearchInput!: ElementRef<HTMLInputElement>;

  @Input() desktopLicenses?: {
    active: DesktopLicenseWithProduct[];
    expired: DesktopLicenseWithProduct[];
    pending: DesktopLicenseWithProduct[];
  };
  @Input() tenant?: Tenant;

  @Output() licenseAdded = new EventEmitter<DesktopLicenseWithProduct>();

  products: Product[] | undefined;
  productVariants: { [prop: string]: ProductVariant[] } = {};

  loadingProducts = false;
  loadingVariants = false;
  productFilters = {
    searchText: '',
    productId: '',
    duration: '',
    priceLockLevel: '',
    deliveryMethod: '',
  };

  addingLicense = false;
  errorAddingLicense = '';
  calculatingExpirationDate = true;

  isSiteLicense = false;
  // isNewLicense = true;
  startDate: string = new Date().toISOString().slice(0, 10);
  expirationDate?: string;

  selectingProduct = true;
  currentProduct: Product | undefined;
  currentVariant: ProductVariant | undefined;
  selectedParentLicenseId: string = '';

  possibleParentLicenses: DesktopLicenseWithProduct[] = [];
  foundParentLicense?: DesktopLicenseWithProduct;

  errorGettingExpirationDate = '';

  constructor(
    private licensesService: LicensesService,
    private loggerService: LoggerService,
    private productsService: ProductsService
  ) {}

  ngOnInit() {
    this.loggerService.log('AddLicenseComponent ngOnInit');
    this.loadProducts();
  }

  get displayProducts() {
    if (
      !this.productFilters.searchText ||
      this.productFilters.searchText === ''
    )
      return this.products;

    return this.products?.filter((p) => {
      const term = this.productFilters.searchText.trim().toLowerCase();
      return (
        p.code.toLowerCase().includes(term) ||
        p.title.toLowerCase().includes(term) ||
        p.variance_code.toLowerCase().includes(term)
      );
    });
  }

  get displayVariants() {
    const variants = this.productVariants[this.productFilters.productId];
    if (!variants) return [];

    let displayVariants = variants;

    displayVariants = displayVariants.filter((p) => {
      if (
        this.productFilters.duration !== '' &&
        p.duration_months !== +this.productFilters.duration
      ) {
        return false;
      }
      if (
        this.productFilters.priceLockLevel !== '' &&
        p.price_lock_level !== +this.productFilters.priceLockLevel
      ) {
        return false;
      }
      if (
        this.productFilters.deliveryMethod !== '' &&
        p.delivery_method !== this.productFilters.deliveryMethod
      ) {
        return false;
      }
      if (
        this.productFilters.priceLockLevel &&
        p.price_lock_level !== +this.productFilters.priceLockLevel
      ) {
        return false;
      }

      return true;
    });

    displayVariants.sort((a, b) => {
      if (a.duration_months !== b.duration_months)
        return a.duration_months - b.duration_months;
      if (a.price_lock_level !== b.price_lock_level)
        return a.price_lock_level - b.price_lock_level;
      return 0;
    });

    return displayVariants;
  }

  get variantDurations() {
    const variants = this.productVariants[this.productFilters.productId];
    if (!variants) return [];
    return variants
      .map((p) => p.duration_months)
      .reduce((acc, curr) => {
        if (acc.indexOf(curr) === -1) {
          acc.push(curr);
        }
        return acc;
      }, [] as number[])
      .sort((a, b) => a - b);
  }

  get priceLockLevels() {
    const variants = this.productVariants[this.productFilters.productId];
    if (!variants) return [];
    return this.productVariants[this.productFilters.productId]
      .map((p) => p.price_lock_level)
      .reduce((acc, curr) => {
        if (acc.indexOf(curr) === -1) {
          acc.push(curr);
        }
        return acc;
      }, [] as number[])
      .sort((a, b) => a - b);
  }

  loadProducts() {
    this.loadingProducts = true;
    this.productsService.getAllProducts().subscribe({
      next: (res) => {
        this.loggerService.log('res: ', res);
        if (res.products) {
          this.products = res.products;
          this.products.sort((a, b) => a.code.localeCompare(b.code));
        } else {
          this.loggerService.error(
            new Error('No products returned from server')
          );
          this.products = [];
        }
      },
      error: (err) => {
        this.loggerService.error(err);
      },
      complete: () => {
        this.loadingProducts = false;
        this.loggerService.log('getAllProducts complete');
      },
    });
  }

  displayDeliveryMethod(deliveryMethod: string) {
    switch (deliveryMethod) {
      case 'XXX':
        return 'Physical';
      case 'DNL':
        return 'Download';
      default:
        return '';
    }
  }

  handleAddLicenseClick(variant: ProductVariant) {
    this.loggerService.log('handleAddLicenseClick: ', variant);
    this.currentVariant = variant;
    this.setAvailableParentLicenses(variant);

    // determine if selected product is already subscribed to
    if (this.desktopLicenses) {
      const checkLists = [
        this.desktopLicenses.active,
        this.desktopLicenses.pending,
      ];

      this.foundParentLicense = undefined;
      this.selectedParentLicenseId = '';

      for (let i = 0; i < checkLists.length; i++) {
        const licenses = checkLists[i];
        const matchingLicense = this.findMatchingLicense(variant, licenses);
        if (matchingLicense) {
          this.loggerService.log('Existing license found: ', matchingLicense);
          this.foundParentLicense = matchingLicense;
          this.selectedParentLicenseId = matchingLicense.id;
          this.isSiteLicense = true;
          break;
        }
      }
    }

    if (this.currentProduct?.requires_parent && !this.selectedParentLicenseId) {
      this.loggerService.debug(
        'No parent license selected yet for required product: ',
        this.currentProduct
      );
    } else {
      // calculate the expiration date for the new license
      this.calculateExpirationDate(
        new Date(this.startDate),
        this.currentVariant.id,
        this.selectedParentLicenseId
      );
    }

    this.selectingProduct = false;
  }

  findMatchingLicense(
    variant: ProductVariant,
    licenses: DesktopLicenseWithProduct[]
  ) {
    this.loggerService.log('findMatchingLicense: ', variant, licenses);
    return licenses.find(
      (license) =>
        license.product.id === variant.product_id &&
        license.product.variance_code === this.currentProduct?.variance_code &&
        license.product_variant.duration_months == variant.duration_months
    );
  }

  calculateExpirationDate(
    startDate: Date,
    variantId: string,
    parentLicenseId?: string
  ) {
    this.calculatingExpirationDate = true;
    this.errorGettingExpirationDate = '';
    if (parentLicenseId === '') {
      parentLicenseId = undefined;
    }

    this.loggerService.log(
      'calculateExpirationDate: ',
      startDate,
      variantId,
      parentLicenseId
    );

    this.licensesService
      .getPreemtiveExpirationDate(startDate, variantId, parentLicenseId)
      .subscribe({
        next: (res) => {
          this.calculatingExpirationDate = false;
          this.loggerService.log('expiration date: ', res);
          this.expirationDate = res.expiration_date.slice(0, 10);
        },
        error: (err) => {
          this.calculatingExpirationDate = false;
          this.loggerService.error(err);
          this.errorGettingExpirationDate = err;
        },
      });
  }

  handleStartDateChange() {
    if (this.startDate && this.currentVariant) {
      this.calculateExpirationDate(
        new Date(this.startDate),
        this.currentVariant.id,
        this.selectedParentLicenseId
      );
    }
  }

  handleParentLicenseChange() {
    if (this.selectedParentLicenseId && this.currentVariant) {
      this.calculateExpirationDate(
        new Date(this.startDate),
        this.currentVariant.id,
        this.selectedParentLicenseId
      );
    }
  }

  // gets the list of valid licenses to use as the main license for an add-on
  setAvailableParentLicenses(variant?: ProductVariant) {
    this.loggerService.debug('availableMainLicenses product: ', variant);
    if (!variant || !this.desktopLicenses) {
      this.possibleParentLicenses = [];
      return;
    }

    const licenses = [];
    licenses.push(
      ...this.desktopLicenses.active,
      ...this.desktopLicenses.pending
    );
    this.loggerService.debug('licenses', licenses);
    if (!licenses) {
      this.possibleParentLicenses = [];
      return;
    }

    this.possibleParentLicenses = licenses.filter((license) => {
      return (
        license.product_variant.duration_months == variant.duration_months &&
        !license.site_license
      );
    });
  }

  handleProductChange(product: Product) {
    this.currentProduct = product;
    this.productFilters.productId = product.id;
    this.productFilters.duration = '';
    this.productFilters.priceLockLevel = '';
    this.productFilters.deliveryMethod = 'DNL';

    this.loadProductVariants(product.id);

    if (document.activeElement) {
      (document.activeElement as HTMLElement).blur();
    }
  }

  loadProductVariants(productId: string) {
    if (this.productVariants[productId] === undefined) {
      // fetch variants list
      this.loadingVariants = true;

      this.productsService.getProductDetails(productId).subscribe({
        next: (res) => {
          this.loggerService.log('res: ', res);
          if (res.product_with_variants) {
            this.productVariants[productId] =
              res.product_with_variants.variants;
            // set default price lock level to highest value
            this.setInitialPriceLockLevel(productId);
          } else {
            this.loggerService.error(
              new Error('No product returned from server')
            );
          }
        },
        error: (err) => {
          this.loggerService.error(err);
        },
        complete: () => {
          this.loadingVariants = false;
          this.loggerService.log('getProductDetails complete');
        },
      });
    } else {
      // already loaded
      this.setInitialPriceLockLevel(productId);
    }
  }

  setInitialPriceLockLevel(productId: string) {
    let highest = -Infinity;
    for (const variant of this.productVariants[productId]) {
      if (variant.price_lock_level > highest) {
        highest = variant.price_lock_level;
      }
    }
    this.productFilters.priceLockLevel = highest.toString();
  }

  focusProductSearchInput() {
    this.productSearchInput.nativeElement.focus();
  }

  clearSelectedValues() {
    this.loggerService.debug('clearing selected values');
    this.selectingProduct = true;
    this.currentProduct = undefined;
    this.currentVariant = undefined;
    this.foundParentLicense = undefined;
    this.selectedParentLicenseId = '';
    this.isSiteLicense = false;
    this.productFilters = {
      searchText: '',
      productId: '',
      duration: '',
      priceLockLevel: '',
      deliveryMethod: '',
    };
  }

  addLicense() {
    this.addingLicense = true;
    this.loggerService.log('addLicense: ', {
      tenant: this.tenant,
      product: this.currentProduct,
      variant: this.currentVariant,
      startDate: this.startDate,
      expirationDate: this.expirationDate,
      isSiteLicense: this.isSiteLicense,
      parentLicenseId: this.selectedParentLicenseId,
    });

    const tenant = this.tenant,
      product = this.currentProduct,
      variant = this.currentVariant;
    if (!tenant || !product || !variant) {
      this.loggerService.error(
        new Error('Missing required data to create license')
      );
      return;
    }

    if (!this.expirationDate) {
      this.errorAddingLicense = 'Expiration date is required';
      this.loggerService.error(new Error('Expiration date is required'));
      return;
    }

    if (
      (this.isSiteLicense || this.currentProduct?.requires_parent) &&
      this.selectedParentLicenseId === ''
    ) {
      this.errorAddingLicense = 'No parent license selected for site license';
      this.loggerService.error(
        new Error('No parent license selected for site license')
      );
      return;
    }

    const req: CreateDesktopLicenseRequest = {
      tenant_id: tenant.id,
      product_variant_id: variant.id,
      start_date: new Date(this.startDate),
      expiration: new Date(this.expirationDate),
      new_license: true,
      is_site_license: this.isSiteLicense,
    };

    if (this.isSiteLicense || this.currentProduct?.requires_parent) {
      req.parent_desktop_license_id = this.selectedParentLicenseId;
    }

    this.licensesService.addLicenseToTenant(req).subscribe({
      next: (res) => {
        this.addingLicense = false;
        this.loggerService.log(
          'tenant-details.component -> addLicense -> res: ',
          res
        );
        if (res.desktop_license) {
          this.licenseAdded.emit(res.desktop_license);
          this.clearSelectedValues();
        } else {
          this.loggerService.error(
            new Error('No desktop_license returned from server')
          );
          this.errorAddingLicense = 'Server error adding license';
        }
      },
      error: (err) => {
        this.addingLicense = false;
        this.loggerService.error(new Error(err));
        this.errorAddingLicense = err;
      },
      complete: () => {
        this.loggerService.log('createDesktopLicense complete');
      },
    });
  }
}
