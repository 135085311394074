<dialog #removePaymentMethodModal class="modal">
  <div class="modal-box w-fit max-w-fit">
    <form class="dialog">
      <button
        class="btn btn-circle btn-ghost btn-sm absolute right-2 top-2"
        (click)="hideRemoveMethodModal()"
      >
        ✕
      </button>
    </form>

    @if (methodToRemove) {
      <div>
        <h4 class="mb-2 text-xl font-medium">Remove Saved Payment Method</h4>
        <p class="mb-2">
          Are you sure you want to remove this saved payment method?
        </p>

        <div class="flex items-center">
          <h6 class="text-lg">{{ methodToRemove.card_type }}</h6>
          <p class="ml-4 text-sm">•••• {{ methodToRemove.last_4_digits }}</p>
        </div>

        <p class="mb-2">Exp: {{ methodToRemove.card_expiration }}</p>

        <p class="mb-4 font-semibold text-warning">
          This action cannot be undone.
        </p>

        <div class="flex justify-center space-x-4">
          <button
            class="btn btn-error btn-sm"
            (click)="deleteSavedPaymentMethod(methodToRemove)"
            [disabled]="removingSavedMethod"
          >
            Remove
          </button>
          <button
            class="btn btn-neutral btn-sm"
            (click)="hideRemoveMethodModal()"
            [disabled]="removingSavedMethod"
          >
            Cancel
          </button>
        </div>
        @if (removingSavedMethod) {
          <div class="flex justify-center">
            <div class="loading loading-spinner loading-md mt-4"></div>
          </div>
        }
      </div>
    } @else {
      <div class="p-2">
        <h4 class="mb-2">No payment method to remove</h4>
        <p class="mb-2">
          There was an error removing the payment method. Please try again.
        </p>
      </div>
    }

    @if (errorRemovingSavedMethod) {
      <div class="p-2 pt-4 text-center text-error">
        <p>{{ errorRemovingSavedMethod }}</p>
      </div>
    }
  </div>
</dialog>
