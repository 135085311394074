<div class="flex flex-col p-4">
  <div class="breadcrumbs text-sm">
    <ul>
      <li>
        <a [routerLink]="['/admin']" class="inline-flex items-center gap-2">
          <app-user-setting-icon
            class="h-5 w-5 text-current"
          ></app-user-setting-icon>
          Admin
        </a>
      </li>
      <li>
        <a
          [routerLink]="['/admin']"
          [queryParams]="{ tab: 'tenants-list' }"
          class="inline-flex items-center gap-2"
        >
          <app-clipboard-icon class="h-5 w-5 text-current"></app-clipboard-icon>
          Tenants List
        </a>
      </li>
      <li>
        <span class="inline-flex items-center gap-2">
          <app-building-icon class="h-5 w-5 text-current"></app-building-icon>
          Tenant Details
        </span>
      </li>
    </ul>
  </div>
  <div class="card">
    <div class="card-body">
      @if (errorFetchingTenant) {
        <p class="text-error">
          {{ errorFetchingTenant }}
        </p>
      } @else {
        @if (loadingTenant) {
          <div class="skeleton h-52 w-52"></div>
        } @else if (tenant) {
          <div class="card-title">Account #: {{ tenant.account_number }}</div>

          <div class="card-title">Company: {{ tenant.company }}</div>

          <div class="flex">
            <div class="w-20">
              <p>Address:</p>
            </div>
            <div class="ml-4">
              <p>{{ tenant.address }}</p>
              <p>{{ tenant.city }}, {{ tenant.st }} {{ tenant.zip }}</p>
            </div>
          </div>

          <div class="flex">
            <div class="w-20">
              <p>Emails:</p>
            </div>
            <div class="ml-4">
              @for (email of tenant.emails; track email) {
                <p>{{ email }}</p>
              } @empty {
                <p>No Emails</p>
              }
            </div>
          </div>

          <div class="flex">
            <div class="w-20">
              <p>Phone #s:</p>
            </div>
            <div class="ml-4">
              @if (tenant.phone1) {
                <p>{{ tenant.phone1 }}</p>
              }
              @if (tenant.phone2) {
                <p>{{ tenant.phone2 }}</p>
              }
              @if (!tenant.phone1 && !tenant.phone2) {
                <p>No Phone Numbers</p>
              }
            </div>
          </div>

          <div class="flex">
            <div class="w-20">
              <p>Franchise:</p>
            </div>
            @if (franchise) {
              <div class="ml-4">
                <p>
                  <a
                    class="link"
                    [routerLink]="'/admin/franchise/' + franchise.id"
                    >{{ franchise.name }}</a
                  >
                </p>
              </div>
            } @else {
              <div class="ml-4">
                <p>N/A</p>
              </div>
            }
          </div>

          <div class="flex">
            <div class="w-20">
              <p>Created At:</p>
            </div>
            <div class="ml-4">
              <p>{{ tenant.created_at | date: 'medium' }}</p>
            </div>
          </div>
        }
      }
    </div>
  </div>

  <div class="px-8">
    <hr class="my-4 border-base-content" />
  </div>

  <!-- LICENSES -->
  <div class="card">
    <div class="card-body">
      <div class="mb-4 flex justify-between">
        <p class="card-title">Licenses</p>

        @if (!errorFetchingLicenses) {
          @if (loadingLicenses) {
            <div class="skeleton h-8 w-28 rounded-md"></div>
          } @else {
            <button
              class="btn btn-primary btn-sm"
              (click)="showAddLicenseTable()"
            >
              + Add License
            </button>
          }
        }
      </div>

      @if (errorFetchingLicenses) {
        <p class="text-error">
          {{ errorFetchingLicenses }}
        </p>
      } @else {
        @if (loadingLicenses) {
          <div class="skeleton mb-4 h-4 w-28 rounded-md"></div>
          <div class="skeleton h-28 rounded-md"></div>
        } @else {
          @if (desktopLicenses?.active) {
            <div class="text-lg"><p>Active</p></div>
            @if (desktopLicenses?.active?.length === 0) {
              <div>
                <p>No active licenses</p>
              </div>
            } @else {
              <div
                class="overflow-x-auto rounded-lg border border-solid border-black"
              >
                <table class="table table-zebra">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Type</th>
                      <th>Expiration</th>
                      <th>Created</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    @for (
                      license of desktopLicenses!.active;
                      track license;
                      let i = $index
                    ) {
                      <tr>
                        <td>{{ license.product.title }}</td>
                        <td>
                          {{
                            license.desktop_license.site_license
                              ? 'Site'
                              : 'Primary'
                          }}
                        </td>
                        <td>
                          {{
                            license.desktop_license.expiration
                              | date: 'mediumDate'
                          }}
                        </td>
                        <td>
                          {{
                            license.desktop_license.created_at | date: 'medium'
                          }}
                        </td>
                        <td>
                          <a
                            [routerLink]="
                              '/admin/edit-license/' +
                              license.desktop_license.id
                            "
                            >Edit</a
                          >
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            }
          }

          @if (
            desktopLicenses?.pending && (desktopLicenses?.pending)!.length > 0
          ) {
            <div class="mb-2 mt-8 text-lg">Pending</div>
            <div
              class="overflow-x-auto rounded-lg border border-solid border-black"
            >
              <table class="table table-zebra">
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Type</th>
                    <th>Expiration</th>
                    <th>Created</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  @for (
                    license of desktopLicenses!.pending;
                    track license;
                    let i = $index
                  ) {
                    <tr>
                      <td>{{ license.product.title }}</td>
                      <td>
                        {{
                          license.desktop_license.site_license ? 'Site' : 'Main'
                        }}
                      </td>
                      <td>
                        {{
                          license.desktop_license.expiration
                            | date: 'mediumDate'
                        }}
                      </td>
                      <td>
                        {{
                          license.desktop_license.created_at | date: 'medium'
                        }}
                      </td>
                      <td>
                        <a
                          [routerLink]="
                            '/admin/edit-license/' + license.desktop_license.id
                          "
                          >Edit</a
                        >
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          }

          @if (
            desktopLicenses?.expired && (desktopLicenses?.expired)!.length > 0
          ) {
            <div class="mb-2 mt-8 text-lg">Expired</div>
            <div
              class="overflow-x-auto rounded-lg border border-solid border-black"
            >
              <table class="table table-zebra">
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Expiration</th>
                    <th>Created</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  @for (
                    license of desktopLicenses!.expired;
                    track license;
                    let i = $index
                  ) {
                    <tr>
                      <td>{{ license.product.title }}</td>
                      <td>
                        {{
                          license.desktop_license.expiration
                            | date: 'mediumDate'
                        }}
                      </td>
                      <td>
                        {{
                          license.desktop_license.created_at | date: 'medium'
                        }}
                      </td>
                      <td>
                        <a
                          [routerLink]="
                            '/admin/edit-license/' + license.desktop_license.id
                          "
                          >Edit</a
                        >
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          }
        }
      }
    </div>
  </div>

  <div class="px-8">
    <hr class="my-4 border-base-content" />
  </div>

  <!-- INVOICES -->
  <div class="card">
    <div class="card-body">
      <div class="mb-4 flex justify-between">
        <div class="card-title">Invoices</div>

        @if (!errorFetchingLicenses) {
          @if (loadingLicenses) {
            <div class="skeleton h-8 w-28 rounded-md"></div>
          } @else {
            <button
              class="btn btn-primary btn-sm"
              (click)="showCreateInvoiceModal()"
            >
              + Create Invoice
            </button>
          }
        }
      </div>

      @if (errorFetchingInvoices) {
        <div class="pt-8 text-center">
          <p class="text-error">Error fetching invoices</p>
        </div>
      } @else {
        <p class="pt-4 text-lg">Pending</p>
        @if (loadingInvoices) {
          <div class="skeleton h-28"></div>
        } @else if (desktopInvoices) {
          @if (desktopInvoices.pending.length === 0) {
            <div>
              <p>No pending invoices</p>
            </div>
          }
          @if (desktopInvoices.pending.length > 0) {
            <div
              class="overflow-x-auto rounded-lg border border-solid border-black"
            >
              <table class="table table-zebra static">
                <thead class="text-base font-medium">
                  <tr>
                    <th>Completed?</th>
                    <th>Total Amount</th>
                    <th>Created Date</th>
                    <th>Expiration Date</th>
                  </tr>
                </thead>
                <tbody>
                  @for (
                    invoice of desktopInvoices.pending;
                    track invoice;
                    let i = $index
                  ) {
                    <tr
                      class="cursor-pointer"
                      (click)="
                        browseToInvoice(
                          invoice.desktop_invoice.id,
                          invoice.desktop_invoice.tenant_id
                        )
                      "
                    >
                      <td>{{ invoice.desktop_invoice.completed }}</td>
                      <td>
                        {{ invoice.desktop_invoice.total_amount | currency }}
                      </td>
                      <td>
                        {{ invoice.desktop_invoice.created_at | date: 'short' }}
                      </td>
                      <td>
                        {{ invoice.desktop_invoice.expiration | date: 'short' }}
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          }
        }

        <p class="pt-4 text-lg">Completed</p>
        @if (loadingInvoices) {
          <div class="skeleton h-28"></div>
        } @else if (desktopInvoices) {
          @if (desktopInvoices.complete.length === 0) {
            <div>
              <p>No completed invoices</p>
            </div>
          }
          @if (desktopInvoices.complete.length > 0) {
            <div
              class="overflow-x-auto rounded-lg border border-solid border-black"
            >
              <table class="table table-zebra static">
                <thead class="text-base font-medium">
                  <tr>
                    <th>Completed?</th>
                    <th>Total Amount</th>
                    <th>Created Date</th>
                    <th>Expiration Date</th>
                  </tr>
                </thead>
                <tbody>
                  @for (
                    invoice of desktopInvoices.complete;
                    track invoice;
                    let i = $index
                  ) {
                    <tr>
                      <td>{{ invoice.desktop_invoice.completed }}</td>
                      <td>
                        {{ invoice.desktop_invoice.total_amount | currency }}
                      </td>
                      <td>
                        {{ invoice.desktop_invoice.created_at | date: 'short' }}
                      </td>
                      <td>
                        {{ invoice.desktop_invoice.expiration | date: 'short' }}
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          }
        }

        <p class="pt-4 text-lg">Expired</p>
        @if (loadingInvoices) {
          <div class="skeleton h-28"></div>
        } @else if (desktopInvoices) {
          @if (desktopInvoices.expired.length === 0) {
            <div>
              <p>No expired invoices</p>
            </div>
          }
          @if (desktopInvoices.expired.length > 0) {
            <div
              class="overflow-x-auto rounded-lg border border-solid border-black"
            >
              <table class="table table-zebra static">
                <thead class="text-base font-medium">
                  <tr>
                    <th>Completed?</th>
                    <th>Total Amount</th>
                    <th>Created Date</th>
                    <th>Expiration Date</th>
                  </tr>
                </thead>
                <tbody>
                  @for (
                    invoice of desktopInvoices.expired;
                    track invoice;
                    let i = $index
                  ) {
                    <tr>
                      <td>{{ invoice.desktop_invoice.completed }}</td>
                      <td>
                        {{ invoice.desktop_invoice.total_amount | currency }}
                      </td>
                      <td>
                        {{ invoice.desktop_invoice.created_at | date: 'short' }}
                      </td>
                      <td>
                        {{ invoice.desktop_invoice.expiration | date: 'short' }}
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          }
        }
      }
    </div>
  </div>

  <div class="px-8">
    <hr class="my-4 border-base-content" />
  </div>

  <!-- USERS -->
  <div class="card">
    <div class="card-body">
      <div class="card-title mb-4 justify-between">
        <p>Users</p>
        @if (!errorFetchingUsers) {
          @if (loadingUsers) {
            <div class="skeleton h-8 w-28 rounded-md"></div>
          } @else {
            <button
              class="btn btn-primary btn-sm"
              onclick="inviteUserModal.showModal()"
            >
              Invite a User
            </button>
          }
        }
      </div>

      @if (errorFetchingUsers) {
        <div class="text-center">
          <p class="text-error">Error fetching users</p>
        </div>
      } @else {
        @if (loadingUsers) {
          <div class="skeleton h-28 rounded-md"></div>
        }
        @if (!loadingUsers) {
          <div
            class="overflow-x-auto rounded-lg border border-solid border-black"
          >
            <table class="table table-zebra static">
              <thead class="text-base font-medium">
                <tr>
                  <td>Email</td>
                  <td>Username</td>
                  <td>Roles</td>
                  <td>Active</td>
                  <td>Created</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                @for (user of users; track user; let i = $index) {
                  <tr>
                    <td>{{ user.email }}</td>
                    <td>{{ user.username }}</td>
                    <td>{{ roleList(user) }}</td>
                    <td>{{ user.active }}</td>
                    <td>{{ user.created_at | date: 'short' }}</td>
                    <td>
                      @if (canEditUser(user)) {
                        <app-edit-icon
                          class="block h-6 w-6 cursor-pointer"
                          [routerLink]="['/edit-user', user.id]"
                        ></app-edit-icon>
                      }
                    </td>
                  </tr>
                } @empty {
                  <tr>
                    <td colspan="4">No Users</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        }
      }
    </div>

    @if (!errorFetchingUsers && users) {
      <dialog
        #inviteUserModal
        id="inviteUserModal"
        class="modal modal-bottom sm:modal-middle"
      >
        <div class="modal-box">
          <h3 class="text-lg font-bold">Invite a User</h3>
          @if (errorFetchingRoles) {
            <div>
              <p class="py-4 text-error">Error fetching roles</p>
              <div class="modal-action">
                <form method="dialog">
                  <button class="btn btn-neutral">Cancel</button>
                </form>
              </div>
            </div>
          } @else if (roles) {
            <div>
              <div class="py-4">
                <div class="mt-8">
                  @if (!sendingInvitation && !successSendingInvitation) {
                    <div class="columns-2">
                      <label class="form-control w-full max-w-xs">
                        <div class="label">
                          <span class="label-text">Email</span>
                        </div>
                        <input
                          type="text"
                          class="input input-bordered w-full max-w-xs"
                          [(ngModel)]="invitationEmail"
                          placeholder="Email"
                        />
                      </label>
                      <label class="form-control w-full max-w-xs">
                        <div class="label">
                          <span class="label-text">Role</span>
                        </div>
                        <select
                          class="select select-bordered"
                          [(ngModel)]="selectedRoleId"
                        >
                          <option disabled selected>Select Role</option>
                          @for (role of roles; track role) {
                            <option [value]="role.id">
                              {{ role.name }}
                            </option>
                          }
                        </select>
                      </label>
                    </div>
                  }
                  @if (sendingInvitation) {
                    <div class="mt-4 flex flex-col items-center text-center">
                      <span
                        class="loading loading-spinner loading-lg text-primary"
                      ></span>
                      <p class="mt-4">Sending Invitation...</p>
                    </div>
                  }
                  @if (successSendingInvitation) {
                    <div class="mt-4 flex flex-col items-center text-center">
                      <svg
                        class="h-12 w-12 text-success"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                      <p class="mt-4">Invitation Sent Successfully</p>
                    </div>
                  }
                </div>
              </div>
              <div class="modal-action">
                @if (successSendingInvitation) {
                  <form method="dialog">
                    <button class="btn btn-neutral">Close</button>
                    <button
                      class="btn btn-primary ml-4"
                      (click)="successSendingInvitation = false"
                    >
                      Invite Another User
                    </button>
                  </form>
                }
                @if (!successSendingInvitation) {
                  <form method="dialog">
                    <!-- if there is a button in form, it will close the modal -->
                    <button class="btn btn-neutral">Cancel</button>
                    <button
                      class="btn btn-primary ml-4"
                      (click)="sendInvitation()"
                      [disabled]="sendingInvitation"
                    >
                      Send Invitation
                    </button>
                  </form>
                }
              </div>
            </div>
          }
        </div>
      </dialog>
    }
  </div>

  <div class="px-8">
    <hr class="my-4 border-base-content" />
  </div>

  <!-- USER INVITATIONS -->
  <div class="card">
    <div class="card-body">
      <div class="card-title mb-2">Invitations</div>

      @if (errorFetchingInvitations) {
        <div class="pt-8 text-center">
          <p class="text-error">Error fetching invitations</p>
        </div>
      } @else {
        <p class="pt-4 text-lg">Pending</p>
        @if (loadingInvitations) {
          <div class="skeleton h-28"></div>
        } @else if (invitations) {
          @if (invitations.pending.length === 0) {
            <div>
              <p>No pending invitations</p>
            </div>
          }
          @if (invitations.pending.length > 0) {
            <div
              class="overflow-x-auto rounded-lg border border-solid border-black"
            >
              <table class="table table-zebra static">
                <thead class="text-base font-medium">
                  <tr>
                    <td>Email</td>
                    <td>Used</td>
                    <td>Expiration</td>
                  </tr>
                </thead>
                <tbody>
                  @for (
                    pendingInvitation of invitations.pending;
                    track pendingInvitation;
                    let i = $index
                  ) {
                    <tr>
                      <td>{{ pendingInvitation.email }}</td>
                      <td>{{ pendingInvitation.used }}</td>
                      <td>
                        {{ pendingInvitation.expiration | date: 'short' }}
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          }
        }

        <p class="pt-4 text-lg">Accepted</p>
        @if (loadingInvitations) {
          <div class="skeleton h-28"></div>
        } @else if (invitations) {
          @if (invitations.accepted.length === 0) {
            <div>
              <p>No accepted invitations</p>
            </div>
          }
          @if (invitations.accepted.length > 0) {
            <div
              class="overflow-x-auto rounded-lg border border-solid border-black"
            >
              <table class="table table-zebra static">
                <thead class="text-base font-medium">
                  <tr>
                    <td>Email</td>
                    <td>Used</td>
                    <td>User Created</td>
                    <td>Expiration</td>
                  </tr>
                </thead>
                <tbody>
                  @for (
                    invitation of invitations.accepted;
                    track invitation;
                    let i = $index
                  ) {
                    <tr>
                      <td>{{ invitation.email }}</td>
                      <td>{{ invitation.used }}</td>
                      <td>{{ invitation.user_id_created }}</td>
                      <td>
                        {{ invitation.expiration | date: 'short' }}
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          }
        }

        <p class="pt-4 text-lg">Expired</p>
        @if (loadingInvitations) {
          <div class="skeleton h-28"></div>
        } @else if (invitations) {
          @if (invitations.expired.length === 0) {
            <div>
              <p>No expired invitations</p>
            </div>
          }
          @if (invitations.expired.length > 0) {
            <div
              class="overflow-x-auto rounded-lg border border-solid border-black"
            >
              <table class="table table-zebra static">
                <thead class="text-base font-medium">
                  <tr>
                    <td>Email</td>
                    <td>Used</td>
                    <td>Expiration</td>
                  </tr>
                </thead>
                <tbody>
                  @for (
                    invitation of invitations.expired;
                    track invitation;
                    let i = $index
                  ) {
                    <tr>
                      <td>{{ invitation.email }}</td>
                      <td>{{ invitation.used }}</td>
                      <td>
                        {{ invitation.expiration | date: 'short' }}
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          }
        }
      }
    </div>
  </div>

  <dialog class="modal" #addLicenseDialog>
    <div class="modal-box h-2/3 max-w-3xl">
      <form method="dialog">
        <button class="btn btn-circle btn-ghost btn-sm absolute right-2 top-2">
          ✕
        </button>
      </form>

      @if (selectingProduct) {
        <div class="pt-4">
          @if (loadingProducts) {
            <span class="loading loading-spinner loading-md"></span>
          } @else {
            <div class="card-title">Add License</div>
            <div class="grid grid-cols-3">
              <label class="form-control mr-4 max-w-xs">
                <div class="label">
                  <span class="label-text">Product</span>
                </div>
                <select
                  class="select select-bordered w-full max-w-xs"
                  (change)="handleProductChange($event)"
                  [(ngModel)]="productFilters.productId"
                >
                  <option disabled selected value="">Select Product</option>
                  @for (product of products; track product) {
                    <option [value]="product.id">
                      {{ product.code }} - {{ product.title }} ({{
                        product.variance_code
                      }})
                    </option>
                  }
                </select>
              </label>
              <label class="form-control mr-4 max-w-xs">
                <div class="label">
                  <span class="label-text">Duration (Months)</span>
                </div>
                <select
                  class="select select-bordered w-full max-w-xs"
                  [(ngModel)]="productFilters.duration"
                >
                  <option disabled selected value="">Select Duration</option>
                  @for (duration of variantDurations; track duration) {
                    <option [value]="duration">
                      {{ duration }}
                    </option>
                  }
                </select>
              </label>
            </div>
            @if (displayVariants.length > 0) {
              <table class="table table-zebra mt-4">
                <thead>
                  <th>Duration</th>
                  <th>Delivery Method</th>
                  <th>Lock Level</th>
                  <th>Price</th>
                  <th>TaxCode</th>
                  <th>Site Price</th>
                  <th>Site Tax Code</th>
                  <th></th>
                </thead>
                <tbody>
                  @for (variant of displayVariants; track variant) {
                    <tr>
                      <td>{{ variant.duration_months }}</td>
                      <td>
                        {{ displayDeliveryMethod(variant.delivery_method) }}
                      </td>
                      <td>{{ variant.price_lock_level }}</td>
                      <td>{{ variant.price }}</td>
                      <td>{{ variant.tax_code }}</td>
                      <td>{{ variant.site_price }}</td>
                      <td>{{ variant.site_tax_code }}</td>
                      <td>
                        <button
                          class="btn btn-primary btn-sm"
                          (click)="handleAddLicenseClick(variant)"
                        >
                          Add
                        </button>
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            }
          }
        </div>
      } @else {
        <h3 class="pb-2 pt-4">{{ currentProduct?.title }}</h3>
        <h3 class="py-4">{{ currentProduct?.description }}</h3>
        <p class="py-4">{{ currentVariant?.description }}</p>

        <div class="form-control w-min">
          <label class="label cursor-pointer">
            <span class="label-text mr-4 whitespace-nowrap"
              >Is Site License</span
            >
            <input
              [(ngModel)]="isSiteLicense"
              type="checkbox"
              checked="checked"
              class="checkbox"
            />
          </label>
        </div>

        @if (isSiteLicense) {
          <div class="form-control w-full max-w-xs">
            <label class="label">
              <span class="label-text">Main License</span>
            </label>
            <select
              [(ngModel)]="currentParentLicenseId"
              class="select select-bordered w-full max-w-xs"
            >
              <option disabled selected value="">Select License</option>
              @for (
                license of getAvailableMainLicenses(currentProduct);
                track license
              ) {
                <option [value]="license.desktop_license.id">
                  {{ license.product.title }} -
                  {{ license.desktop_license.expiration | date: 'mediumDate' }}
                </option>
              } @empty {
                <option disabled selected value="">
                  No licenses available for this product
                </option>
              }
            </select>
          </div>
        }

        <label class="form-control w-full max-w-xs">
          <div class="label">
            <span class="label-text">Start Date</span>
          </div>
          <input
            [value]="startDate.toISOString().slice(0, 10)"
            (input)="setStartDate($event)"
            type="date"
            placeholder="Type here"
            class="input input-bordered w-full max-w-xs"
          />
        </label>

        <div class="modal-action">
          <button
            (click)="addLicense()"
            class="btn btn-primary"
            [disabled]="addingLicense"
          >
            Add License
          </button>
          <form method="dialog">
            <!-- if there is a button in form, it will close the modal -->
            <button class="btn">Close</button>
          </form>
        </div>
        @if (errorAddingLicense) {
          <p class="text-error">
            {{ errorAddingLicense }}
          </p>
        }
      }
    </div>
  </dialog>

  <dialog class="modal" #createInvoiceDialog>
    <div class="modal-box max-w-[80%]">
      <form method="dialog">
        <button class="btn btn-circle btn-ghost btn-sm absolute right-2 top-2">
          ✕
        </button>
      </form>
      <h3 class="py-4">Create Invoice</h3>

      @if (errorFetchingInvoices) {
        <div class="pt-8 text-center">
          <p class="text-error">Error fetching invoices</p>
        </div>
      }

      @if (!validInvoiceLicenses || validInvoiceLicenses.length === 0) {
        <div class="pt-8 text-center">
          <p>No valid licenses to add to invoice</p>
        </div>
      } @else {
        <table class="table table-zebra">
          <thead>
            <tr>
              <th></th>
              <th>Product</th>
              <th>Type</th>
              <th>Duration</th>
              <th>Expiration</th>
              <th>Created</th>
            </tr>
          </thead>
          <tbody>
            @for (license of validInvoiceLicenses; track license) {
              <tr>
                <td>
                  <input
                    type="checkbox"
                    class="checkbox"
                    [value]="license.desktop_license.id"
                    (change)="handleInvoiceLicenseCheck($event)"
                  />
                </td>
                <td>{{ license.product.title }}</td>
                <td>
                  {{
                    license.desktop_license.site_license ? 'Site' : 'Primary'
                  }}
                </td>
                <td>{{ license.product_variant.duration_months }} months</td>
                <td>
                  {{ license.desktop_license.expiration | date: 'mediumDate' }}
                </td>
                <td>
                  {{ license.desktop_license.created_at | date: 'medium' }}
                </td>
              </tr>
            } @empty {
              <tr>
                <td></td>
                <td colspan="4">No valid Licenses to add to invoice</td>
              </tr>
            }
          </tbody>
        </table>

        <div class="mt-8 text-center">
          <button
            (click)="createInvoice()"
            class="btn btn-primary"
            [disabled]="creatingInvoice"
          >
            Create Invoice
          </button>
        </div>
      }

      @if (errorCreatingInvoice) {
        <p class="text-error">
          {{ errorCreatingInvoice }}
        </p>
      }
    </div>
  </dialog>
</div>
