<div class="flex flex-col p-4">
  <div class="breadcrumbs text-sm">
    <ul>
      <li>
        <a [routerLink]="['/admin']" class="inline-flex items-center gap-2">
          <app-user-setting-icon
            class="h-5 w-5 text-current"
          ></app-user-setting-icon>
          Admin
        </a>
      </li>
      <li>
        <a
          [routerLink]="['/admin']"
          [queryParams]="{ tab: 'tenants-list' }"
          class="inline-flex items-center gap-2"
        >
          <app-clipboard-icon class="h-5 w-5 text-current"></app-clipboard-icon>
          Tenants List
        </a>
      </li>
      <li>
        <span class="inline-flex items-center gap-2">
          <app-building-icon class="h-5 w-5 text-current"></app-building-icon>
          Tenant Details
        </span>
      </li>
    </ul>
  </div>
  <div class="card">
    <div class="card-body">
    @if (errorFetchingTenant) {
      <p class="text-error">
        {{ errorFetchingTenant }}
      </p>
    } @else {
      @if (loadingTenant) {
        <div class="skeleton h-52 w-52"></div>
      } @else if (tenant) {
          <app-tenant-info
            [tenant]="tenant"
            [franchise]="franchise"
          ></app-tenant-info>
        }
      }
    </div>
  </div>

  <div class="px-8">
    <hr class="my-4 border-base-content" />
  </div>

  <!-- LICENSES -->
  <div class="card">
    <div class="card-body">
      <div class="mb-4 flex justify-between">
        <p class="card-title">Licenses</p>

        @if (!errorFetchingLicenses) {
          @if (loadingLicenses) {
            <div class="skeleton h-8 w-28 rounded-md"></div>
          } @else {
            <button
              class="btn btn-primary btn-sm"
              (click)="showAddLicenseTable()"
            >
              + Add License
            </button>
          }
        }
      </div>

      @if (errorFetchingLicenses) {
        <p class="text-error">
          {{ errorFetchingLicenses }}
        </p>
      } @else {
        @if (loadingLicenses) {
          <div class="skeleton mb-4 h-4 w-28 rounded-md"></div>
          <div class="skeleton h-28 rounded-md"></div>
        } @else {
          @if (desktopLicenses?.active) {
            <div class="text-lg"><p>Active</p></div>
            @if (desktopLicenses?.active?.length === 0) {
              <div>
                <p>No active licenses</p>
              </div>
            } @else {
              <app-licenses-table
                [licenses]="desktopLicenses?.active"
                [current-sort]="currentLicenseSort"
                (sort-change)="sortLicenses($event)"
              ></app-licenses-table>
            }
          }

          @if (
            desktopLicenses?.pending && (desktopLicenses?.pending)!.length > 0
          ) {
            <div class="mb-2 mt-8 text-lg">Pending</div>
            <app-licenses-table
              [licenses]="desktopLicenses!.pending"
              [current-sort]="currentLicenseSort"
              (sort-change)="sortLicenses($event)"
            ></app-licenses-table>
          }

          @if (
            desktopLicenses?.expired && (desktopLicenses?.expired)!.length > 0
          ) {
            <div class="mb-2 mt-8 text-lg">Expired</div>
            <app-licenses-table
              [licenses]="desktopLicenses!.expired"
              [current-sort]="currentLicenseSort"
              (sort-change)="sortLicenses($event)"
            ></app-licenses-table>
          }

          @if (
            desktopLicenses?.disabled && (desktopLicenses?.disabled)!.length > 0
          ) {
            <div class="mb-2 mt-8 text-lg">Disabled</div>
            <app-licenses-table
              [licenses]="desktopLicenses!.disabled"
              [current-sort]="currentLicenseSort"
              (sort-change)="sortLicenses($event)"
            ></app-licenses-table>
          }
        }
      }
    </div>
  </div>

  <div class="px-8">
    <hr class="my-4 border-base-content" />
  </div>

  <!-- INVOICES -->
  <div class="card">
    <div class="card-body">
      <div class="mb-4 flex justify-between">
        <div class="card-title">Invoices</div>

        @if (!errorFetchingLicenses) {
          @if (loadingLicenses) {
            <div class="skeleton h-8 w-28 rounded-md"></div>
          } @else {
            <button
              class="btn btn-primary btn-sm"
              (click)="showCreateInvoiceModal()"
            >
              + Create Invoice
            </button>
          }
        }
      </div>

      @if (errorFetchingInvoices) {
        <div class="pt-8 text-center">
          <p class="text-error">Error fetching invoices</p>
        </div>
      } @else {
        <p class="pt-4 text-lg">Pending</p>
        @if (loadingInvoices) {
          <div class="skeleton h-28"></div>
        } @else if (desktopInvoices) {
          @if (desktopInvoices.pending.length === 0) {
            <div>
              <p>No pending invoices</p>
            </div>
          }
          @if (desktopInvoices.pending.length > 0) {
            <app-invoices-table
              [invoices]="desktopInvoices.pending"
              [current-sort]="currentInvoiceSort"
              (sort-changed)="sortInvoices($event)"
              (delete-invoice)="promptDeleteInvoice($event)"
            ></app-invoices-table>
          }
        }

        <p class="pt-4 text-lg">Completed</p>
        @if (loadingInvoices) {
          <div class="skeleton h-28"></div>
        } @else if (desktopInvoices) {
          @if (desktopInvoices.complete.length === 0) {
            <div>
              <p>No completed invoices</p>
            </div>
          }
          @if (desktopInvoices.complete.length > 0) {
            <app-invoices-table
              [invoices]="desktopInvoices.complete"
              [current-sort]="currentInvoiceSort"
              (sort-changed)="sortInvoices($event)"
              (delete-invoice)="promptDeleteInvoice($event)"
            ></app-invoices-table>
          }
        }

        <p class="pt-4 text-lg">Expired</p>
        @if (loadingInvoices) {
          <div class="skeleton h-28"></div>
        } @else if (desktopInvoices) {
          @if (desktopInvoices.expired.length === 0) {
            <div>
              <p>No expired invoices</p>
            </div>
          }
          @if (desktopInvoices.expired.length > 0) {
            <app-invoices-table
              [invoices]="desktopInvoices.expired"
              [current-sort]="currentInvoiceSort"
              (sort-changed)="sortInvoices($event)"
              (delete-invoice)="promptDeleteInvoice($event)"
            ></app-invoices-table>
          }
        }
      }
    </div>
  </div>

  <div class="px-8">
    <hr class="my-4 border-base-content" />
  </div>

  <!-- USERS -->
  <div class="card">
    <div class="card-body">
      <div class="card-title mb-4 justify-between">
        <p>Users</p>
        @if (!errorFetchingUsers) {
          @if (loadingUsers) {
            <div class="skeleton h-8 w-28 rounded-md"></div>
          } @else {
            <button
              class="btn btn-primary btn-sm"
              onclick="inviteUserModal.showModal()"
            >
              Invite a User
            </button>
          }
        }
      </div>

      @if (errorFetchingUsers) {
        <div class="text-center">
          <p class="text-error">Error fetching users</p>
        </div>
      } @else {
        @if (loadingUsers) {
          <div class="skeleton h-28 rounded-md"></div>
        }
        @if (!loadingUsers) {
          <app-users-table
            [users]="users"
            [currentSort]="currentUserSort"
            (sort-changed)="sortUsers($event)"
          ></app-users-table>
        }
      }
    </div>

    @if (!errorFetchingUsers && users) {
      <dialog
        #inviteUserModal
        id="inviteUserModal"
        class="modal modal-bottom sm:modal-middle"
      >
        <div class="modal-box">
          <h3 class="text-lg font-bold">Invite a User</h3>
          @if (errorFetchingRoles) {
            <div>
              <p class="py-4 text-error">Error fetching roles</p>
              <div class="modal-action">
                <form method="dialog">
                  <button class="btn btn-neutral">Cancel</button>
                </form>
              </div>
            </div>
          } @else if (roles) {
            <div>
              <div class="py-4">
                <div class="mt-8">
                  @if (!sendingInvitation && !successSendingInvitation) {
                    <div class="columns-2">
                      <label class="form-control w-full max-w-xs">
                        <div class="label">
                          <span class="label-text">Email</span>
                        </div>
                        <input
                          type="text"
                          class="input input-bordered w-full max-w-xs"
                          [(ngModel)]="invitationEmail"
                          placeholder="Email"
                        />
                      </label>
                      <label class="form-control w-full max-w-xs">
                        <div class="label">
                          <span class="label-text">Role</span>
                        </div>
                        <select
                          class="select select-bordered"
                          [(ngModel)]="selectedRoleId"
                        >
                          <option disabled selected>Select Role</option>
                          @for (role of roles; track role) {
                            <option [value]="role.id">
                              {{ role.name }}
                            </option>
                          }
                        </select>
                      </label>
                    </div>
                  }
                  @if (sendingInvitation) {
                    <div class="mt-4 flex flex-col items-center text-center">
                      <span
                        class="loading loading-spinner loading-lg text-primary"
                      ></span>
                      <p class="mt-4">Sending Invitation...</p>
                    </div>
                  }
                  @if (successSendingInvitation) {
                    <div class="mt-4 flex flex-col items-center text-center">
                      <svg
                        class="h-12 w-12 text-success"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                      <p class="mt-4">Invitation Sent Successfully</p>
                    </div>
                  }
                </div>
              </div>
              <div class="modal-action">
                @if (successSendingInvitation) {
                  <form method="dialog">
                    <button class="btn btn-neutral">Close</button>
                    <button
                      class="btn btn-primary ml-4"
                      (click)="successSendingInvitation = false"
                    >
                      Invite Another User
                    </button>
                  </form>
                }
                @if (!successSendingInvitation) {
                  <form method="dialog">
                    <!-- if there is a button in form, it will close the modal -->
                    <button class="btn btn-neutral">Cancel</button>
                    <button
                      class="btn btn-primary ml-4"
                      (click)="sendInvitation()"
                      [disabled]="sendingInvitation"
                    >
                      Send Invitation
                    </button>
                  </form>
                }
              </div>
            </div>
          }
        </div>
      </dialog>
    }
  </div>

  <div class="px-8">
    <hr class="my-4 border-base-content" />
  </div>

  <!-- USER INVITATIONS -->
  <div class="card">
    <div class="card-body">
      <div class="card-title mb-2">Invitations</div>

      @if (errorRevokingInvitation) {
        <div class="py-4 text-center text-error">
          <p>{{ errorRevokingInvitation }}</p>
        </div>
      }

      @if (errorFetchingInvitations) {
        <div class="pt-8 text-center">
          <p class="text-error">Error fetching invitations</p>
        </div>
      } @else {
        <p class="pt-4 text-lg">Pending</p>
        @if (loadingInvitations) {
          <div class="skeleton h-28"></div>
        } @else if (invitations) {
          @if (invitations.pending.length === 0) {
            <div>
              <p>No pending invitations</p>
            </div>
          }
          @if (invitations.pending.length > 0) {
            <div
              class="overflow-x-auto rounded-lg border border-solid border-black"
            >
              <table class="table table-zebra static">
                <thead class="text-base font-medium">
                  <tr>
                    <td>Email</td>
                    <td>Used</td>
                    <td>Expiration</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  @for (
                    pendingInvitation of invitations.pending;
                    track pendingInvitation;
                    let i = $index
                  ) {
                    <tr>
                      <td>{{ pendingInvitation.email }}</td>
                      <td>{{ pendingInvitation.used }}</td>
                      <td>
                        {{
                          pendingInvitation.expiration
                            | date: 'MM/dd/YYYY' : 'UTC'
                        }}
                      </td>
                      <td
                        class="link"
                        (click)="revokeInvitation(pendingInvitation)"
                      >
                        Revoke
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          }
        }

        <p class="pt-4 text-lg">Accepted</p>
        @if (loadingInvitations) {
          <div class="skeleton h-28"></div>
        } @else if (invitations) {
          @if (invitations.accepted.length === 0) {
            <div>
              <p>No accepted invitations</p>
            </div>
          }
          @if (invitations.accepted.length > 0) {
            <div
              class="overflow-x-auto rounded-lg border border-solid border-black"
            >
              <table class="table table-zebra static">
                <thead class="text-base font-medium">
                  <tr>
                    <td>Email</td>
                    <td>Used</td>
                    <td>User Created</td>
                    <td>Expiration</td>
                  </tr>
                </thead>
                <tbody>
                  @for (
                    invitation of invitations.accepted;
                    track invitation;
                    let i = $index
                  ) {
                    <tr>
                      <td>{{ invitation.email }}</td>
                      <td>{{ invitation.used }}</td>
                      <td>{{ invitation.user_id_created }}</td>
                      <td>
                        {{ invitation.expiration | date: 'MM/dd/YYYY' : 'UTC' }}
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          }
        }

        <p class="pt-4 text-lg">Expired</p>
        @if (loadingInvitations) {
          <div class="skeleton h-28"></div>
        } @else if (invitations) {
          @if (invitations.expired.length === 0) {
            <div>
              <p>No expired invitations</p>
            </div>
          }
          @if (invitations.expired.length > 0) {
            <div
              class="overflow-x-auto rounded-lg border border-solid border-black"
            >
              <table class="table table-zebra static">
                <thead class="text-base font-medium">
                  <tr>
                    <td>Email</td>
                    <td>Used</td>
                    <td>Expiration</td>
                  </tr>
                </thead>
                <tbody>
                  @for (
                    invitation of invitations.expired;
                    track invitation;
                    let i = $index
                  ) {
                    <tr>
                      <td>{{ invitation.email }}</td>
                      <td>{{ invitation.used }}</td>
                      <td>
                        {{ invitation.expiration | date: 'MM/dd/YYYY' : 'UTC' }}
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          }
        }

        <p class="pt-4 text-lg">Revoked</p>
        @if (loadingInvitations) {
          <div class="skeleton h-28"></div>
        } @else if (invitations) {
          @if (invitations.revoked.length === 0) {
            <div>
              <p>No revoked invitations</p>
            </div>
          }
          @if (invitations.revoked.length > 0) {
            <div
              class="overflow-x-auto rounded-lg border border-solid border-black"
            >
              <table class="table table-zebra static">
                <thead class="text-base font-medium">
                  <tr>
                    <td>Email</td>
                    <td>Used</td>
                    <td>Revoked</td>
                    <td>Expiration</td>
                  </tr>
                </thead>
                <tbody>
                  @for (
                    invitation of invitations.revoked;
                    track invitation;
                    let i = $index
                  ) {
                    <tr>
                      <td>{{ invitation.email }}</td>
                      <td>{{ invitation.used }}</td>
                      <td>{{ invitation.revoked }}</td>
                      <td>
                        {{ invitation.expiration | date: 'MM/dd/YYYY' : 'UTC' }}
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          }
        }
      }
    </div>
  </div>

  <dialog class="modal" #addLicenseDialog>
    <div class="modal-box h-2/3 max-w-3xl">
      <app-add-license
        [desktopLicenses]="desktopLicenses"
        [tenant]="tenant"
        (licenseAdded)="handleLicenseAdded($event)"
      ></app-add-license>
    </div>
  </dialog>

  <dialog class="modal" #createInvoiceDialog>
    <div class="modal-box max-w-[80%]">
      @if (desktopLicenses && desktopInvoices) {
        <app-create-invoice
          [desktopLicenses]="desktopLicenses"
          [desktopInvoices]="desktopInvoices"
          (invoiceCreated)="handleInvoiceCreated($event)"
        ></app-create-invoice>
      } @else if (errorFetchingLicenses || errorFetchingInvoices) {
        <div class="text-center">
          <p class="text-error">
            {{ errorFetchingLicenses || errorFetchingInvoices }}
          </p>
        </div>
      } @else {
        <div class="text-center">
          <p class="text-error">Unkown error loading licenses or invoices</p>
        </div>
      }
    </div>
  </dialog>

  <dialog class="modal" #deleteInvoiceDialog>
    <div class="modal-box max-w-3xl p-8">
      @if (!invoiceToDelete) {
        <p class="text-center text-error">No invoice to delete</p>
      } @else {
        <h3 class="text-lg font-bold">Confirm Invoice Deletion</h3>

        <div class="my-4">
          <div>
            <p class="mb-2">
              Invoice ID:
              {{ invoiceToDelete.desktop_invoice.id.slice(0, 8) }}
            </p>
            <p class="mb-2">
              Invoice created on:
              {{
                invoiceToDelete.desktop_invoice.created_at | date: 'MM/dd/YYYY'
              }}
            </p>
            <p class="mb-2">
              Total Amount:
              {{
                invoiceToDelete.desktop_invoice.total_amount | currency: 'USD'
              }}
            </p>
            <p class="mb-4 mt-2">
              Due by:
              {{ invoiceToDelete.due_by | date: 'MM/dd/YYYY' : 'UTC' }}
            </p>
          </div>
        </div>

        <div class="overflow-x-auto">
          <table class="table table-zebra">
            <thead class="text-sm">
              <tr>
                <th class="font-medium">Product</th>
                <th class="font-medium">Type</th>
                <th class="font-medium">New Expiration</th>
                <th class="text-right font-medium">Price</th>
              </tr>
            </thead>
            <tbody>
              @for (el of invoiceToDelete.license_details; track el) {
                <tr>
                  <td>{{ el.license_with_product.product.title }}</td>
                  <td>
                    {{ el.license_with_product.site_license ? 'Site' : 'Main' }}
                  </td>
                  <td>
                    {{ el.new_expiration_date | date: 'MM/dd/YYYY' : 'UTC' }}
                  </td>
                  <td class="text-right">
                    {{ el.base_amount | currency: 'USD' }}
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>

        <p class="mt-8 text-sm text-error">
          This deletion is permanent. Are you sure you want to delete this
          invoice?
        </p>

        <div class="mt-4 flex justify-end">
          <button
            class="btn btn-primary mr-4"
            (click)="deleteInvoice()"
            [disabled]="deletingInvoice"
          >
            Delete
          </button>
          <button class="btn btn-neutral" (click)="closeDeleteInvoiceDialog()">
            Cancel
          </button>
        </div>
      }
    </div>
  </dialog>
</div>

@if (invoiceCreatedSuccessfully) {
  <app-toast-timer
    status="success"
    message="Invoice created successfully"
    (clear)="invoiceCreatedSuccessfully = false"
  ></app-toast-timer>
}

@if (invitationRevokedSuccessfully) {
  <app-toast-timer
    status="success"
    message="Invitation revoked successfully"
    (clear)="invitationRevokedSuccessfully = false"
  ></app-toast-timer>
}

@if (invoiceDeletedSuccessfully) {
  <app-toast-timer
    status="success"
    message="Invoice deleted successfully"
    (clear)="invoiceDeletedSuccessfully = false"
  ></app-toast-timer>
}
