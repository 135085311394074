import { Component } from '@angular/core';
import {
  TenantResponse,
  TenantsService,
} from '../../../../services/tenants.service';
import {
  Distributor,
  DistributorsService,
} from 'src/app/services/distributors.service';
import {
  Franchise,
  FranchisesService,
} from 'src/app/services/franchises.service';
import { LoggerService } from 'src/app/services/logger.service';

@Component({
  selector: 'app-create-tenant',
  templateUrl: './create-tenant.component.html',
  styleUrls: ['./create-tenant.component.scss'],
})
export class CreateTenantComponent {
  loadingDistributors = false;
  loadingFranchises = false;

  email: string = '';
  companyName: string = '';

  distributors: Distributor[] = [];
  selectedDistributorId: string = '';

  franchises: Franchise[] = [];
  selectedFranchiseId: string = '';

  creatingTenant = false;
  createResponse?: TenantResponse;
  createError = '';

  constructor(
    private distributorsService: DistributorsService,
    private franchisesService: FranchisesService,
    private loggerService: LoggerService,
    private tenantsService: TenantsService
  ) {
    this.loadDistributors();
    this.loadFranchises();
  }

  get canCreateTenant() {
    return (
      this.selectedDistributorId !== '' &&
      this.email !== '' &&
      this.companyName !== ''
    );
  }

  async loadDistributors() {
    this.loadingDistributors = true;
    this.distributorsService.getAllDistributors().subscribe({
      next: (res) => {
        this.loggerService.log('Response from server: ', res);
        this.distributors = res.distributors;
      },
      error: (err) => {
        this.loggerService.error(err);
      },
      complete: () => {
        this.loadingDistributors = false;
      },
    });
  }

  async loadFranchises() {
    this.loadingFranchises = true;
    this.franchisesService.getAllFranchises().subscribe({
      next: (res) => {
        this.loggerService.log('Response from server: ', res);
        this.franchises = res.franchises;
      },
      error: (err) => {
        this.loggerService.error(new Error(err));
      },
      complete: () => {
        this.loadingFranchises = false;
      },
    });
  }

  async createTenant() {
    this.loggerService.log(`Creating tenant with `, {
      email: this.email,
      companyName: this.companyName,
      distributorId: this.selectedDistributorId,
      franchiseId: this.selectedFranchiseId,
    });

    if (this.selectedDistributorId === '') {
      this.createError = 'No distributor selected';
      return this.loggerService.error(new Error('No distributor selected'));
    }

    this.creatingTenant = true;

    this.tenantsService
      .createTenant(
        this.email,
        this.companyName,
        this.selectedDistributorId,
        this.selectedFranchiseId
      )
      .subscribe({
        next: (res) => {
          this.creatingTenant = false;
          this.loggerService.log('Response from server: ', res);
          this.createResponse = res;
          this.resetValues();
        },
        error: (err) => {
          this.creatingTenant = false;
          this.loggerService.error(err);
        },
      });
  }

  resetValues() {
    this.email = '';
    this.selectedDistributorId = '';
    this.selectedFranchiseId = '';
  }
}
