import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MultiFactorResolver, PhoneMultiFactorInfo } from 'firebase/auth';
import { FirebaseService } from 'src/app/services/firebase.service';
import { LoggerService } from 'src/app/services/logger.service';

@Component({
  selector: 'app-two-factor-login',
  templateUrl: './two-factor-login.component.html',
  styleUrls: ['./two-factor-login.component.scss'],
})
export class TwoFactorLoginComponent implements OnInit {
  @Input() mfaResolver?: MultiFactorResolver;
  @Output() success = new EventEmitter<void>();
  @Output() back = new EventEmitter<void>();

  successMessage = '';
  error = '';

  selected2FAOption: 'totp' | 'phone' = 'totp';

  verifying = false;

  showTOTPInput = false;
  showSMSInput = false;

  totpCode = '';

  sendingSMS = false;
  smsVerificationId = '';
  smsSent = false;
  smsCode = '';

  constructor(
    private firebaseService: FirebaseService,
    private loggerService: LoggerService
  ) {}

  ngOnInit() {
    if (this.mfaResolver) {
      if (this.mfaResolver.hints.findIndex((h) => h.factorId === 'totp') < 0) {
        this.selected2FAOption = 'phone';
      }
    }
  }

  get enrolled2FAOptions() {
    if (this.mfaResolver) {
      return this.mfaResolver.hints.map((h) => h.factorId);
    }
    return [];
  }

  get phoneHint() {
    if (this.mfaResolver) {
      const phoneHint = this.mfaResolver.hints.find(
        (h) => h.factorId === 'phone'
      );
      return phoneHint as PhoneMultiFactorInfo;
    }
    return undefined;
  }

  display2FAInput() {
    if (this.selected2FAOption === 'totp') {
      this.showTOTPInput = true;
      this.showSMSInput = false; // just in case
    } else {
      this.showSMSInput = true;
      this.showTOTPInput = false; // just in case
    }
  }

  verifyTOTPForSignIn() {
    this.loggerService.debug(
      'two-factor-login.component -> verifyTOTPForSignIn'
    );
    if (!this.mfaResolver) {
      this.loggerService.error(new Error('MFA Resolver missing'));
      this.error = 'MFA Resolver missing';
      return;
    }

    this.verifying = true;
    const totpHint = this.mfaResolver.hints.find((h) => h.factorId === 'totp');
    if (!totpHint) {
      const err = new Error('TOTP hint missing');
      this.loggerService.error(err);
      this.error = err.message;
      return;
    }

    this.firebaseService
      .verifyTOTPCodeForSignIn(this.mfaResolver, totpHint.uid, this.totpCode)
      .subscribe({
        next: (res) => {
          this.loggerService.log(
            `two-factor-login.component -> verifyTOTPForSignIn -> TOTP code verified`
          );
          this.successMessage = '2FA successful';
          this.success.emit();
        },
        error: (err) => {
          this.verifying = false;
          this.loggerService.error(err);
          if (
            err.code === 'auth/invalid-verification-code' ||
            err.code === 'auth/request-contains-an-invalid-argument.'
          ) {
            this.error = 'Invalid 2FA verification code';
          } else if (err.code === 'auth/totp-challenge-timeout') {
            this.error =
              'Your session has timed out, please refresh the page and login again';
          } else {
            this.error = 'An unexpected error occurred, please try again';
          }
        },
      });
  }

  sendSMSForSignIn() {
    this.loggerService.debug('two-factor-login.component -> sendSMSForSignIn');
    if (!this.mfaResolver) {
      this.loggerService.error(new Error('MFA Resolver missing'));
      this.error = 'MFA Resolver missing';
      return;
    }

    this.sendingSMS = true;

    const recaptchaVerifier = this.firebaseService.recaptchaVerifier(
      'send-sms-2fa-button'
    );

    this.firebaseService
      .sendSMSForSignIn(this.mfaResolver, recaptchaVerifier)
      .subscribe({
        next: (verificationId) => {
          this.loggerService.log(
            `two-factor-login.component -> sendSMSForSignIn -> SMS sent`
          );
          this.sendingSMS = false;
          this.smsVerificationId = verificationId;
          this.error = '';
          this.smsSent = true;
        },
        error: (err) => {
          this.sendingSMS = false;
          this.loggerService.error(err);
          this.error = 'Unexpected error occurred. Please try again.';
        },
      });
  }

  verifySMSForSignIn() {
    this.loggerService.debug(
      'two-factor-login.component -> verifySMSForSignIn'
    );
    if (!this.mfaResolver) {
      this.loggerService.error(new Error('MFA Resolver missing'));
      this.error = 'MFA Resolver missing';
      return;
    }

    this.verifying = true;

    this.firebaseService
      .verifySMSCodeForSignIn(
        this.mfaResolver,
        this.smsVerificationId,
        this.smsCode
      )
      .subscribe({
        next: (res) => {
          this.verifying = false;
          this.loggerService.log(
            `two-factor-login.component -> verifySMSForSignIn -> SMS code verified`
          );
          this.successMessage = '2FA successful';
          this.success.emit();
        },
        error: (err) => {
          this.verifying = false;
          this.loggerService.error(err);
          if (
            err.code === 'auth/invalid-verification-code' ||
            err.code === 'auth/request-contains-an-invalid-argument.'
          ) {
            this.error = 'Invalid 2FA verification code';
          } else if (err.code === 'auth/totp-challenge-timeout') {
            this.error =
              'Your session has timed out, please refresh the page and login again';
          } else {
            this.error = 'An unexpected error occurred, please try again';
          }
        },
      });
  }
}
