@if (errorFetchingInvoice) {
  <div class="text-error">
    <p>{{ errorFetchingInvoice }}</p>
  </div>
} @else if (invoice) {
  <div
    class="mt-5 w-full max-w-3xl rounded-md border-2 border-gray-600 p-4 dark:border-gray-300"
  >
    <div class="flex justify-between">
      <div>
        <p class="mb-2">
          Invoice ID: {{ invoice.desktop_invoice.id.slice(0, 8) }}
        </p>
        <p class="mb-2">
          Invoice created on:
          {{ invoice.desktop_invoice.created_at | date: 'MM/dd/YYYY' }}
        </p>
        <p class="text-4xl">
          {{ invoice.desktop_invoice.total_amount | currency: 'USD' }}
        </p>
        <p class="mb-4 mt-2">
          Due by: {{ invoice.due_by | date: 'MM/dd/YYYY' : 'UTC' }}
        </p>
      </div>
      <div class="h-24 w-24">
        <app-file-invoice-icon
          class="text-gray-600 dark:text-gray-300 [&_path]:stroke-1"
        ></app-file-invoice-icon>
      </div>
    </div>
    <div class="overflow-x-auto">
      <table class="table table-zebra">
        <thead class="text-sm">
          <tr>
            <th class="font-medium">Product</th>
            <th class="font-medium">Type</th>
            <th class="font-medium">Quantity</th>
            <th class="font-medium">New Expiration</th>
            <th class="text-right font-medium">Price</th>
          </tr>
        </thead>
        <tbody>
          @for (el of groupedLicenseInvoiceDetails; track el) {
            <tr>
              <td>{{ el.license.license_with_product.product.title }}</td>
              <td>
                {{
                  el.license.license_with_product.site_license ? 'Site' : 'Main'
                }}
              </td>
              <td>{{ el.count }}</td>
              <td>
                {{
                  el.license.new_expiration_date | date: 'MM/dd/YYYY' : 'UTC'
                }}
              </td>
              <td class="text-right">
                {{ el.license.base_amount | currency: 'USD' }}
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
    <div class="ml-auto mt-4 w-48">
      <table class="table">
        <tbody>
          <tr>
            <td>Subtotal:</td>
            <td class="text-right">
              {{ invoice.desktop_invoice.base_amount | currency: 'USD' }}
            </td>
          </tr>
          <tr>
            <td>Tax:</td>
            <td class="text-right">
              {{ invoice.desktop_invoice.tax_amount | currency: 'USD' }}
            </td>
          </tr>
          <tr>
            <td>Total:</td>
            <td class="text-right">
              {{ invoice.desktop_invoice.total_amount | currency: 'USD' }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    @if (!invoice.desktop_invoice.completed) {
      <div class="my-4 mr-4 text-right text-sm">
        <a
          class="text-blue-500 underline hover:cursor-pointer"
          [routerLink]="'/edit-invoice/' + invoice.desktop_invoice.id"
          >Edit Products</a
        >
      </div>
    }

    @if (paymentError) {
      <div class="mt-4 text-center text-error">
        {{ paymentError }}
      </div>
    }

    @if (paymentSuccess) {
      <div class="mt-8">
        <p class="text-center text-xl text-success">
          Payment Complete
          {{
            invoice.desktop_invoice.completed_at | date: 'MM/dd/YYYY, hh:mmaa'
          }}
        </p>
      </div>
      <div class="mt-8 flex justify-center">
        <button
          class="btn btn-primary"
          [routerLink]="'/receipt/' + invoice.desktop_invoice.id"
        >
          View Receipt
        </button>
      </div>
    } @else {
      <div class="divider"></div>
      @if (savedPaymentMethods) {
        <div class="mt-4 flex flex-col items-center">
          @for (paymentMethod of savedPaymentMethods; track paymentMethod) {
            <div
              class="m-2 w-full max-w-lg rounded-lg border border-gray-600 p-4 dark:border-gray-300"
            >
              <div class="flex">
                <div class="flex flex-col">
                  <div class="flex">
                    <input
                      class="w-4"
                      type="radio"
                      name="saved-payment-method"
                      [id]="paymentMethod.id"
                      [value]="paymentMethod.id"
                      (change)="selectedSavedPaymentMethod = paymentMethod"
                    />
                    <p class="ml-7 self-center text-lg">
                      {{ paymentMethod.card_type }}
                    </p>
                    <p class="ml-2 self-center text-sm">
                      •••• {{ paymentMethod.last_4_digits }}
                    </p>
                  </div>
                  <span class="ml-11 text-sm">
                    Expires {{ paymentMethod.card_expiration }}
                  </span>
                </div>
                <div class="my-auto ml-auto flex">
                  @if (paymentMethod.is_default) {
                    <p class="my-auto mr-4 text-sm italic">Default</p>
                  }
                  @if (paymentMethod.card_type === 'Visa') {
                    <app-visa-icon></app-visa-icon>
                  } @else if (paymentMethod.card_type === 'MasterCard') {
                    <app-mastercard-icon></app-mastercard-icon>
                  } @else if (paymentMethod.card_type === 'AmericanExpress') {
                    <app-american-express-icon></app-american-express-icon>
                  } @else if (paymentMethod.card_type === 'Discover') {
                    <app-discover-icon></app-discover-icon>
                  } @else if (paymentMethod.card_type === 'JCB') {
                    <app-jcb-icon></app-jcb-icon>
                  } @else if (paymentMethod.card_type === 'DinersClub') {
                    <app-diners-club-icon></app-diners-club-icon>
                  } @else {
                    <app-generic-credit-card-icon></app-generic-credit-card-icon>
                  }
                </div>
              </div>
            </div>
          }
          <button
            class="btn btn-primary mt-4"
            (click)="payWithSelectedMethod()"
            [disabled]="!selectedSavedPaymentMethod || paymentProcessing"
          >
            Pay with Selected Saved Payment Method
            @if (paymentProcessing) {
              <div class="absolute flex h-full">
                <div class="loading loading-spinner">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            }
          </button>
        </div>
        <div class="divider my-4"></div>
      }
      <div class="relative mt-4">
        <div class="flex justify-center">
          <form>
            <button
              type="button"
              class="AcceptUI btn btn-primary"
              data-billingAddressOptions='{"show":true, "required":true}'
              [attr.data-apiLoginID]="authNetAPILoginID"
              [attr.data-clientKey]="authNetClientKey"
              data-acceptUIFormBtnTxt="Submit"
              data-acceptUIFormHeaderTxt="Card Information"
              data-paymentOptions='{"showCreditCard": true}'
              data-responseHandler="acceptJSResponseHandler"
              [disabled]="paymentProcessing"
            >
              <span class="h-6 w-6"
                ><app-credit-card-icon></app-credit-card-icon
              ></span>
              Pay with a New Card
            </button>
          </form>
          @if (paymentProcessing) {
            <div class="absolute flex h-full">
              <div class="loading loading-spinner">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          }
        </div>
      </div>
    }
  </div>
}
