<div class="mx-auto flex w-fit flex-col">
  <div>
    <h1 class="mb-4 text-2xl font-bold">Create New Product Variant</h1>
    @if (product) {
      <p class="mb-4">Creating variant for product: {{ product.title }}</p>
    }
  </div>

  @if (errorFetchingProduct) {
    <div class="flex justify-center">
      <p class="text-error">{{ errorFetchingProduct }}</p>
    </div>
  } @else if (product) {
    <div>
      <div class="form-control mb-4">
        <label class="label">
          <span class="label-text">Description</span>
        </label>
        <textarea
          class="textarea textarea-bordered"
          [(ngModel)]="description"
        ></textarea>
      </div>
      <div class="form-control mb-2 w-fit">
        <label class="label cursor-pointer">
          <input type="checkbox" class="checkbox" [(ngModel)]="active" />
          <span class="label-text ml-4">Active</span>
        </label>
      </div>
      <div class="form-control mb-2 w-fit">
        <label class="label cursor-pointer">
          <input type="checkbox" class="checkbox" [(ngModel)]="isDefault" />
          <span class="label-text ml-4">Is Default</span>
        </label>
      </div>
      <div class="flex space-x-8">
        <div class="form-control mb-2 w-fit">
          <label class="label">
            <span class="label-text">Duration (Months)</span>
          </label>
          <input
            type="number"
            class="input input-bordered"
            [(ngModel)]="durationMonths"
          />
        </div>
        <div class="form-control mb-2 w-fit">
          <label class="label">
            <span class="label-text">Delivery Method</span>
          </label>
          <select class="select select-bordered" [(ngModel)]="deliveryMethod">
            @for (dm of deliveryMethods; track dm) {
              <option [value]="dm.value">
                {{ dm.display }}
              </option>
            }
          </select>
        </div>
      </div>
      <div class="flex space-x-8">
        <div class="form-control mb-2 w-fit">
          <label class="label">
            <span class="label-text">Price Lock Level</span>
          </label>
          <input
            type="number"
            class="input input-bordered"
            [(ngModel)]="priceLockLevel"
          />
        </div>
        <div class="form-control mb-2 w-fit">
          <label class="label">
            <span class="label-text">Variance Code</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            [(ngModel)]="varianceCode"
          />
        </div>
      </div>
      <div class="flex space-x-8">
        <div class="form-control mb-2 w-fit">
          <label class="label">
            <span class="label-text">Price</span>
          </label>
          <input
            type="number"
            class="input input-bordered"
            [(ngModel)]="price"
          />
        </div>
        <div class="form-control mb-2 w-fit">
          <label class="label">
            <span class="label-text">Tax Code</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            [(ngModel)]="taxCode"
          />
        </div>
        <div class="form-control mb-2 w-fit">
          <label class="label">
            <span class="label-text">Price Key</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            [(ngModel)]="priceKey"
          />
        </div>
      </div>
      <div class="flex space-x-8">
        <div class="form-control mb-2 w-fit">
          <label class="label">
            <span class="label-text">Renewal Price</span>
          </label>
          <input
            type="number"
            class="input input-bordered"
            [(ngModel)]="renewalPrice"
          />
        </div>
        <div class="form-control mb-2 w-fit">
          <label class="label">
            <span class="label-text">Renewal Tax Code</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            [(ngModel)]="renewalTaxCode"
          />
        </div>
        <div class="form-control mb-2 w-fit">
          <label class="label">
            <span class="label-text">Renewal Price Key</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            [(ngModel)]="renewalPriceKey"
          />
        </div>
      </div>
      <div class="flex space-x-8">
        <div class="form-control mb-2 w-fit">
          <label class="label">
            <span class="label-text">Site Price</span>
          </label>
          <input
            type="number"
            class="input input-bordered"
            [(ngModel)]="sitePrice"
          />
        </div>
        <div class="form-control mb-2 w-fit">
          <label class="label">
            <span class="label-text">Site Tax Code</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            [(ngModel)]="siteTaxCode"
          />
        </div>
        <div class="form-control mb-2 w-fit">
          <label class="label">
            <span class="label-text">Site Price Key</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            [(ngModel)]="sitePriceKey"
          />
        </div>
      </div>
      <div class="flex space-x-8">
        <div class="form-control mb-2 w-fit">
          <label class="label">
            <span class="label-text">Site Renewal Price</span>
          </label>
          <input
            type="number"
            class="input input-bordered"
            [(ngModel)]="siteRenewalPrice"
          />
        </div>
        <div class="form-control mb-2 w-fit">
          <label class="label">
            <span class="label-text">Site Renewal Tax Code</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            [(ngModel)]="siteRenewalTaxCode"
          />
        </div>
        <div class="form-control mb-2 w-fit">
          <label class="label">
            <span class="label-text">Site Renewal Price Key</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            [(ngModel)]="siteRenewalPriceKey"
          />
        </div>
      </div>
      <div class="mt-8 flex justify-center space-x-4">
        <button
          class="btn btn-primary"
          [disabled]="creatingProductVariant"
          (click)="openConfirmDialog()"
        >
          Save
        </button>
        <button
          class="btn btn-error"
          [routerLink]="'/admin/product/' + product.id"
        >
          Cancel
        </button>
      </div>
      @if (creatingProductVariant) {
        <div class="mt-4 flex justify-center">
          <span class="loading loading-spinner loading-md"></span>
        </div>
      }
      @if (productVariantCreated) {
        <div class="mt-4 flex justify-center">
          <p class="text-success">Product Variant created successfully</p>
        </div>
      }
      @if (errorCreatingProductVariant) {
        <div class="mt-4 flex justify-center">
          <p class="text-error">
            {{ errorCreatingProductVariant }}
          </p>
        </div>
      }
    </div>
  }
</div>

<dialog #confirmDialog class="modal">
  <div class="modal-box max-w-3xl p-8">
    <h2 class="text-2xl font-bold">Confirm Settings</h2>
    <h4 class="text-lg">
      Please confirm the settings below are correct for the new product variant
      you are creating.
    </h4>
    <table class="table my-4">
      <thead class="text-base">
        <tr>
          <th class="font-medium">Field</th>
          <th class="font-medium">Value</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Description</td>
          <td>{{ description }}</td>
        </tr>
        <tr>
          <td>Active</td>
          <td>{{ active }}</td>
        </tr>
        <tr>
          <td>Is Default</td>
          <td>{{ isDefault }}</td>
        </tr>
        <tr>
          <td>Duration (Months)</td>
          <td>{{ durationMonths }}</td>
        </tr>
        <tr>
          <td>Delivery Method</td>
          <td>{{ deliveryMethod }}</td>
        </tr>
        <tr>
          <td>Price Lock Level</td>
          <td>{{ priceLockLevel }}</td>
        </tr>
        <tr>
          <td>Variance Code</td>
          <td>{{ varianceCode }}</td>
        </tr>
        <tr>
          <td>Price</td>
          <td>{{ price }}</td>
        </tr>
        <tr>
          <td>Tax Code</td>
          <td>{{ taxCode }}</td>
        </tr>
        <tr>
          <td>Price Key</td>
          <td>{{ priceKey }}</td>
        </tr>
        <tr>
          <td>Renewal Price</td>
          <td>{{ renewalPrice }}</td>
        </tr>
        <tr>
          <td>Renewal Tax Code</td>
          <td>{{ renewalTaxCode }}</td>
        </tr>
        <tr>
          <td>Renewal Price Key</td>
          <td>{{ renewalPriceKey }}</td>
        </tr>
        <tr>
          <td>Site Price</td>
          <td>{{ sitePrice }}</td>
        </tr>
        <tr>
          <td>Site Tax Code</td>
          <td>{{ siteTaxCode }}</td>
        </tr>
        <tr>
          <td>Site Price Key</td>
          <td>{{ sitePriceKey }}</td>
        </tr>
        <tr>
          <td>Site Renewal Price</td>
          <td>{{ siteRenewalPrice }}</td>
        </tr>
        <tr>
          <td>Site Renewal Tax Code</td>
          <td>{{ siteRenewalTaxCode }}</td>
        </tr>
        <tr>
          <td>Site Renewal Price Key</td>
          <td>{{ siteRenewalPriceKey }}</td>
        </tr>
      </tbody>
    </table>
    <h4 class="text-lg text-warning">
      Are you sure you want to create this product?
    </h4>
    <div class="mt-4 flex justify-center space-x-4">
      <button class="btn btn-primary" (click)="createProductVariant()">
        Yes
      </button>
      <button class="btn btn-error" (click)="closeConfirmDialog()">No</button>
    </div>
  </div>
</dialog>
