import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  private sendLogUrl = environment.serverUrl + '/client-logs/log';

  private app = initializeApp(environment.firebaseConfig);
  private auth = getAuth(this.app);

  constructor(private http: HttpClient) {}

  debug(...args: any[]) {
    if (!args) return;
    if (!environment.production && environment.debug) {
      console.log(...args);
    }
    this.sendLog(args);
  }

  log(...args: any[]) {
    if (!args) return;
    if (!environment.production) {
      console.log(...args);
    }
    this.sendLog(args);
  }

  error(err: Error) {
    if (!err) return;
    if (!environment.production) {
      console.error(err);
    }
    this.sendLog([err.stack || err.message], true);
  }

  private idToken() {
    return new Observable<string>((observer) => {
      if (!this.auth.currentUser) {
        observer.error('No user signed in');
        observer.complete();
        return;
      }

      this.auth.currentUser
        .getIdToken()
        .then((token) => {
          observer.next(token);
        })
        .catch((err) => {
          observer.error(err);
        })
        .finally(() => {
          observer.complete();
        });
    });
  }

  private sendLog(args: any[], isError: boolean = false) {
    const message = this.buildLogMessage(...args);
    this.idToken().subscribe({
      next: (token) => {
        this.http
          .post(
            this.sendLogUrl,
            { message, is_error: isError },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .subscribe({
            error: (err) => {
              console.error('error sending log: ', err);
            },
          });
      },
      error: (err) => {
        // console.error('Error getting ID token: ', err);
        this.http
          .post(this.sendLogUrl, { message, is_error: isError })
          .subscribe({
            error: (err) => {
              console.error('error sending log: ', err);
            },
          });
      },
    });
  }

  private buildLogMessage(...args: any[]) {
    let message = '';
    for (const arg of args) {
      if (typeof arg === 'string') {
        message += arg;
      } else if (typeof arg === 'object') {
        message += JSON.stringify(arg);
      } else {
        message += arg;
      }
    }
    return message;
  }
}
