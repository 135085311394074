import { Component, Input } from '@angular/core';
import { Franchise } from 'src/app/services/franchises.service';
import { Tenant, TenantsService } from 'src/app/services/tenants.service';

@Component({
  selector: 'app-tenant-info',
  templateUrl: './tenant-info.component.html',
  styleUrl: './tenant-info.component.scss',
})
export class TenantInfoComponent {
  @Input() tenant!: Tenant;
  @Input() franchise?: Franchise;

  constructor(private tenantsService: TenantsService) {}

  tenantAddressValid(tenant: Tenant) {
    return this.tenantsService.tenantAddressValid(tenant);
  }
}
