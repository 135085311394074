import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductDetailsResolverResponse } from 'src/app/router/resolvers/product-details';
import { LoggerService } from 'src/app/services/logger.service';
import {
  ProductVariant,
  ProductWithVariants,
} from 'src/app/services/products.service';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent {
  product: ProductWithVariants | undefined;
  error: string = '';
  currentVariantIndex = -1;

  constructor(
    private loggerService: LoggerService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.loggerService.log('ProductDetailsComponent ngOnInit');
    this.loggerService.log(
      'this.route.snapshot.data: ',
      this.route.snapshot.data
    );
    const data = this.route.snapshot.data[
      'product'
    ] as ProductDetailsResolverResponse;

    if (data?.error) {
      this.loggerService.error(new Error(data.error));
      this.error = data.error;
      return;
    } else if (!data || !data.product_with_variants) {
      this.loggerService.error(new Error('No product data found in route'));
      this.error = 'No product data found';
      return;
    }
    this.product = data.product_with_variants;
  }

  get displayVariants(): ProductVariant[] {
    if (!this.product) return [];

    return this.product.variants.sort((a, b) => {
      if (a.is_default && !b.is_default) return -1;
      if (!a.is_default && b.is_default) return 1;

      if (a.price_lock_level != b.price_lock_level) {
        return a.price_lock_level - b.price_lock_level;
      }

      if (a.duration_months != b.duration_months) {
        return a.duration_months - b.duration_months;
      }

      return 0;
    });
  }

  setActiveVariant(index: number) {
    if (this.currentVariantIndex == index) {
      this.currentVariantIndex = -1;
      return;
    }
    this.currentVariantIndex = index;
  }
}
