import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// import { ProductResolverResponse } from 'src/app/router/resolvers/product';
import { Product, ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-add-product-variant',
  templateUrl: './add-product-variant.component.html',
  styleUrls: ['./add-product-variant.component.scss'],
})
export class AddProductVariantComponent {
  @ViewChild('confirmDialog') confirmDialog!: ElementRef<HTMLDialogElement>;

  loadingProduct = false;
  product?: Product;
  errorFetchingProduct = '';

  deliveryMethods = this.productsService.deliveryMethods.map((m) => ({
    value: m,
    display:
      m === 'DNL' ? 'DNL (download)' : m === 'XXX' ? 'XXX (physical)' : m,
  }));

  description = '';
  active = true;
  isDefault = false;
  varianceCode = '';
  deliveryMethod = '';
  durationMonths = 12;
  priceLockLevel = 3;
  priceKey = '';
  sitePriceKey = '';
  renewalPriceKey = '';
  siteRenewalPriceKey = '';
  taxCode = '';
  siteTaxCode = '';
  renewalTaxCode = '';
  siteRenewalTaxCode = '';
  price = 0;
  sitePrice = 0;
  renewalPrice = 0;
  siteRenewalPrice = 0;

  creatingProductVariant = false;
  productVariantCreated = false;
  errorCreatingProductVariant = '';

  constructor(
    private productsService: ProductsService,
    private route: ActivatedRoute
  ) {
    const id = this.route.snapshot.paramMap.get('id');
    if (!id) {
      console.error(new Error('No product ID found in route'));
      this.errorFetchingProduct = 'No product ID found';
      return;
    }
    this.getProduct(id);
  }

  getProduct(id: string) {
    this.loadingProduct = true;
    this.productsService.getProduct(id).subscribe({
      next: (res) => {
        this.loadingProduct = false;
        console.log('res: ', res);
        if (res.product) {
          this.product = res.product;
        } else {
          console.error(
            new Error('No product returned from server')
          );
          this.errorFetchingProduct = 'No product returned from server';
        }
      },
      error: (err) => {
        this.loadingProduct = false;
        console.error(err);
        this.errorFetchingProduct = err;
      },
    });
  }

  openConfirmDialog() {
    if (!this.validateInputs()) {
      return;
    }

    this.confirmDialog.nativeElement.showModal();
  }

  validateInputs() {
    this.errorCreatingProductVariant = '';

    if (!this.description || this.description.trim() === '') {
      this.errorCreatingProductVariant = 'Please enter a description';
      return false;
    }
    if (!this.durationMonths) {
      this.errorCreatingProductVariant = 'Please enter a duration in months';
      return false;
    }
    if (!this.deliveryMethod || this.deliveryMethod.trim() === '') {
      this.errorCreatingProductVariant = 'Please select a delivery method';
      return false;
    }
    if (!this.priceLockLevel) {
      this.errorCreatingProductVariant = 'Please enter a price lock level';
      return false;
    }
    if (!this.varianceCode || this.varianceCode.trim() === '') {
      this.errorCreatingProductVariant = 'Please enter a variance code';
      return false;
    }

    if (!this.price) {
      this.errorCreatingProductVariant = 'Please enter a price';
      return false;
    }
    if (!this.sitePrice) {
      this.errorCreatingProductVariant = 'Please enter a site price';
      return false;
    }
    if (!this.renewalPrice) {
      this.errorCreatingProductVariant = 'Please enter a renewal price';
      return false;
    }
    if (!this.siteRenewalPrice) {
      this.errorCreatingProductVariant = 'Please enter a site renewal price';
      return false;
    }

    if (!this.taxCode || this.taxCode.trim() === '') {
      this.errorCreatingProductVariant = 'Please enter a tax code';
      return false;
    }
    if (!this.siteTaxCode || this.siteTaxCode.trim() === '') {
      this.errorCreatingProductVariant = 'Please enter a site tax code';
      return false;
    }
    if (!this.renewalTaxCode || this.renewalTaxCode.trim() === '') {
      this.errorCreatingProductVariant = 'Please enter a renewal tax code';
      return false;
    }
    if (!this.siteRenewalTaxCode || this.siteRenewalTaxCode.trim() === '') {
      this.errorCreatingProductVariant = 'Please enter a site renewal tax code';
    }

    if (!this.priceKey || this.priceKey.trim() === '') {
      this.errorCreatingProductVariant = 'Please enter a price key';
      return false;
    }
    if (!this.sitePriceKey || this.sitePriceKey.trim() === '') {
      this.errorCreatingProductVariant = 'Please enter a site price key';
      return false;
    }
    if (!this.renewalPriceKey || this.renewalPriceKey.trim() === '') {
      this.errorCreatingProductVariant = 'Please enter a renewal price key';
      return false;
    }
    if (!this.siteRenewalPriceKey || this.siteRenewalPriceKey.trim() === '') {
      this.errorCreatingProductVariant =
        'Please enter a site renewal price key';
    }
    return true;
  }

  createProductVariant() {
    this.closeConfirmDialog();
    if (!this.product) {
      console.error(new Error('No product set'));
      this.errorCreatingProductVariant = 'No product set';
      return;
    }
    this.creatingProductVariant = true;
    this.productVariantCreated = false;
    this.errorCreatingProductVariant = '';

    this.productsService
      .createProductVariant({
        product_id: this.product.id,
        description: this.description,
        active: this.active,
        is_default: this.isDefault,
        variance_code: this.varianceCode,
        delivery_method: this.deliveryMethod,
        duration_months: this.durationMonths,
        price_lock_level: this.priceLockLevel,
        price_key: this.priceKey,
        site_price_key: this.sitePriceKey,
        renewal_price_key: this.renewalPriceKey,
        site_renewal_price_key: this.siteRenewalPriceKey,
        tax_code: this.taxCode,
        site_tax_code: this.siteTaxCode,
        renewal_tax_code: this.renewalTaxCode,
        site_renewal_tax_code: this.siteRenewalTaxCode,
        price: this.price,
        site_price: this.sitePrice,
        renewal_price: this.renewalPrice,
        site_renewal_price: this.siteRenewalPrice,
      })
      .subscribe({
        next: (response) => {
          console.log('response: ', response);
          this.creatingProductVariant = false;
          this.productVariantCreated = true;
          this.errorCreatingProductVariant = '';
        },
        error: (error) => {
          console.error(error);
          this.creatingProductVariant = false;
          this.errorCreatingProductVariant = 'Error creating product variant';
        },
      });
  }

  closeConfirmDialog() {
    this.confirmDialog.nativeElement.close();
  }
}
