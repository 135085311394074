import { Component } from '@angular/core';

@Component({
  selector: 'app-users-icon',
  templateUrl: './users-icon.component.html',
  styleUrl: './users-icon.component.scss'
})
export class UsersIconComponent {

}
