import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { merge } from 'rxjs';
import { InvoiceDetailsResolverResponse } from 'src/app/router/resolvers/invoice-details';
import {
  InvoiceDetails,
  InvoicesService,
} from 'src/app/services/invoices.service';
import {
  DesktopLicenseWithProduct,
  LicensesService,
} from 'src/app/services/licenses.service';
import { LoggerService } from 'src/app/services/logger.service';
import {
  ProductVariant,
  ProductsService,
} from 'src/app/services/products.service';

type LicenseOption = {
  license: DesktopLicenseWithProduct;
  action?: LicenseAction;
};

type LicenseAction = RemoveLicense | UpdateLicenseDuration;

type RemoveLicense = {
  action: 'remove';
};

type UpdateLicenseDuration = {
  action: 'duration';
  new_product_variant: ProductVariant;
};

@Component({
  selector: 'app-edit-invoice-page',
  templateUrl: './edit-invoice-page.component.html',
  styleUrls: ['./edit-invoice-page.component.scss'],
})
export class EditInvoicePageComponent {
  @ViewChild('editLicenseModal', { static: false }) editLicenseModal:
    | ElementRef<HTMLDialogElement>
    | undefined;

  invoice?: InvoiceDetails;
  errorFetchingInvoice = '';
  licenseList: LicenseOption[] = [];

  editingLicense: LicenseOption | null = null;
  loadingDurationOptions = false;
  durationOptions: ProductVariant[] = [];
  selectedDurationOption: ProductVariant | null = null;

  savingChanges = false;
  errorSavingChanges = '';
  changesSaved = false;

  constructor(
    private invoicesService: InvoicesService,
    private licensesService: LicensesService,
    private loggerService: LoggerService,
    private productsService: ProductsService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    const data = this.route.snapshot.data[
      'invoice'
    ] as InvoiceDetailsResolverResponse;
    if (data?.error) {
      this.loggerService.error(new Error(data.error));
      this.errorFetchingInvoice = 'Error fetching invoice';
      return;
    } else if (!data.invoice_details) {
      this.loggerService.log('No invoice data');
      this.errorFetchingInvoice = 'Error fetching invoice';
      return;
    }

    this.invoice = data.invoice_details;
    this.initializeLicenseList(this.invoice);
  }

  initializeLicenseList(invoice: InvoiceDetails) {
    this.licenseList = invoice.desktop_licenses.map((license) => {
      return { license };
    });

    for (const license of invoice.not_renewing_licenses) {
      this.licenseList.push({ license, action: { action: 'remove' } });
    }
  }

  get hasChanges() {
    return this.licenseList.some((license) => {
      if (license.action?.action === 'duration') {
        return true;
      }

      if (
        license.action?.action === 'remove' &&
        license.license.desktop_license.will_renew
      ) {
        return true;
      }

      if (
        license.action == undefined &&
        !license.license.desktop_license.will_renew
      ) {
        return true;
      }

      return false;
    });
  }

  getLicenseDuration(license: LicenseOption) {
    let months =
      license.action?.action === 'duration'
        ? license.action.new_product_variant.duration_months
        : license.license.product_variant.duration_months;
    return this.getDurationString(months);
  }

  getDurationString(months: number) {
    if (months % 12 === 0) {
      return months === 12 ? '1 year' : `${months / 12} years`;
    } else {
      return months === 1 ? '1 month' : `${months} months`;
    }
  }

  getPrice(license: LicenseOption) {
    if (license.action && license.action.action === 'duration') {
      return license.action.new_product_variant.renewal_price;
    }
    return license.license.product_variant.renewal_price;
  }

  removeLicense(license: LicenseOption) {
    this.changesSaved = false;
    license.action = { action: 'remove' };
  }

  undoRemoveLicense(license: LicenseOption) {
    this.changesSaved = false;
    license.action = undefined;
  }

  showDurationOptions(license: LicenseOption) {
    this.editingLicense = license;
    this.durationOptions = [];
    // show the editing modal
    this.editLicenseModal?.nativeElement.showModal();
    this.loadingDurationOptions = true;

    // set initial duration option to current license variant
    this.selectedDurationOption = license.license.product_variant;

    // fetch duration options for the license
    this.productsService
      .getDurationOptions(license.license.product_variant)
      .subscribe({
        next: (options) => {
          this.loggerService.log('Duration options:', options);
          // sort response by duration
          this.durationOptions = options.product_variants.sort(
            (a, b) => a.duration_months - b.duration_months
          );
        },
        error: (err) => {
          this.loggerService.error(err);
        },
        complete: () => {
          this.loadingDurationOptions = false;
        },
      });
  }

  updateDuration() {
    this.changesSaved = false;
    // check that the license has changed
    if (
      this.editingLicense &&
      this.selectedDurationOption &&
      this.selectedDurationOption.id !==
        this.editingLicense.license.product_variant.id
    ) {
      this.editingLicense.action = {
        action: 'duration',
        new_product_variant: this.selectedDurationOption,
      };
    }

    // reset editing license
    this.editingLicense = null;
    this.selectedDurationOption = null;
    this.editLicenseModal?.nativeElement.close();
  }

  returnToInvoice(invoice: InvoiceDetails) {
    // check if there are changes
    if (this.hasChanges) {
      if (!confirm('Are you sure you want to leave without saving changes?')) {
        // do nothing
        return;
      }
    }

    // navigate back to invoice page
    this.router.navigate(['/invoice', invoice.desktop_invoice.id]);
  }

  saveChanges(invoice: InvoiceDetails) {
    this.changesSaved = false;
    this.savingChanges = true;
    this.errorSavingChanges = '';

    const observables = [];
    for (const license of this.licenseList) {
      if (
        license.action?.action === 'remove' &&
        license.license.desktop_license.will_renew
      ) {
        // set will renew to false
        license.license.desktop_license.will_renew = false;
        observables.push(
          this.licensesService.updateLicenseWillRenew(
            license.license.desktop_license
          )
        );
      }

      if (
        license.action == undefined &&
        !license.license.desktop_license.will_renew
      ) {
        // set will renew to true
        license.license.desktop_license.will_renew = true;
        observables.push(
          this.licensesService.updateLicenseWillRenew(
            license.license.desktop_license
          )
        );
      }

      if (license.action?.action === 'duration') {
        observables.push(
          this.invoicesService.updateProductVariantForRenewal({
            invoice_id: invoice.desktop_invoice.id,
            desktop_license_id: license.license.desktop_license.id,
            new_product_variant_id: license.action.new_product_variant.id,
          })
        );
      }
    }

    merge(...observables).subscribe({
      next: (response) => {
        this.loggerService.log('Response:', response);
      },
      error: (err) => {
        this.loggerService.error(err);
        this.errorSavingChanges =
          'Unexpected error occurred. Please try again.';
      },
      complete: () => {
        this.loggerService.log('All changes saved');
        // reload initial invoice details
        this.invoicesService
          .getInvoiceDetails(invoice.desktop_invoice.id)
          .subscribe({
            next: (response) => {
              this.savingChanges = false;
              this.changesSaved = true;
              this.invoice = response.invoice_details;
              this.initializeLicenseList(this.invoice);
            },
            error: (err) => {
              this.loggerService.error(err);
            },
          });
      },
    });
  }
}

