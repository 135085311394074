import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostBinding, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AcceptJSService } from 'src/app/services/accept-js.service';
import {
  GroupedLicenseInvoiceDetails,
  InvoiceDetails,
  InvoicesService,
} from 'src/app/services/invoices.service';
import { DesktopLicenseWithProduct } from 'src/app/services/licenses.service';
import {
  AuthorizeNetSavedPaymentMethod,
  PaymentsService,
  DesktopPayment,
} from 'src/app/services/payments.service';
import { environment } from 'src/environments/environment';

type GroupedLicenses = {
  license: DesktopLicenseWithProduct;
  count: number;
};

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrl: './invoice.component.scss',
})
export class InvoiceComponent {
  @HostBinding('class') class = 'flex justify-center w-full';
  @Input({ required: true }) invoiceId!: string;
  @Input({ required: true }) tenantId!: string;

  invoice?: InvoiceDetails;
  loadingInvoice = false;
  errorFetchingInvoice = '';

  groupedLicenseInvoiceDetails: GroupedLicenseInvoiceDetails[] = [];
  groupedNotRenewingLicenses: GroupedLicenses[] = [];

  // paymentProcessing = false;
  paymentProcessing = false;
  paymentError = '';
  paymentSuccess = false;

  savedPaymentMethods?: AuthorizeNetSavedPaymentMethod[];
  loadingSavedPaymentMethods = false;
  errorFetchingSavedPaymentMethods = '';
  selectedSavedPaymentMethod?: AuthorizeNetSavedPaymentMethod;

  constructor(
    private acceptJSService: AcceptJSService,
    private invoiceService: InvoicesService,
    private paymentsService: PaymentsService,
    private router: Router
  ) {}

  get authNetAPILoginID() {
    return environment.authorizeNet.apiLoginID;
  }

  get authNetClientKey() {
    return environment.authorizeNet.clientKey;
  }

  ngOnInit() {
    window.acceptJSResponseHandler = this.acceptJSResponseHandler.bind(this);

    console.log('invoice.component invoiceId: ', this.invoiceId);
    console.log('invoice.component tenantId: ', this.tenantId);

    if (!this.invoiceId) {
      this.errorFetchingInvoice = 'Error fetching invoice';
      const err = new Error('No invoice ID found');
      console.error(err);
      return;
    }

    if (!this.tenantId) {
      this.errorFetchingInvoice = 'Error fetching invoice';
      const err = new Error('No tenant ID found');
      console.error(err);
      return;
    }

    this.loadInvoice(this.invoiceId, this.tenantId);
  }

  ngOnDestroy() {
    this.acceptJSService.removeScript();
  }

  loadInvoice(invoiceId: string, tenantId: string) {
    this.loadingInvoice = true;

    this.invoiceService.getInvoiceDetails(invoiceId).subscribe({
      next: (res) => {
        this.loadingInvoice = false;
        console.log('Invoice details: ', res);
        this.invoice = res.invoice_details;

        if (this.invoice.desktop_invoice.completed) {
          // payment already completed, navigate to receipt page
          // do not trigger location change event, so that the back button works
          this.router.navigate(['receipt', invoiceId], {
            skipLocationChange: true,
          });
          return;
        }

        this.groupedLicenseInvoiceDetails =
          this.invoiceService.groupLicenseDetailsByProduct(
            this.invoice.license_details
          );
        this.groupedNotRenewingLicenses =
          this.invoiceService.groupLicensesByProduct(
            this.invoice.not_renewing_licenses
          );

        // inject script asynchronously
        this.acceptJSService.injectScript();

        // load saved payment methods
        this.loadSavedPaymentMethods(tenantId);
      },
      error: (err) => {
        this.loadingInvoice = false;
        console.error(err);
        if (err instanceof HttpErrorResponse) {
          if (err.status === 404) {
            this.errorFetchingInvoice = 'Invoice not found';
          } else {
            this.errorFetchingInvoice = err.message;
          }
        } else {
          this.errorFetchingInvoice = err;
        }
      },
    });
  }

  loadSavedPaymentMethods(tenantId: string) {
    this.paymentsService.getSavedPaymentMethods(tenantId).subscribe({
      next: (res) => {
        console.log('Saved payment methods: ', res);
        this.savedPaymentMethods = res.payment_profiles;
        this.savedPaymentMethods.sort((a, b) => {
          if (a.is_default) {
            return -1;
          } else if (b.is_default) {
            return 1;
          } else {
            return 0;
          }
        });
      },
      error: (err) => {
        console.error(err);
        this.errorFetchingSavedPaymentMethods = err;
      },
    });
  }

  getDuration(months: number) {
    if (months % 12 === 0) {
      return months === 12 ? '1 year' : `${months / 12} years`;
    } else {
      return months === 1 ? '1 month' : `${months} months`;
    }
  }

  getLicensePrice(license: DesktopLicenseWithProduct) {
    return this.invoiceService.getLicensePrice(license);
  }

  acceptJSResponseHandler(response: any) {
    console.log('accept payment response: ', response);

    if (response.messages.resultCode === 'Ok') {
      this.paymentProcessing = true;
      console.log('submitting entered payment details');
      this.paymentsService
        .processAuthorizeNetPayment({
          opaque_data: response.opaqueData,
          invoice_id: this.invoice!.desktop_invoice.id,
        })
        .subscribe({
          next: (res) => {
            console.log('Payment processed:', res);
            this.paymentProcessing = false;
            this.handlePaymentResponse(res.desktop_payment);
          },
          error: (err) => {
            console.error(err);
            this.paymentProcessing = false;
            this.handlePaymentError(err);
          },
        });
    }
  }

  payWithSelectedMethod() {
    if (!this.selectedSavedPaymentMethod) {
      console.error(new Error('No selected saved payment method'));
      return;
    }

    this.paymentProcessing = true;

    this.paymentsService
      .processPaymentWithSavedMethod({
        invoice_id: this.invoice!.desktop_invoice.id,
        saved_payment_method_id: this.selectedSavedPaymentMethod.id,
      })
      .subscribe({
        next: (res) => {
          console.log('Payment processed: ', res);
          this.paymentProcessing = false;
          this.handlePaymentResponse(res.desktop_payment);
        },
        error: (err) => {
          console.error(err);
          this.paymentProcessing = false;
          this.handlePaymentError(err);
        },
      });
  }

  handlePaymentResponse(response: DesktopPayment) {
    console.log('payment response: ', response);

    if (response.status === 'completed') {
      this.paymentSuccess = true;
      this.paymentError = '';
    } else if (response.status === 'failed') {
      this.paymentError = 'Payment failed. Please try again.';
    } else if (response.status === 'declined') {
      this.paymentError = 'Payment method was declined.';
    } else if (response.status === 'held') {
      this.paymentError = 'Payment is being held. Please contact support.';
    } else {
      this.paymentError = 'Unexpected error processing payment.';
    }
  }

  handlePaymentError(err: HttpErrorResponse) {
    if (err instanceof HttpErrorResponse) {
      if (err.error && err.error.error) {
        if (err.error.error === 'unable to attempt payment') {
          // payment was not processed nor attempted. Likely a server error
          this.paymentError =
            'Unable to attempt payment. Please contact support.';
          return;
        } else if (err.error.error === 'payment post process failed') {
          // payment was attempted, but some post process failed
          this.paymentError =
            'Payment was submitted, but failed to update license renewal. Please contact support.';
          return;
        }
      }
    }
    this.paymentError = 'Unexpected error processing payment.';
  }
}
