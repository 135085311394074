import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';
import { catchError, mergeMap, of, throwError } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { LoggerService } from 'src/app/services/logger.service';
import { Tenant, TenantsService } from 'src/app/services/tenants.service';

export type TenantResolverResponse = {
  tenant?: Tenant;
  error?: string;
};

export const currentTenantDetailsResolver: ResolveFn<TenantResolverResponse> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const authService = inject(AuthService);
  const tenantsService = inject(TenantsService);
  const loggerService = inject(LoggerService);

  return authService.authReady.pipe(
    mergeMap((ready) => {
      loggerService.log('AuthService: Auth ready: ', ready);
      if (!ready) {
        loggerService.error(new Error('Error starting auth service'));
        return throwError(() => new Error('User not authenticated'));
      }
      return tenantsService.getCurrentTenantDetails().pipe(
        mergeMap((tenant) => {
          return of(tenant as TenantResolverResponse);
        }),
        catchError((err) => {
          loggerService.error(new Error(err));
          return of({ error: 'Error getting current tenant details' });
        })
      );
    })
  );
};
