<svg
  width="48"
  height="32"
  viewBox="0 0 48 32"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g id="dinnerclub-color-large">
    <rect id="card_bg" width="48" height="32" rx="4" fill="white" />
    <path
      id="card_bg-2"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 0H44C46.2091 0 48 1.79086 48 4V28C48 30.2091 46.2091 32 44 32H4C1.79086 32 0 30.2091 0 28V4C0 1.79086 1.79086 0 4 0ZM46 4C46 2.89543 45.1046 2 44 2H4C2.89543 2 2 2.89543 2 4V28C2 29.1046 2.89543 30 4 30H44C45.1046 30 46 29.1046 46 28V4Z"
      fill="#0779BE"
    />
    <g id="dinner_club">
      <path
        id="Shape"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.13 20.73C13.13 21.38 12.75 23 10.44 23H8.63V22.83C8.63881 22.8291 8.64751 22.8282 8.65612 22.8273C9.02893 22.7893 9.22 22.7698 9.22 22.33V19.18C9.22 18.62 8.92 18.62 8.63 18.62V18.45H10.52C11.173 18.3861 11.8233 18.5947 12.3174 19.0263C12.8115 19.4579 13.1056 20.0744 13.13 20.73ZM10.37 18.69C10.2001 18.6802 10.0299 18.6802 9.86 18.69L9.82 22.23C9.82 22.73 10.18 22.79 10.5 22.79C11.0252 22.8104 11.5333 22.6014 11.8923 22.2175C12.2512 21.8336 12.4256 21.3126 12.37 20.79C12.3974 20.2425 12.1989 19.7077 11.8208 19.3107C11.4427 18.9137 10.9182 18.6893 10.37 18.69Z"
        fill="#211E1F"
      />
      <path
        id="Path"
        d="M14.75 23V22.83H14.64C14.45 22.83 14.31 22.83 14.31 22.61V20.11V20.02C14.2705 20.011 14.2295 20.011 14.19 20.02C13.9612 20.1126 13.7275 20.1928 13.49 20.26V20.36C13.73 20.49 13.83 20.53 13.83 20.82V22.58C13.83 22.8 13.7 22.8 13.52 22.8H13.39V23H14.79H14.75Z"
        fill="#211E1F"
      />
      <path
        id="Path_2"
        d="M14.05 19.06C14.1366 19.06 14.2195 19.025 14.2799 18.9628C14.3402 18.9006 14.3727 18.8166 14.37 18.73C14.3646 18.5572 14.2229 18.4199 14.05 18.42C13.8732 18.42 13.73 18.5633 13.73 18.74C13.73 18.9167 13.8732 19.06 14.05 19.06Z"
        fill="#211E1F"
      />
      <path
        id="Path_3"
        d="M18.2 22.87H18.09C17.9 22.87 17.76 22.87 17.76 22.65V21C17.76 20.5 17.57 20.09 17.01 20.09C16.5925 20.124 16.1983 20.2965 15.89 20.58V20.16C15.89 20.11 15.89 20.09 15.89 20.09C15.6092 20.209 15.3221 20.3125 15.03 20.4V20.53C15.33 20.65 15.41 20.72 15.41 20.96V22.68C15.41 22.9 15.28 22.9 15.09 22.9H14.98V23H16.37V22.83H16.25C16.07 22.83 15.93 22.83 15.93 22.61V20.73C16.1488 20.5615 16.4069 20.4514 16.68 20.41C16.8376 20.3886 16.9965 20.4392 17.1128 20.5477C17.229 20.6563 17.2904 20.8113 17.28 20.97V22.65C17.28 22.87 17.15 22.87 16.97 22.87H16.85V23H18.2V22.87Z"
        fill="#211E1F"
      />
      <path
        id="Shape_2"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.63 22.4C20.3723 22.83 19.9202 23.1065 19.42 23.14C19.069 23.1322 18.7357 22.9838 18.4951 22.7282C18.2544 22.4725 18.1265 22.1309 18.14 21.78C18.14 20.5 19 20.12 19.45 20.12C19.7321 20.0916 20.0129 20.1843 20.2227 20.375C20.4325 20.5657 20.5514 20.8365 20.55 21.12V21.24H20.49H18.66C18.6489 21.3664 18.6489 21.4936 18.66 21.62C18.6369 22.2019 19.0792 22.6973 19.66 22.74C19.9993 22.7212 20.3167 22.5661 20.54 22.31L20.63 22.4ZM19.42 20.27C19.4282 20.2698 19.4364 20.2698 19.4446 20.27H19.42ZM19.4517 20.2702C19.4493 20.2701 19.447 20.2701 19.4446 20.27H19.47C19.4639 20.27 19.4578 20.2701 19.4517 20.2702ZM19.4517 20.2702C19.5969 20.2753 19.7347 20.3378 19.8344 20.4446C19.9413 20.5592 19.9943 20.7139 19.98 20.87C19.98 20.95 19.98 21.03 19.8 21.03H18.72C18.7988 20.5672 19.0618 20.2789 19.4517 20.2702Z"
        fill="#211E1F"
      />
      <path
        id="Path_4"
        d="M20.92 22.87H20.74V23H22.43V22.83H22.07C21.88 22.83 21.75 22.83 21.75 22.61V21.12C21.7528 20.8454 21.9576 20.6149 22.23 20.58C22.46 20.58 22.46 20.74 22.66 20.74C22.744 20.7375 22.8235 20.7009 22.88 20.6387C22.9366 20.5764 22.9655 20.4939 22.96 20.41C22.9601 20.3136 22.9205 20.2214 22.8505 20.1552C22.7805 20.0889 22.6862 20.0545 22.59 20.06C22.2 20.06 21.92 20.48 21.76 20.73V20.15C21.76 20.08 21.76 20.06 21.7 20.06C21.64 20.06 21.38 20.23 20.9 20.43V20.53C21 20.53 21.24 20.62 21.24 20.82V22.65C21.23 22.87 21.1 22.87 20.92 22.87Z"
        fill="#211E1F"
      />
      <path
        id="Path_5"
        d="M24.07 22.93C23.6349 22.9063 23.2844 22.5644 23.25 22.13H23.13L23.21 22.87C23.4502 23.011 23.7217 23.09 24 23.1C24.77 23.1 25.12 22.64 25.12 22.21C25.12 21.21 23.61 21.42 23.61 20.66C23.6175 20.5341 23.6763 20.4168 23.7727 20.3355C23.8691 20.2542 23.9947 20.2161 24.12 20.23C24.492 20.2129 24.8085 20.4983 24.83 20.87H25L24.95 20.21C24.7211 20.091 24.4679 20.026 24.21 20.02C23.964 19.9891 23.7163 20.0621 23.5265 20.2216C23.3367 20.381 23.222 20.6124 23.21 20.86C23.21 21.86 24.63 21.66 24.63 22.43C24.6423 22.5771 24.5847 22.7213 24.4747 22.8196C24.3646 22.9179 24.2147 22.9588 24.07 22.93Z"
        fill="#211E1F"
      />
      <path
        id="Path_6"
        d="M30.86 22.79L31.01 21.84H30.86C30.7614 22.488 30.2054 22.9676 29.55 22.97C28.3939 22.8743 27.5294 21.8672 27.61 20.71C27.5472 20.186 27.7113 19.6601 28.0608 19.2647C28.4104 18.8692 28.9122 18.642 29.44 18.64C30.1214 18.5512 30.7468 19.0291 30.84 19.71H31L30.93 18.78C30.4438 18.5765 29.9263 18.4582 29.4 18.43C28.0856 18.3905 26.9842 19.4161 26.93 20.73C26.8906 21.3982 27.1417 22.0508 27.6188 22.5203C28.0959 22.9897 28.7525 23.2302 29.42 23.18C29.9216 23.1497 30.4117 23.017 30.86 22.79Z"
        fill="#211E1F"
      />
      <path
        id="Path_7"
        d="M32.63 23V22.83H32.52C32.33 22.83 32.19 22.83 32.19 22.61V18.11V18.02C32.19 18.02 32.12 18.02 32.05 18.09C31.8363 18.2047 31.6119 18.2985 31.38 18.37V18.48C31.62 18.55 31.72 18.56 31.72 18.99V22.61C31.72 22.83 31.59 22.83 31.41 22.83H31.28V23H32.68H32.63Z"
        fill="#211E1F"
      />
      <path
        id="Path_8"
        d="M33 20.69V22.36C32.9769 22.5608 33.0417 22.7619 33.1777 22.9114C33.3138 23.0609 33.5079 23.1442 33.71 23.14C34.1521 23.1093 34.5664 22.9128 34.87 22.59V23.12C35.178 23.028 35.4921 22.9578 35.81 22.91V22.8H35.6C35.49 22.8 35.41 22.8 35.41 22.68V20.28C35.41 20.21 35.41 20.16 35.41 20.16C35.41 20.16 34.98 20.21 34.41 20.25V20.37C34.64 20.43 34.95 20.51 34.95 20.63V22.39C34.7354 22.5959 34.4635 22.7319 34.17 22.78C33.6 22.78 33.6 22.22 33.6 22.03V20.35C33.6 20.21 33.6 20.16 33.53 20.16L32.69 20.21V20.33C33 20.36 33 20.52 33 20.69Z"
        fill="#211E1F"
      />
      <path
        id="Shape_3"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M36.19 21.8V19C36.19 18.6143 36.097 18.5912 35.8393 18.5273C35.8298 18.5249 35.82 18.5225 35.81 18.52V18.41C36.0432 18.3423 36.268 18.2484 36.48 18.13L36.63 18.06C36.68 18.06 36.68 18.15 36.68 18.15V20.54C36.9494 20.2731 37.3026 20.1071 37.68 20.07C38.0244 20.0852 38.3477 20.2397 38.5759 20.498C38.8041 20.7564 38.9175 21.0964 38.89 21.44C38.9241 22.3403 38.2296 23.1015 37.33 23.15C37.0157 23.1482 36.7066 23.0692 36.43 22.92L36.23 23.07L36.1 23C36.1619 22.603 36.192 22.2018 36.19 21.8ZM37.33 20.38C37.093 20.4061 36.8731 20.5161 36.71 20.69L36.66 22.27C36.6753 22.6335 36.9665 22.9247 37.33 22.94C38.04 22.94 38.33 22.25 38.33 21.67C38.3666 21.0503 37.9392 20.4991 37.33 20.38Z"
        fill="#211E1F"
      />
      <path
        id="Path_9"
        d="M9.13998 24.46H8.61998V24.6H8.68998C8.81998 24.6 8.92998 24.6 8.93998 24.79V26.64C8.93998 26.82 8.81998 26.83 8.68998 26.83H8.59998V27H9.11998H9.67998V26.87H9.64998C9.51998 26.87 9.39998 26.87 9.39998 26.68V24.77C9.39998 24.59 9.51998 24.58 9.64998 24.58H9.71998V24.44L9.13998 24.46Z"
        fill="#211E1F"
      />
      <path
        id="Path_10"
        d="M12.25 24.46H11.75V24.6H11.84C11.95 24.6 12.13 24.6 12.14 24.92V26.22L10.58 24.47C10.46 24.47 10.36 24.47 10.25 24.47H9.84001V24.61H9.90001C9.98129 24.6073 10.0602 24.6377 10.1186 24.6943C10.1771 24.7508 10.2101 24.8287 10.21 24.91V26.41C10.21 26.69 10.14 26.84 9.90001 26.84H9.82001V27H10.31H10.76V26.87H10.68C10.42 26.87 10.37 26.78 10.36 26.47V25L12.16 27H12.33C12.33 26.89 12.33 26.77 12.33 26.65V25C12.33 24.59 12.49 24.57 12.63 24.57H12.7V24.43L12.25 24.46Z"
        fill="#211E1F"
      />
      <path
        id="Path_11"
        d="M15.15 24.33C15.15 24.42 15.07 24.42 14.97 24.42H13.12C13.03 24.42 12.97 24.42 12.94 24.33H12.83C12.8353 24.4266 12.8353 24.5234 12.83 24.62C12.83 24.73 12.83 24.83 12.83 24.92H12.97C13.03 24.66 13.03 24.61 13.29 24.61H13.85V26.49C13.85 26.76 13.77 26.76 13.57 26.77H13.49V27H14.09H14.66V26.87H14.58C14.41 26.87 14.3 26.87 14.3 26.58V24.65H14.86C15.09 24.65 15.1 24.84 15.11 24.98L15.24 24.93V24.64C15.24 24.55 15.24 24.45 15.24 24.35H15.14L15.15 24.33Z"
        fill="#211E1F"
      />
      <path
        id="Path_12"
        d="M17.27 26.32C17.17 26.72 16.99 26.74 16.57 26.74C16.34 26.74 16.15 26.74 16.15 26.52V25.71H16.56C16.8 25.71 16.8 25.84 16.82 26.05H16.95C16.95 25.91 16.95 25.78 16.95 25.65C16.95 25.52 16.95 25.37 16.95 25.23H16.81C16.81 25.42 16.75 25.52 16.55 25.53H16.14V24.63H16.62C16.98 24.63 17.01 24.8 17.03 25.03H17.15C17.15 24.93 17.15 24.79 17.15 24.67V24.47H16.24H15.36V24.61H15.42C15.55 24.61 15.66 24.61 15.67 24.8V26.65C15.67 26.83 15.55 26.84 15.42 26.84H15.36V27H16.3H17.3C17.3 26.82 17.38 26.63 17.42 26.44H17.31L17.27 26.32Z"
        fill="#211E1F"
      />
      <path
        id="Shape_4"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.03 25.71L19.63 26.6C19.6761 26.7647 19.8291 26.8763 20 26.87V27L19.61 26.94H19.27C18.9969 26.5778 18.7496 26.1969 18.53 25.8H18.32V26.6C18.33 26.87 18.43 26.87 18.6 26.87H18.66V27H18.05H17.54V26.81H17.62C17.75 26.81 17.86 26.75 17.86 26.63V24.83C17.86 24.58 17.73 24.58 17.62 24.58H17.54V24.44H18.09H18.66C19.1 24.44 19.52 24.57 19.52 25.08C19.5132 25.375 19.3142 25.6308 19.03 25.71ZM18.47 25.64C18.82 25.64 19 25.52 19 25.1C19.0005 24.9707 18.9466 24.8472 18.8514 24.7597C18.7563 24.6722 18.6287 24.6288 18.5 24.64H18.31V25.64H18.47Z"
        fill="#211E1F"
      />
      <path
        id="Path_13"
        d="M22.93 24.58V24.44H22.49H21.99V24.58H22.08C22.19 24.58 22.37 24.58 22.38 24.9V26.2L20.8 24.45C20.68 24.45 20.58 24.45 20.47 24.45H20.06V24.59H20.12C20.2013 24.5873 20.2802 24.6177 20.3386 24.6743C20.3971 24.7308 20.43 24.8087 20.43 24.89V26.38C20.43 26.67 20.36 26.82 20.12 26.82H20.06V27H20.55H21V26.87H20.92C20.66 26.87 20.61 26.78 20.61 26.47V25L22.4 27H22.57C22.57 26.89 22.57 26.77 22.57 26.65V25C22.57 24.59 22.73 24.57 22.87 24.57H22.94L22.93 24.58Z"
        fill="#211E1F"
      />
      <path
        id="Shape_5"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.0438 26.4165C25.0867 26.5072 25.13 26.599 25.13 26.68C25.1302 26.7585 25.1722 26.831 25.2402 26.8701C25.3083 26.9093 25.392 26.9093 25.46 26.87V27H24.98H24.44V26.87H24.49C24.58 26.87 24.7 26.85 24.7 26.78C24.6867 26.6942 24.6632 26.6102 24.63 26.53L24.49 26.13H23.71L23.6 26.49C23.5676 26.5782 23.5409 26.6684 23.52 26.76C23.52 26.87 23.66 26.87 23.77 26.87H23.83V27H23.4H23.02V26.82C23.1578 26.8215 23.28 26.7319 23.32 26.6L23.88 24.97C23.9372 24.8235 23.9839 24.6731 24.02 24.52C24.1358 24.4839 24.2467 24.4335 24.35 24.37V24.46L25 26.32C25.0132 26.3517 25.0285 26.384 25.0438 26.4165ZM24 24.89L23.69 25.84H24.31L24 24.89Z"
        fill="#211E1F"
      />
      <path
        id="Path_14"
        d="M27.46 24.33C27.46 24.42 27.38 24.42 27.28 24.42H25.44C25.35 24.42 25.28 24.42 25.26 24.33H25.14C25.1503 24.4264 25.1503 24.5236 25.14 24.62C25.14 24.73 25.14 24.83 25.14 24.93H25.28C25.33 24.67 25.33 24.62 25.59 24.62H26.16V26.5C26.16 26.77 26.07 26.77 25.88 26.78H25.79V27H26.39H26.97V26.87H26.89C26.72 26.87 26.61 26.87 26.61 26.58V24.65H27.18C27.4 24.65 27.41 24.84 27.42 24.98L27.55 24.93C27.5448 24.8334 27.5448 24.7366 27.55 24.64C27.55 24.55 27.55 24.45 27.55 24.35H27.45L27.46 24.33Z"
        fill="#211E1F"
      />
      <path
        id="Path_15"
        d="M28.22 24.46H27.69V24.6H27.75C27.88 24.6 28 24.6 28 24.79V26.64C28 26.82 27.88 26.83 27.75 26.83H27.69V27H28.21H28.77V26.87H28.7C28.57 26.87 28.46 26.87 28.46 26.68V24.77C28.46 24.59 28.57 24.58 28.7 24.58H28.77V24.44L28.22 24.46Z"
        fill="#211E1F"
      />
      <path
        id="Shape_6"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.2436 24.7737C29.4918 24.5255 29.8291 24.3873 30.18 24.39C30.531 24.3564 30.88 24.4714 31.1422 24.707C31.4045 24.9427 31.556 25.2774 31.56 25.63C31.574 25.9957 31.4356 26.3509 31.1778 26.6106C30.9199 26.8703 30.5658 27.0114 30.2 27C29.8477 27.0195 29.5032 26.8916 29.249 26.6469C28.9948 26.4022 28.8539 26.0628 28.86 25.71C28.8573 25.3591 28.9955 25.0218 29.2436 24.7737ZM31.03 25.71C31.03 25.19 30.75 24.58 30.18 24.58C29.57 24.58 29.39 25.12 29.39 25.59C29.39 26.22 29.57 26.81 30.24 26.81C30.91 26.81 31.03 26.23 31.03 25.71Z"
        fill="#211E1F"
      />
      <path
        id="Path_16"
        d="M34.5 24.58V24.44H34.07H33.57V24.58H33.66C33.76 24.58 33.95 24.58 33.95 24.9V26.2L32.39 24.45C32.27 24.45 32.17 24.45 32.07 24.45H31.66V24.59H31.72C31.8004 24.5872 31.8783 24.6179 31.9352 24.6748C31.9921 24.7316 32.0228 24.8096 32.02 24.89V26.39C32.02 26.67 31.95 26.82 31.72 26.82H31.66V27H32.14H32.6V26.87H32.53C32.26 26.87 32.22 26.78 32.21 26.47V25L34 27H34.16C34.1497 26.8836 34.1497 26.7664 34.16 26.65V25C34.16 24.59 34.31 24.57 34.46 24.57H34.53L34.5 24.58Z"
        fill="#211E1F"
      />
      <path
        id="Shape_7"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M36.57 26.32C36.6073 26.4421 36.6507 26.5622 36.7 26.68C36.7034 26.7443 36.7361 26.8035 36.7888 26.8406C36.8415 26.8777 36.9083 26.8885 36.97 26.87V27H36.48H35.95V26.87H36C36.08 26.87 36.2 26.85 36.2 26.78C36.1855 26.6944 36.162 26.6107 36.13 26.53L36 26.13H35.35L35.24 26.49C35.2028 26.5765 35.176 26.6672 35.16 26.76C35.16 26.87 35.29 26.87 35.41 26.87V27H34.98H34.6V26.82C34.7341 26.8171 34.8511 26.7283 34.89 26.6L35.46 24.97C35.5171 24.8235 35.5639 24.6731 35.6 24.52C35.7119 24.482 35.8192 24.4317 35.92 24.37V24.46L36.57 26.32ZM35.25 25.84H35.87L35.57 24.89L35.25 25.84Z"
        fill="#211E1F"
      />
      <path
        id="Path_17"
        d="M39 26.31C38.9785 26.3992 38.9413 26.4839 38.89 26.56C38.8069 26.6598 38.6889 26.7242 38.56 26.74H38.1C37.99 26.74 37.9 26.69 37.9 26.55V24.76C37.9 24.58 37.99 24.58 38.12 24.58H38.23V24.44H37.69H37.13V24.58H37.19C37.32 24.58 37.44 24.58 37.44 24.77V26.59C37.44 26.8 37.32 26.81 37.19 26.81H37.13V27H38.07H39C39 26.8 39.09 26.6 39.13 26.4H39V26.31Z"
        fill="#211E1F"
      />
      <path
        id="Shape_8"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M38.6401 24.7365C38.6364 24.5335 38.7971 24.3655 39 24.36C39.1007 24.3544 39.1991 24.3912 39.2713 24.4615C39.3435 24.5318 39.383 24.6292 39.38 24.73C39.3799 24.933 39.2163 25.0981 39.0133 25.0999C38.8102 25.1016 38.6437 24.9395 38.6401 24.7365ZM38.7 24.7C38.7 24.8657 38.8343 25 39 25C39.0796 25 39.1559 24.9684 39.2121 24.9121C39.2684 24.8559 39.3 24.7796 39.3 24.7C39.3 24.5343 39.1657 24.4 39 24.4C38.8343 24.4 38.7 24.5343 38.7 24.7Z"
        fill="#211E1F"
      />
      <path
        id="Shape_9"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M39.16 24.63C39.1609 24.6837 39.1229 24.7302 39.07 24.74V24.82L39.15 24.91H39.08C39.0541 24.91 39.0324 24.8764 39.0013 24.8283C38.9844 24.8021 38.9647 24.7717 38.94 24.74V24.86H38.84V24.58V24.53H39.03C39.1 24.53 39.16 24.56 39.16 24.63ZM38.96 24.57V24.74H38.97C39.04 24.74 39.04 24.7 39.04 24.65C39.0435 24.6279 39.0362 24.6055 39.0204 24.5897C39.0046 24.5738 38.9821 24.5665 38.96 24.57Z"
        fill="#211E1F"
      />
      <g id="mark">
        <path
          id="Path_18"
          d="M25.36 17.59C28.8714 17.61 31.7739 14.8575 31.94 11.35C31.9191 9.63226 31.2117 7.99431 29.9756 6.8014C28.7394 5.60848 27.0774 4.9598 25.36 5H22.36C20.6652 4.96121 19.0284 5.6181 17.8306 6.81777C16.6328 8.01744 15.9785 9.65525 16.02 11.35C16.0584 14.8193 18.8905 17.6067 22.36 17.59H25.36Z"
          fill="#0779BE"
        />
        <path
          id="Shape_10"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22.42 5.52C19.2388 5.52 16.66 8.09884 16.66 11.28C16.66 14.4612 19.2388 17.04 22.42 17.04C25.6011 17.04 28.18 14.4612 28.18 11.28C28.18 8.09884 25.6011 5.52 22.42 5.52ZM18.77 11.28C18.7715 9.76934 19.701 8.41483 21.11 7.87V14.69C19.6991 14.1475 18.7687 12.7915 18.77 11.28ZM26.0683 11.28C26.0683 9.76637 25.1341 8.4098 23.72 7.87V14.69C25.1341 14.1502 26.0683 12.7936 26.0683 11.28Z"
          fill="white"
        />
        <path
          id="Path_19"
          d="M25.36 17.59C28.8714 17.61 31.7739 14.8575 31.94 11.35C31.9191 9.63226 31.2117 7.99431 29.9756 6.8014C28.7394 5.60848 27.0774 4.9598 25.36 5H22.36C20.6652 4.96121 19.0284 5.6181 17.8306 6.81777C16.6328 8.01744 15.9785 9.65525 16.02 11.35C16.0584 14.8193 18.8905 17.6067 22.36 17.59H25.36Z"
          fill="#0779BE"
        />
        <path
          id="Shape_11"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22.42 5.52C19.2388 5.52 16.66 8.09884 16.66 11.28C16.66 14.4612 19.2388 17.04 22.42 17.04C25.6011 17.04 28.18 14.4612 28.18 11.28C28.18 8.09884 25.6011 5.52 22.42 5.52ZM18.77 11.28C18.7715 9.76934 19.701 8.41483 21.11 7.87V14.69C19.6991 14.1475 18.7687 12.7915 18.77 11.28ZM26.0683 11.28C26.0683 9.76637 25.1341 8.4098 23.72 7.87V14.69C25.1341 14.1502 26.0683 12.7936 26.0683 11.28Z"
          fill="white"
        />
      </g>
    </g>
  </g>
</svg>
