<div class="p-4">
  <div class="m-3 mb-8 flex flex-row justify-between">
    <input
      type="text"
      class="input input-bordered w-80"
      placeholder="Search"
      (input)="updateDisplayProducts()"
      [(ngModel)]="searchText"
    />
    <button class="btn btn-primary" [routerLink]="['/admin/add-product']">
      <app-plus-icon class="h-6 w-6"></app-plus-icon>
      Add Product
    </button>
  </div>
  <div class="overflow-x-auto rounded-lg border border-solid border-black">
    <table
      class="table table-pin-rows table-pin-cols"
      [ngClass]="{
        'table-lg': spacing === 'comfortable',
        'table-md': spacing === 'normal',
        'table-xs': spacing === 'compact',
      }"
    >
      <thead class="text-base font-medium">
        <td>Title</td>
        <td class="w-1 whitespace-nowrap">Code</td>
        <td class="w-1 whitespace-nowrap">Variance</td>
        <td class="w-1 whitespace-nowrap">Type</td>
        <td class="w-1 whitespace-nowrap">Standalone</td>
        <td class="w-1 whitespace-nowrap">Service</td>
        <td class="w-1 whitespace-nowrap">Active</td>
        <td class="w-1 whitespace-nowrap">Created</td>
      </thead>
      <tbody>
        @for (product of currentPageProducts; track product; let i = $index) {
          <tr
            class="cursor-pointer text-base"
            [ngClass]="{ 'bg-base-200': i % 2 === 0 }"
            (click)="navigateToProductDetails(product)"
          >
            <td>{{ product.title }}</td>
            <td class="w-1 whitespace-nowrap">{{ product.code }}</td>
            <td class="w-1 whitespace-nowrap">{{ product.variance_code }}</td>
            <td class="w-1 whitespace-nowrap">{{ product.type }}</td>
            <td class="w-1 whitespace-nowrap">{{ product.standalone }}</td>
            <td class="w-1 whitespace-nowrap">{{ product.is_service }}</td>
            <td class="w-1 whitespace-nowrap">{{ product.active }}</td>
            <td class="w-1 whitespace-nowrap">
              {{ product.created_at | date: 'MM/dd/YYYY, hh:mmaa' }}
            </td>
          </tr>
        }
      </tbody>
    </table>
  </div>

  @if (loading) {
    <div class="mt-5 flex justify-center">
      <span class="loading loading-spinner loading-md"></span>
    </div>
  } @else {
    <div class="mt-5">
      <app-pagination
        [spacing]="spacing"
        [pageSize]="pageSize"
        [currentPage]="currentPage"
        [totalPages]="totalPages"
        (pageChanged)="changePage($event)"
        (settingsChanged)="handleSettingsChanged($event)"
      ></app-pagination>
    </div>
  }
</div>
