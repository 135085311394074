import { Component } from '@angular/core';

@Component({
  selector: 'app-visa-icon',
  templateUrl: './visa-icon.component.html',
  styleUrl: './visa-icon.component.scss'
})
export class VisaIconComponent {

}
