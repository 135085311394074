<form class="hidden">
  <button
    #acceptPaymentButton
    type="button"
    class="AcceptUI"
    data-billingAddressOptions='{"show":false, "required":false}'
    [attr.data-apiLoginID]="authNetAPILoginID"
    [attr.data-clientKey]="authNetClientKey"
    data-acceptUIFormBtnTxt="Submit"
    data-acceptUIFormHeaderTxt="Card Information"
    data-paymentOptions='{"showCreditCard": true }'
    data-responseHandler="savedCardResponseHandler"
  >
    Enter Card Information
  </button>
</form>

<dialog #addPaymentMethodModal class="modal">
  <div class="modal-box w-fit max-w-fit">
    <form class="dialog">
      <button
        class="btn btn-circle btn-ghost btn-sm absolute right-2 top-2"
        (click)="hideAddMethodModal()"
      >
        ✕
      </button>
    </form>

    <div>
      <h4 class="text-xl">Add new payment method</h4>
      <div>
        <h6 class="mt-8 text-lg">Billing Address</h6>
      </div>
      <div class="flex space-x-8">
        <div class="w-80">
          <label class="form-control m-auto w-full max-w-lg">
            <div class="label">
              <span class="label-text">First Name</span>
            </div>
            <input
              type="text"
              placeholder="First Name"
              [(ngModel)]="inputs['first'].value"
              class="input input-bordered w-full"
              [ngClass]="{ 'input-error': inputs['first'].error }"
            />
            @if (inputs['first'].error) {
              <div class="label">
                <span class="label-text-alt text-error">{{
                  inputs['first'].error
                }}</span>
              </div>
            }
          </label>
          <label class="form-control m-auto w-full max-w-lg">
            <div class="label">
              <span class="label-text">Last Name</span>
            </div>
            <input
              type="text"
              placeholder="Last Name"
              [(ngModel)]="inputs['last'].value"
              class="input input-bordered w-full"
              [ngClass]="{ 'input-error': inputs['last'].error }"
            />
            @if (inputs['last'].error) {
              <div class="label">
                <span class="label-text-alt text-error">{{
                  inputs['last'].error
                }}</span>
              </div>
            }
          </label>
          <label class="form-control m-auto w-full max-w-lg">
            <div class="label">
              <span class="label-text">Company</span>
            </div>
            <input
              type="text"
              placeholder="Company"
              [(ngModel)]="inputs['company'].value"
              class="input input-bordered w-full"
              [ngClass]="{ 'input-error': inputs['company'].error }"
            />
            @if (inputs['company'].error) {
              <div class="label">
                <span class="label-text-alt text-error">{{
                  inputs['company'].error
                }}</span>
              </div>
            }
          </label>
          <div class="max-x-lg m-auto w-full">
            <label class="label mt-9 cursor-pointer justify-start">
              <input type="checkbox" class="checkbox" [(ngModel)]="isDefault" />
              <span class="ml-2">Save as default method</span>
            </label>
          </div>
        </div>
        <div class="w-80">
          <label class="form-control m-auto w-full max-w-lg">
            <div class="label">
              <span class="label-text">Address</span>
            </div>
            <input
              type="text"
              placeholder="Address"
              [(ngModel)]="inputs['address'].value"
              class="input input-bordered w-full"
              [ngClass]="{ 'input-error': inputs['address'].error }"
            />
            @if (inputs['address'].error) {
              <div class="label">
                <span class="label-text-alt text-error">{{
                  inputs['address'].error
                }}</span>
              </div>
            }
          </label>
          <label class="form-control m-auto w-full max-w-lg">
            <div class="label">
              <span class="label-text">City</span>
            </div>
            <input
              type="text"
              placeholder="City"
              [(ngModel)]="inputs['city'].value"
              class="input input-bordered w-full"
              [ngClass]="{ 'input-error': inputs['city'].error }"
            />
            @if (inputs['city'].error) {
              <div class="label">
                <span class="label-text-alt text-error">{{
                  inputs['city'].error
                }}</span>
              </div>
            }
          </label>
          <label class="form-control m-auto w-full max-w-lg">
            <div class="label">
              <span class="label-text">State</span>
            </div>
            <input
              type="text"
              placeholder="State"
              [(ngModel)]="inputs['state'].value"
              class="input input-bordered w-full"
              [ngClass]="{ 'input-error': inputs['state'].error }"
            />
            @if (inputs['state'].error) {
              <div class="label">
                <span class="label-text-alt text-error">{{
                  inputs['state'].error
                }}</span>
              </div>
            }
          </label>
          <label class="form-control m-auto w-full max-w-lg">
            <div class="label">
              <span class="label-text">Zip</span>
            </div>
            <input
              type="text"
              placeholder="Zip"
              [(ngModel)]="inputs['zip'].value"
              class="input input-bordered w-full"
              [ngClass]="{ 'input-error': inputs['zip'].error }"
            />
            @if (inputs['zip'].error) {
              <div class="label">
                <span class="label-text-alt text-error">{{
                  inputs['zip'].error
                }}</span>
              </div>
            }
          </label>
        </div>
      </div>
      <!-- <div class="mb-4 mt-2 w-fit">
  <label class="label cursor-pointer justify-start">
    <input type="checkbox" class="checkbox" [(ngModel)]="isDefault" />
    <span class="ml-2">Save as default method</span>
  </label>
</div> -->

      <div class="mt-4">
        @if (!creatingSavedMethod) {
          <div class="flex">
            <button
              class="btn btn-primary px-8"
              (click)="validateInputAddress()"
              [disabled]="loading"
            >
              Next
            </button>
            @if (loading) {
              <div class="loading loading-spinner loading-md ml-4"></div>
            }
          </div>
        } @else {
          <div class="my-4 flex">
            <div class="loading"></div>
            <p class="ml-2 italic opacity-80">Creating saved payment method</p>
          </div>
        }
      </div>

      @if (addSavedMethodError) {
        <div class="my-4">
          <p class="text-error">{{ addSavedMethodError }}</p>
        </div>
      }
    </div>
  </div>
</dialog>

<dialog #addressCorrectionModal class="modal">
  <div class="modal-box w-fit max-w-fit">
    <form class="dialog">
      <button
        class="btn btn-circle btn-ghost btn-sm absolute right-2 top-2"
        (click)="hideAddressCorrectionModal()"
      >
        ✕
      </button>
    </form>
    @if (addressValidated) {
      <div class="grid grid-cols-2 gap-8 p-2">
        <div>
          <h4 class="mb-2 text-xl font-medium">Standardized Address</h4>
          <p [innerHTML]="formattedCassAddress"></p>
        </div>
        <div>
          <h4 class="mb-2 text-xl font-medium">Input Address</h4>
          <p [innerHTML]="formattedInputAddress"></p>
        </div>
        <div>
          <button
            class="btn btn-primary w-full"
            (click)="updateAddressFromStandardized()"
          >
            Use Standardized Address
          </button>
        </div>
        <div>
          <button class="btn btn-neutral w-full" (click)="promptForCardInfo()">
            Use Input Address
          </button>
        </div>
      </div>
    } @else {
      <div>
        <div class="p-2">
          <h4 class="mb-2">Address could not be validated.</h4>
          <p class="mb-2">
            Please confirm the input address below was entered correctly
          </p>
          <p [innerHTML]="formattedInputAddress"></p>
        </div>
        <div class="flex justify-center space-x-4">
          <button class="btn btn-warning" (click)="promptForCardInfo()">
            Confirm Address
          </button>
          <button
            class="btn btn-neutral"
            (click)="hideAddressCorrectionModal()"
          >
            Cancel
          </button>
        </div>
      </div>
    }
  </div>
  <div class="modal-backdrop"></div>
</dialog>
