import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';
import { mergeMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DesktopLicenseWithProduct } from './licenses.service';

export type DesktopInvoice = {
  id: string;
  tenant_id: string;
  completed: boolean;
  completed_at?: Date;
  renewal: boolean;
  base_amount: number;
  tax_amount: number;
  total_amount: number;
  quickbooks_invoice_id?: string;
  quickbooks_invoice_number?: string;
  expiration: string;
  created_at: string;
};

export type InvoiceDetails = {
  desktop_invoice: DesktopInvoice;
  license_details: DesktopLicenseInvoiceDetails[];
  not_renewing_licenses: DesktopLicenseWithProduct[];
  due_by: string;
};

export type DesktopLicenseInvoiceDetails = {
  license_with_product: DesktopLicenseWithProduct;
  new_expiration_date: string;
  new_product_variant_id?: string;
  base_amount: number;
  tax_amount: number;
  total_amount: number;
};

export type InvoicesResponse = {
  desktop_invoices: {
    pending: InvoiceDetails[];
    complete: InvoiceDetails[];
    expired: InvoiceDetails[];
  };
};

export type InvoiceResponse = {
  invoice_details: InvoiceDetails;
};

export type CreateInvoiceRequest = {
  desktop_license_ids: string[];
};

export type UpdateProductVariantForRenewalRequest = {
  invoice_id: string;
  desktop_license_id: string;
  new_product_variant_id: string;
};

export type UpdateLicenseDurationRequest = {
  invoice_id: string;
  desktop_license_id: string;
  duration_months: number;
};

export type DeleteInvoiceResponse = {
  success: boolean;
};

export type GroupedLicenseInvoiceDetails = {
  license: DesktopLicenseInvoiceDetails;
  count: number;
};

export type GroupedLicenses = {
  license: DesktopLicenseWithProduct;
  count: number;
};

@Injectable({
  providedIn: 'root',
})
export class InvoicesService {
  getInvoiceDetailsUrl = environment.serverUrl + '/invoices/get';
  invoicesByTenantUrl = environment.serverUrl + '/invoices/for-tenant';
  currentTenantInvoicesUrl = environment.serverUrl + '/invoices/get-active';
  createInvoiceForLicensesUrl =
    environment.serverUrl + '/invoices/create-for-licenses';
  updateLicenseDurationUrl =
    environment.serverUrl + '/invoices/update-license-duration';
  deleteInvoiceUrl = environment.serverUrl + '/invoices/delete';

  constructor(
    private firebaseService: FirebaseService,
    private http: HttpClient
  ) {}

  getInvoiceDetails(invoiceId: string) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.get<InvoiceResponse>(
          this.getInvoiceDetailsUrl + '/' + invoiceId,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }

  getInvoicesForCurrentTenant() {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.get<InvoicesResponse>(this.currentTenantInvoicesUrl, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
      })
    );
  }

  getInvoicesForTenant(tenantId: string) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.get<InvoicesResponse>(
          this.invoicesByTenantUrl + '/' + tenantId,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }

  createInvoice(request: CreateInvoiceRequest) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.post<InvoiceResponse>(
          this.createInvoiceForLicensesUrl,
          request,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }

  updateLicenseDuration(req: UpdateLicenseDurationRequest) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.post<InvoiceResponse>(
          this.updateLicenseDurationUrl,
          req,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }

  deleteInvoice(invoiceId: string) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.delete<DeleteInvoiceResponse>(
          this.deleteInvoiceUrl + '/' + invoiceId,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }

  groupLicensesByProduct(
    licenses: DesktopLicenseWithProduct[]
  ): GroupedLicenses[] {
    const grouped: GroupedLicenses[] = [];

    for (const license of licenses) {
      const existing = grouped.find(
        (l) =>
          l.license.product_variant.id === license.product_variant.id &&
          l.license.site_license === license.site_license &&
          l.license.renewal === license.renewal
      );

      if (existing) {
        existing.count++;
      } else {
        grouped.push({ license, count: 1 });
      }
    }

    return grouped;
  }

  groupLicenseDetailsByProduct(licenseDetails: DesktopLicenseInvoiceDetails[]) {
    const grouped: GroupedLicenseInvoiceDetails[] = [];

    for (const license of licenseDetails) {
      const existing = grouped.find(
        (l) =>
          l.license.license_with_product.product_variant.id ===
            license.license_with_product.product_variant.id &&
          l.license.license_with_product.site_license ===
            license.license_with_product.site_license &&
          l.license.license_with_product.renewal ===
            license.license_with_product.renewal
      );

      if (existing) {
        existing.count++;
      } else {
        grouped.push({ license, count: 1 });
      }
    }

    return grouped;
  }

  getLicensePrice(license: DesktopLicenseWithProduct) {
    if (license.renewal && license.site_license) {
      return license.product_variant.site_renewal_price;
    } else if (license.renewal) {
      return license.product_variant.renewal_price;
    } else if (license.site_license) {
      return license.product_variant.site_price;
    } else {
      return license.product_variant.price;
    }
  }
}
