<div class="mx-auto flex w-fit flex-col">
  <div>
    <h1 class="mb-4 text-2xl font-bold">Edit Product Variant</h1>
  </div>

  @if (errorFetchingProductVariant) {
    <div class="flex justify-center">
      <p class="text-error">{{ errorFetchingProductVariant }}</p>
    </div>
  } @else if (loadingProductVariant) {
    <div class="flex justify-center">
      <div class="loading loading-spinner loading-lg"></div>
    </div>
  } @else if (originalProductVariant) {
    <div>
      <div class="form-control mb-4">
        <label class="label">
          <span class="label-text">Description</span>
        </label>
        <textarea
          class="textarea textarea-bordered"
          [(ngModel)]="description"
        ></textarea>
      </div>

      <div class="form-control mb-2 w-fit">
        <label class="label cursor-pointer">
          <input type="checkbox" class="checkbox" [(ngModel)]="active" />
          <span class="label-text ml-4">Active</span>
        </label>
      </div>

      <div class="form-control mb-2 w-fit">
        <label class="label cursor-pointer">
          <input type="checkbox" class="checkbox" [(ngModel)]="isDefault" />
          <span class="label-text ml-4">Is Default</span>
        </label>
      </div>

      @if (fetchingFranchises) {
        <div class="skeleton h-8 w-28 rounded-md"></div>
      } @else if (errorFetchingFranchises) {
        <div class="text-error">
          <p>Error fetching franchises</p>
          <p>{{ errorFetchingFranchises }}</p>
        </div>
      } @else if (franchises) {
        <div class="form-control mb-2 w-fit">
          <label class="label">
            <span class="label-text">Franchise</span>
          </label>
          <select class="select select-bordered" [(ngModel)]="franchiseId">
            <option value="" selected>N/A</option>
            @for (franchise of franchises; track franchise) {
              <option [value]="franchise.id">
                {{ franchise.name }}
              </option>
            }
          </select>
        </div>
      }

      <div class="flex space-x-8">
        <div class="form-control mb-2 w-fit">
          <label class="label">
            <span class="label-text">Duration (Months)</span>
          </label>
          <input
            type="number"
            class="input input-bordered"
            [(ngModel)]="durationMonths"
          />
        </div>

        <div class="form-control mb-2 w-fit">
          <label class="label">
            <span class="label-text">Delivery Method</span>
          </label>
          <select class="select select-bordered" [(ngModel)]="deliveryMethod">
            @for (dm of deliveryMethods; track dm) {
              <option [value]="dm.value">
                {{ dm.display }}
              </option>
            }
          </select>
        </div>
      </div>

      <div class="flex space-x-8">
        <div class="form-control mb-2 w-fit">
          <label class="label">
            <span class="label-text">Price Lock Level</span>
          </label>
          <input
            type="number"
            class="input input-bordered"
            [(ngModel)]="priceLockLevel"
          />
        </div>

        <div class="form-control mb-2 w-fit">
          <label class="label">
            <span class="label-text">Variance Code</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            [(ngModel)]="varianceCode"
          />
        </div>
      </div>

      <div class="flex space-x-8">
        <div class="form-control mb-2 w-fit">
          <label class="label">
            <span class="label-text">Price</span>
          </label>
          <input
            type="number"
            class="input input-bordered"
            [(ngModel)]="price"
          />
        </div>

        <div class="form-control mb-2 w-fit">
          <label class="label">
            <span class="label-text">Tax Code</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            [(ngModel)]="taxCode"
          />
        </div>

        <div class="form-control mb-2 w-fit">
          <label class="label">
            <span class="label-text">Price Key</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            [(ngModel)]="priceKey"
          />
        </div>
      </div>

      <div class="flex space-x-8">
        <div class="form-control mb-2 w-fit">
          <label class="label">
            <span class="label-text">Renewal Price</span>
          </label>
          <input
            type="number"
            class="input input-bordered"
            [(ngModel)]="renewalPrice"
          />
        </div>

        <div class="form-control mb-2 w-fit">
          <label class="label">
            <span class="label-text">Renewal Tax Code</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            [(ngModel)]="renewalTaxCode"
          />
        </div>

        <div class="form-control mb-2 w-fit">
          <label class="label">
            <span class="label-text">Renewal Price Key</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            [(ngModel)]="renewalPriceKey"
          />
        </div>
      </div>

      <div class="flex space-x-8">
        <div class="form-control mb-2 w-fit">
          <label class="label">
            <span class="label-text">Site Price</span>
          </label>
          <input
            type="number"
            class="input input-bordered"
            [(ngModel)]="sitePrice"
          />
        </div>

        <div class="form-control mb-2 w-fit">
          <label class="label">
            <span class="label-text">Site Tax Code</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            [(ngModel)]="siteTaxCode"
          />
        </div>

        <div class="form-control mb-2 w-fit">
          <label class="label">
            <span class="label-text">Site Price Key</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            [(ngModel)]="sitePriceKey"
          />
        </div>
      </div>

      <div class="flex space-x-8">
        <div class="form-control mb-2 w-fit">
          <label class="label">
            <span class="label-text">Site Renewal Price</span>
          </label>
          <input
            type="number"
            class="input input-bordered"
            [(ngModel)]="siteRenewalPrice"
          />
        </div>

        <div class="form-control mb-2 w-fit">
          <label class="label">
            <span class="label-text">Site Renewal Tax Code</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            [(ngModel)]="siteRenewalTaxCode"
          />
        </div>

        <div class="form-control mb-2 w-fit">
          <label class="label">
            <span class="label-text">Site Renewal Price Key</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            [(ngModel)]="siteRenewalPriceKey"
          />
        </div>
      </div>

      <div class="mt-8 flex justify-center space-x-4">
        <button
          class="btn btn-primary"
          [disabled]="!hasChanges || savingChanges"
          (click)="changeDialog.showModal()"
        >
          Save
        </button>
        <button
          class="btn btn-error"
          [routerLink]="'/admin/product/' + originalProductVariant.product_id"
        >
          Cancel
        </button>
      </div>
    </div>
  }

  @if (savingChanges) {
    <div class="mt-4 flex justify-center">
      <span class="loading loading-spinner loading-md"></span>
    </div>
  }

  @if (productVariantUpdated) {
    <div class="mt-4 flex justify-center">
      <p class="text-success">Product Variant changes saved successfully</p>
    </div>
  }

  @if (errorUpdatingProductVariant) {
    <div class="mt-4 flex justify-center">
      <p class="text-error">
        {{ errorUpdatingProductVariant }}
      </p>
    </div>
  }
</div>

<dialog #changeDialog id="change-dialog" class="modal">
  @if (originalProductVariant) {
    <div class="modal-box max-w-5xl p-8">
      <h2 class="text-2xl font-bold text-error">WARNING</h2>
      <h4 class="text-lg text-error">
        You are changing a live product that may affect users who have this
        product currently licensed.
      </h4>
      <table class="table my-4">
        <thead class="text-base">
          <tr>
            <th class="font-medium">Field</th>
            <th class="font-medium">Original Value</th>
            <th class="font-medium">New Value</th>
          </tr>
        </thead>
        <tbody>
          <tr
            [ngClass]="{
              'text-warning':
                originalProductVariant.description !== description,
            }"
          >
            <td>Description</td>
            <td>{{ originalProductVariant.description }}</td>
            <td>{{ description }}</td>
          </tr>
          <tr
            [ngClass]="{
              'text-warning': originalProductVariant.active !== active,
            }"
          >
            <td>Active</td>
            <td>{{ originalProductVariant.active }}</td>
            <td>{{ active }}</td>
          </tr>
          <tr
            [ngClass]="{
              'text-warning': originalProductVariant.is_default !== isDefault,
            }"
          >
            <td>Is Default</td>
            <td>{{ originalProductVariant.is_default }}</td>
            <td>{{ isDefault }}</td>
          </tr>
          <tr
            [ngClass]="{
              'text-warning':
                originalProductVariant.franchise_id !== franchiseId,
            }"
          >
            <td>Franchise</td>
            <td>{{ getFranchiseName(originalProductVariant.franchise_id) }}</td>
            <td>{{ getFranchiseName(franchiseId) }}</td>
          </tr>
          <tr
            [ngClass]="{
              'text-warning':
                originalProductVariant.variance_code !== varianceCode,
            }"
          >
            <td>Variance Code</td>
            <td>{{ originalProductVariant.variance_code }}</td>
            <td>{{ varianceCode }}</td>
          </tr>
          <tr
            [ngClass]="{
              'text-warning':
                originalProductVariant.delivery_method !== deliveryMethod,
            }"
          >
            <td>Delivery Method</td>
            <td>{{ originalProductVariant.delivery_method }}</td>
            <td>{{ deliveryMethod }}</td>
          </tr>
          <tr
            [ngClass]="{
              'text-warning':
                originalProductVariant.duration_months !== durationMonths,
            }"
          >
            <td>Duration (Months)</td>
            <td>{{ originalProductVariant.duration_months }}</td>
            <td>{{ durationMonths }}</td>
          </tr>
          <tr
            [ngClass]="{
              'text-warning':
                originalProductVariant.price_lock_level !== priceLockLevel,
            }"
          >
            <td>Price Lock Level</td>
            <td>{{ originalProductVariant.price_lock_level }}</td>
            <td>{{ priceLockLevel }}</td>
          </tr>
          <tr
            [ngClass]="{
              'text-warning': originalProductVariant.price_key !== priceKey,
            }"
          >
            <td>Price Key</td>
            <td>{{ originalProductVariant.price_key }}</td>
            <td>{{ priceKey }}</td>
          </tr>
          <tr
            [ngClass]="{
              'text-warning':
                originalProductVariant.renewal_price_key !== renewalPriceKey,
            }"
          >
            <td>Renewal Price Key</td>
            <td>{{ originalProductVariant.renewal_price_key }}</td>
            <td>{{ renewalPriceKey }}</td>
          </tr>
          <tr
            [ngClass]="{
              'text-warning':
                originalProductVariant.site_price_key !== sitePriceKey,
            }"
          >
            <td>Site Price Key</td>
            <td>{{ originalProductVariant.site_price_key }}</td>
            <td>{{ sitePriceKey }}</td>
          </tr>
          <tr
            [ngClass]="{
              'text-warning':
                originalProductVariant.site_renewal_price_key !==
                siteRenewalPriceKey,
            }"
          >
            <td>Site Renewal Price Key</td>
            <td>{{ originalProductVariant.site_renewal_price_key }}</td>
            <td>{{ siteRenewalPriceKey }}</td>
          </tr>
          <tr
            [ngClass]="{
              'text-warning': originalProductVariant.tax_code !== taxCode,
            }"
          >
            <td>Tax Code</td>
            <td>{{ originalProductVariant.tax_code }}</td>
            <td>{{ taxCode }}</td>
          </tr>
          <tr
            [ngClass]="{
              'text-warning':
                originalProductVariant.renewal_tax_code !== renewalTaxCode,
            }"
          >
            <td>Renewal Tax Code</td>
            <td>{{ originalProductVariant.renewal_tax_code }}</td>
            <td>{{ renewalTaxCode }}</td>
          </tr>
          <tr
            [ngClass]="{
              'text-warning':
                originalProductVariant.site_tax_code !== siteTaxCode,
            }"
          >
            <td>Site Tax Code</td>
            <td>{{ originalProductVariant.site_tax_code }}</td>
            <td>{{ siteTaxCode }}</td>
          </tr>
          <tr
            [ngClass]="{
              'text-warning':
                originalProductVariant.site_renewal_tax_code !==
                siteRenewalTaxCode,
            }"
          >
            <td>Site Renewal Tax Code</td>
            <td>{{ originalProductVariant.site_renewal_tax_code }}</td>
            <td>{{ siteRenewalTaxCode }}</td>
          </tr>
          <tr
            [ngClass]="{
              'text-warning': originalProductVariant.price !== price,
            }"
          >
            <td>Price</td>
            <td>{{ originalProductVariant.price }}</td>
            <td>{{ price }}</td>
          </tr>
          <tr
            [ngClass]="{
              'text-warning':
                originalProductVariant.renewal_price !== renewalPrice,
            }"
          >
            <td>Renewal Price</td>
            <td>{{ originalProductVariant.renewal_price }}</td>
            <td>{{ renewalPrice }}</td>
          </tr>
          <tr
            [ngClass]="{
              'text-warning': originalProductVariant.site_price !== sitePrice,
            }"
          >
            <td>Site Price</td>
            <td>{{ originalProductVariant.site_price }}</td>
            <td>{{ sitePrice }}</td>
          </tr>
          <tr
            [ngClass]="{
              'text-warning':
                originalProductVariant.site_renewal_price !== siteRenewalPrice,
            }"
          >
            <td>Site Renewal Price</td>
            <td>{{ originalProductVariant.site_renewal_price }}</td>
            <td>{{ siteRenewalPrice }}</td>
          </tr>
        </tbody>
      </table>
      <h4 class="text-lg text-error">
        Are you sure you want to make these changes?
      </h4>
      <div class="mt-4 flex justify-center space-x-4">
        <button class="btn btn-primary" (click)="saveChanges()">Yes</button>
        <button class="btn btn-error" (click)="closeChangeDialog()">No</button>
      </div>
    </div>
  }
</dialog>
