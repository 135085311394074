import { Component } from '@angular/core';
import { Tenant, TenantsService } from '../../../../services/tenants.service';
import { Router } from '@angular/router';
import { LoggerService } from '../../../../services/logger.service'; // Import LoggerService

@Component({
  selector: 'app-tenants-list',
  templateUrl: './tenants-list.component.html',
  styleUrls: ['./tenants-list.component.scss'],
})
export class TenantsListComponent {
  currentTenants: Tenant[] = [];
  tenantCount: number = 0;
  loading = false;

  pageSize = 20;
  currentPage = 1;

  accountSearchQuery = '';
  currentSearchTerm = '';
  searchComplete = false;

  constructor(
    private tenantsService: TenantsService,
    private router: Router,
    private loggerService: LoggerService // Inject LoggerService
  ) {
    this.loggerService.log('TenantsListComponent constructor');
    this.updateTenants();
  }

  updateTenants() {
    if (this.loading) return;
    this.loading = true;

    const offset = (this.currentPage - 1) * this.pageSize;
    this.tenantsService.getTenantRange(offset, this.pageSize).subscribe({
      next: (res) => {
        this.loggerService.log('res: ', res);
        if (res.tenants) {
          this.currentTenants = res.tenants;
          this.tenantCount = res.total_count;
        } else {
          this.loggerService.error(
            new Error('No tenants returned from server')
          );
          this.loggerService.log('res: ', res);
          this.currentTenants = [];
        }
      },
      error: (err) => {
        this.loggerService.error(
          new Error('Error getting tenants from server')
        );
        this.loggerService.log('err: ', err);
        this.currentTenants = [];
      },
      complete: () => {
        this.loggerService.log('TenantsListComponent updateTenants complete');
        this.loading = false;
      },
    });
  }

  handleSearch() {
    this.currentSearchTerm = this.accountSearchQuery;
    this.accountSearchQuery = '';
    this.searchTenants(this.currentSearchTerm);
  }

  resetSearch() {
    this.currentSearchTerm = '';
    this.accountSearchQuery = '';
    this.currentPage = 1;
    this.searchComplete = false;
    this.updateTenants();
  }

  searchTenants(query: string) {
    this.loading = true;
    const offset = (this.currentPage - 1) * this.pageSize;
    this.tenantsService
      .searchTenantsByAccountNumber(query, offset, this.pageSize)
      .subscribe({
        next: (res) => {
          this.searchComplete = true;
          this.loggerService.log('res: ', res);
          if (res.tenants) {
            this.currentTenants = res.tenants;
            this.tenantCount = res.total_count;
          } else {
            this.loggerService.error(
              new Error('No tenants returned from server')
            );
            this.loggerService.log('res: ', res);
            this.currentTenants = [];
          }
        },
        error: (err) => {
          this.loggerService.error(
            new Error('Error getting tenants from server')
          );
          this.loggerService.log('err: ', err);
          this.currentTenants = [];
        },
        complete: () => {
          this.loggerService.log('TenantsListComponent updateTenants complete');
          this.loading = false;
        },
      });
  }

  navigateToTenantDetails(tenant: Tenant) {
    this.loggerService.log('navigateToTenantDetails: ', tenant);
    this.router.navigate([`admin/tenant/${tenant.id}`]);
  }

  get pageArray() {
    const maxPages = 10;

    if (this.totalPages <= maxPages) {
      const pageArray = [];
      for (let i = 1; i <= this.totalPages; i++) {
        pageArray.push(i);
      }
      return pageArray;
    }

    const pageSet = new Set<number>();
    let offset = 1;
    pageSet.add(1); // always include first page
    pageSet.add(this.totalPages); // always include last page
    pageSet.add(this.currentPage); // always include current page
    while (pageSet.size < maxPages - 2) {
      if (this.currentPage + offset < this.totalPages) {
        pageSet.add(this.currentPage + offset);
      }
      if (this.currentPage - offset > 0) {
        pageSet.add(this.currentPage - offset);
      }
      offset++;
    }

    const pages = Array.from(pageSet).sort((a, b) => a - b);
    const pageArray = [pages[0]];
    for (let i = 0; i < pages.length - 1; i++) {
      if (pages[i] + 1 < pages[i + 1]) {
        pageArray.push(-1);
      }
      pageArray.push(pages[i + 1]);
    }

    return pageArray;
  }

  get totalPages() {
    return Math.ceil(this.tenantCount / this.pageSize);
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updateTenants();
    }
  }

  nextPage() {
    if (this.currentPage < this.pageArray.length) {
      this.currentPage++;
      this.updateTenants();
    }
  }

  changePage(page: number) {
    if (this.currentPage === page) return;
    this.currentPage = page;

    if (this.currentSearchTerm) {
      this.searchTenants(this.currentSearchTerm);
    } else {
      this.updateTenants();
    }
  }
}
