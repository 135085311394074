<div class="p-4">
  <div class="m-3 mb-8 flex flex-row justify-between">
    <input
      type="text"
      class="input input-bordered w-80"
      placeholder="Search"
      (input)="updateDisplayProducts()"
      [(ngModel)]="searchText"
    />
    <button class="btn btn-primary" [routerLink]="['/admin/add-product']">
      <app-plus-icon class="h-6 w-6"></app-plus-icon>
      Add Product
    </button>
  </div>
  <div class="overflow-x-auto rounded-lg border border-solid border-black">
    <table
      class="table table-pin-rows table-pin-cols"
      [ngClass]="{
        'table-lg': spacing === 'comfortable',
        'table-md': spacing === 'normal',
        'table-xs': spacing === 'compact',
      }"
    >
      <thead class="text-base font-medium">
        <th>Title</th>
        <th>Code</th>
        <th>Variance</th>
        <th>Type</th>
        <th>Standalone</th>
        <th>Service</th>
        <th>Active</th>
        <th>Created</th>
      </thead>
      <tbody>
        @for (product of currentPageProducts; track product; let i = $index) {
          <tr
            class="cursor-pointer text-base"
            [ngClass]="{ 'bg-base-200': i % 2 === 0 }"
            (click)="navigateToProductDetails(product)"
          >
            <td>{{ product.title }}</td>
            <td>{{ product.code }}</td>
            <td>{{ product.variance_code }}</td>
            <td>{{ product.type }}</td>
            <td>{{ product.standalone }}</td>
            <td>{{ product.is_service }}</td>
            <td>{{ product.active }}</td>
            <td class="whitespace-nowrap">
              {{ product.created_at | date: 'short' }}
            </td>
          </tr>
        }
      </tbody>
    </table>
  </div>

  @if (loading) {
    <div class="mt-5 flex justify-center">
      <span class="loading loading-spinner loading-md"></span>
    </div>
  } @else {
    <div class="mt-5 flex justify-center">
      <select
        class="select select-bordered mr-2 w-min"
        [(ngModel)]="spacing"
        (change)="saveSettings()"
      >
        <option disabled selected>Table Spacing</option>
        @for (spacingOption of spacingOptions; track spacingOption) {
          <option [value]="spacingOption">
            {{ spacingOption }}
          </option>
        }
      </select>
      <div class="join">
        <button
          class="btn btn-primary join-item"
          [disabled]="currentPage === 1"
          (click)="changePage(1)"
        >
          First
        </button>
        <button
          class="btn btn-primary join-item"
          [disabled]="currentPage === 1"
          (click)="prevPage()"
        >
          Prev
        </button>
      </div>
      <div class="join mx-4">
        @for (i of pageArray; track i) {
          <button
            class="btn join-item btn-md"
            [class]="{ 'btn-active': currentPage === i }"
            (click)="changePage(i)"
          >
            {{ i }}
          </button>
        }
      </div>
      <div class="join">
        <button
          class="btn btn-primary join-item"
          [disabled]="currentPage === totalPages"
          (click)="nextPage()"
        >
          Next
        </button>
        <button
          class="btn btn-primary join-item"
          [disabled]="currentPage === totalPages"
          (click)="changePage(totalPages)"
        >
          Last
        </button>
      </div>
      <select
        class="select select-bordered ml-2 w-min"
        [(ngModel)]="pageSize"
        (change)="saveSettings()"
      >
        <option disabled selected>Page Size</option>
        @for (pageSizeOption of pageSizeOptions; track pageSizeOption) {
          <option [value]="pageSizeOption">
            {{ pageSizeOption }}
          </option>
        }
      </select>
    </div>
  }
</div>
