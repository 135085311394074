<div class="flex h-full w-full flex-col bg-gray-950">
  <img
    class="absolute left-[3vw] top-[3vh] z-10 w-48"
    src="../../../assets/accuzip-logo-white.png"
    alt="AccuZIP Logo"
  />

  <svg
    class="absolute right-[50vw] top-0"
    xmlns="http://www.w3.org/2000/svg"
    width="165"
    height="834"
    fill="none"
  >
    <path
      fill="url(#a)"
      d="M4 0s-23 105 56 118c80 13 69 126 0 163-68 38-19 178 52 257 70 80 18 123 21 217s32 77 32 77V0H4Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1="20.5"
        x2="144"
        y1="65"
        y2="742"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#143A46" />
        <stop offset="1" stop-color="#0095A3" />
      </linearGradient>
    </defs>
  </svg>

  <svg
    class="absolute bottom-0 left-0"
    xmlns="http://www.w3.org/2000/svg"
    width="165"
    height="833"
    fill="none"
  >
    <path
      fill="url(#a)"
      d="M161 833s23-104-56-117c-80-13-69-126 0-164 68-37 19-177-52-257-70-79-18-123-21-217S0 1 0 1v832h161Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1="17.5"
        x2="148"
        y1="50"
        y2="804"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#143A46" />
        <stop offset="1" stop-color="#0180A5" />
      </linearGradient>
    </defs>
  </svg>

  <svg
    class="absolute left-[20vw] top-[20vh]"
    width="226"
    height="226"
    viewBox="0 0 226 226"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="113" cy="113" r="113" fill="url(#paint0_linear_137_179)" />
    <defs>
      <linearGradient
        id="paint0_linear_137_179"
        x1="157.5"
        y1="210"
        x2="64.5"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00AEEF" />
        <stop offset="0.5" stop-color="#00538D" />
        <stop offset="1" stop-color="#002640" />
      </linearGradient>
    </defs>
  </svg>

  <svg
    class="absolute bottom-[20vh] left-[10vw]"
    width="129"
    height="129"
    viewBox="0 0 129 129"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="64.5" cy="64.5" r="64.5" fill="url(#paint0_linear_137_180)" />
    <defs>
      <linearGradient
        id="paint0_linear_137_180"
        x1="41.5"
        y1="5.5"
        x2="89"
        y2="122.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#002640" />
        <stop offset="0.473958" stop-color="#00538D" stop-opacity="0.541667" />
        <stop offset="1" stop-color="#00AEEF" />
      </linearGradient>
    </defs>
  </svg>

  <h2
    class="z-10 m-auto max-w-lg text-center text-5xl font-semibold leading-normal text-white"
  >
    Welcome to the Customer Portal
  </h2>

  <h2
    class="z-10 mb-4 ml-auto mr-4 w-[11.2rem] text-3xl leading-normal text-white"
  >
    {{ subtext }}
    <!-- Login to go to your portal -->
  </h2>
</div>
