<div class="p-4">
  <div class="mb-8 mt-4 flex">
    <label class="form-control">
      <div class="label">
        <span class="label-text">Account Number</span>
      </div>
      <input
        type="text"
        class="input input-bordered"
        (keydown.enter)="handleSearch(); $event.preventDefault()"
        [(ngModel)]="accountSearchQuery"
      />
    </label>
    <div class="ml-8 flex items-end">
      <button class="btn btn-primary" (click)="handleSearch()">Search</button>
    </div>
  </div>

  @if (currentSearchTerm && searchComplete) {
    <div class="my-4 flex">
      <p>Found {{ tenantCount }} results for "{{ currentSearchTerm }}"</p>
      <div class="ml-4">
        <button class="btn btn-neutral btn-sm" (click)="resetSearch()">
          Reset
        </button>
      </div>
    </div>
  }

  <div class="overflow-x-auto rounded-lg border border-solid border-black">
    <table class="table table-pin-rows table-pin-cols table-lg">
      <thead class="text-base font-medium">
        <th>Acct #</th>
        <th>Company</th>
        <th>Created Date</th>
      </thead>
      <tbody>
        @for (tenant of currentTenants; track tenant; let i = $index) {
          <tr
            (click)="navigateToTenantDetails(tenant)"
            class="cursor-pointer"
            [ngClass]="{ 'bg-base-200': i % 2 === 0 }"
          >
            <td>{{ tenant.account_number }}</td>
            <td>{{ tenant.company }}</td>
            <td>{{ tenant.created_at | date: 'short' }}</td>
          </tr>
        }
      </tbody>
    </table>
  </div>

  @if (!loading) {
    <div class="mt-5 flex justify-center">
      <div class="join">
        <button
          class="btn btn-primary join-item"
          [disabled]="currentPage === 1"
          (click)="changePage(1)"
        >
          First
        </button>
        <button
          class="btn btn-primary join-item"
          [disabled]="currentPage === 1"
          (click)="prevPage()"
        >
          Prev
        </button>
      </div>
      <div class="join mx-4">
        @for (i of pageArray; track i) {
          @if (i === -1) {
            <button class="btn disabled join-item btn-md">...</button>
          } @else {
            <button
              class="btn join-item btn-md"
              [class]="{ 'btn-accent': currentPage === i }"
              (click)="changePage(i)"
            >
              {{ i }}
            </button>
          }
        }
      </div>
      <div class="join">
        <button
          class="btn btn-primary join-item"
          [disabled]="currentPage === totalPages"
          (click)="nextPage()"
        >
          Next
        </button>
        <button
          class="btn btn-primary join-item"
          [disabled]="currentPage === totalPages"
          (click)="changePage(totalPages)"
        >
          Last
        </button>
      </div>
    </div>
  }
</div>
