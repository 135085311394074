<div class="flex justify-center p-5">
  @if (errorFetchingInvoice) {
    <div class="text-error">
      <p>{{ errorFetchingInvoice }}</p>
    </div>
  } @else if (invoice) {
    <div class="w-full max-w-3xl">
      <div class="flex justify-between">
        <div>
          <p class="mb-2">
            Invoice created on:
            {{ invoice.desktop_invoice.created_at | date: 'short' }}
          </p>
          <p class="mb-4 mt-2">Due on: {{ invoice.due_by | date: 'short' }}</p>
        </div>
      </div>
      <div class="overflow-x-auto">
        <table class="table table-zebra">
          <thead class="text-sm">
            <tr>
              <th class="font-medium">Product</th>
              <th class="font-medium">Price</th>
              <th class="font-medium">Duration</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            @for (el of licenseList; track el) {
              <tr
                [ngClass]="{ 'line-through': el.action?.action === 'remove' }"
              >
                <td>{{ el.license.product.title }}</td>
                <td>{{ getPrice(el) | currency: 'USD' }}</td>
                <td>
                  {{ getLicenseDuration(el) }}
                  @if (el.action?.action !== 'remove') {
                    <span
                      class="ml-2 text-blue-500 underline hover:cursor-pointer"
                      (click)="showDurationOptions(el)"
                      >(Change)</span
                    >
                  }
                </td>
                <td>
                  @if (el.action?.action !== 'remove') {
                    <button
                      class="btn btn-error btn-sm"
                      (click)="removeLicense(el)"
                    >
                      Remove
                    </button>
                  }
                  @if (el.action?.action === 'remove') {
                    <button
                      class="btn btn-warning btn-sm"
                      (click)="undoRemoveLicense(el)"
                    >
                      Undo
                    </button>
                  }
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
      <div class="mt-16 w-full space-x-8 text-center">
        <button
          class="btn btn-primary"
          (click)="saveChanges(invoice)"
          [disabled]="!hasChanges || savingChanges"
        >
          Save
        </button>
        <button class="btn btn-error" (click)="returnToInvoice(invoice)">
          Back
        </button>
      </div>
      @if (savingChanges) {
        <div class="mt-8 w-full text-center">
          <div class="loading loading-lg"></div>
        </div>
      }
      @if (changesSaved) {
        <div class="mt-8 w-full text-center text-success">
          Changes saved successfully!
        </div>
      }
      @if (errorSavingChanges) {
        <div class="mt-8 w-full text-center text-error">
          {{ errorSavingChanges }}
        </div>
      }
    </div>
  }
</div>

<dialog #editLicenseModal class="modal">
  @if (!errorFetchingInvoice) {
    <div class="modal-box">
      <form method="dialog">
        <button class="btn btn-circle btn-ghost btn-sm absolute right-2 top-2">
          ✕
        </button>
      </form>
      <h3 class="mb-4 text-lg font-bold">Select new duration</h3>
      @if (loadingDurationOptions) {
        <div class="loading"></div>
      } @else {
        <div class="w-fit">
          @for (option of durationOptions; track option) {
            <div class="form-control">
              <label class="label cursor-pointer justify-start">
                <input
                  type="radio"
                  name="duration"
                  class="radio"
                  [value]="option.id"
                  [checked]="option.id === selectedDurationOption?.id"
                  (change)="selectedDurationOption = option"
                />
                <span class="label-text ml-4"
                  >{{ getDurationString(option.duration_months) }} -
                  {{ option.renewal_price | currency: 'USD' }}</span
                >
              </label>
            </div>
          }
        </div>
      }
      <div class="mt-4 flex justify-center space-x-8">
        <button class="btn btn-primary" (click)="updateDuration()">
          Update
        </button>
        <form method="dialog">
          <button class="btn btn-error">Cancel</button>
        </form>
      </div>
    </div>
  }
</dialog>
