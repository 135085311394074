import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoggerService } from './logger.service';

declare global {
  interface Window {
    savedCardResponseHandler?: (response: any) => void;
    acceptJSResponseHandler?: (response: any) => void;
    AcceptUI?: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AcceptJSService {
  constructor(private loggerService: LoggerService) {}

  injectScript() {
    // script must be loaded after the component is mounted in the DOM
    // and the payment form exists. Accept.js binds the click event of the form
    // button on load, so the form must exist before the script is loaded.
    const src = environment.production
      ? 'https://js.authorize.net/v3/AcceptUI.js'
      : 'https://jstest.authorize.net/v3/AcceptUI.js';

    this.loggerService.log('AcceptJS script src:', src);

    const script = document.createElement('script');
    script.id = 'accept-js-script';
    script.src = src;
    script.async = true;
    document.body.appendChild(script);
  }

  removeScript() {
    const script = document.getElementById('accept-js-script');
    if (script) {
      script.remove();
    }

    // remove any existing event handlers
    if (window.acceptJSResponseHandler) {
      window.acceptJSResponseHandler = undefined;
    }
    if (window.savedCardResponseHandler) {
      window.savedCardResponseHandler = undefined;
    }
    if (window.AcceptUI) {
      window.AcceptUI = undefined;
    }

    // remove any Accept.js elements
    const ids = ['AcceptUIBackground', 'AcceptUIContainer'];
    for (const id of ids) {
      const el = document.getElementById(id);
      if (el) {
        el.remove();
      }
    }
  }
}
