@if (errorFetchingInvoice) {
  <div class="text-error">
    <p>{{ errorFetchingInvoice }}</p>
  </div>
} @else if (invoice) {
  <div
    class="mt-5 w-full max-w-3xl rounded-md border-2 border-gray-600 p-4 dark:border-gray-300"
  >
    <div class="flex justify-between">
      <div>
        <p class="mb-2">
          Invoice created on:
          {{ invoice.desktop_invoice.created_at | date: 'short' }}
        </p>
        <p class="text-4xl">
          {{ invoice.desktop_invoice.total_amount | currency: 'USD' }}
        </p>
        <p class="mb-4 mt-2">
          Due by: {{ invoice.due_by | date: 'shortDate' }}
        </p>
      </div>
      <div>
        <svg
          class="h-[100px] w-[100px] text-gray-600 dark:text-gray-300"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1"
            d="M10 3v4c0 .6-.4 1-1 1H5m8-2h3m-3 3h3m-4 3v6m4-3H8M19 4v16c0 .6-.4 1-1 1H6a1 1 0 0 1-1-1V8c0-.4.1-.6.3-.8l4-4 .6-.2H18c.6 0 1 .4 1 1ZM8 12v6h8v-6H8Z"
          />
        </svg>
      </div>
    </div>
    <div class="overflow-x-auto">
      <table class="table table-zebra">
        <thead class="text-sm">
          <tr>
            <th class="font-medium">Product</th>
            <th class="font-medium">Price</th>
            <th class="font-medium">Quantity</th>
            <th class="font-medium">Duration</th>
          </tr>
        </thead>
        <tbody>
          @for (el of groupedLicenses; track el) {
            <tr>
              <td>{{ el.license.product.title }}</td>
              <td>
                {{ getLicensePrice(el.license) | currency: 'USD' }}
              </td>
              <td>{{ el.count }}</td>
              <td>
                {{ getDuration(el.license.product_variant.duration_months) }}
              </td>
            </tr>
          }
        </tbody>
      </table>
      @if (
        invoice.not_renewing_licenses &&
        invoice.not_renewing_licenses.length > 0
      ) {
        <div class="mt-8">
          <p class="text-normal text-error">Not renewing licenses:</p>
          <table class="table table-zebra">
            <thead class="text-sm">
              <tr>
                <th class="font-medium">Product</th>
                <th class="font-medium">Price</th>
                <th class="font-medium">Quantity</th>
                <th class="font-medium">Duration</th>
              </tr>
            </thead>
            <tbody>
              @for (el of groupedNotRenewingLicenses; track el) {
                <tr>
                  <td>{{ el.license.product.title }}</td>
                  <td>
                    {{ getLicensePrice(el.license) | currency: 'USD' }}
                  </td>
                  <td>{{ el.count }}</td>
                  <td>
                    {{
                      getDuration(el.license.product_variant.duration_months)
                    }}
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      }
      @if (!invoice.desktop_invoice.completed) {
        <div class="mr-4 mt-4 text-right text-sm">
          <a
            class="text-blue-500 underline hover:cursor-pointer"
            [routerLink]="'/edit-invoice/' + invoice.desktop_invoice.id"
            >Edit Products</a
          >
        </div>
      }
    </div>
    <div class="ml-auto mt-4 w-64">
      <table class="table">
        <tbody>
          <tr>
            <td>Subtotal:</td>
            <td>
              {{ invoice.desktop_invoice.base_amount | currency: 'USD' }}
            </td>
          </tr>
          <tr>
            <td>Tax:</td>
            <td class="relative">
              {{ invoice.desktop_invoice.tax_amount | currency: 'USD' }}
            </td>
          </tr>
          <tr>
            <td>Total:</td>
            <td>
              {{ invoice.desktop_invoice.total_amount | currency: 'USD' }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    @if (paymentError) {
      <div class="mt-4 text-center text-error">
        {{ paymentError }}
      </div>
    }

    @if (paymentSuccess) {
      <div class="mt-8">
        <p class="text-center text-xl text-success">
          Payment Complete
          {{ invoice.desktop_invoice.completed_at | date: 'short' }}
        </p>
      </div>
      <button
        class="btn btn-primary mt-8"
        [routerLink]="'/receipt/' + invoice.desktop_invoice.id"
      >
        View Receipt
      </button>
    } @else {
      <div class="divider"></div>
      @if (savedPaymentMethods) {
        <div class="mt-4 flex flex-col items-center">
          @for (paymentMethod of savedPaymentMethods; track paymentMethod) {
            <div
              class="m-2 w-fit rounded-lg border border-gray-600 p-4 dark:border-gray-300"
            >
              @if (paymentMethod.is_default) {
                <p class="text-sm italic">Default</p>
              }
              <div class="flex justify-between">
                <input
                  type="radio"
                  name="saved-payment-method"
                  [id]="paymentMethod.id"
                  [value]="paymentMethod.id"
                  (change)="selectedSavedPaymentMethod = paymentMethod"
                />
                <p class="ml-8 self-center text-lg">
                  {{ paymentMethod.card_type }}
                </p>
                <p class="ml-2 self-center text-sm">
                  •••• {{ paymentMethod.last_4_digits }}
                </p>
                <span class="ml-8 text-sm">
                  Exp:<br />
                  {{ paymentMethod.card_expiration }}
                </span>
              </div>
            </div>
          }
          <button
            class="btn btn-primary mt-4"
            (click)="payWithSelectedMethod()"
            [disabled]="!selectedSavedPaymentMethod"
          >
            Pay with Selected Saved Payment Method
          </button>
        </div>
        <div class="divider my-4"></div>
      }
      <div class="relative mt-4">
        <div class="flex justify-center">
          <form>
            <button
              type="button"
              class="AcceptUI btn btn-primary"
              data-billingAddressOptions='{"show":true, "required":true}'
              [attr.data-apiLoginID]="authNetAPILoginID"
              [attr.data-clientKey]="authNetClientKey"
              data-acceptUIFormBtnTxt="Submit"
              data-acceptUIFormHeaderTxt="Card Information"
              data-paymentOptions='{"showCreditCard": true }'
              data-responseHandler="acceptJSResponseHandler"
              [disabled]="paymentProcessing"
            >
              <span class="h-6 w-6"
                ><app-credit-card-icon></app-credit-card-icon
              ></span>
              Pay with a New Card
            </button>
          </form>
          @if (paymentProcessing) {
            <div class="absolute flex h-full">
              <div class="loading loading-spinner">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          }
        </div>
      </div>
    }
  </div>
}
