import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';
import { catchError, of } from 'rxjs';
import {
  InvitationsService,
  ValidateInvitationResponse,
} from 'src/app/services/invitations.service';
import { LoggerService } from 'src/app/services/logger.service';

export const validInvitationResolver: ResolveFn<ValidateInvitationResponse> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const loggerService = inject(LoggerService);
  return inject(InvitationsService)
    .validateInvitation(route.paramMap.get('invitationId')!)
    .pipe(
      catchError((error) => {
        loggerService.error(new Error(error));
        return of({ valid: false });
      })
    );
};
