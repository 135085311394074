import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AuthorizeNetSavedPaymentMethod,
  PaymentsService,
} from 'src/app/services/payments.service';

@Component({
  selector: 'app-remove-payment-method-modal',
  templateUrl: './remove-payment-method-modal.component.html',
  styleUrl: './remove-payment-method-modal.component.scss',
})
export class RemovePaymentMethodModalComponent {
  @Input() methodToRemove?: AuthorizeNetSavedPaymentMethod;
  @Output() deleted = new EventEmitter<AuthorizeNetSavedPaymentMethod>();
  @ViewChild('removePaymentMethodModal')
  removePaymentMethodModal?: ElementRef<HTMLDialogElement>;

  removingSavedMethod = false;
  errorRemovingSavedMethod = '';

  constructor(
    private paymentsService: PaymentsService
  ) {}

  public showRemoveMethodModal() {
    this.removePaymentMethodModal?.nativeElement.showModal();
  }

  hideRemoveMethodModal() {
    this.removePaymentMethodModal?.nativeElement.close();
  }

  deleteSavedPaymentMethod(paymentMethod: AuthorizeNetSavedPaymentMethod) {
    console.log('deleting payment method: ', paymentMethod);
    this.removingSavedMethod = true;
    this.paymentsService.deleteSavedPaymentMethod(paymentMethod).subscribe({
      next: (res) => {
        this.removingSavedMethod = false;
        this.hideRemoveMethodModal();
        console.log('Payment method deleted:', res);
        this.deleted.emit(paymentMethod);
      },
      error: (err) => {
        this.removingSavedMethod = false;
        // this.hideRemoveMethodModal();
        console.error(err);
        this.errorRemovingSavedMethod =
          'There was an error removing the saved payment method.';
      },
    });
  }
}
