import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CurrentInvoicesResolverResponse } from 'src/app/router/resolvers/current-tenant-invoices';
import { InvoiceDetails } from 'src/app/services/invoices.service';
import { LoggerService } from 'src/app/services/logger.service';

@Component({
  selector: 'app-invoices-page',
  templateUrl: './invoices-page.component.html',
  styleUrls: ['./invoices-page.component.scss'],
})
export class InvoicesPageComponent {
  invoices?: {
    pending: InvoiceDetails[];
    complete: InvoiceDetails[];
    expired: InvoiceDetails[];
  };
  allInvoices: InvoiceDetails[] = [];
  errorFetchingInvoices = '';
  // loading = false;

  searchText = '';

  currentPage = 1;

  pageSizeOptions = [10, 15, 20, 50, 100];
  pageSize = 15;

  spacingOptions = ['compact', 'normal', 'comfortable'];
  spacing = 'normal';

  constructor(
    private loggerService: LoggerService,
    private route: ActivatedRoute
  ) {
    this.loggerService.log('InvoiceListComponent constructor');
    const data = this.route.snapshot.data[
      'invoices'
    ] as CurrentInvoicesResolverResponse;
    this.loggerService.log(
      'this.route.snapshot.data: ',
      this.route.snapshot.data
    );

    if (data?.error) {
      this.loggerService.error(new Error(data.error));
      this.errorFetchingInvoices = data.error;
      return;
    } else if (!data.desktop_invoices) {
      this.loggerService.log('No invoices data');
      this.errorFetchingInvoices = 'Error fetching invoices';
      return;
    }

    this.loggerService.log('data.desktop_invoices: ', data.desktop_invoices);
    this.invoices = data.desktop_invoices;

    this.allInvoices.push(...this.invoices.pending);
    this.allInvoices.push(...this.invoices.complete);
    this.allInvoices.push(...this.invoices.expired);

    this.loadSettings();
    // this.getInvoices();
  }

  get pageArray() {
    const pageArray = [];
    for (let i = 1; i <= this.totalPages; i++) {
      pageArray.push(i);
    }
    return pageArray;
  }

  get totalPages() {
    if (!this.allInvoices) return 1;
    return Math.ceil(this.allInvoices.length / this.pageSize);
  }

  loadSettings() {
    try {
      const settings = localStorage.getItem('invoiceTableSettings');
      if (settings) {
        const parsed = JSON.parse(settings);
        this.pageSize = parsed.pageSize;
        this.spacing = parsed.spacing;
      }
    } catch (err) {
      this.loggerService.error(err as Error);
    }
  }

  saveSettings() {
    const settings = {
      pageSize: this.pageSize,
      spacing: this.spacing,
    };
    localStorage.setItem('invoiceTableSettings', JSON.stringify(settings));
  }

  get currentPageInvoices() {
    if (!this.allInvoices) return [];
    const offset = (this.currentPage - 1) * this.pageSize;
    return this.allInvoices.slice(offset, offset + this.pageSize);
    // return this.allInvoices;
  }

  navigateToInvoiceDetails(invoice: InvoiceDetails) {
    window.location.href = '/invoice/' + invoice.desktop_invoice.id;
  }

  changePage(page: number) {
    this.currentPage = page;
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }
}
