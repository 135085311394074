<div class="p-8">
  <div class="flex pb-8">
    <div class="prose">
      <h1>Edit User</h1>
    </div>
  </div>

  @if (fetchingUser) {
    <div class="text-center">
      <div class="loading loading-spinner">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  } @else if (user) {
    <div class="flex space-x-4">
      <div class="flex flex-col space-y-2">
        <div>
          <p>Username:</p>
        </div>
        <div>
          <p>Email:</p>
        </div>
        <div>
          <p>Active:</p>
        </div>
      </div>
      <div class="flex flex-col space-y-2">
        <div>
          <p>{{ user.username }}</p>
        </div>
        <div>
          <p>{{ user.email }}</p>
        </div>
        <div>
          <input type="checkbox" [(ngModel)]="user.active" />
        </div>
      </div>
    </div>

    <div class="mt-8 max-w-sm">
      <div class="flex justify-between">
        <p class="self-center font-semibold">Current Roles</p>
        <button class="btn btn-primary btn-sm" (click)="showAddRoleModal()">
          <app-plus-icon></app-plus-icon> Add Role
        </button>
      </div>

      <ul class="my-4">
        @for (permission of user.permissions; track permission) {
          <li class="px-3 py-2">
            <div class="flex justify-between">
              <div class="mr-4">
                <h6 class="font-semibold">
                  {{ permission.role.display_name }}
                </h6>
                <p class="text-sm">{{ permission.role.description }}</p>
              </div>
              <div class="self-center">
                <app-trash-icon
                  class="block h-6 w-6 cursor-pointer text-error"
                  (click)="showRemoveRoleModal(permission.role)"
                ></app-trash-icon>
              </div>
            </div>
          </li>
        }
      </ul>
    </div>

    <div class="mt-12">
      <div class="flex">
        <button
          class="btn btn-primary mr-8"
          (click)="saveChanges()"
          [disabled]="!hasChanges"
        >
          Save
        </button>
        <button class="btn btn-neutral" (click)="back()">Back</button>
      </div>
    </div>
  }
  @if (error) {
    <div class="mt-4 text-error">
      <p>{{ error }}</p>
    </div>
  }
  @if (errorSavingChanges) {
    <div class="mt-4 text-error">
      <p>{{ errorSavingChanges }}</p>
    </div>
  }
</div>

<dialog class="modal" #removeRoleModal>
  <div class="modal-box p-8">
    <form method="dialog">
      <button class="btn btn-circle btn-ghost btn-sm absolute right-2 top-2">
        ✕
      </button>
    </form>
    @if (roleToRemove) {
      <h3 class="py-4">Remove Role</h3>
      <p class="mb-4">
        Are you sure you want to remove the "{{ roleToRemove.display_name }}"
        role?
      </p>
      <div class="flex justify-center space-x-4">
        <button
          class="btn btn-error btn-sm"
          (click)="removeRole(roleToRemove)"
          [disabled]="removingRole"
        >
          Remove
        </button>
        <button
          class="btn btn-neutral btn-sm"
          (click)="hideRemoveRoleModal()"
          [disabled]="removingRole"
        >
          Cancel
        </button>
      </div>
      @if (removingRole) {
        <div class="mt-4 flex justify-center">
          <div class="loading loading-spinner"></div>
        </div>
      }
    } @else {
      <p class="text-error">No role selected</p>
    }

    @if (errorRemovingRole) {
      <div class="mt-4 text-center text-error">
        <p>{{ errorRemovingRole }}</p>
      </div>
    }
  </div>
</dialog>

<dialog class="modal" #addRoleModal>
  <div class="modal-box overflow-y-visible p-8">
    <form method="dialog">
      <button class="btn btn-circle btn-ghost btn-sm absolute right-2 top-2">
        ✕
      </button>
    </form>
    @if (errorFetchingRoles) {
      <div class="text-error">
        <p>{{ errorFetchingRoles }}</p>
      </div>
    } @else {
      <h3 class="py-4">Add Role</h3>
      <div class="dropdown w-80 overflow-y-visible">
        <div
          tabindex="0"
          role="button"
          class="btn w-full justify-between border-base-content/20 bg-base-100"
        >
          <span>{{ selectedRole?.display_name }}</span>
          <app-chevron-down-icon class="h-5 w-5"></app-chevron-down-icon>
        </div>
        <ul
          tabindex="0"
          class="menu dropdown-content z-[1] w-80 rounded-box bg-base-100 p-2 shadow"
        >
          @for (role of rolesWithAccess; track role; let i = $index) {
            <li
              [ngClass]="{
                'border-t border-base-content/20': i > 0,
              }"
              (click)="setSelectedRole(role)"
            >
              <div class="grid-flow-row gap-1">
                <p class="font-semibold">
                  {{ role.display_name }}
                </p>
                <p class="text-xs">{{ role.description }}</p>
              </div>
            </li>
          }
        </ul>
      </div>
      <div>
        <button
          class="btn btn-primary mt-4"
          (click)="addRole()"
          [disabled]="addingRole"
        >
          Add
        </button>
      </div>
      @if (addingRole) {
        <div class="mt-4 flex justify-center">
          <div class="loading loading-spinner"></div>
        </div>
      }
      @if (errorAddingRole) {
        <div class="mt-4 text-center text-error">
          <p>{{ errorAddingRole }}</p>
        </div>
      }
    }
  </div>
</dialog>

@if (toastMessage) {
  <app-toast-timer
    status="success"
    message="{{ toastMessage }}"
    (clear)="toastMessage = ''"
  ></app-toast-timer>
}
