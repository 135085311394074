import { Component } from '@angular/core';

@Component({
  selector: 'app-trash-icon',
  templateUrl: './trash-icon.component.html',
  styleUrl: './trash-icon.component.scss'
})
export class TrashIconComponent {

}
