import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { AcceptJSService } from 'src/app/services/accept-js.service';
import { AuthService } from 'src/app/services/auth.service';
import { CassResponse, CassService } from 'src/app/services/cass.service';
import { LoggerService } from 'src/app/services/logger.service';
import {
  AuthorizeNetSavedPaymentMethod,
  PaymentsService,
} from 'src/app/services/payments.service';
import { environment } from 'src/environments/environment';

type Input = {
  value: string;
  error: string;
};

@Component({
  selector: 'app-add-payment-method-modal',
  templateUrl: './add-payment-method-modal.component.html',
  styleUrl: './add-payment-method-modal.component.scss',
})
export class AddPaymentMethodModalComponent
  implements AfterViewInit, OnDestroy
{
  @ViewChild('addPaymentMethodModal')
  addPaymentMethodModal!: ElementRef<HTMLDialogElement>;
  @ViewChild('addressCorrectionModal')
  addressCorrectionModal!: ElementRef<HTMLDialogElement>;
  @ViewChild('acceptPaymentButton')
  acceptPaymentButton!: ElementRef<HTMLButtonElement>;

  @Output() methodCreated = new EventEmitter<AuthorizeNetSavedPaymentMethod>();

  inputs: { [prop: string]: Input } = {
    first: { value: '', error: '' },
    last: { value: '', error: '' },
    company: { value: '', error: '' },
    address: { value: '', error: '' },
    city: { value: '', error: '' },
    state: { value: '', error: '' },
    zip: { value: '', error: '' },
  };
  isDefault = false;

  creatingSavedMethod = false;
  savedCardResponse: any;
  loading = false;
  addSavedMethodError = '';

  cassResponse?: CassResponse = undefined;

  constructor(
    private acceptJSService: AcceptJSService,
    private cassService: CassService,
    private loggerService: LoggerService,
    private authService: AuthService,
    private paymentsService: PaymentsService
  ) {}

  ngOnInit() {
    window.savedCardResponseHandler = this.savedCardResponseHandler.bind(this);
  }

  ngAfterViewInit() {
    this.acceptJSService.injectScript();
  }

  ngOnDestroy(): void {
    this.acceptJSService.removeScript();
  }

  get authNetAPILoginID() {
    return environment.authorizeNet.apiLoginID;
  }

  get authNetClientKey() {
    return environment.authorizeNet.clientKey;
  }

  get formattedInputAddress() {
    let str = '';
    if (this.inputs['company'].value) {
      str += `${this.inputs['company'].value}<br>`;
    }
    str +=
      `${this.inputs['address'].value}<br>${this.inputs['city'].value}, ` +
      `${this.inputs['state'].value} ${this.inputs['zip'].value}`;
    return str;
  }

  get formattedCassAddress() {
    if (!this.cassResponse) {
      return '';
    }

    let str = '';
    if (this.cassResponse.company) {
      str += `${this.cassResponse.company}<br>`;
    }
    str +=
      `${this.cassResponse.address}<br>${this.cassResponse.city}, ` +
      `${this.cassResponse.state} ${this.cassResponse.zip}`;
    return str;
  }

  get addressValidated() {
    return (
      this.cassResponse !== undefined &&
      this.cassResponse.dpva !== undefined &&
      this.cassResponse.dpva !== '' &&
      this.cassResponse.dpva !== 'N'
    );
  }

  public showAddMethodModal() {
    this.addPaymentMethodModal.nativeElement.showModal();
  }

  public hideAddMethodModal() {
    this.addPaymentMethodModal.nativeElement.close();
  }

  hideAddressCorrectionModal() {
    this.addressCorrectionModal.nativeElement.close();
  }

  validateInputAddress() {
    if (!this.validateInputs()) {
      return;
    }

    this.cassAddress().subscribe({
      next: (res) => {
        this.cassResponse = res;
        this.promptForAddressCorrection();
        this.loggerService.log('Address validation complete:', res);
      },
      error: (err) => {
        this.promptForAddressCorrection();
        this.loggerService.error(err);
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  validateInputs() {
    for (const key in this.inputs) {
      this.inputs[key].error = '';
    }

    if (
      !(
        (this.inputs['first'].value.trim() &&
          this.inputs['last'].value.trim()) ||
        this.inputs['company'].value.trim()
      )
    ) {
      this.inputs['first'].error =
        'At least one of First/Last or Company is required';
      this.inputs['last'].error =
        'At least one of First/Last or Company is required';
      this.inputs['company'].error =
        'At least one of First/Last or Company is required';
    }

    if (!this.inputs['address'].value.trim()) {
      this.inputs['address'].error = 'Address is required';
    }

    if (!this.inputs['city'].value.trim()) {
      this.inputs['city'].error = 'City is required';
    }

    if (!this.inputs['state'].value.trim()) {
      this.inputs['state'].error = 'State is required';
    }

    if (!this.inputs['zip'].value.trim()) {
      this.inputs['zip'].error = 'Zip is required';
    }

    for (const key in this.inputs) {
      if (this.inputs[key].error) {
        return false;
      }
    }

    return true;
  }

  cassAddress() {
    this.loggerService.log('validating address: ', this.inputs);
    const req = {
      company: this.inputs['company'].value,
      address: this.inputs['address'].value,
      city: this.inputs['city'].value,
      state: this.inputs['state'].value,
      zip: this.inputs['zip'].value,
    };

    this.loading = true;
    this.cassResponse = undefined;

    return this.cassService.validateAddress(req);
  }

  promptForAddressCorrection() {
    this.addressCorrectionModal.nativeElement.showModal();
  }

  updateAddressFromStandardized() {
    this.inputs['company'].value = this.cassResponse?.company || '';
    this.inputs['address'].value = this.cassResponse?.address || '';
    this.inputs['city'].value = this.cassResponse?.city || '';
    this.inputs['state'].value = this.cassResponse?.state || '';
    this.inputs['zip'].value = this.cassResponse?.zip || '';

    this.hideAddressCorrectionModal();
    this.promptForCardInfo();
  }

  promptForCardInfo() {
    this.loggerService.debug('prompting for card info');
    // hide the primary modal so the card info modal is visible
    this.hideAddMethodModal();
    this.acceptPaymentButton.nativeElement.click();
  }

  savedCardResponseHandler(response: any) {
    this.loggerService.log('saved card info response: ', response);
    this.savedCardResponse = JSON.stringify(response, null, 2);

    this.showAddMethodModal();

    if (response.messages.resultCode === 'Ok') {
      this.creatingSavedMethod = true;
      this.loggerService.log('Card info successful');

      const tenantId = this.authService.currentUser?.tenant_id;
      if (!tenantId) {
        this.loggerService.error(new Error('No tenant ID found'));
        return;
      }

      this.paymentsService
        .createNewPaymentMethod({
          opaque_data: response.opaqueData,
          bill_to: {
            firstName: this.inputs['first'].value,
            lastName: this.inputs['last'].value,
            company: this.inputs['company'].value,
            address: this.inputs['address'].value,
            city: this.inputs['city'].value,
            state: this.inputs['state'].value,
            zip: this.inputs['zip'].value,
          },
          tenant_id: tenantId,
          is_default: this.isDefault,
        })
        .subscribe({
          next: (res) => {
            for (const key in this.inputs) {
              this.inputs[key].value = '';
              this.inputs[key].error = '';
            }
            this.loggerService.log('Payment method created:', res);
            this.methodCreated.emit(res.payment_profile);
          },
          error: (err) => {
            this.loggerService.error(err);
            this.addSavedMethodError =
              'There was an error creating your saved payment method.';
          },
          complete: () => {
            this.creatingSavedMethod = false;
          },
        });
    } else {
      this.loggerService.error(
        new Error(JSON.stringify(response.messages, null, 2))
      );
      this.addSavedMethodError = 'There was an error capturing your card info.';
    }

    return;
  }
}
