export const environment = {
  production: false,
  debug: true,
  firebaseConfig: {
    apiKey: 'AIzaSyBxrjr3j_0iaDqbKF0dv6WRD4FoIXOtp2M',
    authDomain: 'customer-portal-dev-403220.firebaseapp.com',
    projectId: 'customer-portal-dev-403220',
    storageBucket: 'customer-portal-dev-403220.appspot.com',
    messagingSenderId: '977337872954',
    appId: '1:977337872954:web:587625cf4299f7a12b3b03',
    measurementId: 'G-MMFWFW4WLW',
  },
  authorizeNet: {
    apiLoginID: '8a8NTmE4W',
    clientKey:
      '9AsU8UJDM3C9ekJ6EcsfN33DpkJKwYu3K7r5gu4v92mcBD6XR6BAhc34QGZwnWaU',
  },
  serverUrl: 'https://customer-portal-server-2j265r5zoa-uc.a.run.app',
};
