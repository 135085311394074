import { Injectable } from '@angular/core';

export type ThemeOption = 'accuzipLightTheme' | 'accuzipDarkTheme';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  theme: ThemeOption = 'accuzipLightTheme';

  constructor() {}

  initTheme() {
    console.log('ThemeService: Initializing theme');
    // check if theme is set in local storage
    const localTheme = this.localThemeSetting();
    if (localTheme) {
      this.setTheme(localTheme);
      console.log(
        'ThemeService: From local - Theme set to',
        this.theme
      );
      return;
    }

    // check if theme is set in system
    const systemTheme = this.systemThemeSetting();
    if (systemTheme) {
      this.setTheme(systemTheme);
      console.log(
        'ThemeService: From system - Theme set to',
        this.theme
      );
    }
  }

  localThemeSetting() {
    const theme = localStorage.getItem('az-theme');
    // check if theme is one of the ThemeOption
    if (theme && ['accuzipLightTheme', 'accuzipDarkTheme'].includes(theme)) {
      return theme as ThemeOption;
    }
    return null;
  }

  systemThemeSetting() {
    if (window.matchMedia) {
      const darkModeOn = window.matchMedia(
        '(prefers-color-scheme: dark)'
      ).matches;
      if (darkModeOn) {
        return 'accuzipDarkTheme' as ThemeOption;
      }
    }
    return 'accuzipLightTheme' as ThemeOption;
  }

  setTheme(theme: ThemeOption, save?: boolean) {
    this.theme = theme;

    document.documentElement.setAttribute('data-theme', theme);

    if (theme === 'accuzipDarkTheme') {
      document.documentElement.classList.add('dark');
    } else if (theme === 'accuzipLightTheme') {
      document.documentElement.classList.remove('dark');
    }

    if (save) {
      localStorage.setItem('az-theme', theme);
    }
  }
}
