<div class="p-4">
  <div class="breadcrumbs text-sm">
    <ul>
      <li>
        <a [routerLink]="['/admin']" class="inline-flex items-center gap-2">
          <app-user-setting-icon
            class="h-5 w-5 text-current"
          ></app-user-setting-icon>
          Admin
        </a>
      </li>
      <li>
        <a
          [routerLink]="['/admin']"
          [queryParams]="{ tab: 'franchises-list' }"
          class="inline-flex items-center gap-2"
        >
          <app-clipboard-icon class="h-5 w-5 text-current"></app-clipboard-icon>
          Franchises List
        </a>
      </li>
      <li>
        <span class="inline-flex items-center gap-2">
          <app-building-icon class="h-5 w-5 text-current"></app-building-icon>
          Franchise Details
        </span>
      </li>
    </ul>
  </div>
  @if (loading) {
    <div class="skeleton h-28 w-28 rounded-md"></div>
    <div class="skeleton mt-8 h-28 rounded-md"></div>
  } @else if (errorFetchingFranchise) {
    <div class="text-error">
      <p>{{ errorFetchingFranchise }}</p>
    </div>
  } @else if (franchiseDetails) {
    <div>
      <div class="card">
        <div class="card-body">
          <div class="card-title">Franchise</div>
          <div class="flex">
            <div class="w-20">
              <p>Name:</p>
            </div>
            <div class="ml-4">
              <p>{{ franchiseDetails.franchise.name }}</p>
            </div>
          </div>

          <div class="flex">
            <div class="w-20">
              <p>Created At:</p>
            </div>
            <div class="ml-4">
              <p>
                {{ franchiseDetails.franchise.created_at | date: 'MM/dd/YYYY, hh:mmaa' }}
              </p>
            </div>
          </div>

          <div
            class="mt-8 overflow-x-auto rounded-lg border border-solid border-black"
          >
            <table class="table table-pin-rows table-pin-cols table-lg">
              <thead class="text-base font-medium">
                <th>Acct #</th>
                <th>Company</th>
                <th>Created Date</th>
                <th></th>
              </thead>
              <tbody>
                @for (tenant of franchiseDetails.tenants; track tenant) {
                  <tr>
                    <td
                      (click)="navigateToTenantDetails(tenant)"
                      class="cursor-pointer"
                    >
                      {{ tenant.account_number }}
                    </td>
                    <td>{{ tenant.company }}</td>
                    <td>{{ tenant.created_at | date: 'MM/dd/YYYY, hh:mmaa' }}</td>
                    <td class="w-24">
                      <button
                        class="btn btn-ghost"
                        (click)="promptForTenantRemove(tenant)"
                      >
                        <app-trash-icon
                          class="h-5 w-5 text-error"
                        ></app-trash-icon>
                      </button>
                    </td>
                  </tr>
                } @empty {
                  <tr>
                    <td colspan="3" class="text-center">
                      <p>No Tenants</p>
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>

          <div class="mt-4 flex justify-end">
            <button class="btn btn-primary" (click)="showAddTenantModal()">
              <app-plus-icon class="h-5 w-5"></app-plus-icon>
              Add Tenant
            </button>
          </div>
        </div>
      </div>
    </div>
  }
</div>

<dialog class="modal" #addTenantModal>
  <div class="modal-box w-full max-w-4xl">
    <form method="dialog">
      <button class="btn btn-circle btn-ghost btn-sm absolute right-2 top-2">
        ✕
      </button>
    </form>
    <h3 class="py-4">Search For Tenants</h3>

    <div class="flex">
      <div class="form-control mr-2 w-40">
        <label class="label">
          <span class="label-text mr-4 whitespace-nowrap">Account Number</span>
        </label>
        <input
          type="text"
          placeholder="Account Number"
          class="input input-bordered"
          [(ngModel)]="searchAccountNumber"
        />
      </div>

      <div class="form-control ml-2 w-80">
        <label class="label">
          <span class="label-text mr-4 whitespace-nowrap">Company Name</span>
        </label>
        <input
          type="text"
          placeholder="Company Name"
          class="input input-bordered"
          [(ngModel)]="searchCompanyName"
        />
      </div>
    </div>

    <div class="mt-4">
      <button
        class="btn btn-primary"
        (click)="searchForTenants()"
        [disabled]="searching"
      >
        Search
      </button>
    </div>

    @if (searchError) {
      <div class="mt-4 text-error">
        <p>{{ searchError }}</p>
      </div>
    } @else if (searching) {
      <div class="loading loading-spinner"></div>
    } @else if (searchResults) {
      <div
        class="mt-8 overflow-x-auto rounded-lg border border-solid border-black"
      >
        <table class="table table-pin-rows table-pin-cols table-lg">
          <thead class="text-base font-medium">
            <th>Acct #</th>
            <th>Company</th>
            <th>Created Date</th>
            <th></th>
          </thead>
          <tbody>
            @for (tenant of searchResults.available; track tenant) {
              <tr>
                <td>{{ tenant.account_number }}</td>
                <td>{{ tenant.company }}</td>
                <td>{{ tenant.created_at | date: 'MM/dd/YYYY, hh:mmaa' }}</td>
                <td class="w-32">
                  <button
                    class="w-18 btn btn-success btn-sm"
                    (click)="addTenantToFranchise(tenant)"
                    [disabled]="tenant.franchise_id"
                  >
                    <app-plus-icon class="h-3 w-3"></app-plus-icon> Add
                  </button>
                </td>
              </tr>
            } @empty {
              <tr>
                <td colspan="3" class="text-center">
                  <p>No Tenants</p>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>

      @if (searchResults.inFranchise.length > 0) {
        <div class="mt-4">
          <p class="">Already in Franchise</p>
          <div
            class="mt-4 overflow-x-auto rounded-lg border border-solid border-black"
          >
            <table class="table table-pin-rows table-pin-cols table-lg">
              <thead class="text-base font-medium">
                <th>Acct #</th>
                <th>Company</th>
                <th>Created Date</th>
                <th></th>
              </thead>
              <tbody>
                @for (tenant of searchResults.inFranchise; track tenant) {
                  <tr class="opacity-40">
                    <td>{{ tenant.account_number }}</td>
                    <td>{{ tenant.company }}</td>
                    <td>{{ tenant.created_at | date: 'MM/dd/YYYY, hh:mmaa' }}</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      }

      @if (errorAddingTenant) {
        <div class="mt-4 text-error">
          <p>{{ errorAddingTenant }}</p>
        </div>
      }
    }
  </div>
</dialog>

<dialog class="modal" #confirmRemoveTenantModal>
  <div class="modal-box">
    <form method="dialog">
      <button class="btn btn-circle btn-ghost btn-sm absolute right-2 top-2">
        ✕
      </button>
    </form>
    <h3 class="py-4">Remove Tenant From Franchise</h3>

    <div class="mt-4">
      <p class="mb-4">
        Are you sure you want to remove this tenant from the franchise?
      </p>

      <div class="flex justify-center space-x-8">
        <button
          class="btn btn-error btn-sm"
          (click)="removeTenantFromFranchise()"
          [disabled]="removingTenant"
        >
          Remove
        </button>
        <button
          class="btn btn-neutral btn-sm"
          (click)="hideConfirmRemoveTenantModal()"
          [disabled]="removingTenant"
        >
          Cancel
        </button>
      </div>

      @if (errorRemovingTenant) {
        <div class="mt-4 text-error">
          <p>{{ errorRemovingTenant }}</p>
        </div>
      }
    </div>
  </div>
</dialog>
