<div class="p-4">
  @if (loading) {
    <div class="skeleton h-28 rounded-md"></div>
  } @else if (errorFetchingFranchises) {
    <div class="text-error">
      <p>{{ errorFetchingFranchises }}</p>
    </div>
  } @else {
    <div class="mt-8">
      <div class="mb-4 flex justify-end">
        <button class="btn btn-primary" (click)="showCreateFranchiseModal()">
          <app-plus-icon class="h-5 w-5"></app-plus-icon>
          Create Franchise
        </button>
      </div>
      <div class="overflow-x-auto rounded-lg border border-solid border-black">
        <table class="table table-zebra table-pin-rows table-pin-cols table-lg">
          <thead class="text-base font-medium">
            <th>Name</th>
            <th>Created Date</th>
          </thead>
          <tbody>
            @for (franchise of franchises; track franchise) {
              <tr
                (click)="navigateToFranchiseDetails(franchise)"
                class="cursor-pointer"
              >
                <td>{{ franchise.name }}</td>
                <td>{{ franchise.created_at | date: 'MM/dd/YYYY, hh:mmaa' }}</td>
              </tr>
            } @empty {
              <tr>
                <td colspan="2" class="text-center">
                  <p>No franchises found</p>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    </div>
  }
</div>

<dialog class="modal" #createFranchiseModal>
  <div class="modal-box">
    <form method="dialog">
      <button class="btn btn-circle btn-ghost btn-sm absolute right-2 top-2">
        ✕
      </button>
    </form>
    <h3 class="py-4">Create Franchise</h3>

    <div class="form-control">
      <label class="label">
        <span class="label-text mr-4 whitespace-nowrap">Name</span>
      </label>
      <input
        type="text"
        placeholder="Franchise Name"
        class="input input-bordered"
        [(ngModel)]="createFranchiseName"
      />
    </div>

    <div class="modal-action">
      <button
        (click)="createFranchise()"
        class="btn btn-primary mr-4"
        [disabled]="creatingFranchise"
      >
        Create Franchise
      </button>
      <form method="dialog">
        <!-- if there is a button in form, it will close the modal -->
        <button class="btn">Close</button>
      </form>
    </div>
    @if (errorCreatingFranchise) {
      <div class="mt-4 text-error">
        <p>{{ errorCreatingFranchise }}</p>
      </div>
    }
  </div>
</dialog>
