import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// import { ProductVariantResolverResponse } from 'src/app/router/resolvers/product-variant';
import {
  Franchise,
  FranchisesService,
} from 'src/app/services/franchises.service';
import {
  ProductVariant,
  ProductsService,
  UpdateProductVariantRequest,
} from 'src/app/services/products.service';

@Component({
  selector: 'app-edit-product-variant',
  templateUrl: './edit-product-variant.component.html',
  styleUrls: ['./edit-product-variant.component.scss'],
})
export class EditProductVariantComponent {
  @ViewChild('changeDialog') changeDialog!: ElementRef<HTMLDialogElement>;

  loadingProductVariant = false;
  originalProductVariant?: ProductVariant;
  errorFetchingProductVariant = '';

  franchises?: Franchise[];
  fetchingFranchises = false;
  errorFetchingFranchises = '';

  deliveryMethods = this.productsService.deliveryMethods.map((m) => ({
    value: m,
    display:
      m === 'DNL' ? 'DNL (download)' : m === 'XXX' ? 'XXX (physical)' : m,
  }));

  productId!: string;
  franchiseId!: string;
  description!: string;
  active!: boolean;
  isDefault!: boolean;
  varianceCode!: string;
  deliveryMethod!: string;
  durationMonths!: number;
  priceLockLevel!: number;
  priceKey!: string;
  sitePriceKey!: string;
  renewalPriceKey!: string;
  siteRenewalPriceKey!: string;
  taxCode!: string;
  siteTaxCode!: string;
  renewalTaxCode!: string;
  siteRenewalTaxCode!: string;
  price!: number;
  sitePrice!: number;
  renewalPrice!: number;
  siteRenewalPrice!: number;

  savingChanges = false;
  productVariantUpdated = false;
  errorUpdatingProductVariant = '';

  constructor(
    private franchisesService: FranchisesService,
    private route: ActivatedRoute,
    private productsService: ProductsService
  ) {}

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    if (!id) {
      console.error(new Error('No product variant id found'));
      this.errorFetchingProductVariant = 'No product variant id found';
      return;
    }
    this.getProductVariant(id);
    this.loadFranchises();
  }

  getProductVariant(id: string) {
    this.loadingProductVariant = true;
    this.productsService.getProductVariant(id).subscribe({
      next: (res) => {
        this.loadingProductVariant = false;
        if (res.product_variant) {
          this.originalProductVariant = res.product_variant;
          this.initializeData(this.originalProductVariant);
        } else {
          console.error(new Error('No product variant returned from server'));
          this.errorFetchingProductVariant = 'No product variant returned';
        }
      },
      error: (err) => {
        this.loadingProductVariant = false;
        console.error(err);
        this.errorFetchingProductVariant = err;
      },
    });
  }

  initializeData(productVariant: ProductVariant) {
    this.productId = productVariant.product_id;
    if (productVariant.franchise_id) {
      this.franchiseId = productVariant.franchise_id;
    } else {
      this.franchiseId = '';
    }
    this.description = productVariant.description;
    this.active = productVariant.active;
    this.isDefault = productVariant.is_default;
    this.varianceCode = productVariant.variance_code;
    this.deliveryMethod = productVariant.delivery_method;
    this.durationMonths = productVariant.duration_months;
    this.priceLockLevel = productVariant.price_lock_level;
    this.priceKey = productVariant.price_key;
    this.sitePriceKey = productVariant.site_price_key;
    this.renewalPriceKey = productVariant.renewal_price_key;
    this.siteRenewalPriceKey = productVariant.site_renewal_price_key;
    this.taxCode = productVariant.tax_code;
    this.siteTaxCode = productVariant.site_tax_code;
    this.renewalTaxCode = productVariant.renewal_tax_code;
    this.siteRenewalTaxCode = productVariant.site_renewal_tax_code;
    this.price = productVariant.price;
    this.sitePrice = productVariant.site_price;
    this.renewalPrice = productVariant.renewal_price;
    this.siteRenewalPrice = productVariant.site_renewal_price;
  }

  loadFranchises() {
    this.fetchingFranchises = true;
    this.franchisesService.getAllFranchises().subscribe({
      next: (res) => {
        this.fetchingFranchises = false;
        this.franchises = res.franchises;
      },
      error: (err) => {
        this.fetchingFranchises = false;
        console.error(err);
        this.errorFetchingFranchises = err.message;
      },
    });
  }

  saveChanges() {
    this.closeChangeDialog();
    if (!this.originalProductVariant) {
      this.errorUpdatingProductVariant =
        'Original product variant data not set';
      return;
    }
    this.savingChanges = true;
    this.productVariantUpdated = false;
    this.errorUpdatingProductVariant = '';

    const updates = this.productVariantUpdates();

    this.productsService
      .updateProductVariant(this.originalProductVariant.id, updates)
      .subscribe({
        next: (response) => {
          console.log('response: ', response);
          this.productVariantUpdated = true;
          this.savingChanges = false;
          this.originalProductVariant = response.product_variant;
        },
        error: (error) => {
          console.error(new Error(error));
          this.errorUpdatingProductVariant = error.message;
          this.savingChanges = false;
        },
      });
  }

  getFranchiseName(franchiseId?: string) {
    if (!this.franchises || !franchiseId) {
      return '';
    }

    for (const franchise of this.franchises) {
      if (franchise.id === franchiseId) {
        return franchise.name;
      }
    }
    return '';
  }

  get hasChanges() {
    if (!this.originalProductVariant) return false;

    // compare franchise id separately. franchiseId equals null on the original
    // product variant, but is converted to an empty string for the component
    let franchiseIdChanged = false;
    if (
      this.originalProductVariant.franchise_id &&
      this.franchiseId !== this.originalProductVariant.franchise_id
    ) {
      franchiseIdChanged = true;
    } else if (
      !this.originalProductVariant.franchise_id &&
      this.franchiseId !== ''
    ) {
      franchiseIdChanged = true;
    }

    return (
      this.productId !== this.originalProductVariant.product_id ||
      franchiseIdChanged ||
      this.description !== this.originalProductVariant.description ||
      this.active !== this.originalProductVariant.active ||
      this.isDefault !== this.originalProductVariant.is_default ||
      this.varianceCode !== this.originalProductVariant.variance_code ||
      this.deliveryMethod !== this.originalProductVariant.delivery_method ||
      this.durationMonths !== this.originalProductVariant.duration_months ||
      this.priceLockLevel !== this.originalProductVariant.price_lock_level ||
      this.priceKey !== this.originalProductVariant.price_key ||
      this.sitePriceKey !== this.originalProductVariant.site_price_key ||
      this.renewalPriceKey !== this.originalProductVariant.renewal_price_key ||
      this.siteRenewalPriceKey !==
        this.originalProductVariant.site_renewal_price_key ||
      this.taxCode !== this.originalProductVariant.tax_code ||
      this.siteTaxCode !== this.originalProductVariant.site_tax_code ||
      this.renewalTaxCode !== this.originalProductVariant.renewal_tax_code ||
      this.siteRenewalTaxCode !==
        this.originalProductVariant.site_renewal_tax_code ||
      this.price !== this.originalProductVariant.price ||
      this.sitePrice !== this.originalProductVariant.site_price ||
      this.renewalPrice !== this.originalProductVariant.renewal_price ||
      this.siteRenewalPrice !== this.originalProductVariant.site_renewal_price
    );
  }

  productVariantUpdates() {
    if (!this.originalProductVariant) return {};

    const updates: UpdateProductVariantRequest = {};
    if (this.productId !== this.originalProductVariant.product_id) {
      updates.product_id = this.productId;
    }
    if (this.franchiseId === '' && this.originalProductVariant.franchise_id) {
      // franchiseId is being removed
      updates.franchise_id = '';
    }
    if (
      this.franchiseId !== '' &&
      this.franchiseId !== this.originalProductVariant.franchise_id
    ) {
      updates.franchise_id = this.franchiseId;
    }
    if (this.description !== this.originalProductVariant.description) {
      updates.description = this.description;
    }
    if (this.active !== this.originalProductVariant.active) {
      updates.active = this.active;
    }
    if (this.isDefault !== this.originalProductVariant.is_default) {
      updates.is_default = this.isDefault;
    }
    if (this.varianceCode !== this.originalProductVariant.variance_code) {
      updates.variance_code = this.varianceCode;
    }
    if (this.deliveryMethod !== this.originalProductVariant.delivery_method) {
      updates.delivery_method = this.deliveryMethod;
    }
    if (this.durationMonths !== this.originalProductVariant.duration_months) {
      updates.duration_months = this.durationMonths;
    }
    if (this.priceLockLevel !== this.originalProductVariant.price_lock_level) {
      updates.price_lock_level = this.priceLockLevel;
    }
    if (this.priceKey !== this.originalProductVariant.price_key) {
      updates.price_key = this.priceKey;
    }
    if (this.sitePriceKey !== this.originalProductVariant.site_price_key) {
      updates.site_price_key = this.sitePriceKey;
    }
    if (
      this.renewalPriceKey !== this.originalProductVariant.renewal_price_key
    ) {
      updates.renewal_price_key = this.renewalPriceKey;
    }
    if (
      this.siteRenewalPriceKey !==
      this.originalProductVariant.site_renewal_price_key
    ) {
      updates.site_renewal_price_key = this.siteRenewalPriceKey;
    }
    if (this.taxCode !== this.originalProductVariant.tax_code) {
      updates.tax_code = this.taxCode;
    }
    if (this.siteTaxCode !== this.originalProductVariant.site_tax_code) {
      updates.site_tax_code = this.siteTaxCode;
    }
    if (this.renewalTaxCode !== this.originalProductVariant.renewal_tax_code) {
      updates.renewal_tax_code = this.renewalTaxCode;
    }
    if (
      this.siteRenewalTaxCode !==
      this.originalProductVariant.site_renewal_tax_code
    ) {
      updates.site_renewal_tax_code = this.siteRenewalTaxCode;
    }
    if (this.price !== this.originalProductVariant.price) {
      updates.price = this.price;
    }
    if (this.sitePrice !== this.originalProductVariant.site_price) {
      updates.site_price = this.sitePrice;
    }
    if (this.renewalPrice !== this.originalProductVariant.renewal_price) {
      updates.renewal_price = this.renewalPrice;
    }
    if (
      this.siteRenewalPrice !== this.originalProductVariant.site_renewal_price
    ) {
      updates.site_renewal_price = this.siteRenewalPrice;
    }
    return updates;
  }

  closeChangeDialog() {
    this.changeDialog.nativeElement.close();
  }
}
