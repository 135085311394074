import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  InvitationsService,
  UserInvitation,
  UserInvitationRequest,
} from 'src/app/services/invitations.service';
import {
  CreateInvoiceRequest,
  InvoiceDetails,
  InvoicesService,
} from 'src/app/services/invoices.service';
import {
  CreateDesktopLicenseRequest,
  DesktopLicenseWithProduct,
  LicensesService,
} from 'src/app/services/licenses.service';
import {
  Product,
  ProductVariant,
  ProductsService,
} from 'src/app/services/products.service';
import { Role, RolesService } from 'src/app/services/roles.service';
import { Tenant, TenantsService } from 'src/app/services/tenants.service';
import {
  UserWithPermissions,
  UsersService,
} from 'src/app/services/users.service';
import { LoggerService } from 'src/app/services/logger.service'; // Import LoggerService
import {
  Franchise,
  FranchisesService,
} from 'src/app/services/franchises.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-tenant-details',
  templateUrl: './tenant-details.component.html',
  styleUrls: ['./tenant-details.component.scss'],
})
export class TenantDetailsComponent {
  @ViewChild('addLicenseDialog', { static: true })
  addLicenseDialog!: ElementRef<HTMLDialogElement>;
  @ViewChild('createInvoiceDialog', { static: true })
  createInvoiceDialog!: ElementRef<HTMLDialogElement>;
  @ViewChild('inviteUserModal') inviteUserModal!: ElementRef<HTMLDialogElement>;

  tenant?: Tenant;
  franchise?: Franchise;
  desktopLicenses?: {
    active: DesktopLicenseWithProduct[];
    expired: DesktopLicenseWithProduct[];
    pending: DesktopLicenseWithProduct[];
  };
  desktopInvoices?: {
    pending: InvoiceDetails[];
    complete: InvoiceDetails[];
    expired: InvoiceDetails[];
  };
  users?: UserWithPermissions[];
  invitations?: {
    pending: UserInvitation[];
    accepted: UserInvitation[];
    expired: UserInvitation[];
  };

  loadingUsers = false;
  loadingInvitations = false;
  loadingTenant = false;
  loadingFranchise = false;
  loadingLicenses = false;
  loadingInvoices = false;

  error = '';
  errorFetchingTenant = '';
  errorFetchingFranchise = '';
  errorFetchingLicenses = '';
  errorFetchingInvoices = '';
  errorFetchingUsers = '';
  errorFetchingInvitations = '';
  errorFetchingRoles = '';

  // showAddLicense = false;
  products: Product[] | undefined;
  productVariants: { [prop: string]: ProductVariant[] } = {};
  addingLicense = false;
  errorAddingLicense = '';

  loadingProducts = false;
  loadingVariants = false;
  productFilters = {
    productId: '',
    duration: '',
  };

  selectingProduct = true;
  currentProduct: Product | undefined;
  currentVariant: ProductVariant | undefined;
  currentParentLicenseId: string = '';

  roles?: Role[];
  selectedRoleId = '';
  invitationEmail = '';
  successSendingInvitation = false;
  sendingInvitation = false;
  errorSendingInvitation = '';

  isSiteLicense = false;
  startDate = new Date();

  creatingInvoice = false;
  errorCreatingInvoice = '';
  licensesIdsForInvoice: string[] = [];

  constructor(
    private authService: AuthService,
    private franchisesService: FranchisesService,
    private invitationsService: InvitationsService,
    private invoicesService: InvoicesService,
    private licensesService: LicensesService,
    private route: ActivatedRoute,
    private productsService: ProductsService,
    private rolesService: RolesService,
    private tenantsService: TenantsService,
    private usersService: UsersService,
    private loggerService: LoggerService
  ) {
    this.startDate.setDate(1);
  }

  ngOnInit(): void {
    this.loggerService.log('ProductDetailsComponent ngOnInit');
    const tenantId = this.route.snapshot.paramMap.get('id');

    if (tenantId === null || tenantId === '') {
      this.loggerService.error(
        new Error('tenant id not provided in route params')
      );
      this.error = 'Tenant ID not provided in route params';
      return;
    }

    this.getTenantDetails(tenantId);
    this.getLicensesForTenant(tenantId);
    this.getInvoicesForTenant(tenantId);
    this.getUsersForTenant(tenantId);
    this.getUserInvitationsForTenant(tenantId);
    this.getRoles();
  }

  getTenantDetails(tenantId: string) {
    this.loadingTenant = true;
    this.tenantsService.getTenantById(tenantId).subscribe({
      next: (tenant) => {
        this.tenant = tenant.tenant;
        if (tenant.tenant.franchise_id) {
          this.getFranchiseDetails(tenant.tenant.franchise_id);
        } else {
          this.loadingTenant = false;
        }
      },
      error: (err) => {
        this.loggerService.error(err);
        this.loadingTenant = false;
        this.errorFetchingTenant = err;
      },
    });
  }

  getFranchiseDetails(franchiseId: string) {
    this.loadingFranchise = true;
    this.franchisesService.getFranchiseById(franchiseId).subscribe({
      next: (franchise) => {
        this.loadingFranchise = false;
        this.loadingTenant = false;
        this.franchise = franchise.franchise;
      },
      error: (err) => {
        this.loadingFranchise = false;
        this.loadingTenant = false;
        this.loggerService.error(err);
        this.errorFetchingFranchise = err;
      },
    });
  }

  getLicensesForTenant(tenantId: string) {
    this.loadingLicenses = true;
    this.licensesService.getLicensesForTenant(tenantId).subscribe({
      next: (licenses) => {
        this.loadingLicenses = false;
        this.loggerService.log('licenses: ', licenses);
        this.desktopLicenses = licenses.desktop_licenses;
      },
      error: (err) => {
        this.loggerService.error(err);
        this.loadingLicenses = false;
        this.errorFetchingLicenses = err;
      },
    });
  }

  getInvoicesForTenant(tenantId: string) {
    this.loadingInvoices = true;
    this.invoicesService.getInvoicesForTenant(tenantId).subscribe({
      next: (invoices) => {
        this.loggerService.log('invoices: ', invoices);
        this.loadingInvoices = false;
        this.desktopInvoices = invoices.desktop_invoices;
      },
      error: (err) => {
        this.loggerService.error(err);
        this.loadingInvoices = false;
        this.errorFetchingInvoices = err;
      },
    });
  }

  getUsersForTenant(tenantId: string) {
    this.loadingUsers = true;
    this.usersService.getUsersForTenant(tenantId).subscribe({
      next: (users) => {
        this.loadingUsers = false;
        this.users = users.users;
      },
      error: (err) => {
        this.loggerService.error(err);
        this.loadingUsers = false;
        this.errorFetchingUsers = err;
      },
    });
  }

  getUserInvitationsForTenant(tenantId: string) {
    this.loadingInvitations = true;
    this.invitationsService.getInvitationsForTenant(tenantId).subscribe({
      next: (res) => {
        this.loadingInvitations = false;
        this.invitations = res.user_invitations;
      },
      error: (err) => {
        this.loggerService.error(err);
        this.loadingInvitations = false;
        this.errorFetchingInvitations = err;
      },
    });
  }

  getRoles() {
    this.rolesService.getRolesWithAccess().subscribe({
      next: (res) => {
        this.roles = res.roles;
      },
      error: (err) => {
        this.errorFetchingRoles = err;
      },
    });
  }

  roleList(user: UserWithPermissions) {
    return user.permissions.map((p) => p.role.display_name).join(', ');
  }

  setStartDate(ev: Event) {
    const startDate = (ev.target as HTMLInputElement).value;
    this.startDate = new Date(startDate);
  }

  showAddLicenseTable() {
    this.selectingProduct = true;
    this.addLicenseDialog?.nativeElement.showModal();

    // this.showAddLicense = true;

    if (this.products === undefined) {
      this.loadingProducts = true;
      // fetch products list
      this.productsService.getAllProducts().subscribe({
        next: (res) => {
          this.loggerService.log('res: ', res);
          if (res.products) {
            this.products = res.products;
            this.products.sort((a, b) => a.code.localeCompare(b.code));
          } else {
            this.loggerService.error(
              new Error('No products returned from server')
            );
            this.products = [];
          }
        },
        error: (err) => {
          this.loggerService.error(err);
          this.products = [];
        },
        complete: () => {
          this.loadingProducts = false;
          this.loggerService.log('getAllProducts complete');
        },
      });
    }
  }

  handleProductChange(ev: Event) {
    const productId = (ev.target as HTMLSelectElement).value;
    const product = this.products?.find((p) => p.id === productId);

    this.currentProduct = product;
    this.productFilters.productId = productId;
    this.productFilters.duration = '';

    this.loadProductVariants(productId);
  }

  loadProductVariants(productId: string) {
    if (this.productVariants[productId] === undefined) {
      // fetch variants list
      this.loadingVariants = true;

      this.productsService.getProductDetails(productId).subscribe({
        next: (res) => {
          this.loggerService.log('res: ', res);
          if (res.product_with_variants) {
            this.productVariants[productId] =
              res.product_with_variants.variants;
          } else {
            this.loggerService.error(
              new Error('No product returned from server')
            );
          }
        },
        error: (err) => {
          this.loggerService.error(err);
        },
        complete: () => {
          this.loadingVariants = false;
          this.loggerService.log('getProductDetails complete');
        },
      });
    } else {
      // already loaded
    }
  }

  get displayVariants() {
    const variants = this.productVariants[this.productFilters.productId];
    if (!variants) return [];

    let displayVariants = variants;

    if (this.productFilters.duration) {
      displayVariants = displayVariants.filter(
        (p) => p.duration_months === +this.productFilters.duration
      );
    }

    return displayVariants;
  }

  get variantDurations() {
    const variants = this.productVariants[this.productFilters.productId];
    if (!variants) return [];
    return variants
      .map((p) => p.duration_months)
      .reduce((acc, curr) => {
        if (acc.indexOf(curr) === -1) {
          acc.push(curr);
        }
        return acc;
      }, [] as number[]);
  }

  displayDeliveryMethod(deliveryMethod: string) {
    switch (deliveryMethod) {
      case 'XXX':
        return 'Physical';
      case 'DNL':
        return 'Download';
      default:
        return '';
    }
  }

  // gets the list of valid licenses to use as the main license for a site license
  getAvailableMainLicenses(product?: Product) {
    if (!product) return [];
    const licenses = this.desktopLicenses?.active;
    if (!licenses) return [];

    return licenses.filter((license) => {
      return (
        license.product.id === product.id &&
        !license.desktop_license.site_license
      );
    });
  }

  handleAddLicenseClick(variant: ProductVariant) {
    this.currentVariant = variant;
    this.selectingProduct = false;
  }

  addLicense() {
    this.addingLicense = true;
    this.loggerService.log('handleAddLicense: ', {
      tenant: this.tenant,
      product: this.currentProduct,
      variant: this.currentVariant,
      startDate: this.startDate,
      isSiteLicense: this.isSiteLicense,
      parentLicenseId: this.currentParentLicenseId,
    });

    const tenant = this.tenant,
      product = this.currentProduct,
      variant = this.currentVariant;
    if (!tenant || !product || !variant) {
      this.loggerService.error(
        new Error('Missing required data to create license')
      );
      return;
    }

    if (this.isSiteLicense && this.currentParentLicenseId === '') {
      this.errorAddingLicense = 'No parent license selected for site license';
      this.loggerService.error(
        new Error('No parent license selected for site license')
      );
      return;
    }

    const req: CreateDesktopLicenseRequest = {
      tenant_id: tenant.id,
      product_variant_id: variant.id,
      start_date: this.startDate,
      is_site_license: this.isSiteLicense,
    };

    if (this.isSiteLicense) {
      req.parent_desktop_license_id = this.currentParentLicenseId;
    }

    this.licensesService.addLicenseToTenant(req).subscribe({
      next: (res) => {
        this.addingLicense = false;
        this.loggerService.log(
          'tenant-details.component -> addLicense -> res: ',
          res
        );
        if (res.desktop_license) {
          const newLicense = res.desktop_license;
          if (this.desktopLicenses) {
            this.desktopLicenses.pending.push(newLicense);
          } else {
            this.desktopLicenses = {
              active: [],
              expired: [],
              pending: [newLicense],
            };
          }

          this.addLicenseDialog?.nativeElement.close();
        } else {
          this.loggerService.error(
            new Error('No desktop_license returned from server')
          );
          this.errorAddingLicense = 'Server error adding license';
        }
      },
      error: (err) => {
        this.addingLicense = false;
        this.loggerService.error(new Error(err));
      },
      complete: () => {
        this.loggerService.log('createDesktopLicense complete');
      },
    });
  }

  showCreateInvoiceModal() {
    this.createInvoiceDialog.nativeElement.showModal();
  }

  get validInvoiceLicenses(): DesktopLicenseWithProduct[] | undefined {
    let all = [];
    if (this.desktopLicenses?.active) {
      all.push(...this.desktopLicenses.active);
    }
    if (this.desktopLicenses?.expired) {
      all.push(...this.desktopLicenses.expired);
    }
    if (this.desktopLicenses?.pending) {
      all.push(...this.desktopLicenses.pending);
    }

    // filter out licenses already used in pending invoices
    if (this.desktopInvoices?.pending) {
      const pendingInvoices = this.desktopInvoices.pending;
      all = all.filter((license) => {
        return !pendingInvoices.find((invoice) => {
          return invoice.desktop_licenses.find(
            (l) => l.desktop_license.id === license.desktop_license.id
          );
        });
      });
    }

    return all;
  }

  handleInvoiceLicenseCheck(ev: Event) {
    const checkbox = ev.target as HTMLInputElement;
    const licenseId = checkbox.value;
    const checked = checkbox.checked;

    if (checked) {
      // add license to pending invoice creation list
      this.licensesIdsForInvoice.push(licenseId);
    } else {
      // remove license from pending invoice creation list
      this.licensesIdsForInvoice = this.licensesIdsForInvoice.filter(
        (id) => id !== licenseId
      );
    }

    this.loggerService.log(
      'licensesIdsForInvoice: ',
      this.licensesIdsForInvoice
    );
  }

  createInvoice() {
    if (this.licensesIdsForInvoice.length === 0) {
      this.errorCreatingInvoice = 'No licenses selected for invoice';
      return;
    }

    this.creatingInvoice = true;
    const request: CreateInvoiceRequest = {
      desktop_license_ids: this.licensesIdsForInvoice,
    };

    this.invoicesService.createInvoice(request).subscribe({
      next: (res) => {
        this.creatingInvoice = false;
        this.loggerService.log('res: ', res);
        if (res.invoice_details) {
          if (this.desktopInvoices) {
            this.desktopInvoices.pending.push(res.invoice_details);
          } else {
            this.desktopInvoices = {
              pending: [res.invoice_details],
              complete: [],
              expired: [],
            };
          }
        } else {
          this.loggerService.error(
            new Error('No invoice_details returned from server')
          );
          this.errorCreatingInvoice = 'Server error creating invoice';
        }
      },
      error: (err) => {
        this.creatingInvoice = false;
        this.loggerService.error(err);
      },
    });
  }

  browseToInvoice(invoiceId: string, tenantId: string) {
    window.location.href =
      '/admin/tenant/' + tenantId + '/invoice/' + invoiceId;
  }

  sendInvitation() {
    if (!this.invitations || !this.tenant) {
      this.errorSendingInvitation = 'Error sending invitation';
      return;
    }

    const request: UserInvitationRequest = {
      email: this.invitationEmail,
      starting_role_id: this.selectedRoleId,
      tenant_id: this.tenant.id,
      first_user: false,
    };

    this.successSendingInvitation = false;
    this.errorSendingInvitation = '';
    this.sendingInvitation = true;
    this.invitationsService.createInvitationForTenant(request).subscribe({
      next: (result) => {
        this.inviteUserModal.nativeElement.close();
        this.sendingInvitation = false;
        if (!this.invitations) {
          this.errorSendingInvitation = 'Error sending invitation';
          return;
        }
        this.successSendingInvitation = true;
        this.invitationEmail = '';
        this.selectedRoleId = '';
        this.loggerService.log('invitation created: ', result);
        this.invitations.pending.push(result.user_invitation);
      },
      error: (err) => {
        this.inviteUserModal.nativeElement.close();
        this.sendingInvitation = false;
        if (err.error?.error === 'email already in use') {
          this.errorSendingInvitation = 'Email already in use';
        } else {
          this.errorSendingInvitation = 'Error creating invitation';
        }
        this.loggerService.error(err);
      },
    });
  }

  canEditUser(userToEdit: UserWithPermissions) {
    if (!this.authService.currentUser) return false;

    return this.usersService.canEditUser(
      this.authService.currentUser,
      userToEdit
    );
  }
}
