import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-graphic-side-panel',
  templateUrl: './graphic-side-panel.component.html',
  styleUrls: ['./graphic-side-panel.component.scss']
})
export class GraphicSidePanelComponent {
  @Input() subtext!: string;
}
