<div class="no-print flex-row">
  <div class="navbar h-16 bg-base-200">
    <div class="flex-1">
      <a
        class="pl-2 pr-11 text-4xl font-medium normal-case"
        [routerLink]="['/']"
        ><span class="text-primary">Accu</span
        ><span class="text-secondary">ZIP</span></a
      >
      <!-- <div class="form-control ml-2">
        <input
          type="text"
          placeholder="Search"
          class="input input-bordered w-24 md:w-auto"
        />
      </div> -->
      @if (currentTenant) {
        <div
          class="tooltip tooltip-right"
          data-tip="Click to copy account number"
        >
          <button class="btn" (click)="copyAccountNumber()">
            <div class="flex items-center">
              Account #: {{ currentTenant.account_number }}
              <app-file-copy-icon class="h-4 w-4"></app-file-copy-icon>
            </div>
          </button>
        </div>
      }
    </div>
    <div class="flex-none space-x-4 text-base">
      <div class="tooltip tooltip-bottom" data-tip="Light/Dark mode toggle">
        <label class="grid cursor-pointer place-items-center">
          <input
            type="checkbox"
            class="toggle col-span-2 col-start-1 row-start-1 h-8 w-14"
            (change)="handleThemeChange($event)"
            [checked]="darkModeEnabled"
          />
          <app-sun-icon
            class="col-start-1 row-start-1 ml-[1px]"
            svgClass="stroke-base-100"
          ></app-sun-icon>
          <app-moon-icon
            class="col-start-2 row-start-1"
            svgClass="fill-base-100"
          ></app-moon-icon>
        </label>
      </div>

      <div>
        <div class="tooltip tooltip-bottom" data-tip="Tasks">
          <button class="btn btn-square">
            <app-clipboard-icon
              class="h-8 w-8 text-current"
            ></app-clipboard-icon>
          </button>
        </div>
        <div class="tooltip tooltip-bottom" data-tip="Notifications">
          <button class="btn btn-square">
            <app-bell-icon class="h-8 w-8 text-current"></app-bell-icon>
          </button>
        </div>
      </div>
      <div class="dropdown dropdown-end">
        <div class="tooltip tooltip-bottom" data-tip="Profile">
          <label tabindex="0" class="btn avatar btn-circle btn-ghost">
            <div class="w-10 rounded-full">
              @if (userImageURL) {
                <img src="{{ userImageURL }}" />
              } @else {
                <app-user-icon class="text-current"></app-user-icon>
              }
            </div>
          </label>
        </div>
        <ul
          tabindex="0"
          class="menu dropdown-content menu-sm z-[1] mt-3 w-60 rounded-box bg-secondary p-2 text-secondary-content shadow"
        >
          @if (currentTenant) {
            <li (click)="copyAccountNumber()">
              <div class="flex items-center">
                Account #: {{ currentTenant.account_number }}
                <app-file-copy-icon class="h-4 w-4"></app-file-copy-icon>
              </div>
            </li>
          }
          <li><a [routerLink]="['/tenant/settings']">Organization</a></li>
          <li><a [routerLink]="['/user-settings']">Profile</a></li>
          <li (click)="signOut()"><a>Logout</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>

@if (accountNumberCopied) {
  <app-toast-timer
    status="info"
    message="Account number copied to clipboard"
    (clear)="accountNumberCopied = false"
  ></app-toast-timer>
}
