import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { FirebaseService } from './firebase.service';
import { mergeMap } from 'rxjs';

export type ValidateInvitationResponse = {
  valid: boolean;
};

export type UserInvitation = {
  id: string;
  tenant_id: string;
  expiration: Date;
  email: string;
  email_transaction_id: string;
  used: boolean;
  revoked: boolean;
  user_id_created: string;
  starting_role_id: string;
  created_by: string;
  created_at: Date;
};

export type UserInvitationListResponse = {
  user_invitations: {
    accepted: UserInvitation[];
    expired: UserInvitation[];
    pending: UserInvitation[];
    revoked: UserInvitation[];
  };
};

export type UserInvitationResponse = {
  user_invitation: UserInvitation;
};

export type UserInvitationRequest = {
  email: string;
  starting_role_id: string;
  tenant_id: string;
  first_user: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class InvitationsService {
  private validateInvitationUrl =
    environment.serverUrl + '/user-invitations/verify';
  private createInvitationUrl =
    environment.serverUrl + '/user-invitations/create';
  private currentTenantInvitationsUrl =
    environment.serverUrl + '/user-invitations/for-current-tenant';
  private getInvitationsForTenantUrl =
    environment.serverUrl + '/user-invitations/for-tenant/';
  private revokeInvitationUrl =
    environment.serverUrl + '/user-invitations/revoke';

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private firebaseService: FirebaseService
  ) {}

  validateInvitation(invitationId: string) {
    return this.http.get<ValidateInvitationResponse>(
      `${this.validateInvitationUrl}/${invitationId}`
    );
  }

  // Creates an invitation for the current user's tenant
  createInvitation(email: string, roleId: string) {
    if (!this.authService.currentUser) {
      throw new Error('No current user');
    }

    return this.createInvitationForTenant({
      email,
      starting_role_id: roleId,
      tenant_id: this.authService.currentUser.tenant_id,
      first_user: false,
    });
  }

  // Creates an invitation for the given tenant
  createInvitationForTenant(request: UserInvitationRequest) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        if (!token) throw new Error('No user token');

        console.log('createInvitation req: ', request);

        return this.http.post<UserInvitationResponse>(
          this.createInvitationUrl,
          request,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }

  getInvitationsForCurrentTenant() {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        if (!token) throw new Error('No user token');

        return this.http.get<UserInvitationListResponse>(
          this.currentTenantInvitationsUrl,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }

  getInvitationsForTenant(tenantId: string) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        if (!token) throw new Error('No user token');

        return this.http.get<UserInvitationListResponse>(
          this.getInvitationsForTenantUrl + tenantId,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }

  revokeInvitation(invitationId: string) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        if (!token) throw new Error('No user token');

        return this.http.put<UserInvitationResponse>(
          this.revokeInvitationUrl + '/' + invitationId,
          null,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }
}
