import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FirebaseService } from './firebase.service';
import { mergeMap } from 'rxjs';

export type Role = {
  id: string;
  name: RoleType;
  display_name: string;
  description: string;
  created_at: Date;
};

export type RoleListResponse = {
  roles: Role[];
};

export type RoleType =
  | 'super_admin'
  | 'internal_admin'
  | 'internal_user'
  | 'owner'
  | 'admin'
  | 'user';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  getRolesWithAccessUrl = environment.serverUrl + '/roles/get-has-access';

  constructor(
    private http: HttpClient,
    private firebaseService: FirebaseService
  ) {}

  getRolesWithAccess() {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.get<RoleListResponse>(this.getRolesWithAccessUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      })
    );
  }
}
