import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import {
  UserWithPermissions,
  UsersService,
} from 'src/app/services/users.service';

type Sort = {
  field: string;
  direction: 'ASC' | 'DESC';
};

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrl: './users-table.component.scss',
})
export class UsersTableComponent {
  @Input('users') users?: UserWithPermissions[];
  @Input('currentSort') currentSort?: Sort;
  @Output('sort-changed') sortChanged = new EventEmitter<string>();

  constructor(
    private authService: AuthService,
    private usersService: UsersService
  ) {}

  roleList(user: UserWithPermissions) {
    return user.permissions.map((p) => p.role.display_name).join(', ');
  }

  canEditUser(userToEdit: UserWithPermissions) {
    if (!this.authService.currentUser) return false;

    return this.usersService.canEditUser(
      this.authService.currentUser,
      userToEdit
    );
  }
}
