import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.scss'],
})
export class AdminPageComponent {
  currentTab:
    | 'tenants-list'
    | 'create-tenant'
    | 'product-list'
    | 'franchises-list'
    | 'kill-switch' = 'tenants-list';

  constructor(private route: ActivatedRoute) {
    this.route.queryParams.subscribe((params) => {
      const tab = params['tab'];
      if (
        tab !== 'tenants-list' &&
        tab !== 'create-tenant' &&
        tab !== 'product-list' &&
        tab !== 'franchises-list' &&
        tab !== 'kill-switch'
      ) {
        this.currentTab = 'tenants-list';
        return;
      }
      this.currentTab = tab;
    });
  }
}
