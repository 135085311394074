import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InvoiceDetails } from 'src/app/services/invoices.service';

type Sort = {
  field: string;
  direction: 'ASC' | 'DESC';
};

@Component({
  selector: 'app-invoices-table',
  templateUrl: './invoices-table.component.html',
  styleUrl: './invoices-table.component.scss',
})
export class InvoicesTableComponent {
  @Input('invoices') invoices?: InvoiceDetails[];
  @Input('current-sort') currentSort?: Sort;
  @Output('sort-changed') sortChanged = new EventEmitter<string>();

  browseToInvoice(invoiceId: string, tenantId: string) {
    window.location.href =
      '/admin/tenant/' + tenantId + '/invoice/' + invoiceId;
  }
}
