<div class="p-4">
  <div class="mb-8 mt-4 flex">
    <label class="form-control">
      <div class="label">
        <span class="label-text">Account Number</span>
      </div>
      <input
        type="text"
        class="input input-bordered"
        (keydown.enter)="searchTenants(); $event.preventDefault()"
        [(ngModel)]="accountSearchQuery"
      />
    </label>
    <label class="form-control ml-4">
      <div class="label">
        <span class="label-text">Company Name</span>
      </div>
      <input
        type="text"
        class="input input-bordered"
        (keydown.enter)="searchTenants(); $event.preventDefault()"
        [(ngModel)]="companySearchQuery"
      />
    </label>
    <div class="ml-8 flex items-end">
      <button class="btn btn-primary" (click)="searchTenants()">Search</button>
    </div>
    <div class="ml-4 flex items-end">
      <button
        class="btn btn-warning"
        (click)="clearSearch()"
        [disabled]="!showingSearchResults"
      >
        Clear
      </button>
    </div>

    @if (searching) {
      <div class="ml-8 flex pt-8">
        <div class="loading loading-spinner"></div>
      </div>
    }
  </div>

  @if (showingSearchResults && !searching) {
    @if (searchResults.length === 0) {
      <div class="my-4">
        <p>No search results found</p>
      </div>
    } @else {
      <div class="my-4">
        <p>Showing {{ searchResults.length }} search results</p>
      </div>
    }
  }

  <div class="overflow-x-auto rounded-lg border border-solid border-black">
    <table
      class="table table-pin-rows table-pin-cols"
      [ngClass]="{
        'table-lg': tableSpacing === 'comfortable',
        'table-md': tableSpacing === 'normal',
        'table-xs': tableSpacing === 'compact',
      }"
    >
      <thead class="text-base font-medium">
        <td>Acct #</td>
        <td>Company</td>
        <td>Created Date</td>
      </thead>
      <tbody>
        @if (errorSearchingTenants) {
          <tr>
            <td colspan="3" class="text-center">
              <p class="text-error">{{ errorSearchingTenants }}</p>
            </td>
          </tr>
        } @else {
          @for (tenant of currentTenants; track tenant; let i = $index) {
            <tr
              (click)="navigateToTenantDetails(tenant)"
              class="cursor-pointer"
              [ngClass]="{ 'bg-base-200': i % 2 === 0 }"
            >
              <td>{{ tenant.account_number }}</td>
              <td>{{ tenant.company }}</td>
              <td>{{ tenant.created_at | date: 'MM/dd/YYYY, hh:mmaa' }}</td>
            </tr>
          } @empty {
            <tr>
              <td colspan="3" class="text-center">
                <p>No tenants found</p>
              </td>
            </tr>
          }
        }
      </tbody>
    </table>
  </div>

  @if (!loading && !searching) {
    <div class="mt-5">
      <app-pagination
        [spacing]="tableSpacing"
        [pageSize]="pageSize"
        [currentPage]="currentPage"
        [totalPages]="totalPages"
        (pageChanged)="changePage($event)"
        (settingsChanged)="handleSettingsChanged($event)"
      ></app-pagination>
    </div>
  }
</div>
