<div>
  <div class="prose">
    <h1>Active Invoices</h1>
  </div>

  @if (errorFetchingInvoices) {
    <div class="mt-4">
      <p class="text-error">{{ errorFetchingInvoices }}</p>
    </div>
  } @else if (allInvoices) {
    <div
      class="mt-8 overflow-x-auto rounded-lg border border-solid border-black"
    >
      <table
        class="table table-pin-rows table-pin-cols"
        [ngClass]="{
          'table-lg': spacing === 'comfortable',
          'table-md': spacing === 'normal',
          'table-xs': spacing === 'compact',
        }"
      >
        <thead class="text-base font-medium">
          <th>Invoice ID</th>
          <th>Paid?</th>
          <th>Total Amount</th>
          <th>Created Date</th>
          <th>Due Date</th>
        </thead>
        <tbody>
          @for (invoice of currentPageInvoices; track invoice; let i = $index) {
            <tr
              class="cursor-pointer text-base"
              [ngClass]="{ 'bg-base-200': i % 2 === 0 }"
              (click)="navigateToInvoiceDetails(invoice)"
            >
              <td>{{ invoice.desktop_invoice.id }}</td>
              <td>{{ invoice.desktop_invoice.completed ? 'Yes' : 'No' }}</td>
              <td>{{ invoice.desktop_invoice.total_amount | currency }}</td>
              <td>
                {{ invoice.desktop_invoice.created_at | date: 'short' }}
              </td>
              <td>
                {{ invoice.desktop_invoice.expiration | date: 'short' }}
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
    <div class="mt-5 flex justify-center">
      <select
        class="select select-bordered mr-2 w-min"
        [(ngModel)]="spacing"
        (change)="saveSettings()"
      >
        <option disabled>Table Spacing</option>
        @for (spacingOption of spacingOptions; track spacingOption) {
          <option [value]="spacingOption">
            {{ spacingOption }}
          </option>
        }
      </select>
      <div class="join">
        <button
          class="btn btn-primary join-item"
          [disabled]="currentPage === 1"
          (click)="changePage(1)"
        >
          First
        </button>
        <button
          class="btn btn-primary join-item"
          [disabled]="currentPage === 1"
          (click)="prevPage()"
        >
          Prev
        </button>
      </div>
      <div class="join mx-4">
        @for (i of pageArray; track i) {
          <button
            class="btn join-item btn-md"
            [class]="{ 'btn-active': currentPage === i }"
            (click)="changePage(i)"
          >
            {{ i }}
          </button>
        }
      </div>
      <div class="join">
        <button
          class="btn btn-primary join-item"
          [disabled]="currentPage === totalPages"
          (click)="nextPage()"
        >
          Next
        </button>
        <button
          class="btn btn-primary join-item"
          [disabled]="currentPage === totalPages"
          (click)="changePage(totalPages)"
        >
          Last
        </button>
      </div>
      <select
        class="select select-bordered ml-2 w-min"
        [(ngModel)]="pageSize"
        (change)="saveSettings()"
      >
        <option disabled>Page Size</option>
        @for (pageSizeOption of pageSizeOptions; track pageSizeOption) {
          <option [value]="pageSizeOption">
            {{ pageSizeOption }}
          </option>
        }
      </select>
    </div>
  }
</div>
