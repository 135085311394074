<div class="flex">
  <app-graphic-side-panel
    class="hidden h-screen w-[50vw] md:flex"
    [subtext]="'Sign up to go to your portal'"
  ></app-graphic-side-panel>
  <div class="flex h-screen w-screen items-center justify-center md:w-[50vw]">
    @if (signedIn) {
      <div class="mx-6 w-full max-w-lg">
        <div class="flex justify-center">
          <div class="loading loading-spinner h-8 w-8"></div>
        </div>
      </div>
    } @else {
      <div class="mx-6 w-full max-w-lg">
        <img
          class="mx-auto my-4 w-36 md:hidden"
          src="../../../assets/accuzip-logo-white.png"
          alt="AccuZIP Logo"
        />
        <h1
          class="z-10 m-auto hidden max-w-lg select-none text-5xl font-semibold leading-normal md:block"
        >
          Sign Up
        </h1>
        <h1
          class="z-10 m-auto max-w-lg select-none text-center text-3xl font-semibold leading-normal md:hidden"
        >
          Sign up to the Customer Portal
        </h1>
        <!-- <p>Invitation ID: {{ invitationId }}</p> -->
        <!-- <p>Invitation Valid?: {{ invitationValid }}</p> -->
        @if (verifyingInvitation) {
          <div class="mt-8">
            <div class="loading loading-spinner loading-lg"></div>
          </div>
        } @else if (!invitationValid) {
          <div class="my-8">
            <p class="text-error">
              This invitation is no longer valid. Please resend a new invitation
              or contact support for assistance.
            </p>
          </div>
        } @else if (!userCreated) {
          <div class="my-8">
            <input
              type="text"
              class="input input-bordered w-full"
              placeholder="Email"
              [(ngModel)]="email"
            />
          </div>

          <div class="my-8">
            <input
              type="password"
              class="input input-bordered w-full"
              placeholder="Password"
              [(ngModel)]="password"
            />
          </div>

          <div class="my-8">
            <input
              type="password"
              class="input input-bordered w-full"
              placeholder="Confirm Password"
              [(ngModel)]="confirmPassword"
            />
          </div>

          <label class="label my-8 cursor-pointer justify-center">
            <input
              type="checkbox"
              class="checkbox"
              [(ngModel)]="agreedToTerms"
            />
            <span class="label-text pl-3"
              >By checking this box I acknowledge that I have read and agree to
              the
              <a
                target="_blank"
                class="link"
                [routerLink]="['/terms-and-conditions']"
                >terms and conditions</a
              >
              and
              <a target="_blank" class="link" [routerLink]="['/privacy-policy']"
                >privacy policy</a
              >.</span
            >
          </label>

          <div>
            <button
              class="btn btn-primary w-full"
              (click)="createUserWithEmail()"
              [disabled]="creatingUser || userCreated || !agreedToTerms"
            >
              Sign up with Email
            </button>
          </div>

          <div class="my-6 flex">
            <hr class="my-auto w-full border-current" />
            <p class="px-4 text-center text-sm">Or</p>
            <hr class="my-auto w-full border-current" />
          </div>

          <div class="my-4">
            <button
              class="btn w-full border border-[#8E918F] bg-[#131314] text-[#E3E3E3] hover:border hover:border-[#8E918F] hover:bg-[#131314]"
              (click)="createUserWithGoogle()"
              [disabled]="creatingUser || userCreated || !agreedToTerms"
            >
              <div class="h-5 w-5">
                <app-google-icon></app-google-icon>
              </div>
              Sign up with Google
            </button>
          </div>

          <div class="my-6">
            <p class="text-center">
              Already have an account?
              <a class="link" [routerLink]="'/login'">Sign in here</a>
            </p>
          </div>
        } @else {
          <p class="my-4 text-success">
            An email verification link was sent to {{ verificationEmail }}
          </p>

          <p>
            Please check your email for the verification link and follow the
            instructions to verify your account.
          </p>
        }

        @if (error) {
          <p class="mt-4 text-lg text-red-500">{{ error }}</p>
        }
      </div>
    }
  </div>
</div>
