<div class="p-8">
  <div class="prose pb-8">
    <h1>Organization Settings</h1>
  </div>

  @if (loadingTenant) {
    <div class="skeleton h-52 w-52"></div>
  } @else if (tenant) {
    <div class="text-lg">
      <div class="flex">
        <div class="w-24">
          <p>Account #:</p>
        </div>
        <div class="ml-4">
          <p>{{ tenant.account_number }}</p>
        </div>
      </div>

      <div class="mt-4 flex">
        <div class="w-24">
          <p>Company:</p>
        </div>
        <div class="ml-4">
          <p>{{ tenant.company }}</p>
        </div>
      </div>

      <div class="mt-4 flex">
        <div class="w-24">
          <p>Address:</p>
        </div>
        <div class="ml-4">
          <p>{{ tenant.address }}</p>
          <p>{{ tenant.city }}, {{ tenant.st }} {{ tenant.zip }}</p>
        </div>
      </div>

      <div class="mt-4 flex">
        <div class="w-24">
          <p>Emails:</p>
        </div>
        <div class="ml-4">
          @for (email of tenant.emails; track email) {
            <p>{{ email }}</p>
          } @empty {
            <p>No Emails</p>
          }
        </div>
      </div>

      <div class="mt-4 flex">
        <div class="w-24">
          <p>Phone #s:</p>
        </div>
        <div class="ml-4">
          @if (tenant.phone1) {
            <p>{{ tenant.phone1 }}</p>
          }
          @if (tenant.phone2) {
            <p>{{ tenant.phone2 }}</p>
          }
          @if (!tenant.phone1 && !tenant.phone2) {
            <p>No Phone Numbers</p>
          }
        </div>
      </div>
    </div>

    <div class="mt-4 flex">
      <button class="btn btn-primary btn-sm" [routerLink]="'../update-info'">
        Edit Info
      </button>
    </div>

    @if (canManagePaymentMethods) {
      <div class="divider my-8"></div>
      <h4 class="text-xl">Autopay Settings</h4>
      <div class="mt-4 flex w-72 items-center justify-between">
        <div>
          <div class="stat-title">Autopay</div>
          <div
            class="stat-value"
            [ngClass]="{
              'text-success': tenant.autopay_enabled && !showAutopayWarning,
              'text-warning': showAutopayWarning,
              'text-error': !tenant.autopay_enabled,
            }"
          >
            {{ tenant.autopay_enabled ? 'On' : 'Off' }}
          </div>
          <div class="stat-desc">Use toggle to enable/disable</div>
        </div>
        <div
          [ngClass]="{
            'text-success': tenant.autopay_enabled && !showAutopayWarning,
            'text-warning': showAutopayWarning,
            'text-error': !tenant.autopay_enabled,
          }"
        >
          <input
            type="checkbox"
            class="toggle toggle-lg"
            [ngClass]="{
              'toggle-success': tenant.autopay_enabled,
              'toggle-warning': showAutopayWarning,
            }"
            (click)="handleAutopayToggle($event)"
            [checked]="tenant.autopay_enabled"
          />
        </div>
      </div>

      @if (showAutopayWarning) {
        <div role="alert" class="alert alert-warning mt-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            class="h-6 w-6 shrink-0 stroke-base-300"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
          <span
            >You have selected to disable autopay. This will require manual
            payment of all invoices to stay current on payments. With autopay
            disabled nothing will be paid for automatically without manual
            intervention.</span
          >
          <div>
            <button
              class="btn btn-neutral btn-sm"
              (click)="showAutopayWarning = false"
            >
              Cancel
            </button>
          </div>
          <div>
            <button class="btn btn-error btn-sm" (click)="disableAutopay()">
              Disable Autopay
            </button>
          </div>
        </div>
      }

      <div class="divider my-8"></div>

      <div class="mb-4 flex">
        <h4 class="text-xl">Saved Payment Methods</h4>
        <button
          class="btn btn-primary btn-sm ml-16"
          (click)="addPaymentMethodModal.showAddMethodModal()"
        >
          <app-plus-icon class="h-4 w-4"></app-plus-icon> Add
        </button>
      </div>
      @if (errorFetchingPaymentMethods) {
        <div class="text-center">
          <p class="text-error">{{ errorFetchingPaymentMethods }}</p>
        </div>
      } @else if (savedPaymentMethods) {
        <div>
          <div class="mt-4">
            <div class="flex flex-wrap">
              @for (paymentMethod of savedPaymentMethods; track paymentMethod) {
                <app-saved-payment-card
                  [paymentMethod]="paymentMethod"
                  [canManagePaymentMethods]="canManagePaymentMethods"
                  (setDefault)="setDefaultPaymentMethod($event)"
                  (deleteMethod)="confirmDeleteSavedMethod($event)"
                ></app-saved-payment-card>
              } @empty {
                <p>No saved payment methods</p>
              }
            </div>
          </div>

          @if (savedPaymentError) {
            <div class="mt-4 text-center text-error">
              <p>{{ savedPaymentError }}</p>
            </div>
          }
        </div>
      }
    }
  }
</div>

<app-add-payment-method-modal
  #addPaymentMethodModal
  (methodCreated)="handlePaymentMethodCreation($event)"
></app-add-payment-method-modal>

<app-remove-payment-method-modal
  #removePaymentMethodModal
  [methodToRemove]="methodToRemove"
  (deleted)="savedMethodDeleted($event)"
></app-remove-payment-method-modal>

@if (toastMessage) {
  <app-toast-timer
    [message]="toastMessage"
    [status]="toastStatus"
    (clear)="toastMessage = ''"
  ></app-toast-timer>
}
