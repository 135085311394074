@if (showNav) {
  <div class="no-print fixed left-0 top-0 z-10 hidden h-16 w-full md:block">
    <app-top-nav></app-top-nav>
  </div>

  <div
    class="no-print fixed left-0 top-16 z-10 hidden h-[calc(100%_-_4rem)] w-52 md:flex"
  >
    <app-side-nav></app-side-nav>
  </div>

  <div class="no-print fixed left-0 top-0 z-10 h-16 w-full md:hidden">
    <app-mobile-nav></app-mobile-nav>
  </div>
}

<div
  [ngClass]="{
    'md:ml-52': showNav,
    'mt-16': showNav,
    'mb-16': showNav,
    'md:mb-0': showNav,
  }"
>
  <div
    [ngClass]="{
      'p-8': showNav,
      'max-w-7xl': showNav,
    }"
    class="m-auto"
  >
    @if (error) {
      <div class="flex h-full items-center justify-center">
        <div class="text-2xl text-red-500">{{ error }}</div>
      </div>
    } @else if (loading) {
      <div class="flex h-full items-center justify-center">
        <div class="loading loading-spinner loading-lg"></div>
      </div>
    } @else if (appReady && !loading) {
      <router-outlet></router-outlet>
    }
  </div>
</div>
