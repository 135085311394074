import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FirebaseService } from './firebase.service';
import { mergeMap } from 'rxjs';

type DesktopPaymentDetailsResponse = {
  payment_details: DesktopPaymentDetails;
};

export type DesktopPaymentDetails = {
  desktop_payment: DesktopPayment;
  payment_method: string;
  // transactions: AuthorizeNetTransaction[];
};

type DesktopPayment = {
  id: string;
  desktop_invoice_id: string;
  completed: boolean;
  status: DesktopPaymentStatus;
  created_at: Date;
  updated_at: Date;
};

type DesktopPaymentStatus =
  | 'created'
  | 'processing'
  | 'failed'
  | 'declined'
  | 'held'
  | 'completed'
  | 'cancelled'
  | 'refunding'
  | 'refunded';

// type AuthorizeNetTransaction = {
//   id: string;
//   ref_id: string;
//   amount: number;
//   auto_payment: boolean;
//   create_transaction_request: any;
//   completed: boolean;
//   transaction_response: any;
//   commit_response: any;
//   desktop_payment_id: string;
//   created_at: Date;
//   created_by: string;
//   updated_at: Date;
// };

@Injectable({
  providedIn: 'root',
})
export class DesktopPaymentsService {
  getPaymentDetailsByInvoiceIdUrl =
    environment.serverUrl + '/desktop-payments/get-for-invoice';

  constructor(
    private http: HttpClient,
    private firebaseService: FirebaseService
  ) {}

  getDesktopPaymentDetails(invoiceId: string) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.get<DesktopPaymentDetailsResponse>(
          `${this.getPaymentDetailsByInvoiceIdUrl}/${invoiceId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      })
    );
  }
}
