<div>
  <div class="flex text-lg">
    <div class="w-24">Account #:</div>
    <div class="ml-4">
      <p>{{ tenant.account_number }}</p>
    </div>
  </div>

  <div class="mt-2 flex text-lg">
    <div class="w-24">Company:</div>
    <div class="ml-4">
      <p>{{ tenant.company }}</p>
    </div>
  </div>

  <div class="mt-2 flex">
    <div class="w-24">
      <p>Address:</p>
    </div>
    <div class="ml-4">
      <p>{{ tenant.address }}</p>
      <p>{{ tenant.city }}, {{ tenant.st }} {{ tenant.zip }}</p>
    </div>
  </div>

  <div class="mt-2 flex">
    <div class="w-24">
      <p>Emails:</p>
    </div>
    <div class="ml-4">
      @for (email of tenant.emails; track email) {
        <p>{{ email }}</p>
      } @empty {
        <p>No Emails</p>
      }
    </div>
  </div>

  <div class="mt-2 flex">
    <div class="w-24">
      <p>Phone #s:</p>
    </div>
    <div class="ml-4">
      @if (tenant.phone1) {
        <p>{{ tenant.phone1 }}</p>
      }
      @if (tenant.phone2) {
        <p>{{ tenant.phone2 }}</p>
      }
      @if (!tenant.phone1 && !tenant.phone2) {
        <p>No Phone Numbers</p>
      }
    </div>
  </div>

  <div class="mt-2 flex">
    <div class="w-24">
      <p>Franchise:</p>
    </div>
    @if (franchise) {
      <div class="ml-4">
        <p>
          <a class="link" [routerLink]="'/admin/franchise/' + franchise.id">{{
            franchise.name
          }}</a>
        </p>
      </div>
    } @else {
      <div class="ml-4">
        <p>N/A</p>
      </div>
    }
  </div>

  <div class="mt-2 flex">
    <div class="w-24">
      <p>Created At:</p>
    </div>
    <div class="ml-4">
      <p>{{ tenant.created_at | date: 'MM/dd/YYYY, hh:mmaa' }}</p>
    </div>
  </div>

  <div class="mt-2 flex">
    <div class="w-24">
      <p>Notes:</p>
    </div>
    <textarea class="textarea textarea-bordered ml-4 w-72" rows="3" readonly>{{
      tenant.notes
    }}</textarea>
  </div>

  <div class="mt-4 flex">
    <a class="link text-sm" [routerLink]="'./edit'">Edit Tenant</a>
  </div>

  @if (!tenantAddressValid(tenant)) {
    <div class="mt-4 flex max-w-96">
      <p class="italic text-error">
        This tenant's address is not valid. We're unable to create invoices for
        this tenant until it is updated. The address is required for tax
        calculations.
      </p>
    </div>
  }
</div>
