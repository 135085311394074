import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent {
  constructor(private authService: AuthService) {}

  get showAdmin() {
    return this.authService.currentUser?.permissions.find(
      (p) => p.role.name === 'internal_admin' || p.role.name === 'super_admin'
    );
  }
}
