import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';
import { catchError, mergeMap, throwError } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import {
  InvoiceDetails,
  InvoicesService,
} from 'src/app/services/invoices.service';
import { LoggerService } from 'src/app/services/logger.service';

export type InvoiceDetailsResolverResponse = {
  invoice_details?: InvoiceDetails;
  error?: string;
};

export const invoiceDetailsResolver: ResolveFn<
  InvoiceDetailsResolverResponse
> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const invoiceService = inject(InvoicesService);
  const loggerService = inject(LoggerService);
  return authService.authReady.pipe(
    mergeMap((ready) => {
      if (!ready) {
        throwError(() => new Error('User not authenticated'));
      }
      return invoiceService.getInvoiceDetails(route.paramMap.get('id')!).pipe(
        catchError((err) => {
          loggerService.error(err);
          return [{ error: 'Error getting invoice details' }];
        })
      );
    })
  );
};
