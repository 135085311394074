import { Component } from '@angular/core';

@Component({
  selector: 'app-chevron-down-icon',
  templateUrl: './chevron-down-icon.component.html',
  styleUrl: './chevron-down-icon.component.scss'
})
export class ChevronDownIconComponent {

}
