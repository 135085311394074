@if (!showSMSSetup && !showTOTPSetup) {
  <div>
    <h1
      class="z-10 m-auto mb-8 max-w-lg text-3xl font-semibold leading-normal md:block md:text-5xl"
    >
      Choose 2FA Method
    </h1>
    <p class="mt-4">
      Please choose a Two Factor Authentication (2FA) method to secure your
      account. We recommend using an authenticator app instead of text message
      (SMS) for better security.
    </p>
  </div>
  <button class="btn btn-primary my-6 w-full" (click)="setupTOTP2FA()">
    Authenticator App (Recommended)
  </button>
  <button class="btn btn-secondary w-full" (click)="setupSMS2FA()">
    Text Message (SMS)
  </button>
} @else if (showTOTPSetup) {
  <app-totp-enrollment
    [totpInfo]="totpInfo"
    (verifyCode)="verifyTOTPForEnrollment($event)"
    (goBack)="goBackTo2FAChoices()"
  ></app-totp-enrollment>
} @else if (showSMSSetup) {
  <app-sms-enrollment
    [smsSent]="smsSent"
    (sendSMS)="sendSMSForEnrollment($event)"
    (verifyCode)="verifySMSCodeForEnrollment($event)"
    (goBack)="goBackTo2FAChoices()"
  ></app-sms-enrollment>
}

@if (error) {
  <div class="text-error mt-8 text-center">
    <p>{{ error }}</p>
  </div>
}
