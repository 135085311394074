import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  Franchise,
  FranchisesService,
} from 'src/app/services/franchises.service';

@Component({
  selector: 'app-franchises-list',
  templateUrl: './franchises-list.component.html',
  styleUrl: './franchises-list.component.scss',
})
export class FranchisesListComponent {
  @ViewChild('createFranchiseModal', { static: true })
  createFranchiseModal!: ElementRef<HTMLDialogElement>;

  franchises?: Franchise[];
  loading = false;
  errorFetchingFranchises = '';

  createFranchiseName = '';
  creatingFranchise = false;
  errorCreatingFranchise = '';

  constructor(
    private franchisesService: FranchisesService,
    private router: Router
  ) {}

  ngOnInit(): void {
    console.log('franchise-list.component -> ngOnInit');
    this.loading = true;
    this.franchisesService.getAllFranchises().subscribe({
      next: (franchises) => {
        this.loading = false;
        console.log(
          'franchise-list.component -> ngOnInit -> franchises: ',
          franchises
        );
        this.franchises = franchises.franchises;
      },
      error: (err) => {
        this.loading = false;
        console.error(err);
        this.errorFetchingFranchises = err.message;
      },
    });
  }

  navigateToFranchiseDetails(franchise: Franchise) {
    this.router.navigate([`admin/franchise/${franchise.id}`]);
  }

  showCreateFranchiseModal() {
    this.createFranchiseModal.nativeElement.show();
  }

  createFranchise() {
    if (this.createFranchiseName.trim() === '') {
      this.errorCreatingFranchise = 'Name is required';
      return;
    }

    this.creatingFranchise = true;
    this.franchisesService
      .createFranchise({
        name: this.createFranchiseName,
      })
      .subscribe({
        next: (franchise) => {
          this.createFranchiseName = '';
          this.creatingFranchise = false;
          console.log('franchise: ', franchise);
          if (!this.franchises) {
            this.franchises = [];
          }
          this.franchises.push(franchise.franchise);
          this.createFranchiseModal.nativeElement.close();
        },
        error: (err) => {
          this.creatingFranchise = false;
          console.error(err);
          this.errorCreatingFranchise = err.message;
        },
      });
  }
}
