import { Component, ElementRef, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  TenantResponse,
  TenantsService,
} from '../../../../services/tenants.service';
import {
  Distributor,
  DistributorsService,
} from 'src/app/services/distributors.service';
import {
  Franchise,
  FranchisesService,
} from 'src/app/services/franchises.service';
import {
  CassRequest,
  CassResponse,
  CassService,
} from 'src/app/services/cass.service';

@Component({
  selector: 'app-create-tenant',
  templateUrl: './create-tenant.component.html',
  styleUrls: ['./create-tenant.component.scss'],
})
export class CreateTenantComponent {
  @ViewChild('cassModal') cassModal!: ElementRef<HTMLDialogElement>;

  loadingDistributors = false;
  loadingFranchises = false;

  distributors: Distributor[] = [];
  franchises: Franchise[] = [];

  formGroup = new FormGroup({
    distributor: new FormControl('', [Validators.required]),
    franchise: new FormControl(''),
    companyName: new FormControl('', [Validators.required]),
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', [Validators.required, this.phoneValidator]),
    address: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    st: new FormControl('', [Validators.required]),
    zip: new FormControl('', [Validators.required]),
  });

  get distributor() {
    return this.formGroup.get('distributor')!;
  }
  get franchise() {
    return this.formGroup.get('franchise')!;
  }
  get companyName() {
    return this.formGroup.get('companyName')!;
  }
  get firstName() {
    return this.formGroup.get('firstName')!;
  }
  get lastName() {
    return this.formGroup.get('lastName')!;
  }
  get email() {
    return this.formGroup.get('email')!;
  }
  get phone() {
    return this.formGroup.get('phone')!;
  }
  get address() {
    return this.formGroup.get('address')!;
  }
  get city() {
    return this.formGroup.get('city')!;
  }
  get st() {
    return this.formGroup.get('st')!;
  }
  get zip() {
    return this.formGroup.get('zip')!;
  }

  states = states;

  validatingAddress = false;
  cassError = '';
  cassInputAddress?: CassRequest;
  cassResponse?: CassResponse;

  creatingTenant = false;
  createResponse?: TenantResponse;
  createError = '';

  constructor(
    private cassService: CassService,
    private distributorsService: DistributorsService,
    private franchisesService: FranchisesService,
    private tenantsService: TenantsService
  ) {
    this.loadDistributors();
    this.loadFranchises();
  }

  loadDistributors() {
    this.loadingDistributors = true;
    this.distributorsService.getAllDistributors().subscribe({
      next: (res) => {
        console.log('Response from server: ', res);
        this.distributors = res.distributors;
      },
      error: (err) => {
        console.error(err);
      },
      complete: () => {
        this.loadingDistributors = false;
      },
    });
  }

  loadFranchises() {
    this.loadingFranchises = true;
    this.franchisesService.getAllFranchises().subscribe({
      next: (res) => {
        console.log('Response from server: ', res);
        this.franchises = res.franchises;
      },
      error: (err) => {
        console.error(new Error(err));
      },
      complete: () => {
        this.loadingFranchises = false;
      },
    });
  }

  phoneValidator(control: AbstractControl) {
    if (control.value.replace(/\D/g, '').length !== 10) {
      return { phoneNumberError: true };
    }
    return null;
  }

  validateAddress() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    this.validatingAddress = true;
    this.cassInputAddress = undefined;
    this.cassResponse = undefined;
    this.cassError = '';
    this.cassModal.nativeElement.showModal();

    const cassReq: CassRequest = {
      company: this.companyName.value!,
      address: this.address.value!,
      city: this.city.value!,
      state: this.st.value!,
      zip: this.zip.value!,
    };

    this.cassInputAddress = cassReq;

    this.cassService.validateAddress(cassReq).subscribe({
      next: (res) => {
        console.log('Response from server: ', res);
        this.validatingAddress = false;
        this.cassError = '';
        this.cassResponse = res;
      },
      error: (err) => {
        console.error(err);
        this.validatingAddress = false;
        this.cassError = err.message;
      },
    });
  }

  closeCassModal() {
    this.cassModal.nativeElement.close();
  }

  createTenantWithCassResponse(cassResponse: CassResponse) {
    this.closeCassModal();

    this.formGroup.get('companyName')?.setValue(cassResponse.company);
    this.formGroup.get('address')?.setValue(cassResponse.address);
    this.formGroup.get('city')?.setValue(cassResponse.city);
    this.formGroup.get('st')?.setValue(cassResponse.state);
    this.formGroup.get('zip')?.setValue(cassResponse.zip);

    this.createTenant();
  }

  createTenant() {
    this.closeCassModal();

    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    const createRequest = {
      distributor_id: this.distributor.value!,
      franchise_id: this.franchise.value,
      company_name: this.companyName.value!,
      first_name: this.firstName.value!,
      last_name: this.lastName.value!,
      email: this.email.value!,
      phone: this.phone.value!,
      address: this.address.value!,
      city: this.city.value!,
      st: this.st.value!,
      zip: this.zip.value!,
      send_invite: false,
    };

    console.log(`Creating tenant with `, createRequest);

    this.creatingTenant = true;
    this.tenantsService.createTenant(createRequest).subscribe({
      next: (res) => {
        this.creatingTenant = false;
        console.log('Response from server: ', res);
        this.createResponse = res;
        this.resetValues();
      },
      error: (err) => {
        this.creatingTenant = false;
        console.error(err);
        this.createError = err.message;
      },
    });
  }

  resetValues() {
    this.formGroup.reset();
    this.formGroup.get('distributor')?.reset('');
    this.formGroup.get('franchise')?.reset('');
    this.formGroup.get('companyName')?.reset('');
    this.formGroup.get('firstName')?.reset('');
    this.formGroup.get('lastName')?.reset('');
    this.formGroup.get('email')?.reset('');
    this.formGroup.get('phone')?.reset('');
    this.formGroup.get('address')?.reset('');
    this.formGroup.get('city')?.reset('');
    this.formGroup.get('st')?.reset('');
    this.formGroup.get('zip')?.reset('');
  }
}

const states = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FM',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MH',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'MP',
  'OH',
  'OK',
  'OR',
  'PW',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VI',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];
