import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-toast-timer',
  templateUrl: './toast-timer.component.html',
  styleUrls: ['./toast-timer.component.scss'],
})
export class ToastTimerComponent {
  @Input() status: '' | 'success' | 'error' | 'warning' | 'info' = '';
  @Input() message: string = '';

  @Output() clear = new EventEmitter<void>();

  isHoveringToast: boolean = false;
  intervalID?: any;

  ngOnInit() {
    this.startToastTimer();
  }

  startToastTimer() {
    // clear any existing interval
    clearInterval(this.intervalID);

    let counter: number = 10;
    this.intervalID = setInterval(() => {
      if (counter > 0 && !this.isHoveringToast) {
        counter--;
      } else if (counter <= 0) {
        this.clearToastTimer();
      }
      document
        .getElementById('toastTimer')
        ?.style.setProperty('--value', counter.toString());
    }, 1000);
  }

  clearToastTimer() {
    clearInterval(this.intervalID);
    this.status = '';
    this.isHoveringToast = false;
    this.clear.emit();
  }

  onHoverToast(ev: Event) {
    ev.stopPropagation();
    this.isHoveringToast = true;
  }

  onMouseOutToast(ev: Event) {
    ev.stopPropagation();
    this.isHoveringToast = false;
  }
}
