import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FirebaseService } from './firebase.service';
import { mergeMap } from 'rxjs';
import { Role } from './roles.service';

type NewUserRequest = {
  user: {
    firebase_id: string;
    username: string;
    email: string;
  };
  invitation_id: string;
};

type User = {
  id: string;
  tenant_id: string;
  firebase_id: string;
  username: string;
  email: string;
  active: boolean;
  last_logged_in: Date;
  created_at: Date;
};

export type UserResponse = {
  user: UserWithPermissions;
};

export type UserListResponse = {
  users: UserWithPermissions[];
};

export type UserWithPermissions = User & {
  permissions: Permission[];
};

export type Permission = {
  id: string;
  user_id: string;
  role_id: string;
  created_at: string;
  role: Role;
};

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  getUserUrl = environment.serverUrl + '/users/get';
  createUserUrl = environment.serverUrl + '/users/create';
  currentTenantUsersUrl = environment.serverUrl + '/users/for-current-tenant';
  usersForTenantUrl = environment.serverUrl + '/users/for-tenant';
  setActiveUrl = environment.serverUrl + '/users/set-active';

  constructor(
    private http: HttpClient,
    private firebaseService: FirebaseService
  ) {}

  createUser(userReq: NewUserRequest) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.post<UserResponse>(this.createUserUrl, userReq, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        });
      })
    );
  }

  getUsersForCurrentTenant() {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.get<UserListResponse>(this.currentTenantUsersUrl, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
      })
    );
  }

  getUsersForTenant(tenantId: string) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.get<UserListResponse>(
          this.usersForTenantUrl + `/${tenantId}`,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }

  getUser(userId: string) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.get<UserResponse>(this.getUserUrl + '/' + userId, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
      })
    );
  }

  setActive(userId: string, active: boolean) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.put<UserResponse>(
          this.setActiveUrl,
          { user_id: userId, active: active },
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }

  canEditUser(user: UserWithPermissions, userToEdit: UserWithPermissions) {
    return user.permissions.some((p) => {
      switch (p.role.name) {
        case 'super_admin':
          return !hasPermissions(userToEdit, ['super_admin']);
        case 'internal_admin':
          return !hasPermissions(userToEdit, ['super_admin', 'internal_admin']);
        case 'internal_user':
          return !hasPermissions(userToEdit, [
            'super_admin',
            'internal_admin',
            'internal_user',
          ]);
        case 'owner':
          return !hasPermissions(userToEdit, [
            'super_admin',
            'internal_admin',
            'internal_user',
          ]);
        case 'admin':
          return !hasPermissions(userToEdit, [
            'super_admin',
            'internal_admin',
            'internal_user',
            'owner',
            'admin',
          ]);
        default:
          return false;
      }
    });

    function hasPermissions(user: UserWithPermissions, roles: string[]) {
      for (const p of user.permissions) {
        for (const role of roles) {
          if (p.role.name === role) return true;
        }
      }
      return false;
    }
  }
}
