import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';
import { catchError } from 'rxjs';
import { LoggerService } from 'src/app/services/logger.service';
import {
  ProductVariant,
  ProductsService,
} from 'src/app/services/products.service';

export type ProductVariantResolverResponse = {
  product_variant?: ProductVariant;
  error?: string;
};

export const productVariantResolver: ResolveFn<
  ProductVariantResolverResponse
> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const loggerService = inject(LoggerService);
  return inject(ProductsService)
    .getProductVariant(route.paramMap.get('id')!)
    .pipe(
      catchError((err) => {
        loggerService.error(err);
        return [{ error: 'Error getting product variant' }];
      })
    );
};
