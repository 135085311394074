import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FirebaseService } from './firebase.service';
import { mergeMap } from 'rxjs';

export type Distributor = {
  id: string;
  account_number_prefix: string;
  name: string;
  created_at: Date;
};

type DistributorsResponse = {
  distributors: Distributor[];
};

@Injectable({
  providedIn: 'root',
})
export class DistributorsService {
  getAllDistributorsUrl = environment.serverUrl + '/distributors/get-all';

  constructor(
    private http: HttpClient,
    private firebaseService: FirebaseService
  ) {}

  getAllDistributors() {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.get<DistributorsResponse>(this.getAllDistributorsUrl, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        });
      })
    );
  }
}
