<div class="container p-12">
  @if (fetchingTenant) {
    <div class="flex justify-center">
      <div class="loading loading-spinner loading-lg"></div>
    </div>
  } @else if (tenantFetchError || !tenant) {
    <div class="mt-4 text-center text-error">
      <p>{{ tenantFetchError ? tenantFetchError : 'Tenant not found' }}</p>
    </div>
  } @else if (tenant) {
    <app-tenant-info-form [tenant]="tenant"></app-tenant-info-form>

    <hr class="my-8" />

    <div>
      <h2 class="text-lg font-semibold">Admin Settings</h2>
      <div class="mt-4">
        <div class="flex flex-wrap justify-between">
          <div class="min-w-[12rem] flex-1 basis-full xl:mr-8 xl:basis-1/4">
            <label class="form-control max-w-xs">
              <div class="label">
                <span class="label-text">Avatax Company Code</span>
              </div>
              <input
                type="text"
                [(ngModel)]="avataxCustomerCode"
                class="input input-bordered w-full"
              />
            </label>
          </div>

          <div class="min-w-[12rem] flex-1 basis-full xl:mr-8 xl:basis-1/4">
            <label class="form-control max-w-xs">
              <div class="label">
                <span class="label-text">Notes</span>
              </div>
              <textarea
                class="textarea textarea-bordered"
                rows="3"
                [(ngModel)]="notes"
              ></textarea>
            </label>
          </div>

          <div class="min-w-[12rem] flex-1 basis-full xl:basis-1/4"></div>
        </div>
      </div>

      <div class="mt-12">
        <div class="flex justify-center space-x-8">
          <button
            class="btn btn-primary"
            [disabled]="updating"
            (click)="submitAdminSettings()"
          >
            Submit
          </button>
          <button class="btn btn-secondary" (click)="back()">Back</button>
        </div>

        @if (updateError) {
          <div class="mt-4 text-center text-error">
            <p>{{ updateError }}</p>
          </div>
        } @else if (updating) {
          <div class="mt-4 flex justify-center">
            <div class="loading loading-spinner loading-lg"></div>
          </div>
        } @else if (updateSuccess) {
          <div class="mt-8 text-center text-success">
            <p>Successfully updated tenant</p>
          </div>
        }
      </div>
    </div>
  }
</div>
