import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoggerService } from 'src/app/services/logger.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-invoice-page',
  templateUrl: './invoice-page.component.html',
  styleUrls: ['./invoice-page.component.scss'],
})
export class InvoicePageComponent {
  invoiceId?: string;
  tenantId?: string;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private loggerService: LoggerService
  ) {
    const params = this.route.snapshot.params;
    this.invoiceId = params['id'];
    this.loggerService.log('invoice page params: ', params);
    this.loggerService.log('invoiceId: ', this.invoiceId);

    const tenantId = this.authService.currentUser?.tenant_id;
    this.tenantId = tenantId;

    return;
  }
}
