import { Component } from '@angular/core';

@Component({
  selector: 'app-generic-credit-card-icon',
  templateUrl: './generic-credit-card-icon.component.html',
  styleUrl: './generic-credit-card-icon.component.scss'
})
export class GenericCreditCardIconComponent {

}
