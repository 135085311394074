import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  TableSettings,
  TableSpacing,
} from 'src/app/components/pagination/pagination.component';
import { Product, ProductsService } from 'src/app/services/products.service';
// import { paginationArray } from 'src/app/utils/pagination';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent implements OnInit {
  allProducts: Product[] = [];
  displayProducts: Product[] = [];
  currentPageProducts: Product[] = [];
  loading = false;

  searchText = '';

  pageSize = 15;
  currentPage = 1;

  pageSizeOptions = [10, 15, 20, 50, 100];

  spacingOptions = ['compact', 'normal', 'comfortable'];
  spacing: TableSpacing = 'normal';

  constructor(
    private productsService: ProductsService,
    private router: Router
  ) {}

  ngOnInit() {
    this.loadSettings();
    this.getProducts();
  }

  get totalPages() {
    return Math.ceil(this.displayProducts.length / this.pageSize);
  }

  loadSettings() {
    try {
      const settings = localStorage.getItem('productTableSettings');
      if (settings) {
        const parsed = JSON.parse(settings);
        this.pageSize = Number(parsed.pageSize);
        this.spacing = parsed.spacing;
      }
    } catch (err) {
      console.error(err as Error);
    }
  }

  getProducts() {
    if (this.loading) return;
    this.loading = true;

    this.productsService.getAllProducts().subscribe({
      next: (res) => {
        console.log('res: ', res);
        if (res) {
          this.allProducts = res.products;
          this.allProducts.sort((a, b) => {
            if (a.code < b.code) return -1;
            if (a.code > b.code) return 1;
            return 0;
          });
          this.updateDisplayProducts();
        } else {
          console.error(
            new Error('No products returned from server')
          );
          console.log('res: ', res);
          this.allProducts = [];
        }
      },
      error: (err) => {
        console.error(
          new Error('Error getting products from server')
        );
        console.log('err: ', err);
        this.allProducts = [];
      },
      complete: () => {
        console.log('ProductListComponent getProducts complete');
        this.loading = false;
      },
    });
  }

  updateDisplayProducts() {
    console.log('this.allProducts: ', this.allProducts);

    if (this.searchText.trim() !== '') {
      this.displayProducts = this.allProducts.filter((product) => {
        const s = this.searchText.toLowerCase();
        return (
          product.title.toLowerCase().includes(s) ||
          product.description.toLowerCase().includes(s) ||
          product.code.toLowerCase().includes(s) ||
          product.type.toLowerCase().includes(s)
        );
      });

      if (this.currentPage - 1 > this.displayProducts.length / this.pageSize) {
        this.currentPage = 1;
      }
    } else {
      this.displayProducts = this.allProducts;
    }

    this.updateCurrentPageProducts();
  }

  updateCurrentPageProducts() {
    const offset = (this.currentPage - 1) * this.pageSize;
    this.currentPageProducts = this.displayProducts.slice(
      offset,
      offset + this.pageSize
    );
  }

  navigateToProductDetails(product: Product) {
    console.log('navigateToProductDetails');
    console.log('product: ', product);
    this.router.navigate([`admin/product/${product.id}`]);
  }

  changePage(page: number) {
    this.currentPage = page;
    this.updateDisplayProducts();
  }

  handleSettingsChanged(settings: TableSettings) {
    console.log('handleSettingsChanged: ', settings);
    this.pageSize = Number(settings.pageSize);
    this.spacing = settings.spacing;
    this.updateCurrentPageProducts();
    const newSettings = {
      pageSize: Number(settings.pageSize),
      spacing: settings.spacing,
    };
    localStorage.setItem('productTableSettings', JSON.stringify(newSettings));
  }
}
