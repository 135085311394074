import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { userGuard } from './auth-guards/user.guard';
import { portalAdminGuard } from './auth-guards/portal-admin.guard';
import { LoginComponent } from '../components/login/login.component';
import { TestComponent } from '../components/test/test.component';
import { AdminPageComponent } from '../components/admin-page/admin-page.component';
import { ProductDetailsComponent } from '../components/admin-page/components/product-details/product-details.component';
import { LicensesPageComponent } from '../components/licenses-page/licenses-page.component';
import { TenantDetailsComponent } from '../components/admin-page/components/tenant-details/tenant-details.component';
import { HistoryComponent } from '../components/history/history.component';
import { SignUpComponent } from '../components/sign-up/sign-up.component';
import { TenantUsersComponent } from '../components/tenant-users/tenant-users.component';
import { TenantInfoSetupFormComponent } from '../components/tenant-info-setup-form/tenant-info-setup-form.component';
import { InvoicePageComponent } from '../components/invoice-page/invoice-page.component';
import { SavedPaymentMethodsComponent } from '../components/saved-payment-methods/saved-payment-methods.component';
import { EditInvoicePageComponent } from '../components/edit-invoice-page/edit-invoice-page.component';
import { EditProductComponent } from '../components/admin-page/components/edit-product/edit-product.component';
import { EditProductVariantComponent } from '../components/admin-page/components/edit-product-variant/edit-product-variant.component';
import { AddProductComponent } from '../components/admin-page/components/add-product/add-product.component';
import { AddProductVariantComponent } from '../components/admin-page/components/add-product-variant/add-product-variant.component';
import { InvoicesPageComponent } from '../components/invoices-page/invoices-page.component';
import { EditLicenseComponent } from '../components/admin-page/components/edit-license/edit-license.component';
import { UserSettingsComponent } from '../components/user-settings/user-settings.component';
import { ManageAuthComponent } from '../components/manage-auth/manage-auth.component';
import { DashboardComponent } from '../components/dashboard/dashboard.component';
import { TenantInvoiceComponent } from '../components/admin-page/components/tenant-invoice/tenant-invoice.component';
import { ReceiptComponent } from '../components/receipt/receipt.component';
import { FranchiseDetailsComponent } from '../components/admin-page/components/franchise-details/franchise-details.component';
import { EditUserComponent } from '../components/edit-user/edit-user.component';
import { TermsAndConditionsComponent } from '../components/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from '../components/privacy-policy/privacy-policy.component';
import { TenantAdminEditComponent } from '../components/admin-page/components/tenant-admin-edit/tenant-admin-edit.component';
import { TenantInfoPageComponent } from '../components/tenant-info-page/tenant-info-page.component';
import { TenantSettingsComponent } from '../components/tenant-settings/tenant-settings.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [userGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'admin',
    canActivate: [userGuard, portalAdminGuard],
    children: [
      {
        path: '',
        component: AdminPageComponent,
      },
      {
        path: 'product/:id',
        component: ProductDetailsComponent,
      },
      {
        path: 'tenant/:id',
        component: TenantDetailsComponent,
      },
      {
        path: 'tenant/:tenantId/invoice/:invoiceId',
        component: TenantInvoiceComponent,
      },
      {
        path: 'tenant/:tenantId/edit',
        component: TenantAdminEditComponent,
      },
      {
        path: 'add-product',
        component: AddProductComponent,
      },
      {
        path: 'add-product-variant/:id',
        component: AddProductVariantComponent,
      },
      {
        path: 'edit-product/:id',
        component: EditProductComponent,
      },
      {
        path: 'edit-product-variant/:id',
        component: EditProductVariantComponent,
      },
      {
        path: 'edit-license/:id',
        component: EditLicenseComponent,
      },
      {
        path: 'franchise/:id',
        component: FranchiseDetailsComponent,
      },
    ],
  },
  {
    path: 'sign-up/:invitationId',
    component: SignUpComponent,
  },
  {
    path: 'manage-auth',
    component: ManageAuthComponent,
  },
  {
    path: 'user-settings',
    canActivate: [userGuard],
    component: UserSettingsComponent,
  },
  {
    path: 'edit-user/:id',
    canActivate: [userGuard],
    component: EditUserComponent,
  },
  {
    path: 'tenant',
    canActivate: [userGuard],
    children: [
      {
        path: 'settings',
        component: TenantSettingsComponent,
      },
      {
        path: 'setup-info',
        component: TenantInfoSetupFormComponent,
      },
      {
        path: 'update-info',
        component: TenantInfoPageComponent,
      },
      {
        path: 'users',
        component: TenantUsersComponent,
      },
    ],
  },
  {
    path: 'test',
    canActivate: [userGuard],
    component: TestComponent,
  },
  {
    path: 'history',
    canActivate: [userGuard],
    component: HistoryComponent,
  },
  {
    path: 'licenses',
    canActivate: [userGuard],
    component: LicensesPageComponent,
  },
  {
    path: 'invoices',
    canActivate: [userGuard],
    component: InvoicesPageComponent,
  },
  {
    path: 'invoice/:id',
    canActivate: [userGuard],
    component: InvoicePageComponent,
  },
  {
    path: 'edit-invoice/:id',
    canActivate: [userGuard],
    component: EditInvoicePageComponent,
  },
  {
    path: 'receipt/:id',
    canActivate: [userGuard],
    component: ReceiptComponent,
  },
  {
    path: 'saved-payment-methods',
    canActivate: [userGuard],
    component: SavedPaymentMethodsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
