import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { mergeMap, of } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

// Guards routes to users with the portal admin role
export const portalAdminGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.authReady.pipe(
    mergeMap((authReady) => {
      if (!authReady || !authService.signedIn) {
        return router.navigate(['/login']);
      }

      const hasPermission =
        authService.checkPermission('internal_admin') ||
        authService.checkPermission('super_admin');
      if (!hasPermission) {
        return router.navigate(['']);
      }

      return of(true);
    })
  );
};
