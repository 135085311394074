import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { MultiFactorResolver } from 'firebase/auth';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  @ViewChild('totpQRCanvas') totpQRCanvas?: ElementRef<HTMLDivElement>;

  username = '';
  password = '';

  passwordResetEmail = '';
  sendingPasswordResetEmail = false;
  passwordResetEmailSent = false;

  pageState:
    | 'login'
    | '2fa-login'
    | '2fa-enroll'
    | 'verify-email'
    | 'forgot-password' = 'login';

  error = '';

  showLogout = false;

  mfaResolver?: MultiFactorResolver;

  showEmailVerificationMessage = false;
  showEmailVerificationError = false;

  successMessage = '';

  constructor(
    private authService: AuthService,
    private firebaseService: FirebaseService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    // on page load, check if the user is already signed in
    this.authService.authReady.subscribe((authReady) => {
      if (authReady && this.authService.signedIn) {
        console.log('login.component -> User is already signed in');
        if (!this.authService.emailVerified) {
          // user needs to complete email verification
          // this.showEmailVerificationMessage = true;
          this.pageState = 'verify-email';
          this.showLogout = true;
        } else if (!this.authService.has2FAEnrollment) {
          // user has not enrolled in 2FA
          this.pageState = '2fa-enroll';
          this.showLogout = true;
        } else {
          this.router.navigate(['']);
        }
      }
    });

    // on page load, check if we need to show email verification message
    this.route.queryParams.subscribe((params) => {
      if (params['emailVerified'] !== undefined) {
        if (params['emailVerified'] === false) {
          this.pageState = 'verify-email';
        }
        // this.showEmailVerificationMessage = params['emailVerified'] === false;
        // this.showLogout = params['emailVerified'] === false;
      }
    });
  }

  get currentUser() {
    return this.authService.currentUser;
  }

  get signedIn() {
    return this.authService.signedIn;
  }

  get enrolledOptions() {
    return this.authService._2FAEnrollments?.map((e) => e.factorId);
  }

  signInWithEmail() {
    this.authService.signInWithEmail(this.username, this.password).subscribe({
      next: this.handleSignInResponse.bind(this),
      error: this.handleSignInError.bind(this),
    });
  }

  signInWithGoogle() {
    this.authService.signInWithGoogle().subscribe({
      next: this.handleSignInResponse.bind(this),
      error: this.handleSignInError.bind(this),
    });
  }

  handleSignInResponse(res: boolean) {
    console.log('login.component -> handleSignInResponse: ', res);
    // This should not succeed if the user has 2FA enabled

    // show email verification message if email is not verified
    if (!this.authService.emailVerified) {
      this.pageState = 'verify-email';
      // this.showEmailVerificationMessage = true;
      return;
    }
    // else if (this.authService.emailVerified) {
    //  this.showEmailVerificationMessage = false;
    // }

    // start 2FA flow
    this.pageState = '2fa-enroll';
    this.error = '';
  }

  handleSignInError(err: any) {
    console.error(err);
    if (err.code === 'auth/multi-factor-auth-required') {
      // THIS IS EXPECTED
      // this means the user has 2FA enabled

      const mfaResolver = this.firebaseService.mfaResolver(err);
      this.mfaResolver = mfaResolver;
      console.log('mfaResolver: ', mfaResolver);
      console.log(this.signedIn);

      if (
        mfaResolver.hints.some((h) => h.factorId === 'phone') ||
        mfaResolver.hints.some((h) => h.factorId === 'totp')
      ) {
        this.pageState = '2fa-login';
        console.log(
          'firebaseService.currentUser: ',
          this.firebaseService.currentUser
        );
      } else {
        console.error(new Error('Unsupported 2FA method'));
        this.error = 'Unsupported 2FA method';
      }
    } else if (
      err.code === 'auth/invalid-credential' ||
      err.code === 'auth/wrong-password'
    ) {
      this.error = 'Invalid email or password';
      // this.error = JSON.stringify(err, null, 2);
    } else {
      this.error = 'Unexpected error encountered, please try again';
    }
  }

  sendEmailVerification() {
    if (!this.firebaseService.currentUser) {
      console.error(
        new Error('Unable to send verification email. Please sign in.')
      );
      this.error = 'Unable to send verification email. Please sign in.';
      return;
    }
    const user = this.firebaseService.currentUser;
    if (user.emailVerified) {
      console.log('user email already verified', user);
      this.error = 'User email already verified';
      return;
    }
    if (!user.email) {
      console.error(
        new Error('Unable to send verification email. No email found.')
      );
      this.error = 'Unable to send verification email. No email found.';
      return;
    }
    this.authService.sendEmailVerificationEmail(user.email).subscribe({
      next: () => {
        console.log('verification email sent');
        this.successMessage = 'Verification email sent';
        this.error = '';
      },
      error: (err) => {
        console.error(new Error(err));
        this.error = 'Error resending verification email';
      },
    });
  }

  sendPasswordResetEmail() {
    this.sendingPasswordResetEmail = true;
    this.authService.sendPasswordResetEmail(this.passwordResetEmail).subscribe({
      next: () => {
        this.sendingPasswordResetEmail = false;
        console.log('password reset email sent');
        this.error = '';
        this.passwordResetEmailSent = true;
      },
      error: (err) => {
        this.sendingPasswordResetEmail = false;
        console.error(err);
        this.passwordResetEmailSent = false;
        if (err.code === 'auth/missing-email') {
          this.error = 'Unable to find account with that email';
        } else {
          this.error = 'Error resending password reset email';
        }
      },
    });
  }

  signOut() {
    this.authService.signOut().subscribe({
      next: (res) => {
        console.log(
          `Response from server: ${JSON.stringify(res, null, 2)}`
        );
        this.showLogout = false;
        this.resetViews();
      },
      error: (err) => {
        console.error(err);
        this.error = 'Unexpected error occurred. Please try again.';
      },
    });
  }

  resetViews() {
    this.pageState = 'login';
    // this.showEmailVerificationMessage = false;
    this.showEmailVerificationError = false;
    this.error = '';
  }

  mfaSuccess() {
    this.authService.getCurrentUserInfo().subscribe({
      next: (res: boolean) => {
        this.loginSuccess();
      },
      error: (err) => {
        console.error(err);
        this.error = 'Unexpected error occurred. Please try again.';
      },
    });
  }

  loginSuccess() {
    this.router.navigate(['/']);
  }
}
