import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';
import { HttpClient } from '@angular/common/http';
import { mergeMap } from 'rxjs';
import { environment } from 'src/environments/environment';

export type KillSwitchData = {
  kill_all: boolean;
  kill_all_title: string;
  kill_all_message: string;
  kill_all_link: string;
  min_version: string;
  min_version_title: string;
  min_version_message: string;
  min_version_link: string;
};

type KillSwitchResponse = {
  version_info: {
    id: string;
    updated_by: string;
    created_at: Date;
    kill_all: boolean;
    kill_all_title: string;
    kill_all_message: string;
    kill_all_link: string;
    min_version: string;
    min_version_title: string;
    min_version_message: string;
    min_version_link: string;
  };
};

@Injectable({
  providedIn: 'root',
})
export class KillSwitchService {
  private killSwitchUrl = environment.serverUrl + '/version-info';

  constructor(
    private firebaseService: FirebaseService,
    private http: HttpClient
  ) {}

  getKillSwitchInfo() {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.get<KillSwitchResponse>(this.killSwitchUrl, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        });
      })
    );
  }

  updateKillSwitchInfo(killSwitchRequest: KillSwitchData) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.post<KillSwitchResponse>(
          this.killSwitchUrl,
          killSwitchRequest,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }
}

