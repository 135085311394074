<form method="dialog">
  <button class="btn btn-circle btn-ghost btn-sm absolute right-2 top-2">
    ✕
  </button>
</form>
<h3 class="py-4 text-lg font-semibold">Create Invoice</h3>

<div class="flex space-x-4 pb-4">
  <div class="flex items-center text-sm">License Statuses:</div>
  <div class="form-control">
    <label class="label cursor-pointer">
      <span class="label-text pr-2">Active</span>
      <input
        type="checkbox"
        class="checkbox"
        [(ngModel)]="createInvoiceFilters.active"
        (change)="filterValidInvoiceLicenses()"
      />
    </label>
  </div>
  <div class="form-control">
    <label class="label cursor-pointer">
      <span class="label-text pr-2">Pending</span>
      <input
        type="checkbox"
        class="checkbox"
        [(ngModel)]="createInvoiceFilters.pending"
        (change)="filterValidInvoiceLicenses()"
      />
    </label>
  </div>
  <div class="form-control">
    <label class="label cursor-pointer">
      <span class="label-text pr-2">Expired</span>
      <input
        type="checkbox"
        class="checkbox"
        [(ngModel)]="createInvoiceFilters.expired"
        (change)="filterValidInvoiceLicenses()"
      />
    </label>
  </div>
</div>

<table class="table table-zebra">
  <thead class="text-base font-medium">
    <tr>
      <td></td>
      <td>Product</td>
      <td>Status</td>
      <td>Type</td>
      <td>Duration</td>
      <td>Expiration</td>
      <td>Created</td>
    </tr>
  </thead>
  <tbody>
    @for (el of validInvoiceLicenses; track el.license) {
      <tr>
        <td>
          <input
            type="checkbox"
            class="checkbox"
            [value]="el.license.id"
            (change)="handleInvoiceLicenseCheck($event)"
          />
        </td>
        <td>{{ el.license.product.title }}</td>
        <td>{{ el.status }}</td>
        <td>
          {{ el.license.site_license ? 'Site' : 'Primary' }}
        </td>
        <td>{{ el.license.product_variant.duration_months }} months</td>
        <td>
          {{ el.license.expiration | date: 'MM/dd/YYYY' : 'UTC' }}
        </td>
        <td>
          {{ el.license.created_at | date: 'MM/dd/YYYY, hh:mmaa' }}
        </td>
      </tr>
    } @empty {
      <tr>
        <td></td>
        <td colspan="4">No valid Licenses to add to invoice</td>
      </tr>
    }
  </tbody>
</table>

<div class="mt-8 text-center">
  <button
    (click)="createInvoice()"
    class="btn btn-primary"
    [disabled]="creatingInvoice"
  >
    Create Invoice
  </button>
</div>

@if (errorCreatingInvoice) {
  <p class="mt-8 text-center text-error">
    {{ errorCreatingInvoice }}
  </p>
}
