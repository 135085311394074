import { Component, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ThemeOption, ThemeService } from 'src/app/services/theme.service';
import { Router, NavigationEnd } from '@angular/router';
import { FirebaseService } from 'src/app/services/firebase.service';
import { LoggerService } from 'src/app/services/logger.service';

@Component({
  selector: 'app-mobile-nav',
  templateUrl: './mobile-nav.component.html',
  styleUrl: './mobile-nav.component.scss',
})
export class MobileNavComponent {
  @ViewChild('mobileNavMenu', { static: true })
  mobileNav!: ElementRef<HTMLDetailsElement>;

  mobileNavOpen: boolean = false;

  currentMobileBottomNavTab = '';

  accountNumberCopied = false;

  constructor(
    private authService: AuthService,
    private loggerService: LoggerService,
    private themeService: ThemeService,
    private router: Router,
    private firebaseService: FirebaseService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.loggerService.log('this.router.url: ', this.router.url);
        this.currentMobileBottomNavTab = this.router.url;
      }
    });
  }

  get showAdmin() {
    return this.authService.currentUser?.permissions.find(
      (p) => p.role.name === 'internal_admin' || p.role.name === 'super_admin'
    );
  }

  signOut() {
    this.authService.signOut().subscribe();
  }

  get currentTenant() {
    return this.authService.currentTenant;
  }

  get darkModeEnabled() {
    return this.themeService.theme === 'accuzipDarkTheme';
  }

  get userImageURL() {
    if (!this.firebaseService.currentUser) {
      return;
    }

    return this.firebaseService.currentUser.photoURL;
  }

  copyAccountNumber() {
    this.accountNumberCopied = false;
    if (this.currentTenant) {
      navigator.clipboard.writeText(this.currentTenant.account_number);
      this.accountNumberCopied = true;
    }
  }

  updateTheme(ev: Event) {
    const theme = (ev.target as HTMLSelectElement).value as ThemeOption;
    this.themeService.setTheme(theme, true);
  }

  handleThemeChange(ev: Event) {
    if (ev.target) {
      if ((ev.target as HTMLInputElement).checked) {
        this.themeService.setTheme('accuzipDarkTheme', true);
      } else {
        this.themeService.setTheme('accuzipLightTheme', true);
      }
    }
  }

  toggleMobileNav(ev: Event) {
    ev.preventDefault();
    ev.stopPropagation();

    this.mobileNavOpen = !this.mobileNavOpen;

    if (this.mobileNavOpen) {
      this.mobileNav.nativeElement.setAttribute('open', '');
    } else {
      this.mobileNav.nativeElement.removeAttribute('open');
    }
  }
}
