<svg
  width="48"
  height="32"
  viewBox="0 0 48 32"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g id="discover-light-large">
    <path
      id="card_bg"
      d="M48 4V28C48 30.2091 46.2091 32 44 32H4C3.53583 32.0035 3.07493 31.9222 2.64 31.76C1.05621 31.1874 0.00071654 29.6841 0 28V4C0 1.79086 1.79086 0 4 0H44C46.2091 0 48 1.79086 48 4Z"
      fill="#DFE3E8"
    />
    <g id="discover">
      <path
        id="Path"
        d="M48 16V27.9646C48 30.1932 46.1839 31.9999 43.9436 31.9999H3.37919C2.90847 32.0034 2.44107 31.9214 2 31.7578C5.40741 31.2735 8.69312 30.6682 11.918 29.9822C12.7698 29.8208 13.6014 29.6191 14.433 29.4375C27.8395 26.3908 39.4409 21.9117 45.9718 17.5132C46.7019 17.0088 47.3915 16.5044 48 16Z"
        fill="#EE7623"
      />
      <path
        id="Shape"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 10.64H5.82V10.6C7.21326 10.4992 8.51258 11.3083 9.03688 12.6031C9.56117 13.8978 9.19084 15.383 8.12 16.28C7.46653 16.792 6.64875 17.048 5.82 17H4V10.64ZM5.58 16C6.20562 16.0549 6.82663 15.8527 7.3 15.44C7.75024 15.0305 8.00481 14.4486 8 13.84C8.00869 13.2306 7.75346 12.6472 7.3 12.24C6.81872 11.8447 6.19969 11.6575 5.58 11.72H5.26V16H5.58Z"
        fill="#231F20"
      />
      <rect
        id="Rectangle"
        x="9.85001"
        y="10.63"
        width="1.25"
        height="6.42"
        fill="#231F20"
      />
      <path
        id="Path_2"
        d="M14.17 13.09C13.42 12.82 13.17 12.63 13.17 12.28C13.17 11.93 13.56 11.57 14.1 11.57C14.4939 11.5865 14.8602 11.777 15.1 12.09L15.75 11.24C15.2369 10.771 14.5651 10.5138 13.87 10.52C13.3578 10.4871 12.8545 10.6651 12.4768 11.0126C12.0991 11.3601 11.8799 11.8469 11.87 12.36C11.87 13.25 12.27 13.7 13.44 14.12C13.7408 14.2163 14.032 14.3402 14.31 14.49C14.5457 14.6261 14.6907 14.8778 14.69 15.15C14.6882 15.4068 14.5791 15.6511 14.3892 15.8239C14.1993 15.9968 13.9458 16.0823 13.69 16.06C13.1068 16.0663 12.5743 15.7296 12.33 15.2L11.52 15.98C11.9927 16.7804 12.8719 17.2508 13.8 17.2C14.3772 17.2395 14.9444 17.0344 15.3629 16.6349C15.7814 16.2354 16.0126 15.6784 16 15.1C16 14.08 15.59 13.61 14.17 13.09Z"
        fill="#231F20"
      />
      <path
        id="Path_3"
        d="M16.41 13.84C16.4017 14.738 16.7575 15.601 17.3962 16.2322C18.035 16.8634 18.9022 17.209 19.8 17.19C20.3453 17.1932 20.8835 17.0664 21.37 16.82V15.35C20.9923 15.7916 20.4411 16.0471 19.86 16.05C19.2722 16.0647 18.7049 15.8335 18.2948 15.4121C17.8847 14.9906 17.6692 14.4172 17.7 13.83C17.6811 13.2582 17.8931 12.7029 18.2881 12.2891C18.6831 11.8753 19.228 11.6378 19.8 11.63C20.4027 11.6324 20.9749 11.8949 21.37 12.35V10.88C20.8987 10.6187 20.3689 10.4811 19.83 10.48C18.9301 10.4693 18.0631 10.818 17.4212 11.4487C16.7792 12.0794 16.4153 12.9401 16.41 13.84Z"
        fill="#231F20"
      />
      <path
        id="Path_4"
        d="M31.3 14.94L29.58 10.63H28.22L30.94 17.21H31.61L34.39 10.63H33.03L31.3 14.94Z"
        fill="#231F20"
      />
      <path
        id="Path_5"
        d="M34.95 17.05H38.51V15.96H36.2V14.23H38.42V13.14H36.2V11.72H38.51V10.63H34.95V17.05Z"
        fill="#231F20"
      />
      <path
        id="Shape_2"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M43.46 12.52C43.5281 13.4204 42.8923 14.2216 42 14.36L44 17H42.48L40.74 14.46H40.6V17H39.34V10.64H41.18C42.64 10.64 43.46 11.32 43.46 12.52ZM40.6 13.58H41C41.74 13.58 42.18 13.32 42.18 12.64C42.18 11.96 41.76 11.64 41 11.64H40.6V13.58Z"
        fill="#231F20"
      />
      <path
        id="Path_6"
        d="M28.59 13.84C28.59 15.7233 27.0633 17.25 25.18 17.25C23.2967 17.25 21.77 15.7233 21.77 13.84C21.77 11.9567 23.2967 10.43 25.18 10.43C27.061 10.4355 28.5845 11.959 28.59 13.84Z"
        fill="#EE7623"
      />
      <path
        id="Shape_3"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M44.12 10.76C44.1168 10.8286 44.0674 10.8863 44 10.9L44.18 11.16H44.02L43.84 10.92V11.16H43.7V10.58H43.9C44.04 10.58 44.12 10.64 44.12 10.76ZM43.98 10.76C43.98 10.7 43.94 10.7 43.88 10.7V10.82H43.9C43.9532 10.82 43.9725 10.82 43.9788 10.811C43.9844 10.803 43.98 10.7881 43.98 10.76Z"
        fill="#231F20"
      />
      <path
        id="Shape_4"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M43.46 10.86C43.4598 10.5915 43.6717 10.3707 43.94 10.36C44.076 10.3546 44.2084 10.4048 44.3065 10.4992C44.4047 10.5936 44.4601 10.7239 44.46 10.86C44.46 11.1361 44.2361 11.36 43.96 11.36C43.6838 11.36 43.46 11.1361 43.46 10.86ZM43.94 11.28C44.0407 11.2832 44.1371 11.2387 44.2 11.16C44.275 11.0778 44.3176 10.9712 44.32 10.86C44.32 10.6501 44.1499 10.48 43.94 10.48C43.7301 10.48 43.56 10.6501 43.56 10.86C43.5517 10.9668 43.5878 11.0723 43.6596 11.1518C43.7315 11.2312 43.8329 11.2776 43.94 11.28Z"
        fill="#231F20"
      />
    </g>
  </g>
</svg>
