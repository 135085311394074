import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { FirebaseService, TOTPInfo } from 'src/app/services/firebase.service';
import { RecaptchaVerifier } from 'firebase/auth';
import { LoggerService } from 'src/app/services/logger.service';

@Component({
  selector: 'app-two-factor-enrollment',
  templateUrl: './two-factor-enrollment.component.html',
  styleUrls: ['./two-factor-enrollment.component.scss'],
})
export class TwoFactorEnrollmentComponent {
  @Output() signOut = new EventEmitter<void>();
  @Output() success = new EventEmitter<void>();

  @ViewChild('totpQRCanvas') totpQRCanvas?: ElementRef<HTMLDivElement>;

  successMessage = '';
  error = '';

  showTOTPSetup = false;
  totpInfo?: TOTPInfo | undefined;
  totpCode = '';

  showSMSSetup = false;
  sendingSMS = false;
  smsSent = false;
  phoneNumber = '';
  smsVerificationId = '';
  smsCode = '';

  constructor(
    private firebaseService: FirebaseService,
    private loggerService: LoggerService
  ) {}

  setupTOTP2FA() {
    this.loggerService.debug('two-factor-enrollment.component -> setupTOTP2FA');
    this.loggerService.log('Setting up TOTP 2FA');
    this.showTOTPSetup = true;
    this.firebaseService.generateTOTPInfo().subscribe({
      next: (res) => {
        this.loggerService.log('TOTP Info: ', res);
        this.totpInfo = res;
        this.error = '';
      },
      error: (err) => {
        this.loggerService.error(err);
        if (
          err.code === 'auth/requires-recent-login' ||
          err.code === 'auth/user-token-expired'
        ) {
          // user needs to reauthenticate, logout and redirect to login
          this.signOut.emit();
        } else {
          this.error = 'Unexpected error occurred. Please try again.';
        }
      },
    });
  }

  verifyTOTPForEnrollment(code: string) {
    this.loggerService.debug(
      'two-factor-enrollment.component -> verifyTOTPForEnrollment'
    );

    if (!this.totpInfo) {
      this.loggerService.error(new Error('TOTP info missing'));
      this.error = 'TOTP info missing';
      return;
    }

    this.firebaseService
      .verifyTOTPCodeForEnrollment(this.totpInfo.secret, code)
      .subscribe({
        next: (res) => {
          this.loggerService.log(`TOTP code verified`);
          this.successMessage = '2FA enrollment successful';
          this.error = '';
          this.success.emit();
        },
        error: (err) => {
          this.loggerService.error(err);
          this.error = 'Error verifying TOTP code. Please try again.';
        },
      });
  }

  setupSMS2FA() {
    this.showSMSSetup = true;
  }

  sendSMSForEnrollment(info: {
    phoneNumber: string;
    recaptchaVerifier: RecaptchaVerifier;
  }) {
    this.loggerService.debug(
      'two-factor-enrollment.component -> sendSMSForEnrollment'
    );

    this.sendingSMS = true;
    this.smsSent = false;
    this.error = '';

    this.firebaseService
      .sendSMSForEnrollment(info.phoneNumber, info.recaptchaVerifier)
      .subscribe({
        next: (verificationId) => {
          this.sendingSMS = false;
          this.loggerService.log('SMS sent: ', verificationId);
          this.smsVerificationId = verificationId;
          this.smsSent = true;
          this.error = '';
        },
        error: (err) => {
          this.sendingSMS = false;
          this.loggerService.error(err);
          this.error = 'Error sending SMS. Please try again.';
        },
      });
  }

  verifySMSCodeForEnrollment(code: string) {
    this.loggerService.debug(
      'two-factor-enrollment.component -> verifySMSCodeForEnrollment'
    );

    if (!this.smsVerificationId) {
      this.loggerService.error(new Error('SMS verification ID missing'));
      this.error = 'SMS verification ID missing';
      return;
    }

    this.firebaseService
      .verifySMSCodeForEnrollment(this.smsVerificationId, code)
      .subscribe({
        next: () => {
          this.loggerService.log(
            `two-factor-enrollment.component -> verifySMSCodeForEnrollment -> SMS code verified`
          );
          this.successMessage = '2FA enrollment successful';
          this.error = '';
          this.success.emit();
        },
        error: (err) => {
          this.loggerService.error(err);
          this.error = 'Error verifying SMS code. Please try again.';
        },
      });
  }

  goBackTo2FAChoices() {
    this.loggerService.log('Going back to 2FA choices');
    this.showTOTPSetup = false;
    this.showSMSSetup = false;
    this.smsSent = false;
    this.error = '';
  }

  goBackToSendSMS() {
    this.smsSent = false;
    this.error = '';
  }
}
