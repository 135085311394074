import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Product,
  ProductType,
  ProductsService,
  UpdateProductRequest,
} from 'src/app/services/products.service';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss'],
})
export class EditProductComponent {
  @ViewChild('changeDialog') changeDialog!: ElementRef<HTMLDialogElement>;

  loadingProduct = false;
  productTypes = this.productsService.productTypes;
  originalProduct?: Product;
  errorFetchingProduct = '';

  active!: boolean;
  type!: ProductType;
  isService!: boolean;
  code!: string;
  varianceCode!: string;
  standalone!: boolean;
  title!: string;
  description!: string;

  savingChanges = false;
  productUpdated = false;
  errorUpdatingProduct = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private productsService: ProductsService
  ) {
    const id = this.route.snapshot.paramMap.get('id');
    if (!id) {
      console.error(new Error('No product ID found in route'));
      this.errorFetchingProduct = 'No product ID found';
      return;
    }
    this.getProduct(id);
  }

  getProduct(id: string) {
    this.loadingProduct = true;
    this.productsService.getProduct(id).subscribe({
      next: (res) => {
        this.loadingProduct = false;
        console.log('res: ', res);
        if (res.product) {
          this.originalProduct = res.product;
          this.initializeData(this.originalProduct);
        } else {
          console.error(
            new Error('No product returned from server')
          );
          this.errorFetchingProduct = 'No product returned from server';
        }
      },
      error: (err) => {
        this.loadingProduct = false;
        console.error(err);
        this.errorFetchingProduct = err;
      },
    });
  }

  initializeData(product: Product) {
    this.active = product.active;
    this.type = product.type;
    this.isService = product.is_service;
    this.code = product.code;
    this.varianceCode = product.variance_code;
    this.standalone = product.standalone;
    this.title = product.title;
    this.description = product.description;
  }

  saveChanges() {
    if (!this.originalProduct) return;

    this.closeChangeDialog();
    this.savingChanges = true;
    this.productUpdated = false;
    this.errorUpdatingProduct = '';
    const updates = this.productUpdates();

    this.productsService
      .updateProduct(this.originalProduct.id, updates)
      .subscribe({
        next: (response) => {
          console.log('response: ', response);
          this.productUpdated = true;
          this.originalProduct = response.product;
        },
        error: (error) => {
          console.error(error);
          this.errorUpdatingProduct = error.message;
        },
        complete: () => {
          this.savingChanges = false;
        },
      });
  }

  get hasChanges() {
    if (!this.originalProduct) return false;
    return (
      this.active !== this.originalProduct.active ||
      this.type !== this.originalProduct.type ||
      this.isService !== this.originalProduct.is_service ||
      this.code !== this.originalProduct.code ||
      this.standalone !== this.originalProduct.standalone ||
      this.title !== this.originalProduct.title ||
      this.description !== this.originalProduct.description
    );
  }

  productUpdates() {
    if (!this.originalProduct) return {};
    const updates: UpdateProductRequest = {};
    if (this.active !== this.originalProduct.active) {
      updates.active = this.active;
    }
    if (this.type !== this.originalProduct.type) {
      updates.type = this.type;
    }
    if (this.isService !== this.originalProduct.is_service) {
      updates.is_service = this.isService;
    }
    if (this.code !== this.originalProduct.code) {
      updates.code = this.code;
    }
    if (this.standalone !== this.originalProduct.standalone) {
      updates.standalone = this.standalone;
    }
    if (this.title !== this.originalProduct.title) {
      updates.title = this.title;
    }
    if (this.description !== this.originalProduct.description) {
      updates.description = this.description;
    }
    return updates;
  }

  closeChangeDialog() {
    this.changeDialog.nativeElement.close();
  }

  navigateToProduct() {
    if (!this.originalProduct) return;
    this.router.navigate(['/admin/product', this.originalProduct.id]);
  }
}
