import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';
import { catchError, mergeMap, throwError } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { LoggerService } from 'src/app/services/logger.service';
import { Role, RolesService } from 'src/app/services/roles.service';

export type RolesWithAccessResolverResponse = {
  roles?: Role[];
  error?: string;
};

export const rolesWithAccessResolver: ResolveFn<
  RolesWithAccessResolverResponse
> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const loggerService = inject(LoggerService);
  const rolesService = inject(RolesService);
  return authService.authReady.pipe(
    mergeMap((ready) => {
      if (!ready) {
        throwError(() => new Error('User not authenticated'));
      }
      return rolesService.getRolesWithAccess().pipe(
        catchError((err) => {
          loggerService.error(err);
          return [{ error: 'Error getting roles' }];
        })
      );
    })
  );
};
