import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FirebaseService } from './firebase.service';
import { mergeMap } from 'rxjs';

export type Tenant = {
  id: string;
  distributor_id: string;
  franchise_id?: string;
  account_number: string;
  stripe_customer_id?: string;
  avatax_customer_code?: string;
  autopay_enabled: boolean;
  first: string;
  last: string;
  title: string;
  company: string;
  address: string;
  address2: string;
  city: string;
  st: string;
  zip: string;
  phone1: string;
  phone2: string;
  emails: string[];
  created_at: Date;
};

export type TenantResponse = {
  tenant: Tenant;
};

export type TenantsResponse = {
  total_count: number;
  tenants: Tenant[];
};

type NewTenantRequest = {
  email: string;
  company_name: string;
  distributor_id: string;
  franchise_id?: string;
};

export type UpdateTenantRequest = {
  first: string;
  last: string;
  title: string;
  company: string;
  address: string;
  address2: string;
  city: string;
  st: string;
  zip: string;
  phone1: string;
  phone2: string;
  emails: string[];
};

export type AddTenantToFranchiseRequest = {
  tenant_id: string;
  franchise_id: string;
};

@Injectable({
  providedIn: 'root',
})
export class TenantsService {
  createTenantUrl = environment.serverUrl + '/tenants/create';
  getTenantUrl = environment.serverUrl + '/tenants/get';
  getCurrentTenantUrl = environment.serverUrl + '/tenants/get-current-tenant';
  getRangeUrl = environment.serverUrl + '/tenants/get-range';
  searchTenantsByAccountUrl =
    environment.serverUrl + '/tenants/search-by-account';
  searchTenantsUrl = environment.serverUrl + '/tenants/search';
  updateTenantUrl = environment.serverUrl + '/tenants/update';
  updateTenantAutopayUrl = environment.serverUrl + '/tenants/update-autopay';
  addTenantToFranchiseUrl = environment.serverUrl + '/tenants/add-to-franchise';
  removeTenantFromFranchiseUrl =
    environment.serverUrl + '/tenants/remove-from-franchise';

  constructor(
    private http: HttpClient,
    private firebaseService: FirebaseService
  ) {}

  createTenant(
    email: string,
    companyName: string,
    distributorId: string,
    franchiseId: string
  ) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        const body: NewTenantRequest = {
          email: email,
          company_name: companyName,
          distributor_id: distributorId,
        };
        if (franchiseId && franchiseId !== '') {
          body.franchise_id = franchiseId;
        }

        return this.http.post<TenantResponse>(this.createTenantUrl, body, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        });
      })
    );
  }

  getTenantRange(offset: number, limit: number) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.get<TenantsResponse>(
          `${this.getRangeUrl}/${offset}/${limit}`,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }

  getTenantById(tenantId: string) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.get<TenantResponse>(
          this.getTenantUrl + '/' + tenantId,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }

  getCurrentTenantDetails() {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.get<TenantResponse>(this.getCurrentTenantUrl, {
          headers: { Authorization: 'Bearer ' + token },
        });
      })
    );
  }

  updateTenant(tenantId: string, update: UpdateTenantRequest) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.put<TenantResponse>(
          this.updateTenantUrl + '/' + tenantId,
          update,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }

  updateTenantAutopay(tenantId: string, autopay: boolean) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.put<TenantResponse>(
          `${this.updateTenantAutopayUrl}/${tenantId}/${autopay}`,
          {
            autopay: autopay,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }

  searchTenantsByAccountNumber(query: string, offset: number, limit: number) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.get<TenantsResponse>(
          `${this.searchTenantsByAccountUrl}/${query}/${offset}/${limit}`,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }

  searchTenants(query: { account_number?: string; company_name?: string }) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.post<TenantsResponse>(this.searchTenantsUrl, query, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
      })
    );
  }

  addTenantToFranchise(tenantId: string, franchiseId: string) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.post<TenantResponse>(
          this.addTenantToFranchiseUrl,
          { tenant_id: tenantId, franchise_id: franchiseId },
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }

  removeTenantFromFranchise(tenantId: string, franchiseId: string) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.post<TenantResponse>(
          this.removeTenantFromFranchiseUrl,
          { tenant_id: tenantId, franchise_id: franchiseId },
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }
}
