import { Component } from '@angular/core';
import { KillSwitchService } from 'src/app/services/kill-switch.service';

@Component({
  selector: 'app-kill-switch',
  templateUrl: './kill-switch.component.html',
  styleUrls: ['./kill-switch.component.scss'],
})
export class KillSwitchComponent {
  loading: boolean = false;
  killAllOptions: { label: string; value: string }[] = [
    { label: 'Enable All', value: 'false' },
    { label: 'Disable All', value: 'true' },
  ];

  // Variables with 'current' prefix are intended to show what is current on the
  // server.
  currentKillAll = false;
  currentKillAllTitle = '';
  currentKillAllMessage = '';
  currentKillAllLink = '';
  currentMinVersion = '';
  currentMinVersionTitle = '';
  currentMinVersionMessage = '';
  currentMinVersionLink = '';
  currentUpdatedAt: Date = new Date();

  killAll = '';
  killAllTitle = '';
  killAllMessage = '';
  killAllLink = '';
  minVersion = '';
  minVersionTitle = '';
  minVersionMessage = '';
  minVersionLink = '';

  constructor(
    private killSwitchService: KillSwitchService  ) {
    this.getKillSwitchInfo();
  }

  // ngOnInit() {
  //   this.getKillSwitchInfo();
  // }

  async getKillSwitchInfo() {
    this.loading = true;
    this.killSwitchService.getKillSwitchInfo().subscribe({
      next: (response) => {
        console.log(
          'Kill switch info fetched successfully',
          response
        );
        this.currentKillAll = response.version_info.kill_all;
        this.currentKillAllTitle = response.version_info.kill_all_title;
        this.currentKillAllMessage = response.version_info.kill_all_message;
        this.currentKillAllLink = response.version_info.kill_all_link;
        this.currentMinVersion = response.version_info.min_version;
        this.currentMinVersionTitle = response.version_info.min_version_title;
        this.currentMinVersionMessage =
          response.version_info.min_version_message;
        this.currentMinVersionLink = response.version_info.min_version_link;
        this.currentUpdatedAt = response.version_info.created_at;

        this.killAll = this.currentKillAll.toString();
        this.killAllTitle = this.currentKillAllTitle;
        this.killAllMessage = this.currentKillAllMessage;
        this.killAllLink = this.currentKillAllLink;
        this.minVersion = this.currentMinVersion;
        this.minVersionTitle = this.currentMinVersionTitle;
        this.minVersionMessage = this.currentMinVersionMessage;
        this.minVersionLink = this.currentMinVersionLink;
      },
      error: (error) => {
        console.error(new Error(error));
      },
      complete: () => {
        console.log('Kill switch info fetch complete');
        this.loading = false;
      },
    });
  }

  updateKillSwitchInfo() {
    this.loading = true;
    const killSwitchRequest = {
      kill_all: this.killAll === 'true' ? true : false,
      kill_all_title: this.killAllTitle,
      kill_all_message: this.killAllMessage,
      kill_all_link: this.killAllLink,
      min_version: this.minVersion,
      min_version_title: this.minVersionTitle,
      min_version_message: this.minVersionMessage,
      min_version_link: this.minVersionLink,
    };
    console.log('Updating kill switch with', killSwitchRequest);

    this.killSwitchService.updateKillSwitchInfo(killSwitchRequest).subscribe({
      next: (response) => {
        console.log('Kill switch updated successfully', response);
        this.currentKillAll = response.version_info.kill_all;
        this.currentKillAllTitle = response.version_info.kill_all_title;
        this.currentKillAllMessage = response.version_info.kill_all_message;
        this.currentKillAllLink = response.version_info.kill_all_link;
        this.currentMinVersion = response.version_info.min_version;
        this.currentMinVersionTitle = response.version_info.min_version_title;
        this.currentMinVersionMessage =
          response.version_info.min_version_message;
        this.currentMinVersionLink = response.version_info.min_version_link;
        this.currentUpdatedAt = response.version_info.created_at;
      },
      error: (error) => {
        console.error(new Error(error));
      },
      complete: () => {
        console.log('Kill switch update complete');
        this.loading = false;
      },
    });
  }
}
