<div class="flex justify-center">
  <select
    class="select select-bordered mr-2 w-min"
    [(ngModel)]="currentSpacing"
    (change)="handleSettingsChanged()"
  >
    <option disabled selected>Table Spacing</option>
    @for (spacingOption of spacingOptions; track spacingOption) {
      <option [value]="spacingOption">
        {{ spacingOption }}
      </option>
    }
  </select>
  <button
    class="btn btn-primary join-item"
    [disabled]="currentPage === 1"
    (click)="prevPage()"
  >
    <app-chevron-left-icon class="h-6 w-6"></app-chevron-left-icon>
  </button>
  <div class="join mx-2">
    @for (i of pageArray; track i) {
      @if (i === -1) {
        <button class="btn disabled join-item btn-md cursor-default">
          ...
        </button>
      } @else {
        <button
          class="btn join-item btn-md"
          [class]="{ 'btn-active': currentPage === i }"
          (click)="changePage(i)"
        >
          <span [ngStyle]="{ 'min-width': buttonMinCharWidth + 'ch' }">{{
            i
          }}</span>
        </button>
      }
    }
  </div>
  <button
    class="btn btn-primary join-item"
    [disabled]="currentPage === totalPages"
    (click)="nextPage()"
  >
    <app-chevron-right-icon class="h-6 w-6"></app-chevron-right-icon>
  </button>
  <select
    class="select select-bordered ml-2 w-min"
    [(ngModel)]="currentPageSize"
    (change)="handleSettingsChanged()"
  >
    <option disabled selected>Page Size</option>
    @for (pageSizeOption of pageSizeOptions; track pageSizeOption) {
      <option [value]="pageSizeOption">
        {{ pageSizeOption }}
      </option>
    }
  </select>
</div>
