<div class="text-center">
  <h1 class="mb-8 text-3xl font-semibold leading-normal">
    Setup Authenticator App
  </h1>
  <p>Scan the QR code with your 2FA app</p>
  <canvas #totpQRCanvas class="mx-auto my-6"></canvas>
  <p class="mb-6">Or enter the key below into your 2FA app:</p>
  <p class="text-accent">{{ totpInfo?.secret?.secretKey }}</p>
  <div>
    <input
      class="input input-bordered my-6"
      type="text"
      [(ngModel)]="totpCode"
      (keydown.enter)="verifyCode.emit(totpCode)"
      placeholder="Enter 2FA Code"
    />
    <button
      class="btn btn-primary mb-6 w-full"
      (click)="verifyCode.emit(totpCode)"
    >
      Verify Authentication Code
    </button>
    <button class="btn btn-secondary w-full" (click)="goBack.emit()">
      Back
    </button>
  </div>
</div>
