import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';
import { ReplaySubject, catchError, mergeMap, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserResponse, UserWithPermissions } from './users.service';
import { RoleType } from './roles.service';
import { TenantsService, Tenant } from './tenants.service';

type SendEmailResponse = {
  email_sent: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserInfoUrl =
    environment.serverUrl + '/users/get-current-user';
  private sendEmailVerificationEmailUrl =
    environment.serverUrl + '/users/send-email-verification';
  private sendPasswordResetEmailUrl =
    environment.serverUrl + '/users/send-password-reset';
  currentUser?: UserWithPermissions;
  currentTenant?: Tenant;

  public UNAUTHENTICATED_REDIRECT = '/login';
  public AUTHENTICATED_REDIRECT = '/';
  public authReady = new ReplaySubject<boolean>(1);

  constructor(
    private firebaseService: FirebaseService,
    private http: HttpClient,
    private router: Router,
    private tenantsService: TenantsService
  ) {
    this.initializeAuth();
  }

  initializeAuth() {
    console.log('AuthService: Initializing auth');
    this.firebaseService.authReady
      .pipe(
        mergeMap((authReady) => {
          if (!authReady) {
            return of(false);
          }

          // if no user signed in, or user not email verfied with 2FA method,
          // no need to fetch user info
          if (
            !this.firebaseService.signedIn ||
            !this.emailVerified ||
            !this.has2FAEnrollment
          ) {
            return of(true);
          }

          return this.getCurrentUserInfo().pipe(
            tap({
              next: (done) => {
                return of(true);
              },
            }),
            catchError((err) => {
              console.error(new Error(err));
              return of(false);
            })
          );
        })
      )
      .subscribe((res) => {
        console.log('AuthService: Auth initialized - res: ', res);
        this.authReady.next(res);
      });
  }

  get signedIn() {
    const signedIn =
      this.currentUser !== undefined &&
      this.firebaseService.currentUser !== null &&
      this.emailVerified &&
      this.has2FAEnrollment;

    // if (!signedIn) {
    //   console.log('AuthService: signedIn: ', signedIn, {
    //     currentUser: this.currentUser,
    //     firebaseUser: this.firebaseService.currentUser,
    //     emailVerified: this.emailVerified,
    //     has2FAEnrollment: this.has2FAEnrollment,
    //   });
    // }

    return signedIn;
  }

  get emailVerified() {
    if (!this.firebaseService.currentUser) {
      return false;
    }
    return this.firebaseService.currentUser.emailVerified;
  }

  get has2FAEnrollment() {
    if (!this.firebaseService.mfaUser?.enrolledFactors) {
      return false;
    }
    return this.firebaseService.mfaUser.enrolledFactors.length > 0;
  }

  get _2FAEnrollments() {
    if (!this.firebaseService.mfaUser?.enrolledFactors) {
      return undefined;
    }
    return this.firebaseService.mfaUser.enrolledFactors;
  }

  signInWithEmail(username: string, password: string) {
    return this.firebaseService.signInWithEmail(username, password).pipe(
      mergeMap((userCredential) => {
        return this.getCurrentUserInfo();
      })
    );
  }

  signInWithGoogle() {
    return this.firebaseService.signInWithGoogle().pipe(
      mergeMap((userCredential) => {
        return this.getCurrentUserInfo();
      })
    );
  }

  signOut(redirect = true) {
    console.log(`auth.service -> signOut(${redirect})`);
    return this.firebaseService.signOut().pipe(
      tap({
        next: () => {
          console.log('Signing out');
          this.currentUser = undefined;
          if (redirect) {
            this.router.navigate([this.UNAUTHENTICATED_REDIRECT]);
          }
        },
        error: (err) => {
          console.error(new Error(err));
          this.currentUser = undefined;
          if (redirect) {
            this.router.navigate([this.UNAUTHENTICATED_REDIRECT]);
          }
        },
      }),
      catchError((err) => {
        console.error(err);
        this.currentUser = undefined;
        if (redirect) {
          this.router.navigate([this.UNAUTHENTICATED_REDIRECT]);
        }
        return of();
      })
    );
  }

  checkPermission(roleType: RoleType) {
    if (!this.currentUser) {
      return false;
    }

    return this.currentUser.permissions.some((p) => p.role.name === roleType);
  }

  /** Fetches the user data from the server, and updates the current user on
   * the service.
   */
  getCurrentUserInfo() {
    // fetch user data from server
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http
          .get<UserResponse>(this.currentUserInfoUrl, {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          })
          .pipe(
            mergeMap((res) => {
              console.log('User from server: ', res);
              this.currentUser = res.user;
              return this.tenantsService.getCurrentTenantDetails().pipe(
                mergeMap((res) => {
                  console.log('Tenant from server: ', res);
                  this.currentTenant = res.tenant;
                  return of(true);
                })
              );
            })
          );
      }),
      catchError((err) => {
        console.error(new Error(err));
        return of(false);
      })
    );
  }

  sendEmailVerificationEmail(email: string) {
    const url = this.sendEmailVerificationEmailUrl + '?email=' + email;
    return this.http.get<SendEmailResponse>(url);
  }

  sendPasswordResetEmail(email: string) {
    const url = this.sendPasswordResetEmailUrl + '?email=' + email;
    return this.http.get<SendEmailResponse>(url);
  }
}
