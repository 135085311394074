<div class="overflow-x-auto rounded-lg border border-solid border-black">
  <table class="table table-zebra static">
    <thead class="text-base font-medium">
      <tr>
        <td>Completed?</td>
        <td>Total Amount</td>
        <td
          class="cursor-pointer"
          (click)="sortChanged.emit('desktop_invoice.created_at')"
        >
          <span>Created Date</span>
          @if (currentSort?.field === 'desktop_invoice.created_at') {
            @if (currentSort?.direction === 'ASC') {
              <app-chevron-up-icon
                class="absolute inline-block h-6 w-6"
              ></app-chevron-up-icon>
            } @else {
              <app-chevron-down-icon
                class="absolute inline-block h-6 w-6"
              ></app-chevron-down-icon>
            }
          }
        </td>
        <td
          class="cursor-pointer"
          (click)="sortChanged.emit('desktop_invoice.expiration')"
        >
          <span>Expiration Date</span>
          @if (currentSort?.field === 'desktop_invoice.expiration') {
            @if (currentSort?.direction === 'ASC') {
              <app-chevron-up-icon
                class="absolute inline-block h-6 w-6"
              ></app-chevron-up-icon>
            } @else {
              <app-chevron-down-icon
                class="absolute inline-block h-6 w-6"
              ></app-chevron-down-icon>
            }
          }
        </td>
      </tr>
    </thead>
    <tbody>
      @for (invoice of invoices; track invoice) {
        <tr
          class="cursor-pointer"
          (click)="
            browseToInvoice(
              invoice.desktop_invoice.id,
              invoice.desktop_invoice.tenant_id
            )
          "
        >
          <td>{{ invoice.desktop_invoice.completed }}</td>
          <td>
            {{ invoice.desktop_invoice.total_amount | currency }}
          </td>
          <td>
            {{ invoice.desktop_invoice.created_at | date: 'short' }}
          </td>
          <td>
            {{
              invoice.desktop_invoice.expiration | date: 'mediumDate' : 'UTC'
            }}
          </td>
        </tr>
      }
    </tbody>
  </table>
</div>
