@if (status) {
  <div class="toast toast-end">
    <div
      (mouseenter)="onHoverToast($event)"
      (mouseleave)="onMouseOutToast($event)"
      class="alert alert-success"
      [ngClass]="{
        'alert-success': status === 'success',
        'alert-error': status === 'error',
        'alert-warning': status === 'warning',
        'alert-info': status === 'info',
      }"
    >
      <span>{{ message }}</span>
      <div class="h-6 w-6">
        <span
          [ngClass]="{
            hidden: isHoveringToast,
          }"
          class="countdown"
        >
          <span id="toastTimer" style="--value: 10"></span>
        </span>
        @if (isHoveringToast) {
          <span>
            <button class="btn btn-square btn-xs" (click)="clearToastTimer()">
              <div class="h-4 w-4 text-base-300 dark:text-white">
                <app-close-icon></app-close-icon>
              </div>
            </button>
          </span>
        }
      </div>
    </div>
  </div>
}
