import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FirebaseService } from '../../services/firebase.service';
import { firstValueFrom } from 'rxjs';
import { InvitationsService } from 'src/app/services/invitations.service';
import { LoggerService } from 'src/app/services/logger.service';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
})
export class TestComponent {
  inviteEmail: string = '';
  startingRoleId: string = 'fa876a4c-d2d1-4657-96a6-06663a9440c6';
  invitationResponse: any;

  constructor(
    private http: HttpClient,
    private firebaseService: FirebaseService,
    private invitationsService: InvitationsService,
    private loggerService: LoggerService
  ) {}

  async testAuth() {
    const userToken = await this.firebaseService.idToken();
    if (!userToken) throw new Error('No user token');

    const result = await firstValueFrom(
      this.http.get('http://localhost:8080/test-auth', {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        responseType: 'text',
      })
    );

    this.loggerService.log(result);
  }

  async testSendInvite() {
    this.invitationsService
      .createInvitation(this.inviteEmail, this.startingRoleId)
      .subscribe({
        next: (result) => {
          this.invitationResponse = result;
          this.loggerService.log('invitation created: ', result);
        },
        error: (err) => {
          this.invitationResponse = err;
          this.loggerService.error(err);
        },
      });
  }

  async testClientLog() {
    this.loggerService.log('test log');
    this.loggerService.error(new Error('test error'));
  }

  handleThemeChange(ev: Event) {
    this.loggerService.log('handleThemeChange', ev);
    document.documentElement.setAttribute(
      'data-theme',
      (ev.target as HTMLSelectElement).value
    );
  }

  get invitationResponseString() {
    return JSON.stringify(this.invitationResponse, null, 2);
  }
}
