import { Component } from '@angular/core';
import { LoggerService } from 'src/app/services/logger.service';
import { Tenant, TenantsService } from 'src/app/services/tenants.service';

@Component({
  selector: 'app-tenant-info-page',
  templateUrl: './tenant-info-page.component.html',
  styleUrl: './tenant-info-page.component.scss',
})
export class TenantInfoPageComponent {
  loadingTenant = false;
  tenant?: Tenant;
  tenantFetchError?: string;

  constructor(
    private loggerService: LoggerService,
    private tenantsService: TenantsService
  ) {
    this.getTenantDetails();
  }

  getTenantDetails() {
    this.loadingTenant = true;
    this.tenantsService.getCurrentTenantDetails().subscribe({
      next: (tenant) => {
        this.loadingTenant = false;
        this.tenant = tenant.tenant;
      },
      error: (err) => {
        this.loadingTenant = false;
        this.loggerService.error(err);
        this.tenantFetchError = 'Error getting tenant details';
      },
    });
  }
}
