<div class="p-5">
  <div class="flex justify-center" [formGroup]="formGroup">
    <div class="mr-8 min-w-72 max-w-96">
      @if (!loadingDistributors) {
        <label class="form-control relative w-full pb-4">
          <label class="label">
            <span class="label-text">Distributor</span>
          </label>
          <select class="select select-bordered" formControlName="distributor">
            <option value="" disabled selected></option>
            @for (distributor of distributors; track distributor) {
              <option [value]="distributor.id">
                {{ distributor.name }}
              </option>
            }
          </select>
          @if (distributor.invalid && distributor.touched) {
            <div class="label">
              <span class="label-text-alt text-error"
                >Please select a distributor</span
              >
            </div>
          }
        </label>
      }

      @if (!loadingFranchises) {
        <label class="form-control relative w-full pb-4">
          <label class="label">
            <span class="label-text">Franchise (optional)</span>
          </label>
          <select class="select select-bordered" formControlName="franchise">
            <option value="" selected>(None)</option>
            @for (franchise of franchises; track franchise) {
              <option [value]="franchise.id">
                {{ franchise.name }}
              </option>
            }
          </select>
        </label>
      }

      <label class="form-control relative w-full pb-4">
        <label class="label">
          <span class="label-text">Company Name</span>
        </label>
        <input
          class="input input-bordered"
          type="text"
          formControlName="companyName"
          placeholder="Company Name"
          name="companyName"
        />
        @if (
          companyName.invalid && (companyName.dirty || companyName.touched)
        ) {
          <div class="label">
            <span class="label-text-alt text-error"
              >Please enter company name</span
            >
          </div>
        }
      </label>
    </div>

    <div class="mr-8 min-w-72 max-w-96">
      <label class="form-control relative w-full pb-4">
        <label class="label">
          <span class="label-text">First Name</span>
        </label>
        <input
          class="input input-bordered"
          type="text"
          formControlName="firstName"
          placeholder="First Name"
          name="firstName"
        />
        @if (firstName.invalid && (firstName.dirty || firstName.touched)) {
          <div class="label">
            <span class="label-text-alt text-error"
              >Please enter a valid first name</span
            >
          </div>
        }
      </label>

      <label class="form-control relative w-full pb-4">
        <label class="label">
          <span class="label-text">Last Name</span>
        </label>
        <input
          class="input input-bordered"
          type="text"
          formControlName="lastName"
          placeholder="Last Name"
          name="lastName"
        />
        @if (lastName.invalid && (lastName.dirty || lastName.touched)) {
          <div class="label">
            <span class="label-text-alt text-error"
              >Please enter a valid last name</span
            >
          </div>
        }
      </label>

      <label class="form-control relative w-full pb-4">
        <label class="label">
          <span class="label-text">Email</span>
        </label>
        <input
          class="input input-bordered"
          type="text"
          formControlName="email"
          placeholder="Email"
          name="email"
        />
        @if (email.invalid && (email.dirty || email.touched)) {
          <div class="label">
            <span class="label-text-alt text-error"
              >Please enter a valid email address</span
            >
          </div>
        }
      </label>

      <label class="form-control relative w-full pb-4">
        <label class="label">
          <span class="label-text">Phone</span>
        </label>
        <input
          class="input input-bordered"
          type="text"
          formControlName="phone"
          placeholder="Phone"
          name="phone"
        />
        @if (phone.invalid && (phone.dirty || phone.touched)) {
          <div class="label">
            <span class="label-text-alt text-error"
              >Please enter a valid phone number</span
            >
          </div>
        }
      </label>
    </div>

    <div class="min-w-72 max-w-96">
      <label class="form-control relative w-full pb-4">
        <label class="label">
          <span class="label-text">Address</span>
        </label>
        <input
          class="input input-bordered"
          type="text"
          formControlName="address"
          placeholder="Address"
          name="address"
        />
        @if (address.invalid && (address.dirty || address.touched)) {
          <div class="label">
            <span class="label-text-alt text-error"
              >Please enter a valid address</span
            >
          </div>
        }
      </label>

      <label class="form-control relative w-full pb-4">
        <label class="label">
          <span class="label-text">City</span>
        </label>
        <input
          class="input input-bordered"
          type="text"
          formControlName="city"
          placeholder="City"
          name="city"
        />
        @if (city.invalid && (city.dirty || city.touched)) {
          <div class="label">
            <span class="label-text-alt text-error"
              >Please enter a valid city</span
            >
          </div>
        }
      </label>

      <label class="form-control relative w-full pb-4">
        <label class="label">
          <span class="label-text">State</span>
        </label>
        <select class="select select-bordered" formControlName="st" name="st">
          <option value="" disabled selected></option>
          @for (state of states; track state) {
            <option [value]="state">
              {{ state }}
            </option>
          }
        </select>
        @if (st.invalid && (st.dirty || st.touched)) {
          <div class="label">
            <span class="label-text-alt text-error"
              >Please enter a valid state</span
            >
          </div>
        }
      </label>

      <label class="form-control relative w-full pb-4">
        <label class="label">
          <span class="label-text">Zip</span>
        </label>
        <input
          class="input input-bordered"
          type="text"
          formControlName="zip"
          placeholder="Zip"
          name="zip"
        />
        @if (zip.invalid && (zip.dirty || zip.touched)) {
          <div class="label">
            <span class="label-text-alt text-error"
              >Please enter a valid zip</span
            >
          </div>
        }
      </label>
    </div>
  </div>

  <div class="mt-4 flex justify-center">
    <button
      class="btn btn-primary"
      (click)="validateAddress()"
      [disabled]="creatingTenant"
    >
      Create New Tenant
    </button>
  </div>

  @if (creatingTenant) {
    <div class="mt-4 flex justify-center">
      <div class="loading-spinner"></div>
    </div>
  }

  @if (createResponse) {
    <div class="mt-4">
      <p class="mb-2 text-center text-success">Tenant created successfully!</p>
      <p class="mb-2 text-center">
        Account Number: {{ createResponse.tenant.account_number }}
      </p>
      <div class="flex justify-center">
        <button
          class="btn btn-primary"
          [routerLink]="'/admin/tenant/' + createResponse.tenant.id"
        >
          View Tenant
        </button>
      </div>
    </div>
  }

  @if (createError) {
    <div class="mt-4">
      <p class="text-center text-error">{{ createError }}</p>
    </div>
  }
</div>

<dialog class="modal" #cassModal>
  <div class="modal-box max-w-3xl">
    <form method="dialog">
      <button class="btn btn-circle btn-ghost btn-sm absolute right-2 top-2">
        ✕
      </button>
    </form>

    <h3 class="text-lg font-bold">Address Validation</h3>

    @if (cassError) {
      <div class="mt-4 text-center text-error">
        <p>{{ cassError }}</p>
      </div>
    } @else if (validatingAddress) {
      <div class="mt-4 text-center">
        <div class="loading loading-spinner"></div>
      </div>
    } @else if (cassResponse && cassInputAddress) {
      <table class="table table-zebra">
        <thead class="text-base">
          <tr>
            <td></td>
            <td class="font-medium">Validated</td>
            <td class="font-medium">Original</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Company</td>
            <td>{{ cassResponse.company }}</td>
            <td>{{ cassInputAddress.company }}</td>
          </tr>
          <tr>
            <td>Address</td>
            <td>{{ cassResponse.address }}</td>
            <td>{{ cassInputAddress.address }}</td>
          </tr>
          <tr>
            <td>City</td>
            <td>{{ cassResponse.city }}</td>
            <td>{{ cassInputAddress.city }}</td>
          </tr>
          <tr>
            <td>State</td>
            <td>{{ cassResponse.state }}</td>
            <td>{{ cassInputAddress.state }}</td>
          </tr>
          <tr>
            <td>Zip</td>
            <td>{{ cassResponse.zip }}</td>
            <td>{{ cassInputAddress.zip }}</td>
          </tr>
          <tr>
            <td>DPV Code</td>
            <td>{{ cassResponse.dpva }}</td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>
              <button
                class="btn btn-success"
                (click)="createTenantWithCassResponse(cassResponse)"
              >
                Use Validated Address
              </button>
            </td>
            <td>
              <button class="btn btn-error" (click)="createTenant()">
                Use Input Address
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      @if (cassResponse.dpva === '' || cassResponse.dpva === 'N') {
        <div class="mt-8 text-error">
          We are unable to validate the input address. Are you sure you want to
          use this address?
        </div>
      }

      <div class="mt-8 flex justify-center space-x-4">
        <button class="btn btn-neutral" (click)="closeCassModal()">
          Cancel
        </button>
      </div>
    } @else {
      <div class="text-center text-error">
        <p>Unexpected error validating address</p>
      </div>
    }
  </div>
</dialog>
