import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { LicenseDetailsResolverResponse } from 'src/app/router/resolvers/desktop-license-details';
import {
  DesktopLicense,
  DesktopLicenseWithProduct,
  LicensesService,
  UpdateDesktopLicenseRequest,
} from 'src/app/services/licenses.service';
import { Tenant } from 'src/app/services/tenants.service';
import { LoggerService } from 'src/app/services/logger.service';

@Component({
  selector: 'app-edit-license',
  templateUrl: './edit-license.component.html',
  styleUrls: ['./edit-license.component.scss'],
})
export class EditLicenseComponent {
  updates!: UpdateDesktopLicenseRequest;
  originalLicense?: DesktopLicenseWithProduct;
  tenant?: Tenant;

  errorFetchingLicense = '';

  updating = false;
  errorUpdating = '';
  updateSuccess = false;

  constructor(
    private route: ActivatedRoute,
    private licensesService: LicensesService,
    private location: Location,
    private loggerService: LoggerService
  ) {
    const data = this.route.snapshot.data[
      'license'
    ] as LicenseDetailsResolverResponse;

    if (data?.error) {
      this.loggerService.error(new Error(data.error));
      this.errorFetchingLicense = data.error;
      return;
    } else if (!data.desktop_license || !data.tenant) {
      this.loggerService.log('No license or tenant data');
      this.errorFetchingLicense = 'Error fetching license and tenant data';
      return;
    }

    this.originalLicense = data.desktop_license;
    this.tenant = data.tenant;
    this.initializeUpdates(this.originalLicense);
    this.loggerService.log('Initialized updates', this.updates);
  }

  initializeUpdates(license: DesktopLicenseWithProduct) {
    this.updates = {
      active: license.desktop_license.active,
      expiration: license.desktop_license.expiration,
      start_date: license.desktop_license.start_date,
      transfers_left: license.desktop_license.transfers_left,
      will_renew: license.desktop_license.will_renew,
    };
  }

  get updateChanges() {
    if (!this.originalLicense) {
      return null;
    }
    const changes: UpdateDesktopLicenseRequest = {};
    if (this.updates.active !== this.originalLicense.desktop_license.active) {
      changes.active = this.updates.active;
    }
    if (
      this.updates.expiration !==
      this.originalLicense.desktop_license.expiration
    ) {
      changes.expiration = this.updates.expiration;
    }
    if (
      this.updates.start_date !==
      this.originalLicense.desktop_license.start_date
    ) {
      changes.start_date = this.updates.start_date;
    }
    if (
      this.updates.transfers_left !==
      this.originalLicense.desktop_license.transfers_left
    ) {
      changes.transfers_left = this.updates.transfers_left;
    }
    if (
      this.updates.will_renew !==
      this.originalLicense.desktop_license.will_renew
    ) {
      changes.will_renew = this.updates.will_renew;
    }

    if (Object.keys(changes).length === 0) {
      return null;
    }
    return changes;
  }

  formatDate(date: string) {
    return new Date(date);
  }

  async updateLicense() {
    if (!this.originalLicense) {
      this.loggerService.error(new Error('No license to update'));
      this.errorUpdating = 'No license to update';
      return;
    }

    this.updating = true;
    this.errorUpdating = '';
    this.updateSuccess = false;

    const updates = this.updateChanges;

    if (!updates) {
      this.updating = false;
      this.errorUpdating = 'No changes to update';
      return;
    }

    this.licensesService
      .updateLicense(this.originalLicense.desktop_license.id, updates)
      .subscribe({
        next: (res) => {
          this.loggerService.log('License updated: ', res);

          this.originalLicense!.desktop_license =
            res.desktop_license as DesktopLicense;
          this.updating = false;
          this.errorUpdating = '';
          this.updateSuccess = true;
        },
        error: (error) => {
          this.loggerService.error(new Error(error));
          this.updating = false;
          this.errorUpdating = error;
          this.updateSuccess = false;
        },
      });
  }

  cancelChanges() {
    this.location.back();
  }
}
