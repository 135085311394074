import { Component } from '@angular/core';
import {
  CategorizedLicenses,
  DesktopLicenseWithProduct,
  LicensesService,
} from 'src/app/services/licenses.service';

@Component({
  selector: 'app-licenses-page',
  templateUrl: './licenses-page.component.html',
  styleUrls: ['./licenses-page.component.scss'],
})
export class LicensesPageComponent {
  loadingLicenses = false;
  licenses?: CategorizedLicenses;
  errorFetchingLicenses: string = '';
  expandedRowId: number = -1;
  showRenewWarning: boolean = false;
  saveSuccess: boolean = false;
  saveError: boolean = false;
  isHoveringToast: boolean = false;
  intervalID?: any;

  constructor(
    private licensesService: LicensesService  ) {
    this.getLicenses();
  }

  getLicenses() {
    this.loadingLicenses = true;
    this.licensesService.getLicensesForCurrentUser().subscribe({
      next: (res) => {
        this.loadingLicenses = false;
        this.licenses = res.desktop_licenses;
      },
      error: (err) => {
        this.loadingLicenses = false;
        console.error(err);
        this.errorFetchingLicenses = err;
      },
    });
  }

  expandRow(idx: number) {
    this.showRenewWarning = false;
    if (this.expandedRowId === idx) {
      this.expandedRowId = -1;
    } else {
      this.expandedRowId = idx;
    }
  }

  getProductDuration(license: DesktopLicenseWithProduct) {
    if (license.product_variant.duration_months % 12 === 0) {
      if (license.product_variant.duration_months / 12 === 1) {
        return `${license.product_variant.duration_months / 12} year`;
      }
      return `${license.product_variant.duration_months / 12} years`;
    } else {
      if (license.product_variant.duration_months === 1) {
        return `${license.product_variant.duration_months} month`;
      }
      return `${license.product_variant.duration_months} months`;
    }
  }

  toggleRenew(license: DesktopLicenseWithProduct, ev: Event) {
    console.log('toggle renew - license: ', license);
    if (license.will_renew) {
      ev.preventDefault();
      this.showRenewWarning = true;
      // license.desktop_license.will_renew = false;
    } else if (!license.will_renew) {
      this.showRenewWarning = false;
      license.will_renew = true;
      this.saveLicense(license);
    }
  }

  disableLicenseRenewal(license: DesktopLicenseWithProduct) {
    license.will_renew = false;
    this.saveLicense(license);
  }

  saveLicense(license: DesktopLicenseWithProduct) {
    console.log('saving - license: ', license);
    this.saveError = false;
    this.saveSuccess = false;
    this.showRenewWarning = false;
    // this.saving = true;
    this.licensesService
      .updateLicenseWillRenew([
        {
          desktop_license_id: license.id,
          will_renew: license.will_renew,
        },
      ])
      .subscribe({
        next: (data) => {
          this.saveSuccess = true;
          console.log('data: ', data);
        },
        error: (err) => {
          this.saveError = true;
          console.log('err: ', err);
        },
      });
  }
}
