<svg
  width="48"
  height="32"
  viewBox="0 0 48 32"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g id="visa-color_large">
    <rect id="card_bg" width="48" height="32" rx="4" fill="#2A2A6C" />
    <path
      id="visa-logo"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30.35 17.67C30.3561 16.1453 29.0986 15.4867 28.1227 14.9756C27.5049 14.6521 27 14.3877 27 14C27 13.67 27.32 13.32 28 13.23C28.8093 13.1499 29.6251 13.2917 30.36 13.64L30.78 11.64C30.0636 11.3714 29.3051 11.2325 28.54 11.23C26.18 11.23 24.54 12.49 24.54 14.29C24.54 15.62 25.73 16.36 26.63 16.8C27.53 17.24 27.88 17.55 27.87 17.95C27.87 18.57 27.13 18.85 26.44 18.86C25.5889 18.871 24.749 18.6644 24 18.26L23.57 20.26C24.4175 20.5908 25.3203 20.7571 26.23 20.75C28.74 20.75 30.39 19.51 30.4 17.59L30.35 17.67ZM23.86 11.39L21.86 20.64H19.46L21.46 11.39H23.86ZM33.92 17.39L35.18 13.92L35.91 17.39H33.92ZM38.81 20.67H36.59L36.3 19.29H33.29L32.8 20.67H30.28L33.87 12.1C34.0355 11.6923 34.43 11.424 34.87 11.42H36.87L38.81 20.67ZM16.61 20.61L20.49 11.36H17.89L15.4 17.65L14.4 12.3C14.3115 11.7563 13.8408 11.3578 13.29 11.36H9.22L9.16 11.63C9.97634 11.7897 10.7693 12.0518 11.52 12.41C11.8398 12.5603 12.0564 12.8683 12.09 13.22L14 20.61H16.61Z"
      fill="white"
    />
  </g>
</svg>
