import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';
import { mergeMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DesktopLicenseWithProduct } from './licenses.service';

type DesktopInvoice = {
  id: string;
  tenant_id: string;
  completed: boolean;
  completed_at: Date;
  renewal: boolean;
  base_amount: number;
  tax_amount: number;
  total_amount: number;
  expiration: Date;
  created_at: Date;
};

export type InvoiceDetails = {
  desktop_invoice: DesktopInvoice;
  desktop_licenses: DesktopLicenseWithProduct[];
  not_renewing_licenses: DesktopLicenseWithProduct[];
  total: number;
  due_by: Date;
};

export type InvoicesResponse = {
  desktop_invoices: {
    pending: InvoiceDetails[];
    complete: InvoiceDetails[];
    expired: InvoiceDetails[];
  };
};

export type InvoiceResponse = {
  invoice_details: InvoiceDetails;
};

export type CreateInvoiceRequest = {
  desktop_license_ids: string[];
};

export type UpdateProductVariantForRenewalRequest = {
  invoice_id: string;
  desktop_license_id: string;
  new_product_variant_id: string;
};

export type GroupedLicenses = {
  license: DesktopLicenseWithProduct;
  count: number;
};

@Injectable({
  providedIn: 'root',
})
export class InvoicesService {
  getInvoiceDetailsUrl = environment.serverUrl + '/invoices/get';
  invoicesByTenantUrl = environment.serverUrl + '/invoices/for-tenant';
  currentTenantInvoicesUrl = environment.serverUrl + '/invoices/get-active';
  createInvoiceForLicensesUrl =
    environment.serverUrl + '/invoices/create-for-licenses';
  updateVariantForRenewalUrl =
    environment.serverUrl + '/invoices/update-product-variant-for-renewal';

  constructor(
    private firebaseService: FirebaseService,
    private http: HttpClient
  ) {}

  getInvoiceDetails(invoiceId: string) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.get<InvoiceResponse>(
          this.getInvoiceDetailsUrl + '/' + invoiceId,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }

  getInvoicesForCurrentTenant() {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.get<InvoicesResponse>(this.currentTenantInvoicesUrl, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
      })
    );
  }

  getInvoicesForTenant(tenantId: string) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.get<InvoicesResponse>(
          this.invoicesByTenantUrl + '/' + tenantId,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }

  createInvoice(request: CreateInvoiceRequest) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.post<InvoiceResponse>(
          this.createInvoiceForLicensesUrl,
          request,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }

  updateProductVariantForRenewal(
    request: UpdateProductVariantForRenewalRequest
  ) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.post<InvoiceResponse>(
          this.updateVariantForRenewalUrl,
          request,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }

  groupLicensesByProduct(
    licenses: DesktopLicenseWithProduct[]
  ): GroupedLicenses[] {
    const grouped: GroupedLicenses[] = [];

    for (const license of licenses) {
      const existing = grouped.find(
        (l) =>
          l.license.product_variant.id === license.product_variant.id &&
          l.license.desktop_license.site_license ===
            license.desktop_license.site_license &&
          l.license.desktop_license.renewal === license.desktop_license.renewal
      );

      if (existing) {
        existing.count++;
      } else {
        grouped.push({ license, count: 1 });
      }
    }

    return grouped;
  }

  getLicensePrice(license: DesktopLicenseWithProduct) {
    const dl = license.desktop_license;
    if (dl.renewal && dl.site_license) {
      return license.product_variant.site_renewal_price;
    } else if (dl.renewal) {
      return license.product_variant.renewal_price;
    } else if (dl.site_license) {
      return license.product_variant.site_price;
    } else {
      return license.product_variant.price;
    }
  }
}
