<div class="flex flex-col">
  <div class="prose mt-6">
    <h1>Current Kill Switch Status</h1>
  </div>

  <div class="stats mt-6 w-full bg-base-300">
    <div class="stat">
      <div class="stat-figure text-secondary">
        @if (!currentKillAll) {
          <svg
            class="h-10 w-10 text-success"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 11.917 9.724 16.5 19 7.5"
            />
          </svg>
        } @else {
          <svg
            class="h-10 w-10 text-error"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        }
      </div>
      <div class="stat-title">Kill All Status</div>

      @if (!currentKillAll) {
        <div class="stat-value text-2xl text-success">Users are online</div>
      } @else {
        <div class="stat-value text-2xl text-error">Users are offline</div>
      }
    </div>

    <div class="stat">
      <div class="stat-title">Min Version</div>
      <div class="stat-value text-2xl">{{ currentMinVersion }}</div>
    </div>

    <div class="stat">
      <div class="stat-title">Updated At</div>
      <div class="stat-value text-2xl">2023/12/05</div>
      <div class="stat-desc">04:27pm Central</div>
    </div>

    <div class="stat">
      <div class="stat-title">Updated By</div>
      <div class="stat-value text-2xl">brock&#64;accuzip.com</div>
    </div>
  </div>

  <div class="mt-6 flex">
    <div class="mr-6 w-1/2">
      <div class="prose">
        <h2>Current Kill All Info</h2>
      </div>
      <div class="card mt-6 w-full bg-base-300">
        <div class="card-body">
          <div class="card-title">
            <div class="text-primary">Title:</div>
            {{ currentKillAllTitle }}
          </div>
          <div>
            <div class="text-primary">Message:</div>
            {{ currentKillAllMessage }}
          </div>
          <div>
            <div class="text-primary">Link:</div>
            {{ currentKillAllLink }}
          </div>
        </div>
      </div>
    </div>
    <div class="ml-6 w-1/2">
      <div class="prose">
        <h2>Current Min Version Info</h2>
      </div>
      <div class="card mt-6 w-full bg-base-300">
        <div class="card-body">
          <div class="card-title">
            <div class="text-primary">Title:</div>
            {{ currentMinVersionTitle }}
          </div>
          <div>
            <div class="text-primary">Message:</div>
            {{ currentMinVersionMessage }}
          </div>
          <div>
            <div class="text-primary">Link:</div>
            {{ currentMinVersionLink }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-6">
    <div class="prose">
      <h2 class="w-max">
        Update The Kill Switch Status/Info Using The Form Below
      </h2>
    </div>
    <div class="card mt-6 w-full bg-base-300 p-8">
      <div class="grid grid-cols-2 gap-8">
        <div class="grid grid-cols-4 grid-rows-[min-content] gap-4">
          <label class="form-control">
            <div class="label">
              <span class="label-text">Kill All</span>
            </div>
            <select class="select select-bordered" [(ngModel)]="killAll">
              @for (killAllOption of killAllOptions; track killAllOption) {
                <option [value]="killAllOption.value">
                  {{ killAllOption.label }}
                </option>
              }
            </select>
          </label>

          <label class="form-control col-span-3">
            <div class="label">
              <span class="label-text">Kill All Title</span>
            </div>
            <input
              type="text"
              class="input input-bordered"
              [(ngModel)]="killAllTitle"
              placeholder="Enter title here..."
            />
          </label>

          <label class="form-control col-span-4 mb-auto">
            <div class="label">
              <span class="label-text">Kill All Message</span>
            </div>
            <textarea
              class="textarea textarea-bordered"
              [(ngModel)]="killAllMessage"
              placeholder="Enter message here..."
            ></textarea>
          </label>

          <label class="form-control col-span-4 mt-auto">
            <div class="label">
              <span class="label-text">Kill All Link</span>
            </div>
            <input
              type="text"
              class="input input-bordered"
              [(ngModel)]="killAllLink"
              placeholder="Enter Link here..."
            />
          </label>
        </div>

        <div class="grid grid-cols-4 grid-rows-[min-content] gap-4">
          <label class="form-control col-span-1">
            <div class="label">
              <span class="label-text">Min Version</span>
            </div>
            <input
              type="text"
              class="input input-bordered"
              [(ngModel)]="minVersion"
              placeholder="5.7.216"
            />
          </label>

          <label class="form-control col-span-3">
            <div class="label">
              <span class="label-text">Min Version Title</span>
            </div>
            <input
              type="text"
              class="input input-bordered"
              [(ngModel)]="minVersionTitle"
              placeholder="Enter title here..."
            />
          </label>

          <label class="form-control col-span-4 mb-auto">
            <div class="label">
              <span class="label-text">Min Version Message</span>
            </div>
            <textarea
              class="textarea textarea-bordered"
              [(ngModel)]="minVersionMessage"
              placeholder="Enter message here..."
            ></textarea>
          </label>

          <label class="form-control col-span-4 mt-auto">
            <div class="label">
              <span class="label-text">Min Version Link</span>
            </div>
            <input
              type="text"
              class="input input-bordered"
              [(ngModel)]="minVersionLink"
              placeholder="Enter Link here..."
            />
          </label>
        </div>

        <div class="col-span-2 text-center">
          <button class="btn btn-primary" (click)="updateKillSwitchInfo()">
            Update The Kill Switch Settings
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Loading Overlay -->
@if (loading) {
  <div
    class="fixed left-0 top-0 z-[1000] flex h-full w-full bg-black bg-opacity-50"
  >
    <span class="loading loading-spinner loading-lg m-auto"></span>
  </div>
}

