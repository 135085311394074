import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';
import { catchError } from 'rxjs';
import { LoggerService } from 'src/app/services/logger.service';
import { Product, ProductsService } from 'src/app/services/products.service';

export type ProductResolverResponse = {
  product?: Product;
  error?: string;
};

export const productResolver: ResolveFn<ProductResolverResponse> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const loggerService = inject(LoggerService);
  return inject(ProductsService)
    .getProduct(route.paramMap.get('id')!)
    .pipe(
      catchError((err) => {
        loggerService.error(err);
        return [{ error: 'Error getting product' }];
      })
    );
};
