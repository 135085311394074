<ul
  class="menu h-full w-52 bg-base-200 p-0 pt-4 text-base [&_li>*]:rounded-none no-print"
>
  <li>
    <a
      routerLink="/"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      class="active text-xl normal-case"
    >
      <app-home-icon class="h-5 w-5 text-current"></app-home-icon>
      Dashboard
    </a>
  </li>
  <li>
    <a
      routerLink="/licenses"
      routerLinkActive="active"
      class="text-xl normal-case"
    >
      <app-clipboard-icon class="h-5 w-5 text-current"></app-clipboard-icon>
      Licenses
    </a>
  </li>
  <li>
    <a
      routerLink="/tenant/users"
      routerLinkActive="active"
      class="text-xl normal-case"
    >
      <app-users-icon class="h-5 w-5 text-current"></app-users-icon>
      Users
    </a>
  </li>
  <li>
    <a
      routerLink="/invoices"
      routerLinkActive="active"
      class="text-xl normal-case"
    >
      <app-file-invoice-icon
        class="h-5 w-5 text-current"
      ></app-file-invoice-icon>
      Invoices
    </a>
  </li>
  <li>
    <a
      routerLink="/saved-payment-methods"
      routerLinkActive="active"
      class="text-xl normal-case"
    >
      <app-credit-card-icon class="h-5 w-5 text-current"></app-credit-card-icon>
      Saved Payment
    </a>
  </li>
  @if (showAdmin) {
    <li>
      <a
        [routerLink]="['/admin']"
        routerLinkActive="active"
        class="text-xl normal-case"
      >
        <app-user-setting-icon
          class="h-5 w-5 text-current"
        ></app-user-setting-icon>
        Admin
      </a>
    </li>
  }
  <li class="mt-auto">
    <a class="text-xl normal-case" routerLink="/test" routerLinkActive="active">
      <app-lightbulb-icon class="h-5 w-5 text-current"></app-lightbulb-icon>
      Test Page
    </a>
  </li>
</ul>
