<div class="p-5 pt-12">
  <div class="tabs tabs-lifted" role="tablist">
    <a
      class="tab"
      role="tab"
      [class]="{ 'tab-active': currentTab === 'tenants-list' }"
      (click)="currentTab = 'tenants-list'"
      [routerLink]="['/admin']"
      [queryParams]="{ tab: 'tenants-list' }"
      >Tenants</a
    >
    <a
      class="tab"
      role="tab"
      [class]="{ 'tab-active': currentTab === 'create-tenant' }"
      (click)="currentTab = 'create-tenant'"
      [routerLink]="['/admin']"
      [queryParams]="{ tab: 'create-tenant' }"
      >Create Tenant</a
    >
    <a
      class="tab"
      role="tab"
      [class]="{ 'tab-active': currentTab === 'product-list' }"
      (click)="currentTab = 'product-list'"
      [routerLink]="['/admin']"
      [queryParams]="{ tab: 'product-list' }"
      >Products</a
    >
    <a
      class="tab"
      role="tab"
      [class]="{ 'tab-active': currentTab === 'franchises-list' }"
      (click)="currentTab = 'franchises-list'"
      [routerLink]="['/admin']"
      [queryParams]="{ tab: 'franchises-list' }"
      >Franchises</a
    >
    <a
      class="tab"
      role="tab"
      [class]="{ 'tab-active': currentTab === 'kill-switch' }"
      (click)="currentTab = 'kill-switch'"
      [routerLink]="['/admin']"
      [queryParams]="{ tab: 'kill-switch' }"
      >Kill Switch</a
    >
    <a class="tab"></a>
  </div>

  @if (currentTab === 'tenants-list') {
    <app-tenants-list></app-tenants-list>
  } @else if (currentTab === 'create-tenant') {
    <app-create-tenant></app-create-tenant>
  } @else if (currentTab === 'product-list') {
    <app-product-list></app-product-list>
  } @else if (currentTab === 'franchises-list') {
    <app-franchises-list></app-franchises-list>
  } @else if (currentTab === 'kill-switch') {
    <app-kill-switch></app-kill-switch>
  }
</div>
