import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { toCanvas } from 'qrcode';

@Component({
  selector: 'app-totp-enrollment',
  templateUrl: './totp-enrollment.component.html',
  styleUrl: './totp-enrollment.component.scss',
})
export class TotpEnrollmentComponent implements OnChanges {
  // @Input() verifyCode!: (code: string) => void;
  @Input() totpInfo?: any;
  @Output() verifyCode = new EventEmitter<string>();
  @Output() goBack = new EventEmitter<void>();

  @ViewChild('totpQRCanvas', { static: false })
  totpQRCanvas?: ElementRef<HTMLDivElement>;

  totpCode = '';

  constructor() {}

  ngOnChanges() {
    if (this.totpInfo && this.totpQRCanvas) {
      this.renderQR();
    }
  }

  renderQR() {
    console.log('totp-enrollment.component -> renderQR');
    toCanvas(this.totpQRCanvas?.nativeElement, this.totpInfo.totpUri).then(
      () => {}
    );
  }
}
