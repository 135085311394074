import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Tenant, TenantsService } from 'src/app/services/tenants.service';
import { LoggerService } from 'src/app/services/logger.service';

@Component({
  selector: 'app-tenant-info-setup-form',
  templateUrl: './tenant-info-setup-form.component.html',
  styleUrls: ['./tenant-info-setup-form.component.scss'],
})
export class TenantInfoSetupFormComponent {
  first: string = '';
  last: string = '';
  title: string = '';
  company: string = '';
  address: string = '';
  address2: string = '';
  city: string = '';
  st: string = '';
  zip: string = '';
  phone1: string = '';
  phone2: string = '';
  emails: string[] = [];

  newEmail: string = '';

  phone1Error: string = '';
  phone2Error: string = '';
  emailError: string = '';

  states = states;

  currentStep: number = 1;

  loadingTenant = false;
  tenant?: Tenant;
  tenantFetchError: string = '';
  error = '';

  constructor(
    private authService: AuthService,
    private loggerService: LoggerService,
    private tenantsService: TenantsService
  ) {
    this.getTenantDetails();
  }

  getTenantDetails() {
    this.loadingTenant = true;
    this.tenantsService.getCurrentTenantDetails().subscribe({
      next: (tenant) => {
        this.loadingTenant = false;
        this.tenant = tenant.tenant;
        this.initializeData(this.tenant);
      },
      error: (err) => {
        this.loadingTenant = false;
        this.loggerService.error(err);
        this.tenantFetchError = 'Error getting tenant details';
      },
    });
  }

  initializeData(tenant: Tenant) {
    this.first = tenant.first;
    this.last = tenant.last;
    this.title = tenant.title;
    this.company = tenant.company;
    this.address = tenant.address;
    this.address2 = tenant.address2;
    this.city = tenant.city;
    this.st = tenant.st;
    this.zip = tenant.zip;
    this.phone1 = tenant.phone1;
    this.phone2 = tenant.phone2;
    this.emails = tenant.emails;
  }

  addEmail() {
    if (!this.newEmail) {
      return;
    }

    if (!this.validateEmail(this.newEmail)) {
      this.emailError = 'Invalid email';
      return;
    }

    if (this.emails.includes(this.newEmail)) {
      this.emailError = 'Email already added';
      return;
    }

    this.emails.push(this.newEmail);
    this.newEmail = '';
  }

  removeEmail(email: string) {
    this.emails = this.emails.filter((e) => e !== email);
  }

  previousStep() {
    this.currentStep--;
  }

  nextStep() {
    this.currentStep++;
  }

  submit() {
    this.loggerService.log('submitting form');

    const request = {
      first: this.first,
      last: this.last,
      title: this.title,
      company: this.company,
      address: this.address,
      address2: this.address2,
      city: this.city,
      st: this.st,
      zip: this.zip,
      phone1: this.phone1,
      phone2: this.phone2,
      emails: this.emails,
    };

    this.loggerService.log('request: ', request);

    const tenantId = this.authService.currentUser?.tenant_id;
    if (!tenantId) {
      this.loggerService.error(new Error('No tenant ID'));
      return;
    }

    this.loggerService.log('tenantId: ', tenantId);

    this.tenantsService.updateTenant(tenantId, request).subscribe({
      next: (response) => {
        this.loggerService.log('response: ', response);
        // this.response = JSON.stringify(response);
      },
      error: (error) => {
        this.loggerService.error(error);
        this.error = JSON.stringify(error);
      },
    });
  }

  handlePhoneChange(ev: Event, num: number) {
    const phone = (ev.target as HTMLInputElement).value;
    const digits = phone.replace(/\D/g, '');

    if (digits.length === 0) {
      if (num === 1) {
        this.phone1 = '';
        this.phone1Error = '';
      } else {
        this.phone2 = '';
        this.phone2Error = '';
      }
      return;
    }

    if (digits.length !== 10) {
      this.loggerService.error(new Error('Phone number must have 10 digits'));
      if (num === 1) {
        this.phone1Error = 'Phone number must have 10 digits';
      } else {
        this.phone2Error = 'Phone number must have 10 digits';
      }
      return;
    }

    const newPhone = `(${digits.slice(0, 3)}) ${digits.slice(
      3,
      6
    )}-${digits.slice(6)}`;

    if (num === 1) {
      this.phone1 = newPhone;
    } else {
      this.phone2 = newPhone;
    }
  }

  validateEmail(email: string) {
    this.loggerService.log('validating email: ', email);
    // email regex
    const re = /\S+@\S+\.\S+/;
    if (!re.test(email)) {
      this.loggerService.error(new Error('Invalid email'));
      return false;
    }

    return true;
  }
}

// a list of state abbreviateions
const states = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FM',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MH',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'MP',
  'OH',
  'OK',
  'OR',
  'PW',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VI',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];
