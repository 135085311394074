<div>
  @if (tenant) {
    <div class="mb-4 flex">
      <h4 class="text-lg font-semibold">
        Updating Account # {{ tenant.account_number }}
      </h4>
    </div>
  }
  <div class="flex flex-wrap justify-between">
    <div class="order-1 min-w-[12rem] flex-1 basis-full xl:mr-8 xl:basis-1/4">
      <label class="form-control m-auto w-full max-w-lg">
        <div class="label">
          <span class="label-text">First Name</span>
        </div>
        <input
          type="text"
          placeholder="First Name"
          [(ngModel)]="first"
          class="input input-bordered w-full"
        />
      </label>
      <label class="form-control m-auto w-full max-w-lg">
        <div class="label">
          <span class="label-text">Last Name</span>
        </div>
        <input
          type="text"
          placeholder="Last Name"
          [(ngModel)]="last"
          class="input input-bordered w-full"
        />
      </label>
      <label class="form-control m-auto w-full max-w-lg">
        <div class="label">
          <span class="label-text">Title</span>
        </div>
        <input
          type="text"
          placeholder="Title"
          [(ngModel)]="title"
          class="input input-bordered w-full"
        />
      </label>
      <label class="form-control m-auto w-full max-w-lg">
        <div class="label">
          <span class="label-text">Company</span>
        </div>
        <input
          type="text"
          placeholder="Company"
          [(ngModel)]="company"
          class="input input-bordered w-full"
        />
      </label>
    </div>

    <div class="order-2 min-w-[12rem] flex-1 basis-full xl:mr-8 xl:basis-1/4">
      <label class="form-control m-auto w-full max-w-lg">
        <div class="label">
          <span class="label-text">Address</span>
        </div>
        <input
          type="text"
          placeholder="Address"
          [(ngModel)]="address"
          class="input input-bordered w-full"
        />
      </label>
      <label class="form-control m-auto w-full max-w-lg">
        <div class="label">
          <span class="label-text">Address 2</span>
        </div>
        <input
          type="text"
          placeholder="Address 2"
          [(ngModel)]="address2"
          class="input input-bordered w-full"
        />
      </label>
      <label class="form-control m-auto w-full max-w-lg">
        <div class="label">
          <span class="label-text">City</span>
        </div>
        <input
          type="text"
          placeholder="City"
          [(ngModel)]="city"
          class="input input-bordered w-full"
        />
      </label>
      <label class="form-control m-auto w-full max-w-lg">
        <div class="label">
          <span class="label-text">State (Abbreviation)</span>
        </div>
        <select class="select select-bordered" [(ngModel)]="st">
          <option value="" disabled selected>Select State</option>
          @for (state of states; track state) {
            <option [value]="state">
              {{ state }}
            </option>
          }
        </select>
      </label>
      <label class="form-control m-auto w-full max-w-lg">
        <div class="label">
          <span class="label-text">Zip</span>
        </div>
        <input
          type="text"
          placeholder="Zip"
          [(ngModel)]="zip"
          class="input input-bordered w-full"
        />
      </label>
    </div>
    
    <div class="order-3 min-w-[12rem] flex-1 basis-full xl:basis-1/4">
      <label class="form-control m-auto w-full max-w-lg">
        <div class="label">
          <span class="label-text">Phone 1</span>
        </div>
        <input
          type="text"
          placeholder="Phone 1"
          [(ngModel)]="phone1"
          (change)="handlePhoneChange($event, 1)"
          [ngClass]="{ 'border-red-500': phone1Error }"
          class="input input-bordered w-full"
        />
        @if (phone1Error) {
          <div class="label">
            <span class="label-text-alt text-error">{{ phone1Error }}</span>
          </div>
        }
      </label>
      <label class="form-control m-auto w-full max-w-lg">
        <div class="label">
          <span class="label-text">Phone 2</span>
        </div>
        <input
          type="text"
          placeholder="Phone 2"
          [(ngModel)]="phone2"
          (change)="handlePhoneChange($event, 2)"
          [ngClass]="{ 'border-red-500': phone2Error }"
          class="input input-bordered w-full"
        />
        @if (phone2Error) {
          <div class="label">
            <span class="label-text-alt text-error">{{ phone2Error }}</span>
          </div>
        }
      </label>
      <label class="label-text mx-auto mb-2 mt-4 block w-full max-w-lg"
        >Emails</label
      >
      <div class="form-control m-auto w-full max-w-lg">
        <div class="join w-full">
          <input
            type="text"
            placeholder="New Email"
            [(ngModel)]="newEmail"
            class="input join-item input-bordered w-full"
          />
          <button
            (click)="addEmail()"
            class="btn join-item rounded-l-none border-base-content/20"
          >
            Add
          </button>
        </div>
        <div class="label">
          <div class="label-text-alt text-error">{{ emailError }}</div>
        </div>
      </div>
      <div class="m-auto mt-2 w-full max-w-lg">
        @if (emails.length > 0) {
          <ul>
            @for (email of emails; track email) {
              <li class="flex w-full">
                <span>{{ email }}</span>
                <span
                  (click)="removeEmail(email)"
                  class="ml-auto cursor-pointer"
                >
                  <app-close-icon class="h-6 w-6 text-error"></app-close-icon>
                </span>
              </li>
            }
          </ul>
        }
        @if (emails.length === 0) {
          <p>No emails added</p>
        }
      </div>
    </div>
  </div>
  <div class="mt-12">
    <div class="flex justify-center space-x-8">
      <button class="btn btn-primary" [disabled]="updating" (click)="submit()">
        Submit
      </button>
      <button class="btn btn-secondary" (click)="back()">Back</button>
    </div>

    @if (updateSuccess) {
      <div class="mt-8 text-center text-success">
        <p>Successfully updated tenant</p>
      </div>
    } @else if (updating) {
      <div class="mt-4 flex justify-center">
        <div class="loading loading-spinner loading-lg"></div>
      </div>
    }
  </div>
</div>
