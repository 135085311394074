<div class="flex">
  <app-graphic-side-panel
    class="hidden h-screen w-[50vw] md:flex"
    [subtext]="'Login to go to your portal'"
  ></app-graphic-side-panel>
  <div class="flex h-screen w-screen items-center justify-center md:w-[50vw]">
    <div class="mx-6 w-full max-w-lg">
      <img
        class="mx-auto my-4 w-36 md:hidden"
        src="../../../assets/accuzip-logo-white.png"
        alt="AccuZIP Logo"
      />
      @if (pageState === 'login') {
        <div>
          <h1
            class="z-10 m-auto hidden max-w-lg text-5xl font-semibold leading-normal md:block"
          >
            Login
          </h1>
          <h1
            class="z-10 m-auto max-w-lg text-center text-3xl font-semibold leading-normal md:hidden"
          >
            Login to the Customer Portal
          </h1>
          <div class="my-8">
            <input
              class="input input-bordered w-full"
              type="text"
              [(ngModel)]="username"
              placeholder="Email"
            />
          </div>
          <div class="my-8">
            <input
              class="input input-bordered w-full"
              type="password"
              [(ngModel)]="password"
              (keydown.enter)="signInWithEmail()"
              placeholder="Password"
            />
          </div>
          <div class="my-8">
            <button class="btn btn-primary w-full" (click)="signInWithEmail()">
              Login with Email
            </button>

            <div class="mt-4 text-center">
              <p class="link text-sm" (click)="pageState = 'forgot-password'">
                Forgot Password?
              </p>
            </div>
          </div>

          <div class="my-8 flex">
            <hr class="my-auto w-full" />
            <p class="px-4 text-center text-sm">Or</p>
            <hr class="my-auto w-full" />
          </div>

          <div class="my-4">
            <button
              class="btn w-full border border-[#8E918F] bg-[#131314] text-[#E3E3E3] hover:border hover:border-[#8E918F] hover:bg-[#131314]"
              (click)="signInWithGoogle()"
            >
              <div class="h-5 w-5">
                <app-google-icon></app-google-icon>
              </div>
              Login with Google
            </button>
          </div>
        </div>
      } @else if (pageState === '2fa-login') {
        <app-two-factor-login
          [mfaResolver]="mfaResolver"
          (success)="mfaSuccess()"
          (back)="pageState = 'login'"
        ></app-two-factor-login>
      } @else if (pageState === '2fa-enroll') {
        <app-two-factor-enrollment
          (signOut)="signOut()"
          (success)="mfaSuccess()"
        ></app-two-factor-enrollment>
      } @else if (pageState === 'verify-email') {
        <div>
          <h1
            class="z-10 m-auto max-w-lg text-3xl font-semibold leading-normal md:block md:text-5xl"
          >
            Email Verification
          </h1>
          <div class="md:mt-4">
            <p class="mb-4">
              A verifcation email has been sent to
              {{ currentUser?.email }}. If you did not receive the email or it
              expired, please click the 'Resend Verification Email' button to
              receive a verification email.
            </p>
            <button
              class="btn btn-primary w-full"
              (click)="sendEmailVerification()"
            >
              Resend Verification Email
            </button>
          </div>
        </div>
      } @else if (pageState === 'forgot-password') {
        <div>
          <h1
            class="z-10 m-auto max-w-lg text-3xl font-semibold leading-normal md:block md:text-5xl"
          >
            Forgot Password
          </h1>
          @if (!passwordResetEmailSent) {
            <div class="mt-4">
              <p class="mb-4">
                Enter your email address below and we will send you a link to
                reset your password.
              </p>
              <div class="flex flex-col">
                <input
                  class="input input-bordered mb-4 w-full"
                  type="text"
                  [(ngModel)]="passwordResetEmail"
                  placeholder="Email"
                />
                <button
                  class="btn btn-primary mb-4 w-full"
                  (click)="sendPasswordResetEmail()"
                  [disabled]="sendingPasswordResetEmail"
                >
                  Send Password Reset Email
                </button>
                <button
                  class="btn btn-secondary w-full"
                  (click)="pageState = 'login'"
                >
                  Cancel
                </button>
              </div>
            </div>
          } @else {
            <div class="mt-4">
              <p class="mb-4">
                A password reset email was sent to {{ passwordResetEmail }}.
                Please check your inbox and follow the instructions to reset
                your password.
              </p>
            </div>
            <button
              class="btn btn-secondary w-full"
              (click)="pageState = 'login'"
            >
              Back
            </button>
          }
        </div>
      }

      @if (showLogout) {
        <div class="my-6">
          <button class="btn btn-neutral w-full" (click)="signOut()">
            Logout
          </button>
        </div>
      }

      @if (showEmailVerificationError) {
        <p class="text-error">
          Your email is not verified, please resend the verification email and
          contact support&#64;accuzip.com if you need further assistance.
        </p>
      }

      @if (successMessage) {
        <div class="mt-4 text-center text-success">
          <p>{{ successMessage }}</p>
        </div>
      }

      @if (error) {
        <div class="mt-4 text-center text-error">
          <p>{{ error }}</p>
        </div>
      }
    </div>
  </div>
</div>
