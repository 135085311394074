import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DesktopInvoice } from 'src/app/services/invoices.service';
import { DesktopLicenseDetails } from 'src/app/services/licenses.service';

type Sort = {
  field: string;
  direction: 'ASC' | 'DESC';
};

@Component({
  selector: 'app-licenses-table',
  templateUrl: './licenses-table.component.html',
  styleUrl: './licenses-table.component.scss',
})
export class LicensesTableComponent {
  @Input('licenses') licenses?: DesktopLicenseDetails[];
  @Input('current-sort') currentSort?: Sort;
  @Output('sort-change') sortChange = new EventEmitter<string>();

  constructor(private router: Router) {}

  latestInvoice(license: DesktopLicenseDetails): DesktopInvoice | null {
    if (!license.invoices || license.invoices.length === 0) {
      return null;
    }

    return license.invoices.reduce((prev, curr) => {
      const prevCreated = new Date(prev.created_at);
      const currCreated = new Date(curr.created_at);
      return prevCreated > currCreated ? prev : curr;
    }, license.invoices[0]);
  }

  browseToInvoice(invoice: DesktopInvoice) {
    this.router.navigate([
      'admin',
      'tenant',
      invoice.tenant_id,
      'invoice',
      invoice.id,
    ]);
  }
}
