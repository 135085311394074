<div class="mx-auto flex max-w-lg flex-col">
  <div>
    <h1 class="mb-4 text-2xl font-bold">Create New Product</h1>
  </div>

  <div class="form-control mb-4">
    <label class="label">
      <span class="label-text">Description</span>
    </label>
    <textarea
      class="textarea textarea-bordered"
      [(ngModel)]="description"
    ></textarea>
  </div>

  <div class="form-control mb-4 w-fit flex-row">
    <label class="label mr-4"><span class="label-text">Type</span></label>
    <select class="select select-bordered" [(ngModel)]="type">
      <option value="" disabled selected>Select a type</option>
      @for (t of productTypes; track t) {
        <option [value]="t">{{ t }}</option>
      }
    </select>
  </div>

  <div class="form-control mb-4">
    <label class="label">
      <span class="label-text">Code</span>
    </label>
    <input class="input input-bordered" [(ngModel)]="code" />
  </div>

  <div class="form-control mb-4">
    <label class="label">
      <span class="label-text">Variance Code</span>
    </label>
    <input class="input input-bordered" [(ngModel)]="varianceCode" />
  </div>

  <div class="form-control mb-2 w-fit">
    <label class="label cursor-pointer">
      <input type="checkbox" class="checkbox" [(ngModel)]="active" />
      <span class="label-text ml-4">Active</span>
    </label>
  </div>

  <div class="form-control mb-2 w-fit">
    <label class="label cursor-pointer">
      <input type="checkbox" class="checkbox" [(ngModel)]="isService" />
      <span class="label-text ml-4">Is Service</span>
    </label>
  </div>

  <div class="form-control w-fit">
    <label class="label cursor-pointer">
      <input type="checkbox" class="checkbox" [(ngModel)]="standalone" />
      <span class="label-text ml-4">Standalone</span>
    </label>
  </div>

  <div class="mt-8 flex justify-center space-x-4">
    <button
      class="btn btn-primary"
      [disabled]="creatingProduct"
      (click)="openConfirmDialog()"
    >
      Create
    </button>
    <button
      class="btn btn-error"
      [disabled]="creatingProduct"
      [routerLink]="['/admin']"
      [queryParams]="{ tab: 'product-list' }"
    >
      Cancel
    </button>
  </div>

  @if (creatingProduct) {
    <div class="mt-4 flex justify-center">
      <span class="loading loading-spinner loading-md"></span>
    </div>
  }

  @if (productCreated) {
    <div class="mt-4 flex justify-center">
      <p class="text-success">Product created successfully</p>
    </div>
  }

  @if (errorCreatingProduct) {
    <div class="mt-4 flex justify-center">
      <p class="text-error">{{ errorCreatingProduct }}</p>
    </div>
  }
</div>

<dialog #confirmDialog class="modal">
  <div class="modal-box max-w-3xl p-8">
    <h2 class="text-2xl font-bold">Confirm Settings</h2>
    <h4 class="text-lg">
      Please confirm the settings below are correct for the new product you are
      creating.
    </h4>
    <table class="table my-4">
      <thead class="text-base">
        <tr>
          <th class="font-medium">Field</th>
          <th class="font-medium">Value</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Description</td>
          <td>{{ description }}</td>
        </tr>
        <tr>
          <td>Type</td>
          <td>{{ type }}</td>
        </tr>
        <tr>
          <td>Code</td>
          <td>{{ code }}</td>
        </tr>
        <tr>
          <td>Active</td>
          <td>{{ active }}</td>
        </tr>
        <tr>
          <td>Is Service</td>
          <td>{{ isService }}</td>
        </tr>
        <tr>
          <td>Standalone</td>
          <td>{{ standalone }}</td>
        </tr>
      </tbody>
    </table>
    <h4 class="text-lg text-warning">
      Are you sure you want to create this product?
    </h4>
    <div class="mt-4 flex justify-center space-x-4">
      <button class="btn btn-primary" (click)="createProduct()">Yes</button>
      <button class="btn btn-error" (click)="closeConfirmDialog()">No</button>
    </div>
  </div>
</dialog>
