<div class="flex flex-col p-5 pt-12">
  @if (tenantFetchError) {
    <div class="mt-4 text-center text-error">
      <p>{{ tenantFetchError }}</p>
    </div>
  } @else {
    <div>
      <ul class="steps mb-8 w-full">
        <li class="step step-primary">Name & Title</li>
        <li class="step" [ngClass]="{ 'step-primary': currentStep >= 2 }">
          Address
        </li>
        <li class="step" [ngClass]="{ 'step-primary': currentStep >= 3 }">
          Contact Information
        </li>
      </ul>
      <div class="m-auto md:w-96">
        @if (currentStep === 1) {
          <div class="w-full rounded-lg border-2 border-white px-6 pb-8 pt-6">
            <label class="form-control m-auto w-full max-w-xs">
              <div class="label">
                <span class="label-text">First Name</span>
              </div>
              <input
                type="text"
                placeholder="First Name"
                [(ngModel)]="first"
                class="input input-bordered w-full max-w-xs"
              />
            </label>
            <label class="form-control m-auto mt-2 w-full max-w-xs">
              <div class="label">
                <span class="label-text">Last Name</span>
              </div>
              <input
                type="text"
                placeholder="Last Name"
                [(ngModel)]="last"
                class="input input-bordered w-full max-w-xs"
              />
            </label>
            <label class="form-control m-auto mt-2 w-full max-w-xs">
              <div class="label">
                <span class="label-text">Title</span>
              </div>
              <input
                type="text"
                placeholder="Title"
                [(ngModel)]="title"
                class="input input-bordered w-full max-w-xs"
              />
            </label>
            <label class="form-control m-auto mt-2 w-full max-w-xs">
              <div class="label">
                <span class="label-text">Company</span>
              </div>
              <input
                type="text"
                placeholder="Company"
                [(ngModel)]="company"
                class="input input-bordered w-full max-w-xs"
              />
            </label>
          </div>
        }
        @if (currentStep === 2) {
          <div class="w-full rounded-lg border-2 border-white px-6 pb-8 pt-6">
            <label class="form-control m-auto w-full max-w-xs">
              <div class="label">
                <span class="label-text">Address</span>
              </div>
              <input
                type="text"
                placeholder="Address"
                [(ngModel)]="address"
                class="input input-bordered w-full max-w-xs"
              />
            </label>
            <label class="form-control m-auto mt-2 w-full max-w-xs">
              <div class="label">
                <span class="label-text">Address 2</span>
              </div>
              <input
                type="text"
                placeholder="Address 2"
                [(ngModel)]="address2"
                class="input input-bordered w-full max-w-xs"
              />
            </label>
            <label class="form-control m-auto mt-2 w-full max-w-xs">
              <div class="label">
                <span class="label-text">City</span>
              </div>
              <input
                type="text"
                placeholder="City"
                [(ngModel)]="city"
                class="input input-bordered w-full max-w-xs"
              />
            </label>
            <label class="form-control m-auto mt-2 w-full max-w-xs">
              <div class="label">
                <span class="label-text">State (Abbreviation)</span>
              </div>
              <select class="select select-bordered" [(ngModel)]="st">
                <option value="" disabled selected>Select State</option>
                @for (state of states; track state) {
                  <option [value]="state">
                    {{ state }}
                  </option>
                }
              </select>
            </label>
            <label class="form-control m-auto mt-2 w-full max-w-xs">
              <div class="label">
                <span class="label-text">Zip</span>
              </div>
              <input
                type="text"
                placeholder="Zip"
                [(ngModel)]="zip"
                class="input input-bordered w-full max-w-xs"
              />
            </label>
          </div>
        }
        @if (currentStep === 3) {
          <div class="w-full rounded-lg border-2 border-white px-6 pb-8 pt-6">
            <label class="form-control m-auto w-full max-w-xs">
              <div class="label">
                <span class="label-text">Phone 1</span>
              </div>
              <input
                type="text"
                placeholder="Phone 1"
                [(ngModel)]="phone1"
                (change)="handlePhoneChange($event, 1)"
                [ngClass]="{ 'border-red-500': phone1Error }"
                class="input input-bordered w-full max-w-xs"
              />
              @if (phone1Error) {
                <div class="label">
                  <span class="label-text-alt text-error">{{
                    phone1Error
                  }}</span>
                </div>
              }
            </label>
            <label class="form-control m-auto mt-2 w-full max-w-xs">
              <div class="label">
                <span class="label-text">Phone 2</span>
              </div>
              <input
                type="text"
                placeholder="Phone 2"
                [(ngModel)]="phone2"
                (change)="handlePhoneChange($event, 2)"
                [ngClass]="{ 'border-red-500': phone2Error }"
                class="input input-bordered w-full max-w-xs"
              />
              @if (phone2Error) {
                <div class="label">
                  <span class="label-text-alt text-error">{{
                    phone2Error
                  }}</span>
                </div>
              }
            </label>
            <label class="label-text m-auto mb-2 mt-4 block max-w-xs"
              >Emails</label
            >
            <div class="form-control m-auto w-full max-w-xs">
              <div class="join w-full max-w-xs">
                <input
                  type="text"
                  placeholder="New Email"
                  [(ngModel)]="newEmail"
                  class="input join-item input-bordered w-full max-w-xs"
                />
                <button
                  (click)="addEmail()"
                  class="btn join-item rounded-l-none"
                >
                  Add
                </button>
              </div>
              <div class="label">
                <div class="label-text-alt text-error">{{ emailError }}</div>
              </div>
            </div>
            <div class="m-auto mt-2 w-full max-w-xs">
              @if (emails.length > 0) {
                <ul>
                  @for (email of emails; track email) {
                    <li class="flex w-full">
                      <span>{{ email }}</span>
                      <span
                        (click)="removeEmail(email)"
                        class="ml-auto cursor-pointer"
                      >
                        <svg
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            <path
                              d="M6.99486 7.00636C6.60433 7.39689 6.60433 8.03005 6.99486 8.42058L10.58 12.0057L6.99486 15.5909C6.60433 15.9814 6.60433 16.6146 6.99486 17.0051C7.38538 17.3956 8.01855 17.3956 8.40907 17.0051L11.9942 13.4199L15.5794 17.0051C15.9699 17.3956 16.6031 17.3956 16.9936 17.0051C17.3841 16.6146 17.3841 15.9814 16.9936 15.5909L13.4084 12.0057L16.9936 8.42059C17.3841 8.03007 17.3841 7.3969 16.9936 7.00638C16.603 6.61585 15.9699 6.61585 15.5794 7.00638L11.9942 10.5915L8.40907 7.00636C8.01855 6.61584 7.38538 6.61584 6.99486 7.00636Z"
                              fill="#dc2626"
                            ></path>
                          </g>
                        </svg>
                      </span>
                    </li>
                  }
                </ul>
              }
              @if (emails.length === 0) {
                <p>No emails added</p>
              }
            </div>
          </div>
        }
        <div class="mt-4 flex">
          <div class="mr-auto">
            @if (currentStep !== 1) {
              <button class="btn btn-secondary" (click)="previousStep()">
                Back
              </button>
            }
          </div>
          <div>
            @if (currentStep !== 3) {
              <button class="btn btn-primary" (click)="nextStep()">Next</button>
            }
            @if (currentStep === 3) {
              <button class="btn btn-primary" (click)="submit()">Submit</button>
            }
          </div>
        </div>
      </div>
    </div>
  }
  @if (error) {
    <div class="mt-4 text-center text-error">
      <p>{{ error }}</p>
    </div>
  }
</div>

