import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sun-icon',
  templateUrl: './sun-icon.component.html',
  styleUrl: './sun-icon.component.scss',
})
export class SunIconComponent {
  @Input() svgClass: string = '';
}
