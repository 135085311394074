import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DesktopLicenseInvoiceDetails,
  InvoiceDetails,
  InvoicesService,
} from 'src/app/services/invoices.service';
import { LicensesService } from 'src/app/services/licenses.service';
import {
  ProductVariant,
  ProductsService,
} from 'src/app/services/products.service';

type LicenseOption = {
  details: DesktopLicenseInvoiceDetails;
  remove?: boolean;
};

@Component({
  selector: 'app-edit-invoice-page',
  templateUrl: './edit-invoice-page.component.html',
  styleUrls: ['./edit-invoice-page.component.scss'],
})
export class EditInvoicePageComponent {
  @ViewChild('editLicenseModal')
  editLicenseModal!: ElementRef<HTMLDialogElement>;
  @ViewChild('removeChildrenModal')
  removeChildrenModal!: ElementRef<HTMLDialogElement>;
  @ViewChild('addParentsModal')
  addParentsModal!: ElementRef<HTMLDialogElement>;
  @ViewChild('confirmRemoveModal')
  confirmRemoveModal!: ElementRef<HTMLDialogElement>;
  @ViewChild('cannotRemoveAllModal')
  cannotRemoveAllModal!: ElementRef<HTMLDialogElement>;

  loadingInvoice = false;
  invoice?: InvoiceDetails;
  errorFetchingInvoice = '';
  licenseList: LicenseOption[] = [];

  editingLicense: LicenseOption | null = null;
  associatedLicenses: LicenseOption[] = [];
  loadingDurationOptions = false;
  errorFetchingDurationOptions = '';
  durationOptions: ProductVariant[] = [];
  selectedDurationOption: ProductVariant | null = null;
  updatingLicenseDuration = false;
  errorUpdatingLicenseDuration = '';

  currentLicenseToRemove?: LicenseOption;
  currentChildLicensesToRemove?: LicenseOption[];

  currentLicenseToAdd?: LicenseOption;
  currentParentLicensesToAdd?: LicenseOption[];

  savingChanges = false;
  errorSavingChanges = '';
  changesSaved = false;

  constructor(
    private invoicesService: InvoicesService,
    private licensesService: LicensesService,
    private productsService: ProductsService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    if (!id) {
      console.error(new Error('No invoice id'));
      this.errorFetchingInvoice =
        'Error fetching invoice. No invoice id parameter provided.';
      return;
    }

    this.getInvoiceDetails(id);
  }

  editLicense(license: LicenseOption) {
    this.editingLicense = license;
    this.associatedLicenses = [];
    const children = this.getChildLicenses(this.licenseList, license);
    const parentLicenses = this.getParentLicenses(this.licenseList, license);
    this.associatedLicenses.push(...children);
    this.associatedLicenses.push(...parentLicenses);

    this.getDurationOptions(
      license.details.license_with_product.product_variant
    );

    this.editLicenseModal.nativeElement.showModal();
  }

  getDurationOptions(productVariant: ProductVariant) {
    return this.productsService.getDurationOptions(productVariant).subscribe({
      next: (data) => {
        console.log('duration options: ', data);
        this.loadingDurationOptions = false;
        // do not include durations that are not exact year multiples, or are
        // the same as the current license's duration
        this.durationOptions = data.product_variants.filter(
          (p) =>
            p.duration_months % 12 === 0 &&
            p.duration_months !==
              this.editingLicense?.details.license_with_product.product_variant
                .duration_months
        );
      },
      error: (err) => {
        this.loadingDurationOptions = false;
        console.error(err);
        this.errorFetchingDurationOptions =
          'Error fetching duration options. Please try again.';
      },
    });
  }

  closeEditDialog() {
    this.editLicenseModal.nativeElement.close();
    this.editingLicense = null;
    this.durationOptions = [];
    this.errorFetchingDurationOptions = '';
    this.selectedDurationOption = null;
  }

  updateLicenseDuration() {
    console.log(
      'updating license duration. license: ',
      this.editingLicense,
      'new duration variant: ',
      this.selectedDurationOption
    );

    if (!this.invoice || !this.editingLicense || !this.selectedDurationOption) {
      const info = {
        invoice: this.invoice,
        editLicense: this.editingLicense,
        selectedDurationOption: this.selectedDurationOption,
      };
      console.error(
        new Error(
          `missing required data in update license duration request ${info}`
        )
      );
      return;
    }

    this.updatingLicenseDuration = true;
    this.invoicesService
      .updateLicenseDuration({
        invoice_id: this.invoice!.desktop_invoice.id,
        desktop_license_id:
          this.editingLicense!.details.license_with_product.id,
        duration_months: this.selectedDurationOption!.duration_months,
      })
      .subscribe({
        next: (data) => {
          console.log('update license duration response: ', data);
          this.updatingLicenseDuration = false;
          this.invoice = data.invoice_details;
          this.initializeLicenseLists(this.invoice);
          this.closeEditDialog();
        },
        error: (err) => {
          this.updatingLicenseDuration = false;
          console.error(err);
          this.errorUpdatingLicenseDuration =
            'Unexpected error occurred. Please try again.';
        },
      });
  }

  getInvoiceDetails(id: string) {
    this.loadingInvoice = true;
    this.invoicesService.getInvoiceDetails(id).subscribe({
      next: (data) => {
        console.log('invoice details: ', data);
        this.loadingInvoice = false;
        this.invoice = data.invoice_details;
        this.initializeLicenseLists(this.invoice);
      },
      error: (err) => {
        this.loadingInvoice = false;
        console.log('error fetching invoice details: ', err);
        this.errorFetchingInvoice = err.message;
      },
    });
  }

  initializeLicenseLists(invoice: InvoiceDetails) {
    this.licenseList = [];

    console.log('invoice license details');
    for (const license of invoice.license_details) {
      const l = license.license_with_product;
      console.log(
        'license: ',
        l.id,
        l.product.title,
        l.parent_desktop_license_id
      );
    }

    const sortedLicenses = this.licensesService.sortLicensesByRelationship(
      invoice.license_details.map((l) => l.license_with_product)
    );

    for (const license of sortedLicenses) {
      console.log('sorted license: ', license.id, license.product.title);
      const details = invoice.license_details.find(
        (l) => l.license_with_product.id === license.id
      );
      if (details) {
        this.licenseList.push({ details: details });
      }
    }
  }

  get hasChanges() {
    return this.licenseList.some((license) => {
      return license.remove;
    });
  }

  getDurationString(months: number) {
    if (months % 12 === 0) {
      return months === 12 ? '1 year' : `${months / 12} years`;
    } else {
      return months === 1 ? '1 month' : `${months} months`;
    }
  }

  removeLicense(license: LicenseOption) {
    // check if there are child licenses
    // only get child licenses that are not being removed
    const children = this.getChildLicenses(this.licenseList, license).filter(
      (l) => !l.remove
    );

    if (children.length > 0) {
      // set both holding variables to be used if the user confirms removal
      this.currentLicenseToRemove = license;
      this.currentChildLicensesToRemove = children;
      this.removeChildrenModal.nativeElement.showModal();
      return;
    }

    this.changesSaved = false;
    license.remove = true;
  }

  removeCurrentChildLicenses() {
    // update the currently set parent/children licenses to be removed
    if (!this.currentLicenseToRemove) {
      console.error(new Error('No parent license to remove'));
      this.removeChildrenModal.nativeElement.close();
      return;
    }

    this.currentLicenseToRemove.remove = true;
    if (this.currentChildLicensesToRemove) {
      for (const license of this.currentChildLicensesToRemove) {
        license.remove = true;
      }
    }

    this.removeChildrenModal.nativeElement.close();
  }

  cancelRemoveChildLicenses() {
    // close modal and reset holding variables
    this.removeChildrenModal.nativeElement.close();
    this.currentLicenseToRemove = undefined;
    this.currentChildLicensesToRemove = undefined;
  }

  addLicense(license: LicenseOption) {
    // check if license has a parent that is still removed
    if (license.details.license_with_product.parent_desktop_license_id) {
      const parents = this.getParentLicenses(this.licenseList, license).filter(
        (p) => p.remove
      );

      if (parents.length > 0) {
        this.currentLicenseToAdd = license;
        this.currentParentLicensesToAdd = parents;
        this.addParentsModal.nativeElement.showModal();
        return;
      }
    }

    this.changesSaved = false;
    license.remove = false;
  }

  addCurrentParentLicenses() {
    // update the currently set parent/children licenses to undo removal
    if (!this.currentLicenseToAdd) {
      console.error(new Error('No parent license to remove'));
      this.addParentsModal.nativeElement.close();
      return;
    }

    this.currentLicenseToAdd.remove = false;
    if (this.currentParentLicensesToAdd) {
      for (const license of this.currentParentLicensesToAdd) {
        license.remove = false;
      }
    }

    this.addParentsModal.nativeElement.close();
  }

  cancelAddParentLicenses() {
    this.currentLicenseToAdd = undefined;
    this.currentParentLicensesToAdd = undefined;
    this.addParentsModal.nativeElement.close();
  }

  returnToInvoice(invoice: InvoiceDetails) {
    // check if there are changes
    if (this.hasChanges) {
      if (!confirm('Are you sure you want to leave without saving changes?')) {
        // do nothing
        return;
      }
    }

    // navigate back to invoice page
    this.router.navigate(['/invoice', invoice.desktop_invoice.id]);
  }

  getChildLicenses(
    licenses: LicenseOption[],
    currentLicense: LicenseOption
  ): LicenseOption[] {
    const newList: LicenseOption[] = [];

    const children = licenses.filter(
      (l) =>
        l.details.license_with_product.parent_desktop_license_id ===
        currentLicense.details.license_with_product.id
    );
    for (const child of children) {
      newList.push(child);
      const grandChildren = this.getChildLicenses(licenses, child);
      newList.push(...grandChildren);
    }

    return newList;
  }

  getParentLicenses(
    licenses: LicenseOption[],
    currentLicense: LicenseOption
  ): LicenseOption[] {
    const newList: LicenseOption[] = [];

    const parent = licenses.find(
      (l) =>
        l.details.license_with_product.id ===
        currentLicense.details.license_with_product.parent_desktop_license_id
    );
    if (parent) {
      newList.push(parent);
      const grandParents = this.getParentLicenses(licenses, parent);
      newList.push(...grandParents);
    }

    return newList;
  }

  get licensesToRemove() {
    return this.licenseList.filter((l) => l.remove);
  }

  promptConfirmChanges() {
    this.confirmRemoveModal.nativeElement.showModal();
  }

  closeConfirmRemoveModal() {
    this.confirmRemoveModal.nativeElement.close();
  }

  closeCannotRemoveAllModal() {
    this.cannotRemoveAllModal.nativeElement.close();
  }

  saveChanges() {
    this.closeConfirmRemoveModal();

    if (!this.invoice) {
      console.error(new Error('No invoice to save changes to'));
      return;
    }

    // check that some licenses will remain
    if (this.licenseList.filter((l) => !l.remove).length === 0) {
      console.error(
        'Cannot save changes. Would remove all licenses'
      );
      this.cannotRemoveAllModal.nativeElement.showModal();
      return;
    }

    const invoice = this.invoice;

    this.changesSaved = false;
    this.savingChanges = true;
    this.errorSavingChanges = '';

    const req = this.licenseList
      .filter((l) => l.remove)
      .map((l) => ({
        desktop_license_id: l.details.license_with_product.id,
        will_renew: false,
      }));

    this.licensesService.updateLicenseWillRenew(req).subscribe({
      next: (data) => {
        console.log('data: ', data);
        this.savingChanges = false;
        this.changesSaved = true;

        // get the updated invoice details in the response
        const invoiceDetails = data.desktop_invoices?.find(
          (i) => i.desktop_invoice.id === invoice.desktop_invoice.id
        );

        if (invoiceDetails) {
          console.log('invoice details: ', invoiceDetails);
          this.invoice = invoiceDetails;
          this.initializeLicenseLists(this.invoice);
        }
      },
      error: (err) => {
        console.error(err);
        this.errorSavingChanges =
          'Unexpected error occurred. Please try again.';
      },
    });
  }
}
