import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// import { ProductDetailsResolverResponse } from 'src/app/router/resolvers/product-details';
import { LoggerService } from 'src/app/services/logger.service';
import {
  ProductVariant,
  ProductWithVariants,
  ProductsService,
} from 'src/app/services/products.service';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent {
  loadingProduct = false;
  product: ProductWithVariants | undefined;
  error: string = '';
  currentVariantIndex = -1;

  constructor(
    private loggerService: LoggerService,
    private productsService: ProductsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.loggerService.log('ProductDetailsComponent ngOnInit');

    const id = this.route.snapshot.paramMap.get('id');
    if (!id) {
      this.loggerService.error(new Error('No product ID found in route'));
      this.error = 'No product ID found';
      return;
    }
    this.getProductDetails(id);
  }

  getProductDetails(id: string) {
    this.loadingProduct = true;
    this.productsService.getProductDetails(id).subscribe({
      next: (res) => {
        this.loadingProduct = false;
        if (res.product_with_variants) {
          this.product = res.product_with_variants;
        } else {
          this.loggerService.error(
            new Error('No product details returned from server')
          );
          this.error = 'No product details returned from server';
        }
      },
      error: (err) => {
        this.loadingProduct = false;
        this.loggerService.error(err);
        this.error = err;
      },
    });
  }

  get displayVariants(): ProductVariant[] {
    if (!this.product) return [];

    return this.product.variants.sort((a, b) => {
      if (a.is_default && !b.is_default) return -1;
      if (!a.is_default && b.is_default) return 1;

      if (a.price_lock_level != b.price_lock_level) {
        return a.price_lock_level - b.price_lock_level;
      }

      if (a.duration_months != b.duration_months) {
        return a.duration_months - b.duration_months;
      }

      return 0;
    });
  }

  setActiveVariant(index: number) {
    if (this.currentVariantIndex == index) {
      this.currentVariantIndex = -1;
      return;
    }
    this.currentVariantIndex = index;
  }
}
