<div>
  <div class="prose">
    <h1>Users</h1>
  </div>

  <div class="pt-8">
    <div class="mb-4 flex justify-between">
      <div class="prose">
        <h2 class="ml-6">Active Users</h2>
      </div>
      @if (!errorFetchingUsers && users) {
        <button
          class="btn btn-primary btn-sm"
          onclick="inviteUserModal.showModal()"
        >
          Invite a User
        </button>
      }
    </div>

    @if (errorFetchingUsers) {
      <div class="text-center">
        <p class="text-error">Error fetching users</p>
      </div>
    } @else if (loadingUsers) {
      <div class="flex justify-center">
        <div class="loading loading-spinner loading-lg"></div>
      </div>
    } @else if (users) {
      <dialog
        #inviteUserModal
        id="inviteUserModal"
        class="modal modal-bottom sm:modal-middle"
      >
        <div class="modal-box overflow-visible">
          <h3 class="text-lg font-bold">Invite a User</h3>
          @if (errorFetchingRoles) {
            <div>
              <p class="py-4 text-error">Error fetching roles</p>
              <div class="modal-action">
                <form method="dialog">
                  <button class="btn btn-neutral">Cancel</button>
                </form>
              </div>
            </div>
          } @else if (loadingRoles) {
            <div class="flex justify-center py-4">
              <div class="loading loading-spinner loading-lg"></div>
            </div>
          } @else if (roles) {
            <div>
              <div class="py-4">
                @if (!sending && !sendSuccess) {
                  <div class="columns-2">
                    <label class="form-control w-full max-w-xs">
                      <div class="label">
                        <span class="label-text">Email</span>
                      </div>
                      <input
                        type="text"
                        class="input input-bordered w-full max-w-xs"
                        [(ngModel)]="inviteEmail"
                        placeholder="Email"
                      />
                    </label>
                    <div>
                      <p class="px-1 py-2 text-sm">Role</p>
                      <div class="dropdown w-full">
                        <div
                          tabindex="0"
                          role="button"
                          class="btn w-full justify-between border-base-content/20 bg-base-100"
                        >
                          <span>{{ selectedRoleDisplayName }}</span>
                          <app-chevron-down-icon
                            class="h-5 w-5"
                          ></app-chevron-down-icon>
                        </div>
                        <ul
                          tabindex="0"
                          class="menu dropdown-content z-[1] w-80 rounded-box bg-base-100 p-2 shadow"
                        >
                          @for (role of roles; track role; let i = $index) {
                            <li
                              [ngClass]="{
                                'border-t border-base-content/20': i > 0,
                              }"
                              (click)="handleRoleChange(role)"
                            >
                              <div class="grid-flow-row gap-1">
                                <p class="font-semibold">
                                  {{ role.display_name }}
                                </p>
                                <p class="text-xs">{{ role.description }}</p>
                              </div>
                            </li>
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                } @else if (sending) {
                  <div class="mt-4 flex flex-col items-center text-center">
                    <span
                      class="loading loading-spinner loading-lg text-primary"
                    ></span>
                  </div>
                } @else if (sendSuccess) {
                  <div class="mt-4 flex flex-col items-center text-center">
                    <svg
                      class="h-12 w-12 text-success"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                    <p class="mt-4">Invitation Sent Successfully</p>
                  </div>
                }
              </div>
              @if (invalidInvite) {
                <div class="mt-4 text-center text-error">
                  <p>{{ invalidInvite }}</p>
                </div>
              }
              <div class="modal-action">
                <button
                  class="btn btn-neutral"
                  (click)="closeInviteUserModal()"
                >
                  Cancel
                </button>
                <button
                  class="btn btn-primary ml-4"
                  (click)="sendInvitation()"
                  [disabled]="sending"
                >
                  Send Invitation
                </button>
              </div>
            </div>
          }
        </div>
      </dialog>
    }

    @if (!errorFetchingUsers && users) {
      <div class="overflow-x-auto rounded-lg border border-solid border-black">
        <table
          class="table table-zebra table-pin-rows table-pin-cols table-lg static"
        >
          <thead class="text-base font-medium">
            <tr>
              <td>Email</td>
              <td>Username</td>
              <td>Roles</td>
              <td>Active</td>
              <td>Created</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            @for (user of users; track user) {
              <tr>
                <td>{{ user.email }}</td>
                <td>{{ user.username }}</td>
                <td>{{ roleList(user) }}</td>
                <td>{{ user.active }}</td>
                <td>{{ user.created_at | date: 'short' }}</td>
                <td>
                  @if (canEditUser(user)) {
                    <button
                      class="btn btn-ghost btn-sm"
                      (click)="browseToEditUser(user)"
                    >
                      <app-edit-icon></app-edit-icon>
                    </button>
                  }
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    }
  </div>

  <div class="pt-8">
    <div class="prose mb-4">
      <h2 class="ml-6">Pending Invitations</h2>
    </div>

    @if (errorFetchingInvitations) {
      <div class="text-center">
        <p class="text-error">Error fetching invitations</p>
      </div>
    } @else if (loadingInvitations) {
      <div class="flex justify-center">
        <div class="loading loading-spinner loading-lg"></div>
      </div>
    } @else if (pendingInvitations) {
      @if (pendingInvitations.length === 0) {
        <div class="text-center">
          <p>No pending invitations</p>
        </div>
      }
      @if (pendingInvitations.length > 0) {
        <div
          class="overflow-x-auto rounded-lg border border-solid border-black"
        >
          <table
            class="table table-zebra table-pin-rows table-pin-cols table-lg static"
          >
            <thead class="text-base font-medium">
              <tr>
                <td>Email</td>
                <td>Used</td>
                <td>Expiration</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              @for (
                pendingInvitation of pendingInvitations;
                track pendingInvitation;
                let i = $index
              ) {
                <tr
                  [ngClass]="{
                    'bg-base-200': i % 2 === 0,
                    'bg-base-100': i % 2 !== 0,
                  }"
                >
                  <td>{{ pendingInvitation.email }}</td>
                  <td>{{ pendingInvitation.used }}</td>
                  <td>{{ pendingInvitation.expiration | date: 'short' }}</td>
                  <td
                    class="link"
                    (click)="revokeInvitation(pendingInvitation)"
                  >
                    Revoke
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      }
    }
  </div>
</div>

@if (sendSuccess) {
  <app-toast-timer
    status="success"
    message="Invite sent successfully"
    (clear)="sendSuccess = false"
  ></app-toast-timer>
}
@if (sendError) {
  <app-toast-timer
    status="error"
    [message]="sendError"
    (clear)="sendError = ''"
  ></app-toast-timer>
}

@if (revokeSuccess) {
  <app-toast-timer
    status="success"
    message="Invitation revoked successfully"
    (clear)="revokeSuccess = false"
  ></app-toast-timer>
}
@if (revokeError) {
  <app-toast-timer
    status="error"
    [message]="revokeError"
    (clear)="revokeError = ''"
  ></app-toast-timer>
}
