<div>
  <div class="prose">
    <h1>Active Licenses</h1>
  </div>

  @if (errorFetchingLicenses) {
    <div class="mt-4">
      <p class="text-error">{{ errorFetchingLicenses }}</p>
    </div>
  } @else if (licenses) {
    <div class="pt-8">
      <div class="overflow-x-auto rounded-lg border border-solid border-black">
        <table
          class="table table-zebra table-pin-rows table-pin-cols table-lg static"
        >
          <thead class="text-base font-medium">
            <tr>
              <th>Product/Service</th>
              <th>Active</th>
              <th>Type</th>
              <th>Expiration Date</th>
              <th>Auto Renew/Pay</th>
              <th>Renewal Price</th>
            </tr>
          </thead>
          <tbody>
            @for (
              license of licenses.active;
              track license.desktop_license.id;
              let i = $index
            ) {
              <tr
                (click)="expandRow(i)"
                [ngClass]="{
                  'bg-base-300': i === expandedRowId,
                }"
                class="cursor-pointer"
              >
                <td>{{ license.product.title }}</td>
                <td>{{ license.desktop_license.active ? 'Yes' : 'No' }}</td>
                <td>
                  {{
                    license.desktop_license.site_license ? 'Site' : 'Primary'
                  }}
                </td>
                <td>
                  {{ license.desktop_license.expiration | date: 'MM/dd/YYYY' }}
                </td>
                <td>
                  {{ license.desktop_license.will_renew ? 'Yes' : 'No' }}
                </td>
                <td>
                  {{
                    (license.desktop_license.site_license
                      ? license.product_variant.site_renewal_price
                      : license.product_variant.renewal_price
                    ) | currency
                  }}
                </td>
              </tr>
              @if (i === expandedRowId) {
                <tr class="h-10 w-full bg-base-300">
                  <td colspan="6">
                    <div class="flex">
                      <div class="stats w-full border-2 bg-base-300">
                        <div class="stat">
                          <div
                            class="stat-figure"
                            [ngClass]="{
                              'text-success':
                                license.desktop_license.will_renew &&
                                !showRenewWarning,
                              'text-warning': showRenewWarning,
                              'text-error': !license.desktop_license.will_renew,
                            }"
                          >
                            <input
                              type="checkbox"
                              class="toggle toggle-lg"
                              [ngClass]="{
                                'toggle-success':
                                  license.desktop_license.will_renew,
                                'toggle-warning': showRenewWarning,
                              }"
                              (click)="toggleRenew(license, $event)"
                              [checked]="license.desktop_license.will_renew"
                            />
                          </div>
                          <div class="stat-title">Auto Renew License?</div>
                          <div
                            class="stat-value"
                            [ngClass]="{
                              'text-success':
                                license.desktop_license.will_renew &&
                                !showRenewWarning,
                              'text-warning': showRenewWarning,
                              'text-error': !license.desktop_license.will_renew,
                            }"
                          >
                            {{
                              license.desktop_license.will_renew ? 'Yes' : 'No'
                            }}
                          </div>
                          <div class="stat-desc">
                            Use toggle to enable/disable
                          </div>
                        </div>
                        <div class="stat">
                          <div class="stat-figure text-primary">
                            <svg
                              class="h-[48px] w-[48px]"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                                d="m15.141 6 5.518 4.95a1.05 1.05 0 0 1 0 1.549l-5.612 5.088m-6.154-3.214v1.615a.95.95 0 0 0 1.525.845l5.108-4.251a1.1 1.1 0 0 0 0-1.646l-5.108-4.251a.95.95 0 0 0-1.525.846v1.7c-3.312 0-6 2.979-6 6.654v1.329a.7.7 0 0 0 1.344.353 5.174 5.174 0 0 1 4.652-3.191l.004-.003Z"
                              />
                            </svg>
                          </div>
                          <div class="stat-title">Annual Transfers Left</div>
                          <div class="stat-value text-primary">
                            {{ license.desktop_license.transfers_left }}
                          </div>
                        </div>
                        <div class="stat">
                          <div class="stat-figure text-accent">
                            <svg
                              class="h-[48px] w-[48px]"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                                d="M12 8v4l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                              />
                            </svg>
                          </div>
                          <div class="stat-title">License Duration</div>
                          <div class="stat-value text-accent">
                            {{ getProductDuration(license) }}
                          </div>
                        </div>
                      </div>
                    </div>
                    @if (showRenewWarning) {
                      <div role="alert" class="alert alert-warning mt-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          class="h-6 w-6 shrink-0 stroke-base-300"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          ></path>
                        </svg>
                        <span
                          >You have selected to not auto-renew this license.
                          This will cause the license to expire on the
                          expiration date, and it will not be included in the
                          next invoice.</span
                        >
                        <div>
                          <button
                            class="btn btn-neutral btn-sm"
                            (click)="showRenewWarning = false"
                          >
                            Cancel
                          </button>
                        </div>
                        <div>
                          <button
                            class="btn btn-error btn-sm"
                            (click)="disableLicenseRenewal(license)"
                          >
                            Disable Auto Renew
                          </button>
                        </div>
                      </div>
                    }
                  </td>
                </tr>
              }
            } @empty {
              <tr>
                <td colspan="6" class="text-center">
                  <div class="flex justify-center">
                    <div>No active licenses</div>
                  </div>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    </div>
  }
</div>

@if (saveSuccess) {
  <app-toast-timer
    status="success"
    message="License saved successfully"
    (clear)="saveSuccess = false"
  ></app-toast-timer>
}
@if (saveError) {
  <app-toast-timer
    status="error"
    message="Error saving license"
    (clear)="saveError = false"
  ></app-toast-timer>
}
