<div class="flex w-full justify-center">
  @if (errorFetchingInvoice) {
    <div class="text-error">
      <p>{{ errorFetchingInvoice }}</p>
    </div>
  } @else if (errorFetchingPaymentDetails) {
    <div class="text-error">
      <p>{{ errorFetchingPaymentDetails }}</p>
    </div>
  } @else if (loadingInvoice || loadingPaymentDetails) {
    <div class="text-center">
      <div class="loading loading-spinner">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  } @else if (invoice && paymentDetails) {
    <div
      class="mt-5 w-full max-w-3xl rounded-md border-2 border-gray-600 p-4 dark:border-gray-300"
    >
      <div>
        <p class="mb-4 text-4xl">Receipt</p>
        <p class="mb-2">
          <span class="inline-block min-w-36">Completed on:</span>
          {{ paymentDetails.desktop_payment.created_at | date: 'short' }}
        </p>
        <p class="mb-2">
          <span class="inline-block min-w-36">Payment method:</span>
          {{ paymentDetails.payment_method }}
        </p>
        <p class="mb-4">
          <span class="inline-block min-w-36">Amount paid:</span>
          {{ invoice.desktop_invoice.total_amount | currency: 'USD' }}
        </p>
      </div>
      <div class="overflow-x-auto">
        <table class="table table-zebra">
          <thead class="text-sm">
            <tr>
              <th class="font-medium">Product</th>
              <th class="font-medium">Price</th>
              <th class="font-medium">Quantity</th>
              <th class="font-medium">Duration</th>
            </tr>
          </thead>
          <tbody>
            @for (el of groupedLicenses; track el) {
              <tr>
                <td>{{ el.license.product.title }}</td>
                <td>
                  {{ getPrice(el.license) | currency: 'USD' }}
                </td>
                <td>{{ el.count }}</td>
                <td>
                  {{ getDuration(el.license.product_variant.duration_months) }}
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
      <div class="ml-auto mt-4 w-64">
        <table class="table">
          <tbody>
            <tr>
              <td>Subtotal:</td>
              <td>
                {{ invoice.desktop_invoice.base_amount | currency: 'USD' }}
              </td>
            </tr>
            <tr>
              <td>Tax:</td>
              <td class="relative">
                {{ invoice.desktop_invoice.tax_amount | currency: 'USD' }}
              </td>
            </tr>
            <tr>
              <td>Total:</td>
              <td>
                {{ invoice.desktop_invoice.total_amount | currency: 'USD' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mt-8 mb-4 flex flex-col items-center">
        <button class="btn btn-primary" [routerLink]="'/invoices'">
          View Invoices
        </button>
        <button class="btn btn-primary mt-4" [routerLink]="'/'">
          Go to Dashboard
        </button>
      </div>
    </div>
  }
</div>
