import { Component } from '@angular/core';

@Component({
  selector: 'app-google-icon',
  templateUrl: './google-icon.component.html',
  styleUrl: './google-icon.component.scss'
})
export class GoogleIconComponent {

}
