import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FirebaseService } from './firebase.service';
import { mergeMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export type CassRequest = {
  company: string;
  address: string;
  city: string;
  state: string;
  zip: string;
};

export type CassResponse = {
  company: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  dpva: string;
};

@Injectable({
  providedIn: 'root',
})
export class CassService {
  private validateAddressUrl = environment.serverUrl + '/cass/validate';

  constructor(
    private http: HttpClient,
    private firebaseService: FirebaseService
  ) {}

  validateAddress(request: CassRequest) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.post<CassResponse>(this.validateAddressUrl, request, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      })
    );
  }
}

