<div class="flex justify-center p-5">
  @if (errorFetchingInvoice) {
    <div class="text-error">
      <p>{{ errorFetchingInvoice }}</p>
    </div>
  } @else if (loadingInvoice) {
    <div class="w-full text-center">
      <div class="loading loading-spinner loading-lg"></div>
    </div>
  } @else if (invoice) {
    <div class="w-full max-w-3xl">
      <div class="flex justify-between">
        <div>
          <p class="mb-2">
            Invoice created on:
            {{ invoice.desktop_invoice.created_at | date: 'short' }}
          </p>
          <p class="mb-4 mt-2">Due on: {{ invoice.due_by | date: 'short' }}</p>
        </div>
      </div>
      <div class="overflow-x-auto">
        <table class="table table-zebra">
          <thead class="text-sm">
            <tr>
              <th class="font-medium">Product</th>
              <th class="font-medium">Price</th>
              <th class="font-medium">New Expiration</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            @for (el of licenseList; track el) {
              <tr
                [ngClass]="{ 'line-through': el.action?.action === 'remove' }"
              >
                <td>{{ el.details.license_with_product.product.title }}</td>
                <td>{{ el.details.total_amount | currency: 'USD' }}</td>
                <td>
                  {{ el.details.next_expiration | date: 'shortDate' : 'UTC' }}
                </td>
                <td>
                  @if (el.action?.action !== 'remove') {
                    <button
                      class="btn btn-error btn-sm"
                      (click)="removeLicense(el)"
                    >
                      Remove
                    </button>
                  }
                  @if (el.action?.action === 'remove') {
                    <button
                      class="btn btn-warning btn-sm"
                      (click)="undoRemoveLicense(el)"
                    >
                      Undo
                    </button>
                  }
                </td>
              </tr>
            }

            @for (el of notRenewingList; track el) {
              <tr [ngClass]="{ 'line-through': el.action?.action !== 'add' }">
                <td>{{ el.license.product.title }}</td>
                <td>
                  {{ el.license.product_variant.price | currency: 'USD' }}
                </td>
                <td>N/A</td>
                <td>
                  @if (el.action?.action !== 'add') {
                    <button
                      class="btn btn-warning btn-sm"
                      (click)="reAddLicense(el)"
                    >
                      Undo
                    </button>
                  }
                  @if (el.action?.action === 'add') {
                    <button
                      class="btn btn-error btn-sm"
                      (click)="undoAddLicense(el)"
                    >
                      Remove
                    </button>
                  }
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
      <div class="mt-16 w-full space-x-8 text-center">
        <button
          class="btn btn-primary"
          (click)="saveChanges(invoice)"
          [disabled]="!hasChanges || savingChanges"
        >
          Save
        </button>
        <button class="btn btn-error" (click)="returnToInvoice(invoice)">
          Back
        </button>
      </div>
      @if (savingChanges) {
        <div class="mt-8 w-full text-center">
          <div class="loading loading-lg"></div>
        </div>
      }
      @if (changesSaved) {
        <div class="mt-8 w-full text-center text-success">
          Changes saved successfully!
        </div>
      }
      @if (errorSavingChanges) {
        <div class="mt-8 w-full text-center text-error">
          {{ errorSavingChanges }}
        </div>
      }
    </div>
  }
</div>
